// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations.organization_profiles

import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.organizations.organization_profiles.LicenseAgreementTypeEnum
import youversion.red.organizations.api.model.organizations.organization_profiles.LicenseStatusEnum

@Serializable
data class PostProfileLicense(
  @SerialName("status")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.LicenseStatusEnumSerializer::class)
  @JvmField
  val status: LicenseStatusEnum? = null,
  @SerialName("type")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.LicenseAgreementTypeEnumSerializer::class)
  @JvmField
  val type: LicenseAgreementTypeEnum? = null
) : red.Serializable {
  init {
    freeze()
  }
}
