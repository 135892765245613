// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events.event_content

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent

@Serializable
@SerialName("admin.events.event_content.AdminImageContent")
data class AdminImageContent(
  /**
   * The ID of the image
   */
  @SerialName("image_id")
  @ProtoNumber(30)
  val imageId: Long = 0L,
  /**
   * The alt text of the image
   */
  @SerialName("alt_text")
  @ProtoNumber(31)
  val altText: String? = null,
  /**
   * The height of the image
   */
  @SerialName("height")
  @ProtoNumber(33)
  val height: Int = 0,
  /**
   * The width of the image
   */
  @SerialName("width")
  @ProtoNumber(34)
  val width: Int = 0,
  /**
   * The image proxy pre-pended url to the image from the relevant GCS bucket for the asset.
   */
  @SerialName("url")
  @ProtoNumber(35)
  val url: String? = null,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Long? = null
) : AdminBaseContent, red.Serializable {
  init {
    freeze()
  }
}
