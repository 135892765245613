// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ServiceTime(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String = "",
  /**
   * This is the time of day that services happen on.
   */
  @SerialName("time_of_day")
  @ProtoNumber(2)
  @JvmField
  val timeOfDay: String = "",
  /**
   * This is the day of the week services happen on. This is an integer because start of week takes
   * days into integers.
   */
  @SerialName("day_of_week")
  @ProtoNumber(3)
  @JvmField
  val dayOfWeek: Int = 0,
  @SerialName("description")
  @ProtoNumber(4)
  @JvmField
  val description: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
