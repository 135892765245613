// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminServiceTime(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * This is the time of day the organization has a service.
   */
  @SerialName("time_of_day")
  @ProtoNumber(2)
  @JvmField
  val timeOfDay: String? = null,
  /**
   * This is the day of the week services happen on. This is an integer because start of week takes
   * days into integers.
   */
  @SerialName("day_of_week")
  @ProtoNumber(3)
  @JvmField
  val dayOfWeek: Int? = null,
  @SerialName("i18ns")
  @ProtoNumber(4)
  @JvmField
  val i18ns: List<ServiceTimeI18n>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
