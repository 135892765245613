// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
@SerialName("organizations.organization_profiles.AdminGivingProfile")
data class AdminGivingProfile(
  @SerialName("status")
  @ProtoNumber(11)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  /**
   * Mission statement for ministry that is displayed on email communication.
   */
  @SerialName("ministry_mission")
  @ProtoNumber(12)
  val ministryMission: String? = null,
  /**
   * The email address that should be contacted when a financial support need arises.
   */
  @SerialName("giving_contact_email")
  @ProtoNumber(13)
  val givingContactEmail: String? = null,
  /**
   * The phone number that should be contacted when a financial support need arises.
   */
  @SerialName("giving_contact_phone")
  @ProtoNumber(14)
  val givingContactPhone: String? = null,
  /**
   * The signature line that should be displayed at the bottom of any email communication (before
   * church name.)
   */
  @SerialName("signature")
  @ProtoNumber(15)
  val signature: String? = null,
  /**
   * Which Stripe account should be used for donations to this organization.
   */
  @SerialName("stripe_connect_id")
  @ProtoNumber(16)
  val stripeConnectId: String? = null,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: String = "",
  @SerialName("organization_id")
  @ProtoNumber(2)
  override val organizationId: String = ""
) : BaseProfile, red.Serializable {
  init {
    freeze()
  }
}
