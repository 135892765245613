// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.reader

import kotlin.Int
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("reader.ReaderThemeSetEvent")
data class ReaderThemeSetEvent(
  /**
   * Reader theme selected
   */
  @SerialName("theme_id")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.dataman.api.model.reader.ReaderThemeSerializer::class)
  val themeId: ReaderTheme? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(themeId: ReaderTheme? = null, createdmicros: Int? = null) : this(themeId,
      red.platform.now(), createdmicros)
}
