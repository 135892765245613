// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.common

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class UtmContent(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  VERSE("verse", 1),

  IMAGE("image", 2),

  PLAN("plan", 3),

  PLAN_DAY("plan-day", 4),

  PLAN_COLLECTION("plan-collection", 5),

  PODCAST("podcast", 6),

  VIDEO("video", 7),

  VIDEO_COLLECTION("video-collection", 8),

  TEACHING_CLIP("teaching-clip", 9),

  STORY_CLIP("story-clip", 10);
}

internal class UtmContentSerializer : KSerializer<UtmContent> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.movies.api.model.common.UtmContent")

  override fun serialize(encoder: Encoder, value: UtmContent) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): UtmContent {
    if (decoder is JsonDecoder) {
      return UtmContent.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> UtmContent.UNSUPPORTED_PLACEHOLDER
      1 -> UtmContent.VERSE
      2 -> UtmContent.IMAGE
      3 -> UtmContent.PLAN
      4 -> UtmContent.PLAN_DAY
      5 -> UtmContent.PLAN_COLLECTION
      6 -> UtmContent.PODCAST
      7 -> UtmContent.VIDEO
      8 -> UtmContent.VIDEO_COLLECTION
      9 -> UtmContent.TEACHING_CLIP
      10 -> UtmContent.STORY_CLIP
      else -> UtmContent.UNSUPPORTED_PLACEHOLDER
    }
  }
}
