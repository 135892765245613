// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api.model.progress

import kotlin.Int
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ProgressPost(
  /**
   * Achievement unique identifier.
   */
  @SerialName("achievement_id")
  @ProtoNumber(1)
  @JvmField
  val achievementId: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
