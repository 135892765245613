// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.podcasts

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent
import youversion.red.dataman.api.model.PodcastPlayerControlType

@Serializable
@SerialName("podcasts.PodcastEpisodeInteraction")
data class PodcastEpisodeInteraction(
  /**
   * The open-player-control represents lottie button, the player-view represents the podcast audio
   * player, the quick-play-control represents the pill button on each episode, and the audio-widget
   * represnts the system audio player controls
   */
  @SerialName("player_control_type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.dataman.api.model.PodcastPlayerControlTypeSerializer::class)
  val playerControlType: PodcastPlayerControlType? = null,
  /**
   * The episode title 
   */
  @SerialName("episode_author")
  @ProtoNumber(2)
  val episodeAuthor: String? = null,
  /**
   * The episode title 
   */
  @SerialName("episode_title")
  @ProtoNumber(3)
  val episodeTitle: String? = null,
  /**
   * The unique id of the podcast episode the user is interacting with
   */
  @SerialName("episode_id")
  @ProtoNumber(4)
  val episodeId: Int? = null,
  /**
   * App interface language
   */
  @SerialName("app_language")
  @ProtoNumber(5)
  val appLanguage: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    playerControlType: PodcastPlayerControlType? = null,
    episodeAuthor: String? = null,
    episodeTitle: String? = null,
    episodeId: Int? = null,
    appLanguage: String? = null,
    createdmicros: Int? = null
  ) : this(playerControlType, episodeAuthor, episodeTitle, episodeId, appLanguage,
      red.platform.now(), createdmicros)
}
