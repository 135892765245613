// Auto-Generated from OpenAPI Spec
package youversion.red.plans.api.model.events

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class PlanDayCompleteFireBase(
  @SerialName("plan_id")
  @ProtoNumber(2)
  val planId: Int? = null,
  @SerialName("day")
  @ProtoNumber(3)
  val day: Int? = null,
  @SerialName("language_tag")
  @ProtoNumber(4)
  val languageTag: String? = null,
  /**
   * my desc
   */
  @SerialName("completion_date_timestamp")
  @ProtoNumber(5)
  val completionDateTimestamp: Long? = null
) : Event(), red.Serializable {
  override val key: String = "plan_day_complete"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    planId?.let { bundle.putInt("plan_id", planId) }
    day?.let { bundle.putInt("day", day) }
    languageTag?.let { bundle.putString("language_tag", languageTag) }
    completionDateTimestamp?.let { bundle.putLong("completion_date_timestamp",
        completionDateTimestamp) }
    return bundle
  }
}
