// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PastorPatchMutation(
  /**
   * The name of the pastor.
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String? = null,
  /**
   * The title of the pastor.
   */
  @SerialName("title")
  @ProtoNumber(4)
  @JvmField
  val title: String? = null,
  /**
   * The image id of the image that was set.
   */
  @SerialName("image_id")
  @ProtoNumber(5)
  @JvmField
  val imageId: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
