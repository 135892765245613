// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.giving

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("giving.GivingClickEvent")
data class GivingClickEvent(
  /**
   * Give now button pressed
   */
  @SerialName("give_button")
  @ProtoNumber(1)
  val giveButton: Boolean? = null,
  /**
   * Payment Method pressed
   */
  @SerialName("payment_method")
  @ProtoNumber(2)
  val paymentMethod: Boolean? = null,
  /**
   * Currency pressed
   */
  @SerialName("currency")
  @ProtoNumber(3)
  val currency: Boolean? = null,
  /**
   * Help Button pressed
   */
  @SerialName("help_button")
  @ProtoNumber(4)
  val helpButton: Boolean? = null,
  /**
   * Privacy Statement Button pressed
   */
  @SerialName("privacy_statement_button")
  @ProtoNumber(5)
  val privacyStatementButton: Boolean? = null,
  /**
   * Referral source of the Giving Form screen
   */
  @SerialName("referrer")
  @ProtoNumber(6)
  @Serializable(with=youversion.red.dataman.api.model.giving.GivingFormReferralSourceSerializer::class)
  val referrer: GivingFormReferralSource? = null,
  /**
   * Suggested Giving Amount Selected
   */
  @SerialName("suggested_amount")
  @ProtoNumber(7)
  val suggestedAmount: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    giveButton: Boolean? = null,
    paymentMethod: Boolean? = null,
    currency: Boolean? = null,
    helpButton: Boolean? = null,
    privacyStatementButton: Boolean? = null,
    referrer: GivingFormReferralSource? = null,
    suggestedAmount: String? = null,
    createdmicros: Int? = null
  ) : this(giveButton, paymentMethod, currency, helpButton, privacyStatementButton, referrer,
      suggestedAmount, red.platform.now(), createdmicros)
}
