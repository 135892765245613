// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object GivingMikeyDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<GivingMikeyDeepLinkListener?> = AtomicReference(null)

  var listener: GivingMikeyDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/give") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val fund = queries["fund"]?.firstOrNull()
        val amount = queries["amount"]?.firstOrNull()?.toDoubleOrNull()
        val frequency = queries["frequency"]?.firstOrNull()
        listener.onGive(context, fund, amount, frequency)
    }
    register("/give/canceled") { context, _, _ -> 
        listener.onGiftCanceled(context)
    }
    register("/give/success") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val amount = queries["amount"]?.firstOrNull()?.toDoubleOrNull()
        val token = queries["token"]?.firstOrNull()
        listener.onGiftSuccess(context, amount, token)
    }
    register("/give/history") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val year = queries["year"]?.firstOrNull()?.toIntOrNull()
        listener.onHistory(context, year)
    }
    register("/give/scheduled") { context, _, _ -> 
        listener.onScheduled(context)
    }
    register("/give/landing") { context, _, _ -> 
        listener.onGivingLanding(context)
    }
    register("/give/cause") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val causeId = queries["cause_id"]?.firstOrNull()
        listener.onGivingCause(context, causeId)
    }
    register("/give/$STRING_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val amount = queries["amount"]?.firstOrNull()?.toDoubleOrNull()
        val organizationId = values[1]
        listener.onOrganizationGive(context, organizationId, amount)
    }
    freeze()
  }
}
