package youversion.red.metrics.aggregates.aggregators

import red.resolvers.metrics.model.IMetric
import red.resolvers.metrics.model.RequestManagerUrlMetric
import youversion.red.dataman.api.model.AnalyticsEvent
import youversion.red.dataman.api.model.metrics.RequestManagerUrlMetricEvent
import youversion.red.metrics.aggregates.IMetricAggregator

object RequestManagerUrlMetricAggregator : IMetricAggregator {

    @Suppress("UNCHECKED_CAST")
    override fun aggregate(
        metrics: List<IMetric>,
        collectedSessionId: String
    ): List<AnalyticsEvent> {
        return metrics.aggregate(
            groupByKeySelector = { metric: RequestManagerUrlMetric ->
                "${metric.url}-${metric.requestMethod}"
            } as (IMetric) -> String,
            foldInitialValue = RequestManagerUrlMetric(),
            foldOperation = { aggregate: RequestManagerUrlMetric, element: RequestManagerUrlMetric ->
                element.copy(
                    requestBodyBytes = aggregate.requestBodyBytes + element.requestBodyBytes,
                    responseBodyBytes = aggregate.responseBodyBytes + element.responseBodyBytes,
                    responseTimeMillis = aggregate.responseTimeMillis + element.responseTimeMillis
                )
            } as (IMetric, IMetric) -> IMetric,
            aggregateMapOperation = { metric: RequestManagerUrlMetric, count: Int ->
                RequestManagerUrlMetricEvent(
                    url = metric.url,
                    requestMethod = metric.requestMethod,
                    averageRequestBodyBytes = metric.requestBodyBytes / count,
                    averageResponseBodyBytes = metric.responseBodyBytes / count,
                    averageResponseTimeMillis = metric.responseTimeMillis / count,
                    count = count,
                    collectedSessionId = collectedSessionId
                )
            } as (IMetric, Int) -> AnalyticsEvent
        )
    }
}
