// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class ProfileType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ADDRESS("address", 1),

  BIBLE("bible", 2),

  CHURCH("church", 3),

  CONTENT("content", 4),

  GIVING("giving", 5);
}

internal class ProfileTypeSerializer : KSerializer<ProfileType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.ProfileType")

  override fun serialize(encoder: Encoder, value: ProfileType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ProfileType {
    if (decoder is JsonDecoder) {
      return ProfileType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ProfileType.UNSUPPORTED_PLACEHOLDER
      1 -> ProfileType.ADDRESS
      2 -> ProfileType.BIBLE
      3 -> ProfileType.CHURCH
      4 -> ProfileType.CONTENT
      5 -> ProfileType.GIVING
      else -> ProfileType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
