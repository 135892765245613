// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.saves

import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Save(
  @SerialName("content_id")
  @ProtoNumber(1)
  @JvmField
  val contentId: Long? = null,
  @SerialName("comment")
  @ProtoNumber(2)
  @JvmField
  val comment: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
