// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.metrics

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("metrics.RequestManagerCumulativeResponseMetricEvent")
data class RequestManagerCumulativeResponseMetricEvent(
  /**
   * The total response body size in bytes
   */
  @SerialName("total_response_body_bytes")
  @ProtoNumber(1)
  val totalResponseBodyBytes: Int? = null,
  /**
   * The time when the earliest metric was collected
   */
  @SerialName("earliest_metric_collected_time")
  @ProtoNumber(2)
  val earliestMetricCollectedTime: @Serializable(with=red.platform.DateSerializer::class) Date? =
      null,
  /**
   * The time when the latest metric was collected
   */
  @SerialName("latest_metric_collected_time")
  @ProtoNumber(3)
  val latestMetricCollectedTime: @Serializable(with=red.platform.DateSerializer::class) Date? =
      null,
  /**
   * The number of metrics collected
   */
  @SerialName("count")
  @ProtoNumber(4)
  val count: Int? = null,
  /**
   * The session ID that the metrics are collected
   */
  @SerialName("collected_session_id")
  @ProtoNumber(5)
  val collectedSessionId: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    totalResponseBodyBytes: Int? = null,
    earliestMetricCollectedTime: @Serializable(with=red.platform.DateSerializer::class) Date? =
        null,
    latestMetricCollectedTime: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
    count: Int? = null,
    collectedSessionId: String? = null,
    createdmicros: Int? = null
  ) : this(totalResponseBodyBytes, earliestMetricCollectedTime, latestMetricCollectedTime, count,
      collectedSessionId, red.platform.now(), createdmicros)
}
