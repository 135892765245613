// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.events.event_contents

import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import red.Serializable
import red.platform.threads.freeze

interface BaseContent : Serializable {
  val id: Long

  val comment: String?

  companion object {
    @Suppress("unchecked_cast")
    fun serializer(): KSerializer<BaseContent> = PolymorphicSerializer(BaseContent::class)
  }
}
