// Auto-Generated from OpenAPI Spec
package youversion.red.search.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object SearchDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<SearchDeepLinkListener?> = AtomicReference(null)

  var listener: SearchDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/explore") { context, _, _ -> 
        listener.onExplore(context)
    }
    register("/verse-of-the-day/$STRING_EXPRESSION/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val usfm = values[1]
        val imageid = values[2].toIntOrNull() ?: return@register false
        listener.onVotdImage(context, usfm, imageid)
    }
    register("/search/bible") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val query = queries["query"]?.firstOrNull()
        listener.onSearch(context, query)
    }
    freeze()
  }
}
