// Auto-Generated from OpenAPI Spec
package youversion.red.banner.model.userstate

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Namespace(
    @SerialName("name")
    @ProtoNumber(1)
    @JvmField
    val name: String = "",
    @SerialName("description")
    @ProtoNumber(2)
    @JvmField
    val description: String? = null
) : red.Serializable {
    init {
        freeze()
    }
}
