// Auto-Generated from OpenAPI Spec
package youversion.red.animations.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class AnimationsPathProviderName(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  LOTTIES("lotties", 1),

  RIVES("rives", 2);
}

internal class AnimationsPathProviderNameSerializer : KSerializer<AnimationsPathProviderName> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.animations.api.model.AnimationsPathProviderName")

  override fun serialize(encoder: Encoder, value: AnimationsPathProviderName) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): AnimationsPathProviderName {
    if (decoder is JsonDecoder) {
      return AnimationsPathProviderName.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> AnimationsPathProviderName.UNSUPPORTED_PLACEHOLDER
      1 -> AnimationsPathProviderName.LOTTIES
      2 -> AnimationsPathProviderName.RIVES
      else -> AnimationsPathProviderName.UNSUPPORTED_PLACEHOLDER
    }
  }
}
