// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class GivingSubscriptionPatch(
  /**
   * Value to be charged in string form
   */
  @SerialName("amount")
  @ProtoNumber(1)
  @JvmField
  val amount: String? = null,
  /**
   * Unique ID of Currency code
   */
  @SerialName("currency_id")
  @ProtoNumber(2)
  @JvmField
  val currencyId: Int? = null,
  @SerialName("frequency_id")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.givingmikey.api.model.FrequencyTypeSerializer::class)
  @JvmField
  val frequencyId: FrequencyType? = null,
  /**
   * Unique ID of Fund
   */
  @SerialName("fund_id")
  @ProtoNumber(4)
  @JvmField
  val fundId: Int? = null,
  /**
   * Unique ID of the payment method that is used by this subscription.
   */
  @SerialName("payment_method_id")
  @ProtoNumber(5)
  @JvmField
  val paymentMethodId: Int? = null,
  /**
   * the next payment date of this subscription in ISO-8601 format
   */
  @SerialName("next_payment_date")
  @ProtoNumber(6)
  @JvmField
  val nextPaymentDate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The IANA timezone name of the timezone in which the next_payment_date applies
   */
  @SerialName("payment_date_tz")
  @ProtoNumber(7)
  @JvmField
  val paymentDateTz: String? = null,
  @SerialName("transaction_source")
  @ProtoNumber(8)
  @Serializable(with=youversion.red.givingmikey.api.model.GivingTransactionSourceTypeSerializer::class)
  @JvmField
  val transactionSource: GivingTransactionSourceType? = null
) : red.Serializable {
  init {
    freeze()
  }
}
