package red.module

import kotlin.Unit
import red.module.ModuleRegistry

public class RedRedFrameworkModuleBuilder : IModuleBuilder {
  public override fun build(): Unit {
    ModuleRegistry.register(red.module.RedCoreModule)
    ModuleRegistry.register(red.module.RedAnalyticsModule)
    ModuleRegistry.register(red.module.RedBlueModule)
    ModuleRegistry.register(red.module.RedGeoipModule)
    ModuleRegistry.register(red.module.RedInstallationModule)
    ModuleRegistry.register(red.module.RedBlueSharedModule)
    ModuleRegistry.register(red.module.RedAnalyticsDatamanModule)
    ModuleRegistry.register(red.module.RedLoginModule)
    ModuleRegistry.register(red.module.RedUsersModule)
    ModuleRegistry.register(red.module.RedCoreGoogleModule)
    ModuleRegistry.register(red.module.RedBibleModule)
    ModuleRegistry.register(red.module.RedBibleSharedModule)
    ModuleRegistry.register(red.module.RedImagesSharedModule)
    ModuleRegistry.register(red.module.RedBibleReferenceModule)
    ModuleRegistry.register(red.module.RedVersificationSharedModule)
    ModuleRegistry.register(red.module.RedMomentsSharedModule)
    ModuleRegistry.register(red.module.RedBannerModule)
    ModuleRegistry.register(red.module.RedDownloadsModule)
    ModuleRegistry.register(red.module.RedSearchModule)
    ModuleRegistry.register(red.module.RedPlansModule)
    ModuleRegistry.register(red.module.RedVersificationModule)
    ModuleRegistry.register(red.module.RedMoviesModule)
    ModuleRegistry.register(red.module.RedImagesModule)
    ModuleRegistry.register(red.module.RedChurchesSharedModule)
    ModuleRegistry.register(red.module.RedMomentsModule)
    ModuleRegistry.register(red.module.RedAchievementsModule)
    ModuleRegistry.register(red.module.RedLocalesModule)
    ModuleRegistry.register(red.module.RedGuidedPrayerModule)
    ModuleRegistry.register(red.module.RedStoriesSharedModule)
    ModuleRegistry.register(red.module.RedPrayerModule)
    ModuleRegistry.register(red.module.RedStoriesModule)
    ModuleRegistry.register(red.module.RedGivingModule)
    ModuleRegistry.register(red.module.RedSettingsModule)
    ModuleRegistry.register(red.module.RedAnimationsModule)
    ModuleRegistry.register(red.module.RedDiscoverModule)
    ModuleRegistry.register(red.module.RedChurchesModule)
    ModuleRegistry.register(red.module.RedMetricsModule)
    ModuleRegistry.register(red.module.RedRedFrameworkModule)

  }
}
