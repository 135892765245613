// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.giving

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Referral source of the Giving Form screen
 */
enum class GivingFormReferralSource(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  MORE_SCREEN("more_screen", 1),

  GIVING_LANDING("giving_landing", 2),

  GIVING_CAUSES("giving_causes", 3),

  SCHEDULED_GIVING("scheduled_giving", 4),

  DEEP_LINK("deep_link", 5);
}

internal class GivingFormReferralSourceSerializer : KSerializer<GivingFormReferralSource> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.givingmikey.api.model.giving.GivingFormReferralSource")

  override fun serialize(encoder: Encoder, value: GivingFormReferralSource) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GivingFormReferralSource {
    if (decoder is JsonDecoder) {
      return GivingFormReferralSource.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GivingFormReferralSource.UNKNOWN
      1 -> GivingFormReferralSource.MORE_SCREEN
      2 -> GivingFormReferralSource.GIVING_LANDING
      3 -> GivingFormReferralSource.GIVING_CAUSES
      4 -> GivingFormReferralSource.SCHEDULED_GIVING
      5 -> GivingFormReferralSource.DEEP_LINK
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
