// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Boolean
import kotlin.Int
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PostAction(
  /**
   * Type of action
   */
  @SerialName("type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.organizations.api.model.organizations.PostActionKindSerializer::class)
  @JvmField
  val type: PostActionKind =
      youversion.red.organizations.api.model.organizations.PostActionKind.UNSUPPORTED_PLACEHOLDER,
  /**
   * Value of action
   */
  @SerialName("taken")
  @ProtoNumber(2)
  @JvmField
  val taken: Boolean = false,
  /**
   * Count of actions
   */
  @SerialName("count")
  @ProtoNumber(3)
  @JvmField
  val count: Int = 0
) : red.Serializable {
  init {
    freeze()
  }
}
