// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.accounts

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Account(
  /**
   * The id of the account
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * The id of the user
   */
  @SerialName("user_id")
  @ProtoNumber(2)
  @JvmField
  val userId: Int? = null,
  /**
   * The status of the account
   */
  @SerialName("status")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.users.api.model.accounts.AccountStatusSerializer::class)
  @JvmField
  val status: AccountStatus? = null,
  /**
   * The email the invite is going too.
   */
  @SerialName("invite_email")
  @ProtoNumber(4)
  @JvmField
  val inviteEmail: String? = null,
  /**
   * This is the date when the invite will expire
   */
  @SerialName("invite_expire_dt")
  @ProtoNumber(5)
  @JvmField
  val inviteExpireDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The base64/url encoded email or user id AuthZ principal string
   */
  @SerialName("principal")
  @ProtoNumber(16)
  @JvmField
  val principal: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
