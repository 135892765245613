// Auto-Generated from OpenAPI Spec
package youversion.red.movies.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object MoviesDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<MoviesDeepLinkListener?> = AtomicReference(null)

  var listener: MoviesDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/$STRING_EXPRESSION/videos/$INTEGER_EXPRESSION-$STRING_EXPRESSION") { context, parts,
        url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val language = values[1]
        val id = values[2].toIntOrNull() ?: return@register false
        val slug = values[3]
        listener.onVideoWithSlug(context, language, id, slug, collection)
    }
    register("/$STRING_EXPRESSION/videos/$INTEGER_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val orientation = queries["orientation"]?.firstOrNull()?.let { enumValue ->
        youversion.red.movies.api.model.videos.OrientationType.values().firstOrNull {
        it.serialName.equals(enumValue, ignoreCase = true) } }
        val utmContent = queries["utm_content"]?.firstOrNull()?.let { enumValue ->
        youversion.red.movies.api.model.common.UtmContent.values().firstOrNull {
        it.serialName.equals(enumValue, ignoreCase = true) } }
        val utmMedium = queries["utm_medium"]?.firstOrNull()?.let { enumValue ->
        youversion.red.movies.api.model.common.UtmMedium.values().firstOrNull {
        it.serialName.equals(enumValue, ignoreCase = true) } }
        val utmSource = queries["utm_source"]?.firstOrNull()?.let { enumValue ->
        youversion.red.movies.api.model.common.UtmSource.values().firstOrNull {
        it.serialName.equals(enumValue, ignoreCase = true) } }
        val language = values[1]
        val id = values[2].toIntOrNull() ?: return@register false
        listener.onVideo(context, language, id, collection, orientation, utmContent, utmMedium,
        utmSource)
    }
    register("/videos/$INTEGER_EXPRESSION/series") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onVideoSeries(context, id, collection)
    }
    register("/videos/collections/$INTEGER_EXPRESSION-$STRING_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        val slug = values[2]
        listener.onCollectionWithSlug(context, id, slug, collection)
    }
    register("/videos/collections/$INTEGER_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onCollection(context, id, collection)
    }
    register("/videos/publishers/$INTEGER_EXPRESSION-$STRING_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        val slug = values[2]
        listener.onPublisherWithSlug(context, id, slug, collection)
    }
    register("/videos/publishers/$INTEGER_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onPublisher(context, id, collection)
    }
    freeze()
  }
}
