package youversion.red.blue.service

import red.lifecycle.AppLifecycleEventSource
import red.lifecycle.AppLifecycleListener
import red.lifecycle.AppLifecycleState
import red.platform.now
import red.platform.threads.AtomicReference
import red.platform.threads.set
import red.platform.toLong
import red.platform.toMillis
import red.tasks.CoroutineDispatchers.launch
import youversion.red.security.User
import youversion.red.security.UserListener

internal class BlueListener : AppLifecycleListener, UserListener {

    private val blueService by BlueService()
    private val hasUser = AtomicReference(false)
    private val lastStateChange = AtomicReference(0L)

    override fun onStateChanged(state: AppLifecycleState, source: AppLifecycleEventSource) {
        if (state == AppLifecycleState.Allocated || state == AppLifecycleState.Foreground) {
            val now = now().toMillis().toLong()
            // add a quick check to make sure successive calls to
            // onStateChanged doesn't cause update to be called
            if (now > lastStateChange.value + timeout) {
                lastStateChange.set(now)
                launch { blueService.update() }
            }
        }
    }

    override fun onSessionStarted() {
    }

    override fun onUserChanged(user: User?, error: Throwable?, fromInitialization: Boolean) {
        if (user != null || hasUser.value) {
            hasUser.set(user != null)
            launch { blueService.update() }
        }
    }

    companion object {

        private const val timeout = 120000L
    }
}
