// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class OrganizationPostView(
  /**
   * Id of the organization post that was viewed
   */
  @SerialName("post_id")
  @ProtoNumber(1)
  val postId: String = ""
) : Event(), red.Serializable {
  override val key: String = "OrganizationPostView"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putString("post_id", postId)
    return bundle
  }
}
