package youversion.red.plans.api

import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.plans.model.PlanDay

object PlansApi : AbstractApi(
    ApiDefaults(
        "plans",
        if (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO,
        if (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO,
        "4.0"
    )
) {

    suspend fun getPlanDay(planId: Int, planDay: Int, together: Boolean): PlanDay = execute(
        path = "/plans/$planId/days/$planDay",
        method = RequestMethods.GET,
        version = "4.0",
        authAllowed = false,
        authOptional = false,
        serializer = PlanDay.serializer(),
        queryString = red.platform.http.queryString {
            add("together", together)
        }
    ) ?: throw NullPointerException("Response not sent from server")
}
