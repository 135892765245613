package youversion.red.prayer.guided.model

import red.Serializable
import red.platform.threads.freeze
import youversion.red.bible.reference.BibleReference
import youversion.red.bible.reference.VersionId
import youversion.red.guidedprayer.api.model.GuidedPrayerModuleType
import youversion.red.prayer.model.Prayer
import youversion.red.stories.shared.IStoriesModule
import youversion.red.versification.VersificationTransaction

data class GuidedPrayerModule(
    val guideId: Int,
    override val id: Int,
    val dayId: Int,
    val type: GuidedPrayerModuleType? = null,
    override val title: String? = null,
    val header: String? = null,
    val usfm: String? = null,
    val usfmVersified: String? = null,
    val text: String? = null,
    val ctaText: String? = null,
    val ctaUrl: String? = null,
    val animationId: Int? = null,
    val prayer: Prayer? = null
) : IStoriesModule, Serializable {
    init {
        freeze()
    }

    internal suspend fun versify(
        versificationTransaction: VersificationTransaction,
        versionId: VersionId
    ): GuidedPrayerModule {
        val usfmVersified = usfm?.let {
            @Suppress("DEPRECATION")
            versificationTransaction.versification.versifier.versify(
                BibleReference(it, 1), versionId
            )[0].usfm
        } ?: return this
        return copy(usfmVersified = usfmVersified).freeze()
    }
}
