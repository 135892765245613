// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.content_partners

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * What kind of id is this?
 */
enum class Kind(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  PARTNER_PORTAL_ORGANIZATION("partner_portal_organization", 1),

  VIDEO_PUBLISHER("video_publisher", 2),

  YV_BIBLE_PUBLISHER("yv_bible_publisher", 3);
}

internal class KindSerializer : KSerializer<Kind> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.admin.content_partners.Kind")

  override fun serialize(encoder: Encoder, value: Kind) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): Kind {
    if (decoder is JsonDecoder) {
      return Kind.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> Kind.UNSUPPORTED_PLACEHOLDER
      1 -> Kind.PARTNER_PORTAL_ORGANIZATION
      2 -> Kind.VIDEO_PUBLISHER
      3 -> Kind.YV_BIBLE_PUBLISHER
      else -> Kind.UNSUPPORTED_PLACEHOLDER
    }
  }
}
