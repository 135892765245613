package youversion.red.login

enum class AuthViewType {
    SIGN_IN,
    SIGN_UP,
    SIGN_IN_FORM,
    SIGN_UP_FORM,
    GDPR,
    UPDATE_INFO,
    UPDATE_MASKED_EMAIL,
    UPDATE_EMAIL,
    PENDING_EMAIL,
    PENDING_PASSWORD_EMAIL,
    FORGOT_PASSWORD,
    LANDING
}
