// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.accounts

import kotlin.Boolean
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class InviteRequest(
  /**
   * This is the email you are sending an invite too.
   */
  @SerialName("email")
  @ProtoNumber(1)
  @JvmField
  val email: String? = null,
  /**
   * This is the date when the invite will expire. If null is provided then the invite will never
   * expire.
   */
  @SerialName("invite_expire_dt")
  @ProtoNumber(2)
  @JvmField
  val inviteExpireDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * Controls whether or not the API sends the invite url directly.
   */
  @SerialName("send_invite")
  @ProtoNumber(3)
  @JvmField
  val sendInvite: Boolean? = null,
  /**
   * list of roles to assign the invited account
   */
  @SerialName("roles")
  @ProtoNumber(4)
  @JvmField
  val roles: List<Role>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
