// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.events

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.stories.api.model.StoryModuleType
import youversion.red.stories.api.model.StoryReferrer
import youversion.red.stories.api.model.lessons.AgeGroupWithDefault

@Serializable
data class StoryModuleView(
  /**
   * Id of the lesson that was viewed
   */
  @SerialName("lesson_id")
  @ProtoNumber(1)
  val lessonId: Int = 0,
  @SerialName("type")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.stories.api.model.StoryModuleTypeSerializer::class)
  val type: StoryModuleType = youversion.red.stories.api.model.StoryModuleType.UNKNOWN,
  /**
   * Id of the module that was viewed
   */
  @SerialName("module_id")
  @ProtoNumber(3)
  val moduleId: Int = 0,
  /**
   * Did a share happen from this module
   */
  @SerialName("share")
  @ProtoNumber(4)
  val share: Boolean = false,
  /**
   * USFM of the module
   */
  @SerialName("reference")
  @ProtoNumber(5)
  val reference: String? = null,
  /**
   * Did user start a note
   */
  @SerialName("start_note")
  @ProtoNumber(6)
  val startNote: Boolean = false,
  /**
   * The ID of the video being played
   */
  @SerialName("video_id")
  @ProtoNumber(7)
  val videoId: Int? = null,
  /**
   * Rounded percent of total (0 to 100)
   */
  @SerialName("video_play_time")
  @ProtoNumber(8)
  val videoPlayTime: Int? = null,
  /**
   * Did user save the plan
   */
  @SerialName("plan_save")
  @ProtoNumber(9)
  val planSave: Boolean = false,
  /**
   * Did the user start the prayer
   */
  @SerialName("start_prayer")
  @ProtoNumber(10)
  val startPrayer: Boolean = false,
  /**
   * If the user exited the story from this module
   */
  @SerialName("exit_story")
  @ProtoNumber(11)
  val exitStory: Boolean = false,
  /**
   * Age group of this module
   */
  @SerialName("age_group")
  @ProtoNumber(12)
  @Serializable(with=youversion.red.stories.api.model.lessons.AgeGroupWithDefaultSerializer::class)
  val ageGroup: AgeGroupWithDefault? = null,
  @SerialName("referrer")
  @ProtoNumber(13)
  @Serializable(with=youversion.red.stories.api.model.StoryReferrerSerializer::class)
  val referrer: StoryReferrer? = null,
  /**
   * Duration that user viewed module in seconds
   */
  @SerialName("duration")
  @ProtoNumber(14)
  val duration: Int? = null
) : Event(), red.Serializable {
  override val key: String = "StoryModuleView"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putInt("lesson_id", lessonId)
    bundle.putString("type", type.serialName)
    bundle.putInt("module_id", moduleId)
    bundle.putBoolean("share", share)
    reference?.let { bundle.putString("reference", reference) }
    bundle.putBoolean("start_note", startNote)
    videoId?.let { bundle.putInt("video_id", videoId) }
    videoPlayTime?.let { bundle.putInt("video_play_time", videoPlayTime) }
    bundle.putBoolean("plan_save", planSave)
    bundle.putBoolean("start_prayer", startPrayer)
    bundle.putBoolean("exit_story", exitStory)
    ageGroup?.let { bundle.putString("age_group", ageGroup.serialName) }
    referrer?.let { bundle.putString("referrer", referrer.serialName) }
    duration?.let { bundle.putInt("duration", duration) }
    return bundle
  }
}
