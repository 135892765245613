// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The type of version.
 */
enum class BibleVersionType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  TEXT("text", 1),

  AUDIO("audio", 2);
}

internal class BibleVersionTypeSerializer : KSerializer<BibleVersionType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.BibleVersionType")

  override fun serialize(encoder: Encoder, value: BibleVersionType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): BibleVersionType {
    if (decoder is JsonDecoder) {
      return BibleVersionType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> BibleVersionType.UNSUPPORTED_PLACEHOLDER
      1 -> BibleVersionType.TEXT
      2 -> BibleVersionType.AUDIO
      else -> BibleVersionType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
