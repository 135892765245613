// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.v2

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The source that started the app
 */
enum class ForegroundSource(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  APP_ICON("app_icon", 1),

  NOTIFICATION("notification", 2),

  DEEPLINK("deeplink", 3),

  HANDOFF("handoff", 4),

  NOTIFICATION_SETTINGS("notification_settings", 5),

  SHORTCUT("shortcut", 6),

  WIDGET("widget", 7),

  LINK("link", 8);
}

internal class ForegroundSourceSerializer : KSerializer<ForegroundSource> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.v2.ForegroundSource")

  override fun serialize(encoder: Encoder, value: ForegroundSource) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ForegroundSource {
    if (decoder is JsonDecoder) {
      return ForegroundSource.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ForegroundSource.UNKNOWN
      1 -> ForegroundSource.APP_ICON
      2 -> ForegroundSource.NOTIFICATION
      3 -> ForegroundSource.DEEPLINK
      4 -> ForegroundSource.HANDOFF
      5 -> ForegroundSource.NOTIFICATION_SETTINGS
      6 -> ForegroundSource.SHORTCUT
      7 -> ForegroundSource.WIDGET
      8 -> ForegroundSource.LINK
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
