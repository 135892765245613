// Auto-Generated from OpenAPI Spec
package youversion.red.locales.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class BibleLocaleHeader(
  @SerialName("language_tag")
  @ProtoNumber(1)
  @JvmField
  val languageTag: String = "",
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  @SerialName("local_name")
  @ProtoNumber(3)
  @JvmField
  val localName: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
