// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.events.EventStatus

@Serializable
data class AdminEventCreated(
  /**
   * This is the ID of the event.
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Long? = null,
  /**
   * This is the ID of the organization.
   */
  @SerialName("organization_id")
  @ProtoNumber(2)
  @JvmField
  val organizationId: String? = null,
  /**
   * The status of the event.
   */
  @SerialName("status")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.events.api.model.events.EventStatusSerializer::class)
  @JvmField
  val status: EventStatus? = null,
  @SerialName("description")
  @ProtoNumber(4)
  @JvmField
  val description: String? = null,
  /**
   * This is the title of the event.
   */
  @SerialName("title")
  @ProtoNumber(5)
  @JvmField
  val title: String? = null,
  @SerialName("image")
  @ProtoNumber(6)
  @JvmField
  val image: AdminImage? = null
) : red.Serializable {
  init {
    freeze()
  }
}
