// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GivingCurrency(
  /**
   * Unique id for a Currency
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * Name of the Currency
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String? = null,
  /**
   * Short code for Currency
   */
  @SerialName("code")
  @ProtoNumber(3)
  @JvmField
  val code: String? = null,
  /**
   * Symbol that represents the currency.
   */
  @SerialName("symbol")
  @ProtoNumber(4)
  @JvmField
  val symbol: String? = null,
  /**
   * A character that marks the beginning or end of a unit of data.
   */
  @SerialName("delimiter")
  @ProtoNumber(5)
  @JvmField
  val delimiter: String? = null,
  /**
   * A character that separates two items.
   */
  @SerialName("separator")
  @ProtoNumber(6)
  @JvmField
  val separator: String? = null,
  /**
   * Numeric representation of decimal places a value contains.
   */
  @SerialName("precision")
  @ProtoNumber(7)
  @JvmField
  val precision: Int? = null,
  /**
   * Format for the currency value.
   */
  @SerialName("format")
  @ProtoNumber(8)
  @JvmField
  val format: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
