// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The type of content.
 */
enum class ContentType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  PLAN("PLAN", 1),

  VIDEO("VIDEO", 2);
}

internal class ContentTypeSerializer : KSerializer<ContentType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.ContentType")

  override fun serialize(encoder: Encoder, value: ContentType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ContentType {
    if (decoder is JsonDecoder) {
      return ContentType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ContentType.UNSUPPORTED_PLACEHOLDER
      1 -> ContentType.PLAN
      2 -> ContentType.VIDEO
      else -> ContentType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
