// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.Int
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Events(
  @SerialName("events")
  @ProtoNumber(1)
  @JvmField
  val events: List<AnalyticsEvent> = emptyList(),
  @SerialName("context")
  @ProtoNumber(2)
  @JvmField
  val context: AnalyticsContext,
  @SerialName("sent")
  @ProtoNumber(3)
  @JvmField
  val sent: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The microsecond component of the Sent timestamp
   */
  @SerialName("sentMicros")
  @ProtoNumber(4)
  @JvmField
  val sentmicros: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
