package youversion.red.prayer.tasks

import red.lifecycle.MutableLiveData
import red.platform.Log
import red.platform.http.RequestException
import red.platform.io.IOException
import red.platform.newDate
import red.platform.now
import red.platform.threads.newLockOwner
import red.platform.threads.sync
import red.tasks.assertNotMainThread
import youversion.red.prayer.api.PrayerApi
import youversion.red.prayer.api.model.CommentOrder
import youversion.red.prayer.ext.toDb
import youversion.red.prayer.model.Prayer
import youversion.red.prayer.model.PrayerComment
import youversion.red.prayer.model.PrayerReaction
import youversion.red.prayer.model.PrayerShare
import youversion.red.prayer.model.PrayerUpdate
import youversion.red.prayer.service.PrayerService
import youversion.red.prayer.service.PrayerStore
import youversion.red.prayer.service.PrayerStore.NEW_CLIENT_ID
import youversion.red.prayer.util.PrayerUtil.STATE_DELETED
import youversion.red.prayer.util.PrayerUtil.STATE_DIRTY
import youversion.red.prayer.util.PrayerUtil.STATE_NEW
import youversion.red.security.service.UsersService

object PrayerSync {

    private val prayerService by PrayerService()
    private val usersService by UsersService()

    suspend fun process(clientId: String? = null, serverId: Int? = null): Int? { // return server id
        assertNotMainThread()
        requireNetwork { return null }
        requireUser { return null }

        var prayerServerId: Int? = null
        val lockOwner = newLockOwner()
        changesLock.sync(lockOwner) {
            try {
                PrayerChangesSync.process(lockOwner)

                val p = processPrayer(clientId, serverId)
                requireNotNull(p) { "prayer was null while processing" }
                prayerServerId = p.serverId
                processUpdates(p)
                processReactions(p.clientId)
                processCommentIds(p.clientId)
                processComments(p.clientId)
                processShares(
                    prayerServerId ?: throw Exception("no server id for prayer: $p"),
                    p.clientId
                )
            } catch (e: Exception) {
                Log.e("red-prayer", "error syncing prayer id: $prayerServerId", e)
                @Suppress("DEPRECATION_ERROR")
                if (e !is IOException) {
                    throw e
                }
            }
        }

        return prayerServerId
    }

    private suspend fun processPrayer(clientId: String?, serverId: Int?): Prayer? {
        var prayerIsMine = true
        try {
            clientId
                ?: serverId
                ?: throw IllegalArgumentException("must pass either serverId or clientId in order to sync prayer")
            val oldPrayer =
                if (clientId != null)
                    PrayerStore.getPrayerSync(clientId)
                else
                    PrayerStore.getPrayerByServerIdSync(serverId!!)
            prayerIsMine = (oldPrayer?.userId ?: 0L) == usersService.getCurrentUser()?.id?.toLong()
            if (oldPrayer?.state?.and(STATE_DIRTY) == STATE_DIRTY && prayerIsMine) {
                Log.i(
                    "red-prayer",
                    "prayer is unsynced, we're not going to overwrite our changes from the server: $oldPrayer"
                )
                return oldPrayer
            }
            val prayer = PrayerApi.getPrayer(
                prayerId = serverId ?: oldPrayer?.serverId
                ?: throw NullPointerException("can't get prayer from api, server id was null: $oldPrayer")
            )
            requireNotNull(prayer) { "prayer was null from api: id: $serverId - $clientId" }
            val p = prayer.toDb(oldPrayer).copy(
                lastSync = newDate(),
                clientId = oldPrayer?.clientId ?: NEW_CLIENT_ID
            )
            PrayerStore.addPrayer(p)
            return p
        } catch (e: Exception) {
            Log.e("red-prayer", "error syncing prayer: $clientId, $serverId", e)
            when ((e as? RequestException)?.errorCode) {
                404, 403 -> {
                    if (prayerIsMine) {
                        Log.e(
                            "red-prayer",
                            "getting our prayer failed because server doesn't know about it, we'll create it"
                        )
                        PrayerStore.updatePrayerState(STATE_NEW or STATE_DIRTY, clientId ?: throw e)
                    } else {
                        Log.e(
                            "red-prayer",
                            "getting friend's prayer failed because server doesn't know about it, we'll delete it"
                        )
                        PrayerStore.updatePrayerState(STATE_DELETED or STATE_DIRTY, clientId ?: throw e)
                    }
                }
                else -> {
                    throw e
                }
            }
            throw e
        }
    }

    private suspend fun processUpdates(prayer: Prayer) {
        try {
            val oldUpdates = PrayerStore.getPrayerUpdatesByPrayerIdSync(prayer.clientId)
            val updates = mutableListOf<PrayerUpdate>()
            val data = PrayerApi.getUpdates(
                prayer.serverId ?: prayerService.findServerId(prayer.clientId)
                ?: throw NullPointerException("couldn't sync updates with no server id")
            )
            data.data.forEach {
                val o =
                    oldUpdates.find { old -> (old.serverId == it.id) || old.createdDt == it.createdDt }
                val update = it.toDb(o).copy(
                    lastSync = newDate(),
                    prayerServerId = prayer.serverId,
                    prayerClientId = prayer.clientId
                )
                updates.add(update)
            }
            PrayerStore.processUpdates(updates)
        } catch (e: Exception) {
            Log.e("red-prayer", "error syncing prayer updates: $prayer", e)
        }
    }

    private suspend fun processShares(prayerServerId: Int, prayerClientId: String) {
        try {
            val updates = mutableListOf<PrayerShare>()
            val data = PrayerApi.getShares(prayerId = prayerServerId)
            data?.data?.forEach {
                val oldShare = PrayerStore.getShareSync(it.id!!)
                val share = it.toDb(oldShare).copy(
                    prayerClientId = prayerClientId,
                    prayerServerId = prayerServerId
                )
                updates.add(share)
            }
            PrayerStore.processShares(updates)
        } catch (e: Exception) {
            Log.e("red-prayer", "error processing shares for prayer: $prayerServerId", e)
        }
    }

    internal suspend fun processReactions(prayerClientId: String) {
        assertNotMainThread()
        requireUser { return }
        requireNetwork { return }

        PrayerChangesSync.syncReactions()

        try {
            val serverId = PrayerStore.getPrayerSync(prayerClientId)?.serverId
                ?: throw NullPointerException("couldn't get server id for prayerClientId: $prayerClientId")
            val updates = mutableListOf<PrayerReaction>()
            val data = PrayerApi.getReactions(serverId)
            data.data.forEach {
                updates.add(
                    PrayerReaction(
                        prayerClientId = prayerClientId,
                        prayerServerId = serverId,
                        userId = it.userId
                            ?: throw NullPointerException("no user id came back for reaction: $it"),
                        total = it.total ?: 1,
                        needsSyncing = false,
                        updatedDt = newDate()
                    )
                )
            }
            PrayerStore.processReactions(updates)
        } catch (e: Exception) {
            Log.e("red-prayer", "error processing reactions for prayer: $prayerClientId", e)
        }
    }

    private suspend fun processCommentIds(prayerClientId: String) {
        try {
            val serverId = prayerService.findServerId(prayerClientId)
                ?: throw NullPointerException("couldn't get server id for prayerClientId: $prayerClientId")
            val ids = mutableListOf<Int>()
            val oldComments = PrayerStore.getCommentsSync(prayerClientId)

            var nextPage = 1
            while (nextPage != -1) {
                val data = PrayerApi.getComments(
                    prayerId = serverId,
                    fields = "id",
                    pageSize = 50,
                    page = nextPage
                )
                ids.addAll(data.data.mapNotNull { it.id })
                nextPage = if (data.nextPage == true) nextPage + 1 else -1
            }
            val deletes = oldComments.filterNot { ids.contains(it.serverId) }
            Log.i("red-prayer", "deleting ${deletes.size} comments: $deletes")
            PrayerStore.updateAndDeleteComments(emptyList(), deletes)
        } catch (e: Exception) {
            Log.e("red-prayer", "error processing comments ids for prayer: $prayerClientId", e)
        }
    }

    val hasNextPageComments = MutableLiveData<Boolean>()
    internal suspend fun processComments(prayerClientId: String, page: Int = 0) {
        try {
            val serverId = prayerService.findServerId(prayerClientId)
                ?: throw NullPointerException("couldn't get server id for prayerClientId: $prayerClientId")
            val updates = mutableListOf<PrayerComment>()
            val oldComments = PrayerStore.getCommentsSync(prayerClientId)
            val fetchPreview = page == 0
            val data = PrayerApi.getComments(
                prayerId = serverId,
                order = CommentOrder.DESC,
                page = if (fetchPreview) 1 else page,
                pageSize = if (fetchPreview) 5 else 25
            )
            data.data.forEach {
                val oldComment =
                    oldComments.find { old -> (old.serverId == it.id) || old.createdDt == it.createdDt }
                updates.add(
                    it.toDb(oldComment).copy(
                        lastSync = now(),
                        prayerClientId = prayerClientId,
                        prayerServerId = serverId
                    )
                )
            }
            hasNextPageComments.postValue(data.nextPage == true)
            PrayerStore.processComments(updates)
        } catch (e: Exception) {
            Log.e("red-prayer", "error processing comments for prayer: $prayerClientId", e)
        }
    }
}
