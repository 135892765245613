// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api.model.achievements

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AchievementPost(
  /**
   * Localized achievement title. Localizations are controlled by the admin internal tool and pulled
   * from our localization volunteer resources.
   */
  @SerialName("title")
  @ProtoNumber(1)
  @JvmField
  val title: String? = null,
  @SerialName("topic")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.achievements.api.model.achievements.TopicTypeSerializer::class)
  @JvmField
  val topic: TopicType? = null,
  /**
   * List of collection set ids. These get used to find the (localized) collection id for the CTA
   * and the associated plans that can be used to earn/level the achievement.
   */
  @SerialName("topic_ids")
  @ProtoNumber(3)
  @JvmField
  val topicIds: List<Int> = emptyList(),
  /**
   * Achievement description in English. Localizations are pulled into our localizations table via
   * an automated pipeline from Crowdin
   */
  @SerialName("description")
  @ProtoNumber(4)
  @JvmField
  val description: String? = null,
  /**
   * The type of achievement
   */
  @SerialName("category")
  @ProtoNumber(5)
  @JvmField
  val category: String? = null,
  /**
   * This is a list that for certain achievement categories (activity) shows you *how many* of an
   * action must take place to earn the achievement. The 0th element is to achieve level 1 of the
   * badge, etc. For other achievements, this may be null / empty.
   */
  @SerialName("level_counts")
  @ProtoNumber(6)
  @JvmField
  val levelCounts: List<Int>? = null,
  /**
   * If not null, this array indicates that an achievement has a start & end date. The start day is
   * the first element, and the end date is the last element. The format is 2 char month, hyphen, and
   * then 2 character day like "01-01"
   */
  @SerialName("dates")
  @ProtoNumber(7)
  @JvmField
  val dates: List<String>? = null,
  /**
   * This lets the client know whether the badge is discoverable and should be displayed in the
   * trophy case.
   */
  @SerialName("discoverable")
  @ProtoNumber(8)
  @JvmField
  val discoverable: Boolean = false,
  /**
   * Indicates the minimum date that the achievement can be min_award_date
   */
  @SerialName("min_award_date")
  @ProtoNumber(9)
  @JvmField
  val minAwardDate: String? = null,
  @SerialName("action_text")
  @ProtoNumber(10)
  @JvmField
  val actionText: String? = null,
  @SerialName("action_link")
  @ProtoNumber(11)
  @JvmField
  val actionLink: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
