// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.accounts

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AuthzRole(
  /**
   * The identifier of an Authz Role
   */
  @SerialName("role_id")
  @ProtoNumber(1)
  @JvmField
  val roleId: String? = null,
  /**
   * The fully qualified resource name
   */
  @SerialName("fqrn")
  @ProtoNumber(2)
  @JvmField
  val fqrn: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
