// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Boolean
import kotlin.Int
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class OrganizationFollowersWithCursor(
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val data: List<OrganizationFollower>? = null,
  @SerialName("limit")
  @ProtoNumber(2)
  @JvmField
  val limit: Int? = null,
  @SerialName("next_page")
  @ProtoNumber(3)
  @JvmField
  val nextPage: Boolean? = null,
  /**
   * The cursor to be used for the next page of results
   */
  @SerialName("next_cursor")
  @ProtoNumber(4)
  @JvmField
  val nextCursor: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
