package youversion.red.plans.api

import red.platform.http.ContentTypes
import red.platform.http.queryString
import red.platform.localization.LocaleContext
import red.platform.localization.Locales
import red.platform.localization.resolve
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.plans.model.Discover
import youversion.red.plans.model.Plan
import youversion.red.plans.model.PlanCollection
import youversion.red.plans.model.PlanCollectionItems
import youversion.red.plans.model.PlanResults
import youversion.red.plans.model.PlansConfiguration
import youversion.red.plans.model.RecommendedCollection

internal object ReadingPlansApi : AbstractApi(ApiDefaults("reading-plans", ContentTypes.PROTO, ContentTypes.PROTO)) {

    suspend fun getDiscover(languageTag: String) = execute(
        path = "/discover.proto",
        authOptional = true,
        serializer = Discover.serializer(),
        queryString = queryString {
            add("language_tag", languageTag)
        }
    )

    suspend fun getPlansByCategory(category: String): PlanResults? {
        val languageTag = (Locales.resolve(LocaleContext.PLANS).firstOrNull() ?: Locales.resolve(LocaleContext.DEFAULT).firstOrNull() ?: Locales.getDefault()).getApiTag2()
        return execute(
            "/reading_plans.json",
            hostPrefix = "search",
            accept = ContentTypes.JSON,
            contentType = ContentTypes.JSON,
            authOptional = true,
            queryString = queryString {
                add("query", "*")
                add("category", category)
                add("language_tag", languageTag)
                add("page", 1)
            },
            serializer = PlanResults.serializer()
        )
    }

    suspend fun getPlansByReference(usfm: String): PlanResults? {
        val languageTag = (Locales.resolve(LocaleContext.PLANS).firstOrNull() ?: Locales.resolve(LocaleContext.DEFAULT).firstOrNull() ?: Locales.getDefault()).getApiTag2()
        return execute(
            "/reading_plans.json",
            hostPrefix = "search",
            accept = ContentTypes.JSON,
            contentType = ContentTypes.JSON,
            authOptional = true,
            queryString = queryString {
                add("query", "*")
                add("usfm", usfm)
                add("language_tag", languageTag)
                add("page", 1)
            },
            serializer = PlanResults.serializer()
        )
    }

    suspend fun getSuggestedPlans(planId: Int, languageTag: String) = execute(
        path = "/recommendations.json",
        authAllowed = false,
        accept = ContentTypes.JSON,
        contentType = ContentTypes.JSON,
        serializer = RecommendedCollection.serializer(),
        queryString = queryString {
            add("id", planId)
            add("language_tag", languageTag)
        }
    )

    suspend fun getPlan(id: Int, languageTag: String) = execute(
        "/view.proto",
        queryString = queryString {
            add("id", id)
            add("language_tag", languageTag)
        },
        serializer = Plan.serializer(),
        authOptional = true
    )

    suspend fun getUnAuthedPlan(id: Int, languageTag: String) = execute(
        "/view.proto",
        queryString = queryString {
            add("id", id)
            add("language_tag", languageTag)
        },
        serializer = Plan.serializer(),
        authAllowed = false
    )

    suspend fun getConfiguration() = execute(
        path = "/configuration",
        authAllowed = false,
        serializer = PlansConfiguration.serializer(),
        hostPrefix = "plans",
        version = "4.0"
    )

    suspend fun getDiscoverCollection(collectionId: Int, page: Int) = execute(
        path = "/collections_items.proto",
        authAllowed = false,
        serializer = PlanCollectionItems.serializer(),
        queryString = queryString {
            addInts("ids", listOf(collectionId))
            add("page", page)
        }
    )

    suspend fun getPlanCollection(id: Int) = execute(
        path = "/collections_view.proto",
        authAllowed = false,
        serializer = PlanCollection.serializer(),
        queryString = queryString {
            add("id", id)
        }
    )
}
