// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
data class LocationPostMutation(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * This is the name of the location.
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String? = null,
  /**
   * This is the website_url of the location.
   */
  @SerialName("website_url")
  @ProtoNumber(3)
  @JvmField
  val websiteUrl: String? = null,
  /**
   * This is the phone number of the location.
   */
  @SerialName("phone")
  @ProtoNumber(4)
  @JvmField
  val phone: String? = null,
  /**
   * This is the email of the location.
   */
  @SerialName("email")
  @ProtoNumber(5)
  @JvmField
  val email: String? = null,
  /**
   * the different descriptions and urls associated with an organization's international identity
   */
  @SerialName("i18ns")
  @ProtoNumber(6)
  @JvmField
  val i18ns: List<I18n>? = null,
  /**
   * This is the default hex color code for the background color of an organization if none is
   * supplied the hex color for Regent Grey will be used as the default.
   */
  @SerialName("background_color")
  @ProtoNumber(7)
  @JvmField
  val backgroundColor: String? = null,
  /**
   * This is the weekly attendance of the church profile.
   */
  @SerialName("weekly_attendance")
  @ProtoNumber(9)
  @JvmField
  val weeklyAttendance: Int? = null,
  @SerialName("status")
  @ProtoNumber(10)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  @JvmField
  val status: ProfileStatus? = null,
  /**
   * This is the id of the logo image.
   */
  @SerialName("logo_image_id")
  @ProtoNumber(11)
  @JvmField
  val logoImageId: String? = null,
  @SerialName("pastor")
  @ProtoNumber(12)
  @JvmField
  val pastor: PastorPostMutation? = null,
  /**
   * This is the timezone of the location.
   */
  @SerialName("timezone")
  @ProtoNumber(20)
  @JvmField
  val timezone: String? = null,
  /**
   * The primary language of the organization represented as a an iso 639 language code
   */
  @SerialName("primary_language")
  @ProtoNumber(23)
  @JvmField
  val primaryLanguage: String? = null,
  @SerialName("address")
  @ProtoNumber(22)
  @JvmField
  val address: Address? = null
) : red.Serializable {
  init {
    freeze()
  }
}
