// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * A frequency for the donation which indicates how often the gift should be made, i.e. only one
 * time, weekly, monthly, etc.
 */
enum class FrequencyType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ONETIME("onetime", 1),

  WEEKLY("weekly", 2),

  EVERY_2_WEEKS("every_2_weeks", 3),

  TWICE_MONTHLY("twice_monthly", 4),

  MONTHLY("monthly", 5);
}

internal class FrequencyTypeSerializer : KSerializer<FrequencyType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.givingmikey.api.model.FrequencyType")

  override fun serialize(encoder: Encoder, value: FrequencyType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): FrequencyType {
    if (decoder is JsonDecoder) {
      return FrequencyType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> FrequencyType.UNSUPPORTED_PLACEHOLDER
      1 -> FrequencyType.ONETIME
      2 -> FrequencyType.WEEKLY
      3 -> FrequencyType.EVERY_2_WEEKS
      4 -> FrequencyType.TWICE_MONTHLY
      5 -> FrequencyType.MONTHLY
      else -> FrequencyType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
