// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.events.EventStatus

@Serializable
data class AdminEventTransition(
  /**
   * The status of the event.
   */
  @SerialName("status")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.events.api.model.events.EventStatusSerializer::class)
  @JvmField
  val status: EventStatus? = null
) : red.Serializable {
  init {
    freeze()
  }
}
