package youversion.red.discover.service

import kotlin.js.JsName
import red.service.ExportedService
import red.service.IService
import red.service.ServiceRegistry
import red.service.service
import youversion.red.discover.api.model.DiscoverChurch
import youversion.red.discover.api.model.DiscoverEmotion
import youversion.red.discover.api.model.DiscoverPlan
import youversion.red.discover.api.model.RecommendedPlans
import youversion.red.discovery.api.model.DiscoverSection
import youversion.red.movies.Movie
import youversion.red.movies.MoviePublisher

@Deprecated("use by DiscoverService()")
val DiscoverService: IDiscoverService
    get() = ServiceRegistry[IDiscoverService::class]

@JsName("DiscoverServiceProvider")
fun DiscoverService() = service<IDiscoverService>()

@ExportedService
interface IDiscoverService : IService {

    suspend fun getOrder(): List<DiscoverSection>?

    suspend fun getTeachingClips(
        languageTag: String? = null,
        pageSize: Int? = null
    ): Pair<List<Pair<Movie, MoviePublisher?>>, Boolean>

    suspend fun getFeaturedPlans(languageTag: String, page: Int): Pair<List<DiscoverPlan>?, Int?>?

    suspend fun getNewToFaithPlans(languageTag: String, page: Int): Pair<List<DiscoverPlan>?, Int?>?

    suspend fun getFeaturedVideos(
        page: Int? = null,
        pageSize: Int? = null
    ): Pair<List<Pair<Movie, MoviePublisher?>>, Boolean>

    suspend fun getRecommendedPlans(
        languageTag: String,
        planId: Int,
        page: Int? = null,
        pageSize: Int? = null
    ): Pair<RecommendedPlans?, Boolean?>

    suspend fun getTrendingPlans(
        languageTag: String,
        page: Int? = null,
        pageSize: Int? = null,
        treatmentId: Int? = null
    ): Pair<List<DiscoverPlan>?, Boolean?>

    suspend fun getFriendsPlans(
        languageTag: String,
        page: Int? = null,
        pageSize: Int? = null
    ): Pair<List<DiscoverPlan>?, Boolean?>?

    suspend fun getEmotions(languageTag: String): List<DiscoverEmotion>

    suspend fun getPlanThumbnail(id: Int, minWidth: Int): String?

    suspend fun getChurchesMyFriendsAttend(page: Int? = null, pageSize: Int? = null): Pair<List<DiscoverChurch>?, Boolean?>?

    suspend fun getChurchesNearMe(latitude: Double, longitude: Double, page: Int? = null, pageSize: Int? = null): Pair<List<DiscoverChurch>?, Boolean?>
}
