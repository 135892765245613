package youversion.red.prayer.guided.service

import red.lifecycle.LiveData
import red.platform.localization.Locale
import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.bible.reference.VersionId
import youversion.red.guidedprayer.api.model.GuidedPrayerMusic
import youversion.red.prayer.guided.model.GuideModuleItems
import youversion.red.prayer.guided.model.GuidedPrayerColors
import youversion.red.prayer.guided.model.GuidedPrayerDay
import youversion.red.prayer.guided.model.GuidedPrayerGuide
import youversion.red.prayer.guided.model.IBackgroundAudioVariation

fun GuidedPrayerService() = service<IGuidedPrayerService>()

@ExportedService
interface IGuidedPrayerService : IService {

    suspend fun isSupported(guideId: Int, cacheOnly: Boolean = false): Boolean
    suspend fun isSupportedWithLocale(guideId: Int, locale: Locale, cacheOnly: Boolean = false): Boolean
    suspend fun getGuide(guideId: Int, cacheOnly: Boolean = false): GuidedPrayerGuide?
    fun getGuideLiveData(guideId: Int): LiveData<GuidedPrayerGuide>
    fun getDayLiveData(guideId: Int, dayOfYear: Int): LiveData<GuidedPrayerDay>
    suspend fun getDay(guideId: Int, dayOfYear: Int, cacheOnly: Boolean = false): GuidedPrayerDay?
    suspend fun getModules(guideId: Int, dayOfYear: Int, versionId: VersionId? = null): GuideModuleItems
    fun getModulesLiveData(guideId: Int, dayOfYear: Int, versionId: VersionId? = null): LiveData<GuideModuleItems>
    suspend fun getMusic(): GuidedPrayerMusic
    suspend fun getVariations(): List<IBackgroundAudioVariation>
    val selectedVariation: LiveData<IBackgroundAudioVariation>
    fun selectVariation(variation: IBackgroundAudioVariation)
    val isAudioOptionShown: LiveData<Boolean>
    fun setAudioOptionShown(shown: Boolean)
    suspend fun clearCache()
    fun getColors(isDark: Boolean = true): GuidedPrayerColors

    companion object {
        const val YV_PRAYER_GUIDE_ID = 1 // should eventually come from the network. once an api exists
    }
}
