// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.guidedprayer.api.model.guidedprayer.GuidedPrayerAnalyticsModuleType

@Serializable
data class GuideModuleActionFireBase(
  /**
   * Id of the guide that was viewed
   */
  @SerialName("guide_id")
  @ProtoNumber(1)
  val guideId: Int = 0,
  /**
   * Id of the guide module that was viewed
   */
  @SerialName("module_id")
  @ProtoNumber(2)
  val moduleId: Int = 0,
  /**
   * Id of the guide module day that was viewed
   */
  @SerialName("day_id")
  @ProtoNumber(3)
  val dayId: Int = 0,
  /**
   * Call to action destination
   */
  @SerialName("url")
  @ProtoNumber(4)
  val url: String? = null,
  /**
   * Call to action text
   */
  @SerialName("text")
  @ProtoNumber(5)
  val text: String? = null,
  /**
   * Type of the module
   */
  @SerialName("type")
  @ProtoNumber(6)
  @Serializable(with=youversion.red.guidedprayer.api.model.guidedprayer.GuidedPrayerAnalyticsModuleTypeSerializer::class)
  val type: GuidedPrayerAnalyticsModuleType =
      youversion.red.guidedprayer.api.model.guidedprayer.GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER
) : Event(), red.Serializable {
  override val key: String = "GuideModuleAction"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putInt("guide_id", guideId)
    bundle.putInt("module_id", moduleId)
    bundle.putInt("day_id", dayId)
    url?.let { bundle.putString("url", url) }
    text?.let { bundle.putString("text", text) }
    bundle.putString("type", type.serialName)
    return bundle
  }
}
