package youversion.red.images.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.cache.CacheClearService

@ModuleDependencies("core")
class ImagesModuleInitializer : IModuleInitializer {

    private val cacheClearListener = ImagesCacheClearListener()

    override fun initialize() {
        CacheClearService.register(cacheClearListener)
    }
}
