package youversion.red.achievements.service.js

import kotlin.Int
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.achievements.api.model.achievements.Achievement
import youversion.red.achievements.api.model.progress.Progress
import youversion.red.achievements.model.ActionShareAchievement
import youversion.red.achievements.service.IAchievementsService

public object AchievementsService {
  private val service: IAchievementsService by
      service<youversion.red.achievements.service.IAchievementsService>()

  @JsName(name = "getAchievements")
  public fun getAchievements(plansLanguageTag: String?): Promise<List<Achievement>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getAchievements(plansLanguageTag)

  }.toPromise()

  @JsName(name = "getProgress")
  public fun getProgress(userId: Int): Promise<List<Progress>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getProgress(userId)

  }.toPromise()

  @JsName(name = "getAchievement")
  public fun getAchievement(id: Int, plansLanguageTag: String?): Promise<Achievement?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getAchievement(id, plansLanguageTag)

  }.toPromise()

  @JsName(name = "incrementProgress")
  public fun incrementProgress(achievement: ActionShareAchievement): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.incrementProgress(achievement)

  }.toPromise()
}
