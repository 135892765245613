// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The UI of the giving form. `baseline` is the current experience. `one_screen` is the variation
 * being tested that looks like the Life.Church app.
 */
enum class ExperienceType(
  internal val serialName: String,
  internal val serialId: Int
) {
  BASELINE("baseline", 0),

  ONE_SCREEN("one_screen", 1);
}

internal class ExperienceTypeSerializer : KSerializer<ExperienceType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.givingmikey.api.model.ExperienceType")

  override fun serialize(encoder: Encoder, value: ExperienceType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ExperienceType {
    if (decoder is JsonDecoder) {
      return ExperienceType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ExperienceType.BASELINE
      1 -> ExperienceType.ONE_SCREEN
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
