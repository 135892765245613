package youversion.red.metrics.service.repository

import kotlinx.serialization.KSerializer
import red.platform.threads.newLockOwner
import red.resolvers.metrics.model.IMetric

internal object MetricsRepository {

    fun isCurrentSessionFile(fileName: String): Boolean {
        return MetricsStorage.isCurrentSessionFile(fileName)
    }

    fun getSessionMetricsSessionId(fileName: String): String {
        return MetricsStorage.getSessionMetricsSessionId(fileName)
    }

    fun getPeriodicMetricsSessionId(folderName: String): String {
        return MetricsStorage.getPeriodicMetricsSessionId(folderName)
    }

    suspend fun saveMetric(metric: IMetric, serializer: KSerializer<out IMetric>) {
        MetricsStorage.writeMetric(newLockOwner(), metric, serializer)
    }

    suspend fun savePeriodicMetric(metric: IMetric, serializer: KSerializer<out IMetric>) {
        MetricsStorage.writePeriodicMetric(newLockOwner(), metric, serializer)
    }

    suspend fun loadMetrics(fileName: String): List<IMetric> {
        return MetricsStorage.readMetrics(newLockOwner(), fileName)
    }

    suspend fun loadPeriodicMetrics(folderName: String, fileName: String): List<IMetric> {
        return MetricsStorage.readPeriodicMetrics(newLockOwner(), folderName, fileName)
    }

    suspend fun getMetricsFileNames(): List<String> {
        return MetricsStorage.getMetricsFileNames(newLockOwner())
    }

    suspend fun getPreviousPeriodicMetricsBatch(): MetricsFolderAndFileName {
        return MetricsStorage.getPreviousPeriodicMetricsBatchFolderAndFileName(newLockOwner())
    }

    suspend fun getAllPeriodicMetricsBatchFolderAndFileNames(): List<MetricsFolderAndFileName> {
        return MetricsStorage.getAllPeriodicMetricsBatchFolderAndFileNames(newLockOwner())
    }

    suspend fun clearSessionData(fileName: String) {
        return MetricsStorage.clearSessionData(newLockOwner(), fileName)
    }

    suspend fun clearPeriodicMetricsBatchData(folderName: String, fileName: String) {
        return MetricsStorage.clearPeriodicMetricsBatchData(newLockOwner(), folderName, fileName)
    }

    suspend fun updatePeriodicMetricsStorage() {
        MetricsStorage.updatePeriodicMetricsStorage(newLockOwner())
    }

    suspend fun clearCache() {
        MetricsStorage.clearCache(newLockOwner())
    }
}
