package youversion.red.analytics

import red.platform.Log

internal actual fun setAnalyticsUserId(userId: Int?) {
    Log.e("Analytics", "setAnalyticsUserId NOT IMPLEMENTED")
}

internal actual fun setAnalyticsInstallationId(installationId: String) {
    Log.e("Analytics", "setAnalyticsInstallationId NOT IMPLEMENTED")
}

internal actual fun logEvent(key: String, bundle: Bundle): Unit = TODO()

internal actual fun Bundle.formatValuesForFirebaseAnalytics(): Bundle {
    val newBundle = Bundle()
    for ((key, value) in data.entries) {
        when (value) {
            is String -> newBundle.putString(key, if (value.length > 100) value.substring(0, 100) else value)
            is Int -> newBundle.putInt(key, value)
            is Boolean -> newBundle.putBoolean(key, value)
            is Float -> newBundle.putFloat(key, value)
            is Long -> newBundle.putLong(key, value)
        }
    }
    return newBundle
}
