// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine the profile type.
 */
enum class ProfileTypeEnum(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ADDRESS("address", 1),

  CONTENT("content", 2),

  BIBLE("bible", 3),

  CHURCH("church", 4);
}

internal class ProfileTypeEnumSerializer : KSerializer<ProfileTypeEnum> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.organization_profiles.ProfileTypeEnum")

  override fun serialize(encoder: Encoder, value: ProfileTypeEnum) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ProfileTypeEnum {
    if (decoder is JsonDecoder) {
      return ProfileTypeEnum.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ProfileTypeEnum.UNSUPPORTED_PLACEHOLDER
      1 -> ProfileTypeEnum.ADDRESS
      2 -> ProfileTypeEnum.CONTENT
      3 -> ProfileTypeEnum.BIBLE
      4 -> ProfileTypeEnum.CHURCH
      else -> ProfileTypeEnum.UNSUPPORTED_PLACEHOLDER
    }
  }
}
