package youversion.red.metrics.service

import kotlinx.serialization.KSerializer
import red.platform.Log
import red.resolvers.metrics.model.IMetric
import red.service.DefaultService
import red.tasks.CoroutineDispatchers
import youversion.red.analytics.log
import youversion.red.dataman.api.model.AnalyticsEvent
import youversion.red.metrics.aggregates.MetricsAggregatorManager
import youversion.red.metrics.service.repository.MetricsRepository

@DefaultService(IMetricsService::class)
class MetricsServiceImpl : IMetricsService {

    override suspend fun collectMetric(metric: IMetric, serializer: KSerializer<out IMetric>) {
        MetricsRepository.saveMetric(metric, serializer)
    }

    override suspend fun uploadMetrics() {
        val sessionFileNames = MetricsRepository.getMetricsFileNames()
        Log.i(
            "MetricsService",
            "Attempting to upload metrics, ${sessionFileNames.size} sessions available."
        )
        sessionFileNames.forEach {
            CoroutineDispatchers.launch {
                uploadSessionMetrics(it)
            }
        }
    }

    override suspend fun collectPeriodicMetric(
        metric: IMetric,
        serializer: KSerializer<out IMetric>
    ) {
        MetricsRepository.savePeriodicMetric(metric, serializer)
    }

    override suspend fun uploadPreviousPeriodicMetricsBatch() {
        MetricsRepository.updatePeriodicMetricsStorage()
        val metricsFolderAndFileName = MetricsRepository.getPreviousPeriodicMetricsBatch()
        metricsFolderAndFileName.fileName?.let {
            uploadPeriodicMetricsBatch(metricsFolderAndFileName.folderName, it)
        }
    }

    override suspend fun flushPeriodicMetrics() {
        val periodicMetricsFolderAndFileNames = MetricsRepository.getAllPeriodicMetricsBatchFolderAndFileNames()
        Log.i(
            "MetricsService",
            "Attempting to flush periodic metrics, ${periodicMetricsFolderAndFileNames.size} batches available."
        )
        periodicMetricsFolderAndFileNames.forEach {
            CoroutineDispatchers.launch {
                uploadPeriodicMetricsBatch(it.folderName, it.fileName!!)
            }
        }
    }

    override suspend fun clearCache() {
        MetricsRepository.clearCache()
    }

    internal suspend fun getSessionMetrics(fileName: String): List<AnalyticsEvent> {
        val metrics = MetricsRepository.loadMetrics(fileName)
        val collectedSessionId = MetricsRepository.getSessionMetricsSessionId(fileName)
        return MetricsAggregatorManager.aggregate(metrics, collectedSessionId)
    }

    private suspend fun uploadSessionMetrics(fileName: String) {
        if (MetricsRepository.isCurrentSessionFile(fileName)) {
            Log.i("MetricsService", "Skipping upload for current session $fileName metrics")
            return
        }
        val sessionMetrics = getSessionMetrics(fileName)
        Log.i(
            "MetricsService",
            "Uploading session $fileName metrics, ${sessionMetrics.size} available, $sessionMetrics"
        )
        sessionMetrics.forEach { metric ->
            metric.log()
        }
        MetricsRepository.clearSessionData(fileName)
    }

    internal suspend fun getPeriodicMetricsBatch(folderName: String, fileName: String): List<AnalyticsEvent> {
        val metrics = MetricsRepository.loadPeriodicMetrics(folderName, fileName)
        val collectedSessionId = MetricsRepository.getPeriodicMetricsSessionId(folderName)
        return MetricsAggregatorManager.aggregate(metrics, collectedSessionId)
    }

    private suspend fun uploadPeriodicMetricsBatch(folderName: String, fileName: String) {
        val batchPeriodicMetrics = getPeriodicMetricsBatch(folderName, fileName)
        Log.i(
            "MetricsService",
            "Uploading periodic metrics in folder = $folderName, file = $fileName, " +
                    "${batchPeriodicMetrics.size} available, metrics = $batchPeriodicMetrics"
        )
        batchPeriodicMetrics.forEach { metric ->
            metric.log()
        }
        MetricsRepository.clearPeriodicMetricsBatchData(folderName, fileName)
    }
}
