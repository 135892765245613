// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api.model.progress

import kotlin.Int
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Progress(
  /**
   * Achievement unique identifier.
   */
  @SerialName("achievement_id")
  @ProtoNumber(1)
  @JvmField
  val achievementId: Int? = null,
  /**
   * The levels array contains a list of RFC 3339 date-time strings displaying when each new level
   * was achieved. You can also calculate the level number from this array. For example, an array with
   * a single element means the user has achieved level 1, etc.
   */
  @SerialName("levels")
  @ProtoNumber(2)
  @JvmField
  val levels: List<@Serializable(with=red.platform.DateSerializer::class) Date>? = null,
  /**
   * This field returns an integer of a user's progress towards an achievement. What this field
   * means is based off the category of the achievement similar to the parameters field of the
   * achievements object. For example, if the category is activity, this is the total count of times
   * the user has performed that activity.
   */
  @SerialName("count")
  @ProtoNumber(3)
  @JvmField
  val count: Int? = null,
  /**
   * Details about how to obtain the next level
   */
  @SerialName("upcoming_level")
  @ProtoNumber(4)
  @JvmField
  val upcomingLevel: UpcomingLevel? = null
) : red.Serializable {
  init {
    freeze()
  }
}
