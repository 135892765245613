// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractShareEvent

@Serializable
@SerialName("organizations.OrganizationProfileShareEvent")
data class OrganizationProfileShareEvent(
  @SerialName("organizationId")
  @ProtoNumber(1)
  val organizationid: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractShareEvent(), red.Serializable {
  init {
    freeze()
  }
}
