// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
@SerialName("organizations.organization_profiles.GivingProfile")
data class GivingProfile(
  @SerialName("status")
  @ProtoNumber(11)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: String = "",
  @SerialName("organization_id")
  @ProtoNumber(2)
  override val organizationId: String = ""
) : BaseProfile, red.Serializable {
  init {
    freeze()
  }
}
