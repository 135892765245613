// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object PrayerDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<PrayerDeepLinkListener?> = AtomicReference(null)

  var listener: PrayerDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/prayer") { context, _, _ -> 
        listener.onPrayerLanding(context)
    }
    register("/prayers") { context, _, _ -> 
        listener.onPrayers(context)
    }
    register("/prayers/archived") { context, _, _ -> 
        listener.onArchivedPrayers(context)
    }
    register("/prayers/answered") { context, _, _ -> 
        listener.onAnsweredPrayers(context)
    }
    register("/prayers/about") { context, _, _ -> 
        listener.onAboutPrayer(context)
    }
    register("/prayers/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val prayerId = values[1].toIntOrNull() ?: return@register false
        listener.onPrayer(context, prayerId)
    }
    register("/prayers/add") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val title = queries["title"]?.firstOrNull()
        val description = queries["description"]?.firstOrNull()
        val usfm = queries["usfm"]?.firstOrNull()
        listener.onAddPrayer(context, title, description, usfm)
    }
    freeze()
  }
}
