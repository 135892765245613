// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.featured_organizations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.images.OrganizationImage

@Serializable
data class FeaturedOrganization(
  /**
   * The organization id of the organization.
   */
  @SerialName("organization_id")
  @ProtoNumber(1)
  @JvmField
  val organizationId: String = "",
  /**
   * This is the name of the organization.
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  @SerialName("logo")
  @ProtoNumber(3)
  @JvmField
  val logo: OrganizationImage,
  /**
   * The order of the featured organization.
   */
  @SerialName("sort")
  @ProtoNumber(4)
  @JvmField
  val sort: Int = 0,
  /**
   * The hex color code for the background color of an organization
   */
  @SerialName("background_color")
  @ProtoNumber(5)
  @JvmField
  val backgroundColor: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
