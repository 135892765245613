package youversion.red.metrics

import red.tasks.CoroutineDispatchers

external fun setInterval(handler: dynamic, timeout: Int = definedExternally, vararg arguments: Any?): Int
external fun clearInterval(handle: Int = definedExternally)

actual object MetricsTimer {

    private var intervalId: Int? = null

    actual fun register(timeInSeconds: Int, callback: suspend () -> Unit) {
        unregister()
        intervalId = setInterval({
            CoroutineDispatchers.launch { callback() }
        }, timeInSeconds * 1000)
    }

    actual fun unregister() {
        intervalId?.let { clearInterval(it) }
        intervalId = null
    }
}
