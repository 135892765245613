// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Int
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class OrganizationFollower(
  /**
   * This is the id of a user following an organization
   */
  @SerialName("user_id")
  @ProtoNumber(1)
  @JvmField
  val userId: Int = 0
) : red.Serializable {
  init {
    freeze()
  }
}
