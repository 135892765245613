// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.givingmikey.api.model.ExperienceType

@Serializable
data class EditScheduleStarted(
  /**
   * The UI of the giving form. `baseline` is the current experience. `one_screen` is the variation
   * being tested that looks like the Life.Church app.
   */
  @SerialName("experience")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.givingmikey.api.model.ExperienceTypeSerializer::class)
  val experience: ExperienceType? = null,
  /**
   * The amount of the donation at the time of the event.
   */
  @SerialName("amount")
  @ProtoNumber(2)
  val amount: String? = null,
  /**
   * The currency code of the donation at the time of the event. e.g. “USD”
   */
  @SerialName("currency")
  @ProtoNumber(3)
  val currency: String? = null,
  /**
   * The frequency code of the donation at the time of the event. e.g. “onetime”
   */
  @SerialName("frequency")
  @ProtoNumber(4)
  val frequency: String? = null,
  /**
   * The cause code of the donation at the time of the event. e.g. “YouVersion”
   */
  @SerialName("fund")
  @ProtoNumber(5)
  val fund: String? = null,
  /**
   * The type of payment method of the donation at the time of the event. e.g. “ACH”, “Credit Card”
   */
  @SerialName("payment_method")
  @ProtoNumber(6)
  val paymentMethod: String? = null,
  /**
   * The date the donation will process. e.g. “2020-09-08”
   */
  @SerialName("start_date")
  @ProtoNumber(7)
  val startDate: String? = null
) : Event(), red.Serializable {
  override val key: String = "EditScheduleStarted"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    experience?.let { bundle.putString("experience", experience.serialName) }
    amount?.let { bundle.putString("amount", amount) }
    currency?.let { bundle.putString("currency", currency) }
    frequency?.let { bundle.putString("frequency", frequency) }
    fund?.let { bundle.putString("fund", fund) }
    paymentMethod?.let { bundle.putString("payment_method", paymentMethod) }
    startDate?.let { bundle.putString("start_date", startDate) }
    return bundle
  }
}
