// Auto-Generated from OpenAPI Spec
package youversion.red.events.api

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.events.api.model.admin.events.AdminEvent
import youversion.red.events.api.model.admin.events.AdminEventContent
import youversion.red.events.api.model.admin.events.AdminEventCreate
import youversion.red.events.api.model.admin.events.AdminEventCreated
import youversion.red.events.api.model.admin.events.AdminEventDuplicate
import youversion.red.events.api.model.admin.events.AdminEventTimes
import youversion.red.events.api.model.admin.events.AdminEventTransition
import youversion.red.events.api.model.admin.events.AdminEvents
import youversion.red.events.api.model.events.Event
import youversion.red.events.api.model.events.Events
import youversion.red.events.api.model.images.ImagePropertiesEvents
import youversion.red.events.api.model.images.PresignedImageResponse
import youversion.red.events.api.model.saves.SaveCreate
import youversion.red.events.api.model.saves.Saved
import youversion.red.events.api.model.saves.Saves

/**
 * Events Service
 */
object EventsApi : AbstractApi(ApiDefaults("events", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", EventsApiSerializer)) {
  /**
   * @param organizationId The organization's id
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return paged collection of an organization's events
   */
  suspend fun getAdminEvents(
    organizationId: String,
    page: Int? = null,
    pageSize: Int? = null
  ): AdminEvents = execute("in/4.0/events", method = RequestMethods.GET, version = "admin",
      authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      add("organization_id", organizationId)
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = AdminEvents.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Return the created event
   */
  suspend fun createEvent(body: AdminEventCreate? = null): AdminEventCreated =
      execute("in/4.0/events", version = "admin", method = RequestMethods.POST, authAllowed = true,
      authOptional = false, body = body, bodySerializer = AdminEventCreate.serializer(), serializer
      = AdminEventCreated.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param eventId The id of the event resource
   * @return return an event resource
   */
  suspend fun getAdminEvent(eventId: Long): AdminEvent = execute("in/4.0/events/${eventId}", method
      = RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, serializer
      = AdminEvent.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param eventId The id of the event resource
   */
  suspend fun deleteEvent(eventId: Long) = execute("in/4.0/events/${eventId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param eventId The id of the event resource
   * @return Event duplicated successfully
   */
  suspend fun duplicateEvent(eventId: Long, body: AdminEventDuplicate? = null): AdminEvent =
      execute("in/4.0/events/${eventId}:duplicate", version = "admin", method = RequestMethods.POST,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      AdminEventDuplicate.serializer(), serializer = AdminEvent.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param eventId The id of the event resource
   * @return Event state transitioned successfully.
   */
  suspend fun transitionEvent(eventId: Long, body: AdminEventTransition? = null): AdminEvent =
      execute("in/4.0/events/${eventId}:transition", version = "admin", method =
      RequestMethods.POST, authAllowed = true, authOptional = false, body = body, bodySerializer =
      AdminEventTransition.serializer(), serializer = AdminEvent.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param eventId The id of the event resource
   * @return Successfully updated
   */
  suspend fun updateEventContent(eventId: Long, body: AdminEventContent? = null): AdminEventContent
      = execute("in/4.0/events/${eventId}/content", version = "admin", method = RequestMethods.PUT,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      AdminEventContent.serializer(), serializer = AdminEventContent.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param eventId The id of the event resource
   * @return Successfully updated
   */
  suspend fun updateEventTimes(eventId: Long, body: AdminEventTimes? = null): AdminEventTimes =
      execute("in/4.0/events/${eventId}/times", version = "admin", method = RequestMethods.PUT,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      AdminEventTimes.serializer(), serializer = AdminEventTimes.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Return presigned url for image upload
   */
  suspend fun signEventsImage(body: ImagePropertiesEvents? = null): PresignedImageResponse =
      execute("in/4.0/images", version = "admin", method = RequestMethods.POST, authAllowed = true,
      authOptional = false, body = body, bodySerializer = ImagePropertiesEvents.serializer(),
      serializer = PresignedImageResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Successful Request
   */
  suspend fun createSave(body: SaveCreate? = null): Saves = execute("/saves", version = "3.3",
      method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = SaveCreate.serializer(), serializer = Saves.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return User's saved events
   */
  suspend fun getSaved(page: Int? = null, pageSize: Int? = null): Saved = execute("/saves", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Saved.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param eventId The id of the event resource
   */
  suspend fun deleteSaves(eventId: Long) = execute("/saves/${eventId}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Unit.serializer())

  /**
   * @param organizationId The organization's id
   * @return Return an event collection
   */
  suspend fun getEvents(organizationId: String): Events = execute("/events", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true, queryString =
      red.platform.http.queryString {
      add("organization_id", organizationId)
  }, serializer = Events.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param eventId The id of the event resource
   * @return return an event resource
   */
  suspend fun getEvent(eventId: Long): Event = execute("/events/${eventId}", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true, serializer =
      Event.serializer()) ?: throw NullPointerException("Response not sent from server")
}
