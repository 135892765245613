// Auto-Generated from OpenAPI Spec
package youversion.red.animations.api

import kotlin.Int
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.animations.api.model.Animation
import youversion.red.animations.api.model.AnimationsPathProviderName
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults

/**
 * The animations service contains all the CRUD API calls a client would need to work with
 * animations.
 */
object AnimationsApi : AbstractApi(ApiDefaults("animations", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0")) {
  /**
   * This is an authenticated retrieval of a single animation resource.
   *
   * @param animationId The unique identifier for the Animation resource being referenced.
   * @param providerName The unique identifier for the kind of Animation being referenced.
   * @return Successful request.
   */
  suspend fun getAnimation(animationId: Int, providerName: AnimationsPathProviderName): Animation? =
      execute("/animations/${providerName}/${animationId}", method = RequestMethods.GET, version =
      "4.0", authAllowed = false, authOptional = false, serializer = Animation.serializer())
}
