// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.modules

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
@SerialName("modules.PostSermonModule")
data class PostSermonModule(
  @SerialName("video_id")
  @ProtoNumber(4)
  val videoId: Int? = null,
  @SerialName("show_publisher")
  @ProtoNumber(5)
  val showPublisher: Boolean? = null,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Int? = null,
  @SerialName("title")
  @ProtoNumber(2)
  override val title: String? = null,
  @SerialName("created_dt")
  @ProtoNumber(3)
  override val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : PostBaseModule, red.Serializable {
  init {
    freeze()
  }
}
