// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class GivingPayment(
  /**
   * Value to be charged in string form
   */
  @SerialName("amount")
  @ProtoNumber(1)
  @JvmField
  val amount: String? = null,
  /**
   * Unique ID of Currency code
   */
  @SerialName("currency_id")
  @ProtoNumber(2)
  @JvmField
  val currencyId: Int? = null,
  @SerialName("frequency_id")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.givingmikey.api.model.FrequencyTypeSerializer::class)
  @JvmField
  val frequencyId: FrequencyType? = null,
  /**
   * Unique ID of Fund
   */
  @SerialName("fund_id")
  @ProtoNumber(4)
  @JvmField
  val fundId: Int? = null,
  /**
   * Unique ID of the payment method to use (Can be left out if using PayPal). Client must provide
   * either this OR payment_token field NOT BOTH.
   */
  @SerialName("payment_method_id")
  @ProtoNumber(5)
  @JvmField
  val paymentMethodId: Int? = null,
  /**
   * Success title
   */
  @SerialName("title")
  @ProtoNumber(6)
  @JvmField
  val title: String? = null,
  /**
   * Success message
   */
  @SerialName("message")
  @ProtoNumber(7)
  @JvmField
  val message: String? = null,
  /**
   * the next payment date of the new subscription in ISO-8601 format if a subscription was created
   */
  @SerialName("next_payment_date")
  @ProtoNumber(8)
  @JvmField
  val nextPaymentDate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * ID of the new subscription in Rock if a subscription was created
   */
  @SerialName("subscription_id")
  @ProtoNumber(9)
  @JvmField
  val subscriptionId: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
