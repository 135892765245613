// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GivingPaymentMethod(
  /**
   * Unique id for a PaymentMethod
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * Last 4 digits of the payment method
   */
  @SerialName("last_4")
  @ProtoNumber(2)
  @JvmField
  val last4: String? = null,
  /**
   * when does this payment method expire?
   */
  @SerialName("expiration_label")
  @ProtoNumber(3)
  @JvmField
  val expirationLabel: String? = null,
  /**
   * Kind of payment method this is.
   */
  @SerialName("payment_method_type")
  @ProtoNumber(4)
  @JvmField
  val paymentMethodType: String? = null,
  /**
   * Brand of payment method type.
   */
  @SerialName("payment_type")
  @ProtoNumber(5)
  @JvmField
  val paymentType: String? = null,
  /**
   * Label to display for this payment method.
   */
  @SerialName("display_label")
  @ProtoNumber(6)
  @JvmField
  val displayLabel: String? = null,
  /**
   * Payment gateway for this payment method.
   */
  @SerialName("gateway")
  @ProtoNumber(7)
  @JvmField
  val gateway: String? = null,
  /**
   * Whether or not this is the default payment method.
   */
  @SerialName("default")
  @ProtoNumber(8)
  @JvmField
  val default: Boolean? = null
) : red.Serializable {
  init {
    freeze()
  }
}
