package youversion.red.churches.ext

import youversion.red.churches.model.AddressProfile
import youversion.red.churches.model.ChurchProfile
import youversion.red.churches.model.ChurchServiceTime
import youversion.red.churches.model.ContentProfile
import youversion.red.churches.model.GivingProfile
import youversion.red.churches.model.Location
import youversion.red.churches.model.Organization
import youversion.red.churches.model.ParentOrganization
import youversion.red.churches.model.Pastor
import youversion.red.organizations.api.model.admin.content_partners.Kind
import youversion.red.organizations.api.model.images.OrganizationImage
import youversion.red.organizations.api.model.images.PastorImage
import youversion.red.organizations.api.model.organizations.Organization as ApiOrganization
import youversion.red.organizations.api.model.organizations.OrganizationLocation as ApiLocation
import youversion.red.organizations.api.model.organizations.ParentOrganization as ApiParentOrganization
import youversion.red.organizations.api.model.organizations.ServiceTime as ApiServiceTime
import youversion.red.organizations.api.model.organizations.organization_profiles.AddressProfile as ApiAddressProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.ChurchProfile as ApiChurchProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.ContentProfile as ApiContentProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.GivingProfile as ApiGivingProfile
import youversion.red.organizations.api.model.organizations.organizations_profiles.Pastor as ApiPastor

fun ApiOrganization.toOrganization(contentLanguage: String? = null): Organization = Organization(
    id = this.id,
    name = this.name,
    websiteUrl = this.websiteUrl,
    phone = this.phone,
    email = this.email,
    description = this.description,
    imageUrl = this.logo.toImageUrl(),
    brandColor = this.backgroundColor,
    parentOrganization = this.parentOrganization?.toParentOrganization(),
    timezone = this.timezone,
    contentLanguage = contentLanguage
)

fun OrganizationImage.toImageUrl(): String = this.imageUrl
    .replace("{w}", this.width.toString())
    .replace("{h}", this.height.toString())

fun ApiParentOrganization.toParentOrganization(): ParentOrganization = ParentOrganization(
    id = this.id ?: "",
    name = this.name ?: ""
)

fun ApiServiceTime.toServiceTime(): ChurchServiceTime = ChurchServiceTime(
    id = this.id,
    dayOfWeek = this.dayOfWeek,
    timeOfDay = this.timeOfDay,
    description = this.description
)

fun ApiLocation.toLocation(): Location = Location(
    id = this.id,
    name = this.name,
    formattedAddress = this.formattedAddress,
    formattedLocality = this.formattedLocality,
    longitude = this.longitude,
    latitude = this.latitude,
    distance = this.distance,
    placeId = this.placeId,
    administrativeAreaLevel1 = this.administrativeAreaLevel1,
    locality = this.locality
)

fun BaseProfile.toConcreteBaseProfile() = when (this) {
    is ApiChurchProfile -> this.toChurchProfile()
    is ApiAddressProfile -> this.toAddressProfile()
    is ApiGivingProfile -> this.toGivingProfile()
    is ApiContentProfile -> this.toContentProfile()
    else -> throw NullPointerException("This is neither a ChurchProfile nor an AddressProfile")
}

fun ApiChurchProfile.toChurchProfile(): ChurchProfile = ChurchProfile(
    id = this.id,
    organizationId = this.organizationId,
    pastor = this.pastor.toPastor(),
    locationCount = this.locationCount
)

fun ApiPastor.toPastor(): Pastor = Pastor(
    id = this.id,
    name = this.name,
    churchProfileId = this.churchProfileId,
    title = this.title,
    imageUrl = this.image?.toImageUrl()
)

fun PastorImage.toImageUrl(): String = this.imageUrl
    .replace("{w}", this.width.toString())
    .replace("{h}", this.height.toString())

fun ApiAddressProfile.toAddressProfile(): AddressProfile = AddressProfile(
    id = this.id,
    organizationId = this.organizationId,
    formattedAddress = this.formattedAddress,
    formattedLocality = this.formattedLocality,
    longitude = this.longitude,
    latitude = this.latitude,
    country = this.country,
    placeId = this.placeId,
    administrativeAreaLevel1 = this.administrativeAreaLevel1,
    locality = this.locality
)

fun ApiGivingProfile.toGivingProfile(): GivingProfile = GivingProfile(
    id = this.id,
    organizationId = this.organizationId
)

fun ApiContentProfile.toContentProfile(): ContentProfile = ContentProfile(
    id = this.id,
    organizationId = this.organizationId,
    portalPlanOrgId = this.externalIds?.firstOrNull { it.type == Kind.PARTNER_PORTAL_ORGANIZATION }?.id,
    videoPublisherId = this.externalIds?.firstOrNull { it.type == Kind.VIDEO_PUBLISHER }?.id,
    status = this.status,
    createdDt = this.createdDt,
    updatedDt = this.updatedDt
)
