// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.serializer
import red.platform.Date
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.stories.api.model.CohortFilter
import youversion.red.stories.api.model.GCSPresignResponse
import youversion.red.stories.api.model.LessonStatusFilter
import youversion.red.stories.api.model.LookupType
import youversion.red.stories.api.model.colors.Colors
import youversion.red.stories.api.model.crowdin_events.TranslationUpdatedEvent
import youversion.red.stories.api.model.crowdin_key.CrowdinKey
import youversion.red.stories.api.model.images.Image
import youversion.red.stories.api.model.languages.Languages
import youversion.red.stories.api.model.lesson_translations.Translation
import youversion.red.stories.api.model.lesson_translations.Translations
import youversion.red.stories.api.model.lessons.Lesson
import youversion.red.stories.api.model.lessons.Lessons
import youversion.red.stories.api.model.lessons.PutLesson
import youversion.red.stories.api.model.module_types.ModuleTypes
import youversion.red.stories.api.model.modules.BaseModule
import youversion.red.stories.api.model.modules.Modules
import youversion.red.stories.api.model.modules.PostBaseModule
import youversion.red.stories.api.model.string_translations.StringTranslation

/**
 * The Stories API will facilitate the feature temporarily named Stories. It will provide data
 * necessary to navigate a `path` consisting of different `lessons` composed of `modules`.
 */
object StoriesApi : AbstractApi(ApiDefaults("stories", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", StoriesApiSerializer)) {
  /**
   * @return Successful request
   */
  suspend fun getLanguages(): Languages = execute("/languages", method = RequestMethods.GET, version
      = "4.0", authAllowed = false, authOptional = false, serializer = Languages.serializer()) ?:
      throw NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request
   */
  suspend fun getModuleTypes(
    fields: String? = null,
    pageSize: Int? = null,
    page: Int? = null
  ): ModuleTypes = execute("/moduleTypes", method = RequestMethods.GET, version = "4.0", authAllowed
      = false, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = ModuleTypes.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return Successful request
   */
  suspend fun getColors(fields: String? = null): Colors = execute("/colors", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Colors.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param references Search criteria for lessons. An array of plus-delimited verses.
   * @param status Indicate what status the returned lessons should be limited to.
   * @param liveDate Only show stories that are live on this date
   * @param languageTag Indicate which language returned lessons should be in. Defaults to en for
   * backward compatibility with initial launch clients.
   * @param cohort Filter down to desired cohort for stories (pass * for all cohorts)
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request
   */
  suspend fun getLessons(
    references: List<String>? = null,
    status: LessonStatusFilter? = null,
    liveDate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
    languageTag: String? = null,
    localizable: Boolean? = null,
    cohort: CohortFilter? = null,
    fields: String? = null,
    pageSize: Int? = null,
    page: Int? = null
  ): Lessons = execute("/lessons", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      references?.let { addStrings("references", references) }
      status?.let { add("status", status.serialName) }
      liveDate?.let { add("live_date", liveDate) }
      languageTag?.let { add("language_tag", languageTag) }
      localizable?.let { add("localizable", localizable) }
      cohort?.let { add("cohort", cohort.serialName) }
      fields?.let { add("fields", fields) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = Lessons.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Created
   */
  suspend fun addLesson(body: Lesson? = null): Lesson = execute("/lessons", version = "4.0", method
      = RequestMethods.POST, authAllowed = true, authOptional = false, body = body, bodySerializer =
      Lesson.serializer(), serializer = Lesson.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return Successful Request
   */
  suspend fun getLesson(id: Int, fields: String? = null): Lesson = execute("/lessons/${id}", method
      = RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Lesson.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Successful Request
   */
  suspend fun updateLesson(id: Int, body: PutLesson? = null): PutLesson = execute("/lessons/${id}",
      version = "4.0", method = RequestMethods.PUT, authAllowed = true, authOptional = false, body =
      body, bodySerializer = PutLesson.serializer(), serializer = PutLesson.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  suspend fun removeLesson(id: Int) = execute("/lessons/${id}", method = RequestMethods.DELETE,
      version = "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * @return Successful Request
   */
  suspend fun publishLesson(id: Int): Lesson = execute("/lessons/${id}:publish", method =
      RequestMethods.PUT, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Lesson.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param lessonId The lesson's unique identifier.
   * @param moduleId The module's unique identifier.
   */
  suspend fun hideLessonModules(lessonId: Int, moduleId: Int) =
      execute("/lessons/${lessonId}/modules/${moduleId}:hide", method = RequestMethods.POST, version
      = "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * @param lessonId The lesson's unique identifier.
   * @param moduleId The module's unique identifier.
   */
  suspend fun showLessonModules(lessonId: Int, moduleId: Int) =
      execute("/lessons/${lessonId}/modules/${moduleId}:show", method = RequestMethods.POST, version
      = "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * @return Successful request
   */
  suspend fun getLessonTranslations(id: Int): Translations = execute("/lessons/${id}/translations",
      method = RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false,
      serializer = Translations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Successful request
   */
  suspend fun addLessonTranslation(id: Int, body: Translation? = null): Translation =
      execute("/lessons/${id}/translations", version = "4.0", method = RequestMethods.POST,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      Translation.serializer(), serializer = Translation.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Successful request
   */
  suspend fun getLessonTranslation(id: Int, languageTag: String): Translation =
      execute("/lessons/${id}/translations/${languageTag}", method = RequestMethods.GET, version =
      "4.0", authAllowed = true, authOptional = false, serializer = Translation.serializer()) ?:
      throw NullPointerException("Response not sent from server")

  /**
   * @return Successful Request
   */
  suspend fun updateModule(id: Int, body: BaseModule? = null): BaseModule =
      execute("/modules/${id}", version = "4.0", method = RequestMethods.PUT, authAllowed = true,
      authOptional = false, body = body, bodySerializer = BaseModule.serializer(), serializer =
      BaseModule.serializer()) ?: throw NullPointerException("Response not sent from server")

  suspend fun removeModule(id: Int) = execute("/modules/${id}", method = RequestMethods.DELETE,
      version = "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * @param lesson Filter modules by lesson id.
   * @param showHidden Show hidden modules.
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return Successful Request
   */
  suspend fun getModules(
    lesson: Int,
    showHidden: Boolean? = null,
    fields: String? = null
  ): Modules = execute("/modules", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      add("lesson", lesson)
      showHidden?.let { add("show_hidden", showHidden) }
      fields?.let { add("fields", fields) }
  }, serializer = Modules.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Created
   */
  suspend fun addModule(body: PostBaseModule? = null): BaseModule = execute("/modules", version =
      "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = PostBaseModule.serializer(), serializer = BaseModule.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request
   */
  suspend fun searchModules(
    type: LookupType,
    contentId: Int? = null,
    references: List<String>? = null,
    fields: String? = null,
    pageSize: Int? = null,
    page: Int? = null
  ): Modules = execute("/modules:lookup", method = RequestMethods.GET, version = "4.0", authAllowed
      = true, authOptional = false, queryString = red.platform.http.queryString {
      add("type", type.serialName)
      contentId?.let { add("content_id", contentId) }
      references?.let { addStrings("references", references) }
      fields?.let { add("fields", fields) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = Modules.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Successful request
   */
  suspend fun getCrowdinKey(): CrowdinKey = execute("/crowdin_key", method = RequestMethods.GET,
      version = "4.0", authAllowed = true, authOptional = false, serializer =
      CrowdinKey.serializer()) ?: throw NullPointerException("Response not sent from server")

  suspend fun addCrowdinEvent(body: TranslationUpdatedEvent? = null) = execute("/crowdin_events",
      version = "4.0", method = RequestMethods.POST, authAllowed = false, authOptional = false, body
      = body, bodySerializer = TranslationUpdatedEvent.serializer(), serializer = Unit.serializer())

  /**
   * @return Successful request
   */
  suspend fun editStringTranslation(
    crowdinStringId: Int,
    languageTag: String,
    body: StringTranslation? = null
  ): StringTranslation = execute("/strings/${crowdinStringId}/translations/${languageTag}", version
      = "4.0", method = RequestMethods.PUT, authAllowed = true, authOptional = false, body = body,
      bodySerializer = StringTranslation.serializer(), serializer = StringTranslation.serializer())
      ?: throw NullPointerException("Response not sent from server")

  /**
   * @return Return presigned url for image upload
   */
  suspend fun addImage(body: Image? = null): GCSPresignResponse = execute("/images", version =
      "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = Image.serializer(), serializer = GCSPresignResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Successful Request
   */
  suspend fun publishImage(id: Int): Image = execute("/images/${id}:publish", method =
      RequestMethods.PUT, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Image.serializer()) ?: throw NullPointerException("Response not sent from server")
}
