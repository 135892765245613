// Auto-Generated from OpenAPI Spec
package youversion.red.events.api

import kotlin.ByteArray
import kotlin.OptIn
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.serializer
import red.platform.http.FormatType
import red.platform.http.Serializer
import red.platform.threads.freeze
import youversion.red.events.api.model.admin.events.event_content.AdminAnnouncementContent
import youversion.red.events.api.model.admin.events.event_content.AdminImageContent
import youversion.red.events.api.model.admin.events.event_content.AdminPlanContent
import youversion.red.events.api.model.admin.events.event_content.AdminReferenceContent
import youversion.red.events.api.model.admin.events.event_content.AdminTextContent
import youversion.red.events.api.model.admin.events.event_content.AdminURLContent
import youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent
import youversion.red.events.api.model.events.event_content.AnnouncementContent
import youversion.red.events.api.model.events.event_content.ImageContent
import youversion.red.events.api.model.events.event_content.PlanContent
import youversion.red.events.api.model.events.event_content.ReferenceContent
import youversion.red.events.api.model.events.event_content.TextContent
import youversion.red.events.api.model.events.event_content.URLContent
import youversion.red.events.api.model.events.event_contents.BaseContent

object EventsApiSerializer : Serializer {
  private val context: SerializersModule = SerializersModule {

      polymorphic(youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminTextContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminTextContent.serializer())
      polymorphic(youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminAnnouncementContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminAnnouncementContent.serializer())
      polymorphic(youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminImageContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminImageContent.serializer())
      polymorphic(youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminPlanContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminPlanContent.serializer())
      polymorphic(youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminReferenceContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminReferenceContent.serializer())
      polymorphic(youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminURLContent::class,
          youversion.red.events.api.model.admin.events.event_content.AdminURLContent.serializer())
      polymorphic(youversion.red.events.api.model.events.event_contents.BaseContent::class,
          youversion.red.events.api.model.events.event_content.TextContent::class,
          youversion.red.events.api.model.events.event_content.TextContent.serializer())
      polymorphic(youversion.red.events.api.model.events.event_contents.BaseContent::class,
          youversion.red.events.api.model.events.event_content.AnnouncementContent::class,
          youversion.red.events.api.model.events.event_content.AnnouncementContent.serializer())
      polymorphic(youversion.red.events.api.model.events.event_contents.BaseContent::class,
          youversion.red.events.api.model.events.event_content.ImageContent::class,
          youversion.red.events.api.model.events.event_content.ImageContent.serializer())
      polymorphic(youversion.red.events.api.model.events.event_contents.BaseContent::class,
          youversion.red.events.api.model.events.event_content.PlanContent::class,
          youversion.red.events.api.model.events.event_content.PlanContent.serializer())
      polymorphic(youversion.red.events.api.model.events.event_contents.BaseContent::class,
          youversion.red.events.api.model.events.event_content.ReferenceContent::class,
          youversion.red.events.api.model.events.event_content.ReferenceContent.serializer())
      polymorphic(youversion.red.events.api.model.events.event_contents.BaseContent::class,
          youversion.red.events.api.model.events.event_content.URLContent::class,
          youversion.red.events.api.model.events.event_content.URLContent.serializer())
      }

  private val json: Json = Json { isLenient = true; ignoreUnknownKeys = true;
      allowSpecialFloatingPointValues = true; classDiscriminator = "_type";  serializersModule =
      context }

  @OptIn(kotlinx.serialization.ExperimentalSerializationApi::class)
  private val protobuf: ProtoBuf = ProtoBuf { encodeDefaults = true; serializersModule = context }

  init {
    freeze()}

  override fun <T> serialize(
    type: FormatType,
    serializer: SerializationStrategy<T>,
    obj: T
  ): ByteArray = when (type) {
     FormatType.JSON -> json.encodeToString(serializer, obj).encodeToByteArray()
     FormatType.PROTOBUF -> protobuf.encodeToByteArray(serializer, obj)
  }
  override fun <T> deserialize(
    type: FormatType,
    deserializer: DeserializationStrategy<T>,
    data: ByteArray
  ): T? = when (type) {
     FormatType.JSON -> json.decodeFromString(deserializer, data.decodeToString())
     FormatType.PROTOBUF -> protobuf.decodeFromByteArray(deserializer, data)
  }}
