// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.content_partners

import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.organizations.organization_profiles.HubspotTypes

@Serializable
data class ContentPartnerPost(
  @SerialName("email")
  @ProtoNumber(2)
  @JvmField
  val email: String? = null,
  @SerialName("name")
  @ProtoNumber(3)
  @JvmField
  val name: String? = null,
  @SerialName("external_ids")
  @ProtoNumber(4)
  @JvmField
  val externalIds: List<ExternalIds>? = null,
  @SerialName("primary_language")
  @ProtoNumber(5)
  @JvmField
  val primaryLanguage: String? = null,
  @SerialName("hubspot_partner_type")
  @ProtoNumber(6)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.HubspotTypesSerializer::class)
  @JvmField
  val hubspotPartnerType: HubspotTypes? = null
) : red.Serializable {
  init {
    freeze()
  }
}
