// Auto-Generated from OpenAPI Spec
package youversion.red.banner.model.userstate.state

import kotlin.Boolean
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("state.BooleanState")
data class BooleanState(
    @SerialName("enabled")
    @ProtoNumber(3)
    val enabled: Boolean? = null
) : State, red.Serializable {
    init {
        freeze()
    }
}
