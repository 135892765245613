// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations.organization_profiles

import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class AcceptProfileLicense(
  @SerialName("version")
  @ProtoNumber(1)
  @JvmField
  val version: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
