package youversion.red.banner.service

import youversion.red.banner.model.variant.SUPPORTED_VARIANTS_VERSION
import youversion.red.banner.model.variant.VARIANTS_VERSION_PARAMETER_KEY
import youversion.red.geoip.service.GeoIPService

interface ParameterResolver {

    fun resolve(name: String): String?
}

internal object InternalParameterResolver {

    private val geoIPService by GeoIPService()

    suspend fun resolve(name: String, type: String?): String? = when (name) {
        "country" -> geoIPService.getGeoIP()?.countryCode
        "state" -> geoIPService.getGeoIP()?.region
        "city" -> geoIPService.getGeoIP()?.city
        "gdpr" -> geoIPService.getGeoIP()?.gdpr?.let { if (it) "true" else "false" }
        "type" -> type
        VARIANTS_VERSION_PARAMETER_KEY -> SUPPORTED_VARIANTS_VERSION.toString()
        else -> null
    }
}
