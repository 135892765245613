// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events.event_content

import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent

@Serializable
@SerialName("admin.events.event_content.AdminTextContent")
data class AdminTextContent(
  /**
   * The body of the text content
   */
  @SerialName("body")
  @ProtoNumber(60)
  val body: String = "",
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Long? = null
) : AdminBaseContent, red.Serializable {
  init {
    freeze()
  }
}
