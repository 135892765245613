package youversion.red.banner.api

import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.protobuf.ProtoBuf
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.FormatType
import red.platform.http.RequestMethods
import red.platform.http.Serializer
import red.platform.http.URL
import red.platform.http.queryString
import red.platform.platformType
import red.platform.threads.SharedImmutable
import red.platform.threads.freeze
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.banner.model.Banner
import youversion.red.banner.model.BannerConfiguration
import youversion.red.banner.model.BannerConfigurationId
import youversion.red.banner.model.BannerId
import youversion.red.banner.model.BannerServerConfiguration
import youversion.red.banner.model.BannerServerConfigurations
import youversion.red.banner.model.Parameter
import youversion.red.banner.model.blocks.BannerBlock
import youversion.red.banner.model.blocks.DefaultBlock
import youversion.red.banner.model.userstate.state.BooleanState
import youversion.red.banner.model.userstate.state.DeviceState
import youversion.red.banner.model.userstate.state.State
import youversion.red.banner.model.userstate.state.UserState
import youversion.red.banner.model.userstate.state.banner.BannerImpressionsState

@SharedImmutable
private val contentType = if (platformType == PlatformType.JavaScript) {
    ContentTypes.JSON
} else {
    ContentTypes.PROTO
}

expect fun newBannerUrl(url: String): URL

object BannerApiSerializer : Serializer {

    private val context: SerializersModule = SerializersModule {
        polymorphic(BannerBlock::class, DefaultBlock::class, DefaultBlock.serializer())
        polymorphic(State::class, BooleanState::class, BooleanState.serializer())
        polymorphic(State::class, DeviceState::class, DeviceState.serializer())
        polymorphic(State::class, UserState::class, UserState.serializer())
        polymorphic(State::class, BannerImpressionsState::class, BannerImpressionsState.serializer())
    }

    private val json: Json = Json {
        isLenient = true; ignoreUnknownKeys = true
        allowSpecialFloatingPointValues = true; classDiscriminator = "_type"; serializersModule =
        context
    }

    @OptIn(ExperimentalSerializationApi::class)
    private val protobuf: ProtoBuf = ProtoBuf { encodeDefaults = true; serializersModule = context }

    init {
        freeze()
    }

    override fun <T> serialize(
        type: FormatType,
        serializer: SerializationStrategy<T>,
        obj: T
    ): ByteArray = when (type) {
        FormatType.JSON -> json.encodeToString(serializer, obj).encodeToByteArray()
        FormatType.PROTOBUF -> protobuf.encodeToByteArray(serializer, obj)
    }

    override fun <T> deserialize(
        type: FormatType,
        deserializer: DeserializationStrategy<T>,
        data: ByteArray
    ): T? = when (type) {
        FormatType.JSON -> json.decodeFromString(deserializer, data.decodeToString())
        FormatType.PROTOBUF -> protobuf.decodeFromByteArray(deserializer, data)
    }
}

internal object BannerApi : AbstractApi(ApiDefaults("banner", contentType, contentType, version = "4.0", serializer = BannerApiSerializer)) {

    override fun toURL(
        path: String,
        hostPrefix: String,
        host: String,
        version: String,
        queryString: String?
    ): URL {
        val query = if (queryString == null) "" else "?$queryString"
        val url = "https://$host$path$query"
        return urlResolver.resolveURL(url)
    }

    suspend fun getConfiguration() =
        execute("/configuration", serializer = BannerConfiguration.serializer(), authAllowed = false)

    suspend fun getBanners() =
        execute("/banners", serializer = BannerServerConfigurations.serializer())

    suspend fun addBanner(banner: BannerServerConfiguration) =
        execute(
            "/banners",
            method = RequestMethods.PUT,
            serializer = BannerConfigurationId.serializer(),
            bodySerializer = BannerServerConfiguration.serializer(),
            body = banner
        )

    suspend fun editBanner(banner: BannerServerConfiguration) =
        execute(
            "/banners/${banner.id}",
            method = RequestMethods.PUT,
            serializer = Unit.serializer(),
            bodySerializer = BannerServerConfiguration.serializer(),
            body = banner
        )

    suspend fun deleteBanner(id: BannerId) =
        execute(
            "/banners/$id",
            method = RequestMethods.DELETE,
            serializer = Unit.serializer()
        )

    suspend fun findBanner(parameters: List<Parameter<*>>) =
        execute(
            "/find",
            queryString = queryString {
                parameters.forEach {
                    it.value?.let { value -> add(it.key, value.toString()) }
                    it.valueSet?.let { value -> add(it.key, value.joinToString(",")) }
                }
            },
            serializer = Banner.serializer(),
            authOptional = true
        )

    suspend fun impression(id: String, index: Int, uuid: String?, parameters: List<Parameter<*>>) =
        execute(
            "/impression",
            queryString = queryString {
                add("_id", id)
                add("_index", index)
                uuid?.let {
                    add("_uuid", it)
                }
                parameters.forEach {
                    it.value?.let { value -> add(it.key, value.toString()) }
                    it.valueSet?.let { value -> add(it.key, value.joinToString(",")) }
                }
            },
            serializer = Unit.serializer(),
            authOptional = true
        )

    suspend fun click(id: String, index: Int, uuid: String?, parameters: List<Parameter<*>>) =
        execute(
            "/click",
            queryString = queryString {
                add("_id", id)
                add("_index", index)
                uuid?.let {
                    add("_uuid", it)
                }
                parameters.forEach {
                    it.value?.let { value -> add(it.key, value.toString()) }
                    it.valueSet?.let { value -> add(it.key, value.joinToString(",")) }
                }
            },
            serializer = Unit.serializer(),
            authOptional = true
        )

    suspend fun dismiss(id: String, index: Int, uuid: String?, parameters: List<Parameter<*>>) =
        execute(
            "/dismiss",
            queryString = queryString {
                add("_id", id)
                add("_index", index)
                uuid?.let {
                    add("_uuid", it)
                }
                parameters.forEach {
                    it.value?.let { value -> add(it.key, value.toString()) }
                    it.valueSet?.let { value -> add(it.key, value.joinToString(",")) }
                }
            },
            serializer = Unit.serializer(),
            authOptional = true
        )
}
