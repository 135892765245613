package youversion.red.discover.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.discovery.deeplink.DiscoveryDeepLinkHandler

@ModuleDependencies("core", "plans", "moments", "bible", "churches-shared")
class DiscoverModuleInitializer : IModuleInitializer {

    override fun initialize() {
        DeepLinks.addHandlers(DiscoveryDeepLinkHandler as AbstractDeepLinkHandler)
    }
}
