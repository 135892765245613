// Auto-Generated from OpenAPI Spec
package youversion.red.locales.api.model

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class BibleLocaleHeaders(
  /**
   * List of bible locale headers
   */
  @SerialName("locales")
  @ProtoNumber(1)
  @JvmField
  val locales: List<BibleLocaleHeader> = emptyList()
) : red.Serializable {
  init {
    freeze()
  }
}
