// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.v2

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AnalyticsContentType

@Serializable
@SerialName("v2.UserPropertiesEvent")
data class UserPropertiesEvent(
  /**
   * The version identifier of the Bible currently set for the user
   */
  @SerialName("appBibleVersionId")
  @ProtoNumber(1)
  val appbibleversionid: Int? = null,
  /**
   * The version identifier of the audio Bible currently set for the user
   */
  @SerialName("appAudioBibleVersionId")
  @ProtoNumber(2)
  val appaudiobibleversionid: Int? = null,
  /**
   * The selected plan language tag, if the UI provides a language selector for Plans
   */
  @SerialName("appPlanLanguageTag")
  @ProtoNumber(3)
  val appplanlanguagetag: String? = null,
  /**
   * Whether or not notifications are enabled on the OS
   */
  @SerialName("osNotificationsEnabled")
  @ProtoNumber(4)
  val osnotificationsenabled: Boolean? = null,
  /**
   * Whether or not VOTD text notifications are enabled
   */
  @SerialName("votdTextNotificationsEnabled")
  @ProtoNumber(5)
  val votdtextnotificationsenabled: Boolean? = null,
  /**
   * The UTC time the user has configured to show the VOTD text notification (ISO 8601, time only)
   */
  @SerialName("votdTextNotificationsTime")
  @ProtoNumber(6)
  val votdtextnotificationstime: String? = null,
  /**
   * The version identifier of the Bible version set for VOTD text notifications
   */
  @SerialName("votdTextNotificationsVersionId")
  @ProtoNumber(7)
  val votdtextnotificationsversionid: Int? = null,
  /**
   * Whether or not VOTD image notifications are enabled
   */
  @SerialName("votdImageNotificationsEnabled")
  @ProtoNumber(8)
  val votdimagenotificationsenabled: Boolean? = null,
  /**
   * The UTC time the user has configured to show the VOTD image notification (ISO 8601, time only)
   */
  @SerialName("votdImageNotificationsTime")
  @ProtoNumber(9)
  val votdimagenotificationstime: String? = null,
  /**
   * The language tag set for VOTD image push notification
   */
  @SerialName("votdImageNotificationsLanguageTag")
  @ProtoNumber(10)
  val votdimagenotificationslanguagetag: String? = null,
  /**
   * Whether or not the streaks reminder is enabled
   */
  @SerialName("streaksReminderEnabled")
  @ProtoNumber(11)
  val streaksreminderenabled: Boolean? = null,
  /**
   * Whether or not the prayer reminder is enabled
   */
  @SerialName("prayerReminderEnabled")
  @ProtoNumber(12)
  val prayerreminderenabled: Boolean? = null,
  @SerialName("planReminderEnabledIds")
  @ProtoNumber(13)
  val planreminderenabledids: List<Int>? = null,
  @SerialName("contentType")
  @ProtoNumber(15)
  @Serializable(with=youversion.red.dataman.api.model.AnalyticsContentTypeSerializer::class)
  val contenttype: AnalyticsContentType? = null,
  /**
   * The UTC time the user has configured to show the prayer reminder notification (ISO 8601, time
   * only)
   */
  @SerialName("prayerReminderTime")
  @ProtoNumber(16)
  val prayerremindertime: String? = null,
  /**
   * The device_id that the braze_sdk provides
   */
  @SerialName("braze_device_id")
  @ProtoNumber(17)
  val brazeDeviceId: String? = null,
  /**
   * An alphanumeric string that uniquely identifies a device to the app’s vendor.
   */
  @SerialName("idfv")
  @ProtoNumber(18)
  val idfv: String? = null,
  /**
   * A unique, user-resettable, device-wide, per-profile ID for advertising.
   */
  @SerialName("adid")
  @ProtoNumber(19)
  val adid: String? = null,
  /**
   * The android id that firebase would give if adid is unavailable
   */
  @SerialName("firebase_id")
  @ProtoNumber(20)
  val firebaseId: String? = null,
  /**
   * The primary YouVersion identifier for the content
   */
  @SerialName("primaryContentId")
  @ProtoNumber(50)
  override val primarycontentid: String? = null,
  /**
   * A unique identifier for the event (Firebase push ID)
   */
  @SerialName("eventId")
  @ProtoNumber(51)
  override val eventid: String = "",
  /**
   * An identifier for the UI element (snake case)
   */
  @SerialName("elementId")
  @ProtoNumber(52)
  override val elementid: String? = null,
  /**
   * A secondary identifier describing the content
   */
  @SerialName("secondaryContentId")
  @ProtoNumber(53)
  override val secondarycontentid: String? = null,
  /**
   * A tertiary identifier describing the content
   */
  @SerialName("tertiaryContentId")
  @ProtoNumber(54)
  override val tertiarycontentid: String? = null,
  /**
   * A quaternary identifier describing the content
   */
  @SerialName("quaternaryContentId")
  @ProtoNumber(55)
  override val quaternarycontentid: String? = null,
  /**
   * A platform-specific screen identifier
   */
  @SerialName("screenId")
  @ProtoNumber(56)
  override val screenid: String? = null,
  /**
   * Extra contextual event metadata
   */
  @SerialName("extraA")
  @ProtoNumber(57)
  override val extraa: String? = null,
  /**
   * Extra contextual event metadata
   */
  @SerialName("extraB")
  @ProtoNumber(58)
  override val extrab: String? = null,
  /**
   * Extra contextual event metadata
   */
  @SerialName("extraC")
  @ProtoNumber(59)
  override val extrac: String? = null,
  /**
   * Extra contextual event metadata
   */
  @SerialName("extraD")
  @ProtoNumber(60)
  override val extrad: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractEvent(), red.Serializable {
  init {
    freeze()
  }
}
