// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.metrics

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("metrics.RequestManagerResponseCodeMetricEvent")
data class RequestManagerResponseCodeMetricEvent(
  /**
   * The executed request url
   */
  @SerialName("url")
  @ProtoNumber(1)
  val url: String? = null,
  /**
   * The request method of the executed request
   */
  @SerialName("request_method")
  @ProtoNumber(2)
  val requestMethod: String? = null,
  /**
   * The response code
   */
  @SerialName("response_code")
  @ProtoNumber(3)
  val responseCode: Int? = null,
  /**
   * The number of times the response returns this code
   */
  @SerialName("count")
  @ProtoNumber(4)
  val count: Int? = null,
  /**
   * The session ID that the metrics are collected
   */
  @SerialName("collected_session_id")
  @ProtoNumber(5)
  val collectedSessionId: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    url: String? = null,
    requestMethod: String? = null,
    responseCode: Int? = null,
    count: Int? = null,
    collectedSessionId: String? = null,
    createdmicros: Int? = null
  ) : this(url, requestMethod, responseCode, count, collectedSessionId, red.platform.now(),
      createdmicros)
}
