// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Where the user downloaded offline version from
 */
enum class OfflineDownloadReferrer(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  DEEP_LINK("deep_link", 1),

  MOMENT("moment", 2),

  READER_CHAPTER("reader_chapter", 3),

  VERSIONS("versions", 4),

  UPDATE_VERSIONS("update_versions", 5),

  BACKGROUND("background", 6),

  BUNDLE("bundle", 7);
}

internal class OfflineDownloadReferrerSerializer : KSerializer<OfflineDownloadReferrer> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.users.api.model.OfflineDownloadReferrer")

  override fun serialize(encoder: Encoder, value: OfflineDownloadReferrer) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): OfflineDownloadReferrer {
    if (decoder is JsonDecoder) {
      return OfflineDownloadReferrer.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> OfflineDownloadReferrer.UNKNOWN
      1 -> OfflineDownloadReferrer.DEEP_LINK
      2 -> OfflineDownloadReferrer.MOMENT
      3 -> OfflineDownloadReferrer.READER_CHAPTER
      4 -> OfflineDownloadReferrer.VERSIONS
      5 -> OfflineDownloadReferrer.UPDATE_VERSIONS
      6 -> OfflineDownloadReferrer.BACKGROUND
      7 -> OfflineDownloadReferrer.BUNDLE
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
