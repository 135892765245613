package youversion.red.images.service.js

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.Unit
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.bible.reference.BibleReference
import youversion.red.images.model.ImageCategory
import youversion.red.images.model.ImageMetadata
import youversion.red.images.model.ImageOrientation
import youversion.red.images.model.Images
import youversion.red.images.service.IImagesService

public object ImagesService {
  private val service: IImagesService by service<youversion.red.images.service.IImagesService>()

  @JsName(name = "getImage")
  public fun getImage(id: Int, cacheOnly: Boolean): Promise<ImageMetadata?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getImage(id, cacheOnly)

  }.toPromise()

  @JsName(name = "getImagesForReader")
  public fun getImagesForReader(
    reference: BibleReference?,
    languageTag: String?,
    category: ImageCategory?,
    orientation: ImageOrientation?,
    page: Int
  ): Promise<Images?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getImagesForReader(reference, languageTag, category, orientation, page)

  }.toPromise()

  @JsName(name = "getImages")
  public fun getImages(
    reference: BibleReference?,
    languageTag: String?,
    category: ImageCategory?,
    orientation: ImageOrientation?,
    page: Int
  ): Promise<Images?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getImages(reference, languageTag, category, orientation, page)

  }.toPromise()

  @JsName(name = "clearCache")
  public fun clearCache(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.clearCache()

  }.toPromise()
}
