// Auto-Generated from OpenAPI Spec
package youversion.red.banner.model.userstate.state

import kotlin.Suppress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import red.Serializable

interface State : Serializable {
    companion object {
        @Suppress("unchecked_cast")
        fun serializer(): KSerializer<State> = PolymorphicSerializer(State::class)
    }
}
