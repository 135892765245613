// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.accounts

import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class InviteResend(
  /**
   * This is the date when the invite will expire. If null is provided then the invite will never
   * expire.
   */
  @SerialName("invite_expire_dt")
  @ProtoNumber(1)
  @JvmField
  val inviteExpireDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
