// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Locality(
  /**
   * Represents the short version of the incorporated city or town, only supplied from the places
   * API if present
   */
  @SerialName("short_name")
  @ProtoNumber(91)
  @JvmField
  val shortName: String? = null,
  /**
   * Represents the long version of the incorporated city or town, only supplied from the places API
   * if present
   */
  @SerialName("long_name")
  @ProtoNumber(92)
  @JvmField
  val longName: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
