// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.v2

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class CompletionContext(
  internal val serialName: String,
  internal val serialId: Int
) {
  NONE("none", 0),

  INTERACTION("interaction", 1),

  SHARE("share", 2),

  COPY("copy", 3),

  SEARCH("search", 4),

  LOGIN("login", 5),

  SIGN_UP("sign_up", 6),

  VIDEO("video", 7),

  INSTALLATION("installation", 8),

  AUDIO("audio", 9),

  GIVE_INTENT("give_intent", 10),

  NON_IDLE_SEGMENT("non_idle_segment", 11),

  DOWNLOAD("download", 12),

  ANSWER("answer", 13),

  PLAY("play", 14);
}

internal class CompletionContextSerializer : KSerializer<CompletionContext> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.v2.CompletionContext")

  override fun serialize(encoder: Encoder, value: CompletionContext) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): CompletionContext {
    if (decoder is JsonDecoder) {
      return CompletionContext.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> CompletionContext.NONE
      1 -> CompletionContext.INTERACTION
      2 -> CompletionContext.SHARE
      3 -> CompletionContext.COPY
      4 -> CompletionContext.SEARCH
      5 -> CompletionContext.LOGIN
      6 -> CompletionContext.SIGN_UP
      7 -> CompletionContext.VIDEO
      8 -> CompletionContext.INSTALLATION
      9 -> CompletionContext.AUDIO
      10 -> CompletionContext.GIVE_INTENT
      11 -> CompletionContext.NON_IDLE_SEGMENT
      12 -> CompletionContext.DOWNLOAD
      13 -> CompletionContext.ANSWER
      14 -> CompletionContext.PLAY
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
