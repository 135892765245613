package youversion.red.churches.service.js

import kotlin.Double
import kotlin.Int
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.js.JsName
import kotlin.js.Promise
import kotlinx.coroutines.flow.Flow
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.churches.model.BaseOrganizationProfile
import youversion.red.churches.model.ChurchServiceTime
import youversion.red.churches.model.Location
import youversion.red.churches.model.Organization
import youversion.red.churches.service.IChurchesService
import youversion.red.events.api.model.events.Events
import youversion.red.organizations.api.model.organizations.OrganizationMember
import youversion.red.organizations.api.model.organizations.OrganizationMembers
import youversion.red.organizations.api.model.organizations.OrganizationPost
import youversion.red.organizations.api.model.organizations.OrganizationPosts
import youversion.red.organizations.api.model.organizations.PostAction
import youversion.red.organizations.api.model.organizations.PostActionKind
import youversion.red.organizations.api.model.organizations.VisibilityPreference
import youversion.red.plans.model.Plan

public object ChurchesService {
  private val service: IChurchesService by
      service<youversion.red.churches.service.IChurchesService>()

  public val savedOrganization: Flow<Organization?>
    get() = service.savedOrganization

  @JsName(name = "saveOrganization")
  public fun saveOrganization(id: String, visibilityPreference: VisibilityPreference?):
      Promise<Unit?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.saveOrganization(id, visibilityPreference)

  }.toPromise()

  @JsName(name = "leaveOrganization")
  public fun leaveOrganization(id: String): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.leaveOrganization(id)

  }.toPromise()

  @JsName(name = "getFeaturedPlan")
  public fun getFeaturedPlan(organizationId: String): Flow<Plan?> =
      service.getFeaturedPlan(organizationId)

  @JsName(name = "getOrganizations")
  public fun getOrganizations(userId: Int?): Flow<List<Organization>> =
      service.getOrganizations(userId)

  @JsName(name = "getOrganization")
  public fun getOrganization(id: String): Flow<Organization?> = service.getOrganization(id)

  @JsName(name = "getOrganizationSync")
  public fun getOrganizationSync(id: String): Promise<Organization?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getOrganizationSync(id)

  }.toPromise()

  @JsName(name = "getProfiles")
  public fun getProfiles(organizationId: String): Flow<List<BaseOrganizationProfile>> =
      service.getProfiles(organizationId)

  @JsName(name = "getLocations")
  public fun getLocations(
    organizationId: String,
    latitude: Double?,
    longitude: Double?
  ): Flow<List<Location>> = service.getLocations(organizationId, latitude, longitude)

  @JsName(name = "getChurchServiceTimes")
  public fun getChurchServiceTimes(organizationId: String): Flow<List<ChurchServiceTime>> =
      service.getChurchServiceTimes(organizationId)

  @JsName(name = "getOrganizationMembers")
  public fun getOrganizationMembers(
    organizationId: String,
    userId: Int?,
    page: Int?,
    pageSize: Int?
  ): Flow<OrganizationMembers?> = service.getOrganizationMembers(organizationId, userId, page,
      pageSize)

  @JsName(name = "getMembersCount")
  public fun getMembersCount(organizationId: String): Flow<Int> =
      service.getMembersCount(organizationId)

  @JsName(name = "getOrganizationMember")
  public fun getOrganizationMember(organizationId: String, userId: Int?): Flow<OrganizationMember?>
      = service.getOrganizationMember(organizationId, userId)

  @JsName(name = "getOrganizationPost")
  public fun getOrganizationPost(
    organizationId: String,
    postId: String,
    bodyAccept: String?
  ): Flow<OrganizationPost?> = service.getOrganizationPost(organizationId, postId, bodyAccept)

  @JsName(name = "getOrganizationPosts")
  public fun getOrganizationPosts(
    organizationId: String,
    page: Int?,
    pageSize: Int?,
    bodyAccept: String?
  ): Flow<OrganizationPosts> = service.getOrganizationPosts(organizationId, page, pageSize,
      bodyAccept)

  @JsName(name = "getOrganizationPostActions")
  public fun getOrganizationPostActions(organizationId: String, postId: String):
      Flow<List<PostAction>> = service.getOrganizationPostActions(organizationId, postId)

  @JsName(name = "createOrganizationPostAction")
  public fun createOrganizationPostAction(
    organizationId: String,
    postId: String,
    postActionKind: PostActionKind
  ): Promise<PostAction?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.createOrganizationPostAction(organizationId, postId, postActionKind)

  }.toPromise()

  @JsName(name = "deleteOrganizationPostAction")
  public fun deleteOrganizationPostAction(
    organizationId: String,
    postId: String,
    postActionKind: PostActionKind
  ): Promise<Unit?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.deleteOrganizationPostAction(organizationId, postId, postActionKind)

  }.toPromise()

  @JsName(name = "getEvents")
  public fun getEvents(organizationId: String): Promise<Events?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getEvents(organizationId)

  }.toPromise()
}
