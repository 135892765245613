// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.modules

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("modules.CustomDevotionalModule")
data class CustomDevotionalModule(
  @SerialName("blocks")
  @ProtoNumber(3)
  val blocks: List<CustomDevotionalModuleBlock>? = null,
  @SerialName("language_tag")
  @ProtoNumber(4)
  val languageTag: String? = null,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Int? = null,
  @SerialName("hidden")
  @ProtoNumber(6)
  override val hidden: Boolean? = null,
  @SerialName("title")
  @ProtoNumber(2)
  override val title: String? = null
) : BaseModule, red.Serializable {
  init {
    freeze()
  }
}
