// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events.event_content

import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent

@Serializable
@SerialName("admin.events.event_content.AdminURLContent")
data class AdminURLContent(
  /**
   * The title of the text content
   */
  @SerialName("title")
  @ProtoNumber(70)
  val title: String = "",
  /**
   * The body of the text content
   */
  @SerialName("body")
  @ProtoNumber(71)
  val body: String? = null,
  /**
   * A url to a web page
   */
  @SerialName("url")
  @ProtoNumber(72)
  val url: String = "",
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Long? = null
) : AdminBaseContent, red.Serializable {
  init {
    freeze()
  }
}
