package youversion.red.metrics.module

import kotlinx.serialization.KSerializer
import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.Log
import red.resolvers.metrics.MetricsServiceResolver
import red.resolvers.metrics.model.IMetric
import red.tasks.CoroutineDispatchers
import youversion.red.metrics.MetricsTimer
import youversion.red.metrics.service.MetricsService

@ModuleDependencies("core", "analytics-dataman")
class MetricsModuleInitializer : IModuleInitializer {

    override fun initialize() {
        MetricsServiceResolver.resolver = MetricsServiceResolverImpl()
        MetricsServiceResolver.resolver.uploadMetrics()
        MetricsServiceResolver.resolver.flushPeriodicMetrics()
        MetricsServiceResolver.resolver.startUploadingPeriodicMetrics()
    }
}

private class MetricsServiceResolverImpl : MetricsServiceResolver {

    private val metricsService by MetricsService()

    override fun collectMetric(metric: IMetric, serializer: KSerializer<out IMetric>) {
        try {
            CoroutineDispatchers.launch {
                metricsService.collectMetric(metric, serializer)
            }
        } catch (exception: Exception) {
            Log.e("MetricsServiceResolver", "Failed to collect metric $metric", exception)
        }
    }

    override fun uploadMetrics() {
        try {
            CoroutineDispatchers.launch {
                metricsService.uploadMetrics()
            }
        } catch (exception: Exception) {
            Log.e("MetricsServiceResolver", "Failed to upload metrics", exception)
        }
    }

    override fun collectPeriodicMetric(metric: IMetric, serializer: KSerializer<out IMetric>) {
        try {
            CoroutineDispatchers.launch {
                metricsService.collectPeriodicMetric(metric, serializer)
            }
        } catch (exception: Exception) {
            Log.e("MetricsServiceResolver", "Failed to collect periodic metric $metric", exception)
        }
    }

    override fun flushPeriodicMetrics() {
        try {
            CoroutineDispatchers.launch {
                metricsService.flushPeriodicMetrics()
            }
        } catch (exception: Exception) {
            Log.e("MetricsServiceResolver", "Failed to flush periodic metrics", exception)
        }
    }

    override fun startUploadingPeriodicMetrics() {
        try {
            MetricsTimer.register(30) {
                metricsService.uploadPreviousPeriodicMetricsBatch()
            }
        } catch (exception: Exception) {
            Log.e("MetricsServiceResolver", "Failed to start uploading periodic metrics", exception)
        }
    }
}
