// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.guidedprayer

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.media.AbstractMediaPlayEvent

@Serializable
@SerialName("guidedprayer.GPBackgroundAudioPlayEvent")
data class GPBackgroundAudioPlayEvent(
  @SerialName("audioCategoryId")
  @ProtoNumber(1)
  val audiocategoryid: Int? = null,
  /**
   * session for this media event
   */
  @SerialName("mediaSessionId")
  @ProtoNumber(50)
  override val mediasessionid: String? = null,
  /**
   * expected duration (in seconds) of the media
   */
  @SerialName("duration")
  @ProtoNumber(51)
  override val duration: Int? = null,
  /**
   * position (in seconds) in the stream when played
   */
  @SerialName("position")
  @ProtoNumber(52)
  override val position: Int? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractMediaPlayEvent(), red.Serializable {
  init {
    freeze()
  }
}
