// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class GivingPaymentPost(
  /**
   * Value to be charged in string form
   */
  @SerialName("amount")
  @ProtoNumber(1)
  @JvmField
  val amount: String? = null,
  /**
   * Unique ID of Currency code
   */
  @SerialName("currency_id")
  @ProtoNumber(2)
  @JvmField
  val currencyId: Int? = null,
  @SerialName("frequency_id")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.givingmikey.api.model.FrequencyTypeSerializer::class)
  @JvmField
  val frequencyId: FrequencyType? = null,
  /**
   * Unique ID of Fund
   */
  @SerialName("fund_id")
  @ProtoNumber(4)
  @JvmField
  val fundId: Int? = null,
  /**
   * When the payment should take place
   */
  @SerialName("charge_dt")
  @ProtoNumber(5)
  @JvmField
  val chargeDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The IANA timezone name of the timezone in which the charge_dt applies
   */
  @SerialName("charge_tz")
  @ProtoNumber(6)
  @JvmField
  val chargeTz: String? = null,
  /**
   * Unique token for the desired dynamic payment method (currently PayPal or Apple Pay).  Client
   * must provide either this OR payment_method_id field NOT BOTH.
   */
  @SerialName("payment_token")
  @ProtoNumber(7)
  @JvmField
  val paymentToken: String? = null,
  /**
   * Unique ID of the payment method to use (Can be left out if using PayPal). Client must provide
   * either this OR payment_token field NOT BOTH.
   */
  @SerialName("payment_method_id")
  @ProtoNumber(8)
  @JvmField
  val paymentMethodId: Int? = null,
  /**
   * The first name of the user (required only in the case of guest giving in order to populate
   * Rock)
   */
  @SerialName("first_name")
  @ProtoNumber(9)
  @JvmField
  val firstName: String? = null,
  /**
   * The last name of the user (required only in the case of guest giving in order to populate Rock)
   */
  @SerialName("last_name")
  @ProtoNumber(10)
  @JvmField
  val lastName: String? = null,
  @SerialName("transaction_source")
  @ProtoNumber(11)
  @Serializable(with=youversion.red.givingmikey.api.model.GivingTransactionSourceTypeSerializer::class)
  @JvmField
  val transactionSource: GivingTransactionSourceType? = null
) : red.Serializable {
  init {
    freeze()
  }
}
