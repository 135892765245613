// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object GuidedPrayerDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<GuidedPrayerDeepLinkListener?> = AtomicReference(null)

  var listener: GuidedPrayerDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/guides/$INTEGER_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val dayOfYear = queries["day_of_year"]?.firstOrNull()?.toIntOrNull()
        val guideId = values[1].toIntOrNull() ?: return@register false
        listener.onGuide(context, guideId, dayOfYear)
    }
    freeze()
  }
}
