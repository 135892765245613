// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.users

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminSearch(
  /**
   * Query to search first_name, last_name, or email
   */
  @SerialName("query")
  @ProtoNumber(1)
  @JvmField
  val query: String = "",
  /**
   * A subset list of user_ids to query
   */
  @SerialName("user_ids")
  @ProtoNumber(2)
  @JvmField
  val userIds: List<Int> = emptyList()
) : red.Serializable {
  init {
    freeze()
  }
}
