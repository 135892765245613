// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.podcasts

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.media.AbstractMediaPlayEvent

@Serializable
@SerialName("podcasts.EpisodeAudioPlayEvent")
data class EpisodeAudioPlayEvent(
  /**
   * The episode title 
   */
  @SerialName("episode_author")
  @ProtoNumber(1)
  val episodeAuthor: String? = null,
  /**
   * The episode title 
   */
  @SerialName("episode_title")
  @ProtoNumber(2)
  val episodeTitle: String? = null,
  /**
   * The unique id of the podcast episode the user is interacting with
   */
  @SerialName("episode_id")
  @ProtoNumber(3)
  val episodeId: Int? = null,
  /**
   * App interface language
   */
  @SerialName("app_language")
  @ProtoNumber(4)
  val appLanguage: String? = null,
  /**
   * session for this media event
   */
  @SerialName("mediaSessionId")
  @ProtoNumber(50)
  override val mediasessionid: String? = null,
  /**
   * expected duration (in seconds) of the media
   */
  @SerialName("duration")
  @ProtoNumber(51)
  override val duration: Int? = null,
  /**
   * position (in seconds) in the stream when played
   */
  @SerialName("position")
  @ProtoNumber(52)
  override val position: Int? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractMediaPlayEvent(), red.Serializable {
  init {
    freeze()
  }
}
