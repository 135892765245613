// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.organizations.api.model.admin.content_partners.ExternalIds
import youversion.red.organizations.api.model.profiles.ProfileStatus
import youversion.red.organizations.api.model.profiles.Socials

@Serializable
@SerialName("organizations.organization_profiles.ContentProfile")
data class ContentProfile(
  @SerialName("status")
  @ProtoNumber(77)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  val status: ProfileStatus? = null,
  /**
   * The time that this bible version grant was created.
   */
  @SerialName("created_dt")
  @ProtoNumber(78)
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The time that this bible version grant was created
   */
  @SerialName("updated_dt")
  @ProtoNumber(79)
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The name of the contact person
   */
  @SerialName("primary_contact_name")
  @ProtoNumber(81)
  val primaryContactName: String? = null,
  /**
   * The email of the contact person
   */
  @SerialName("primary_contact_email")
  @ProtoNumber(82)
  val primaryContactEmail: String? = null,
  @SerialName("social_media")
  @ProtoNumber(83)
  val socialMedia: List<Socials>? = null,
  @SerialName("external_ids")
  @ProtoNumber(84)
  val externalIds: List<ExternalIds>? = null,
  @SerialName("hubspot_partner_type")
  @ProtoNumber(85)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.HubspotTypesSerializer::class)
  val hubspotPartnerType: HubspotTypes? = null,
  @SerialName("license_status")
  @ProtoNumber(86)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.LicenseStatusEnumSerializer::class)
  val licenseStatus: LicenseStatusEnum? = null,
  @SerialName("license_type")
  @ProtoNumber(87)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.LicenseAgreementTypeEnumSerializer::class)
  val licenseType: LicenseAgreementTypeEnum? = null,
  /**
   * The stringified version of the date the license agreement was last updated.
   */
  @SerialName("license_version")
  @ProtoNumber(88)
  val licenseVersion: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The user_id of the user that updated the license agreement.
   */
  @SerialName("license_updated_by")
  @ProtoNumber(89)
  val licenseUpdatedBy: Long? = null,
  /**
   * The date and time that the license agreement was accepted.
   */
  @SerialName("license_accepted_dt")
  @ProtoNumber(90)
  val licenseAcceptedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The date and time that the license agreement was rejected.
   */
  @SerialName("license_rejected_dt")
  @ProtoNumber(91)
  val licenseRejectedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: String = "",
  @SerialName("organization_id")
  @ProtoNumber(2)
  override val organizationId: String = ""
) : BaseProfile, red.Serializable {
  init {
    freeze()
  }
}
