// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
@SerialName("organizations.organization_profiles.PatchGivingProfile")
data class PatchGivingProfile(
  @SerialName("status")
  @ProtoNumber(41)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  /**
   * Mission statement for ministry that is displayed on email communication.
   */
  @SerialName("ministry_mission")
  @ProtoNumber(42)
  val ministryMission: String? = null,
  /**
   * The email address that should be contacted when a financial support need arises.
   */
  @SerialName("giving_contact_email")
  @ProtoNumber(43)
  val givingContactEmail: String? = null,
  /**
   * The phone number that should be contacted when a financial support need arises.
   */
  @SerialName("giving_contact_phone")
  @ProtoNumber(44)
  val givingContactPhone: String? = null,
  /**
   * The signature line that should be displayed at the bottom of any email communication (before
   * church name.)
   */
  @SerialName("signature")
  @ProtoNumber(45)
  val signature: String? = null,
  /**
   * Which Stripe account should be used for donations to this organization.
   */
  @SerialName("stripe_connect_id")
  @ProtoNumber(46)
  val stripeConnectId: String? = null
) : PatchBaseProfile, red.Serializable {
  init {
    freeze()
  }
}
