// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations.organization_posts

import kotlin.Boolean
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminOrganizationPostsRequest(
  /**
   * The organization id of the organization to create a post for.
   */
  @SerialName("organization_id")
  @ProtoNumber(1)
  @JvmField
  val organizationId: String = "",
  /**
   * The title of the post.
   */
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String = "",
  /**
   * The body of the post containing html tags only bold, italics, links, headings, numbered lists,
   * and bulleted lists.
   */
  @SerialName("body")
  @ProtoNumber(3)
  @JvmField
  val body: String = "",
  /**
   * Indicates if the parent's post should be pulled or inherited by the child when querying a child
   * organizations posts. This only applies to parent organizations.
   */
  @SerialName("inherit")
  @ProtoNumber(4)
  @JvmField
  val inherit: Boolean = false,
  /**
   * Indicates if currently active posts of any children organizations should be set to active=false
   * to allow the new parent entities inherit settings to take affect. This only applies to parent
   * organizations.
   */
  @SerialName("override")
  @ProtoNumber(5)
  @JvmField
  val override: Boolean = false
) : red.Serializable {
  init {
    freeze()
  }
}
