package youversion.red.metrics.aggregates.aggregators

import red.resolvers.metrics.model.IMetric
import red.resolvers.metrics.model.RequestManagerResponseCodeMetric
import youversion.red.dataman.api.model.AnalyticsEvent
import youversion.red.dataman.api.model.metrics.RequestManagerResponseCodeMetricEvent
import youversion.red.metrics.aggregates.IMetricAggregator

object RequestManagerResponseCodeMetricAggregator : IMetricAggregator {

    @Suppress("UNCHECKED_CAST")
    override fun aggregate(
        metrics: List<IMetric>,
        collectedSessionId: String
    ): List<AnalyticsEvent> {
        return metrics.aggregate(
            groupByKeySelector = { metric: RequestManagerResponseCodeMetric ->
                "${metric.url}-${metric.requestMethod}-${metric.responseCode}"
            } as (IMetric) -> String,
            foldInitialValue = RequestManagerResponseCodeMetric(),
            foldOperation = { _: RequestManagerResponseCodeMetric, element: RequestManagerResponseCodeMetric ->
                element.copy()
            } as (IMetric, IMetric) -> IMetric,
            aggregateMapOperation = { metric: RequestManagerResponseCodeMetric, count: Int ->
                RequestManagerResponseCodeMetricEvent(
                    url = metric.url,
                    requestMethod = metric.requestMethod,
                    responseCode = metric.responseCode,
                    count = count,
                    collectedSessionId = collectedSessionId
                )
            } as (IMetric, Int) -> AnalyticsEvent
        )
    }
}
