package youversion.red.moments.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.builtins.ByteArraySerializer
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Calendar
import red.platform.Date
import red.platform.DateSerializer
import red.platform.now
import red.platform.threads.freeze
import red.platform.toCalendar
import youversion.red.banner.model.Banner
import youversion.red.banner.model.BannerConfiguration
import youversion.red.bible.model.ChapterPartSerializer
import youversion.red.bible.model.IChapterPart
import youversion.red.bible.model.VerseOfTheDay
import youversion.red.bible.reference.BibleReference
import youversion.red.stories.api.model.lessons.Lesson
import youversion.red.stories.api.model.modules.BaseModule

@Serializable
data class ClientExtras(
    @ProtoNumber(1)
    val likedByMe: Boolean = false
) {

    init {
        freeze()
    }
}

@Serializable
data class MomentStory(
    @ProtoNumber(1) val lesson: Lesson,
    @Deprecated("This was used for getting the thumbnailUrl which is now accessible")
    @ProtoNumber(2) val module: BaseModule? = null,
    @ProtoNumber(3) val imageUrl: String? = null,
    @ProtoNumber(4) val thumbnailUrl: String? = null,
    @ProtoNumber(5) val gradientColor: Pair<String?, String?>? = null,
    @ProtoNumber(6) val started: Boolean = false
) {

    init {
        freeze()
    }
}

@Serializable
data class MomentPlan(
    @ProtoNumber(1) val id: Int,
    @ProtoNumber(2) val name: String,
    @ProtoNumber(3) val images: List<MomentImages>? = null,
    @ProtoNumber(4) val progress: Float = 0f,
    @ProtoNumber(5) val totalDays: Int = 0,
    @ProtoNumber(6) val organizationId: String? = null,
    @ProtoNumber(7) val organizationImageUrl: String? = null
)

@Serializable
data class MomentPlans(
    @ProtoNumber(1) val myPlans: List<MomentPlan> = emptyList(),
    @ProtoNumber(2) val recommended: List<MomentPlan> = emptyList()
)

@Serializable
data class MomentBadgeProgress(
    @ProtoNumber(1) val id: Int,
    @ProtoNumber(2) val nextLevel: Int,
    @ProtoNumber(3) val imageUrl: String? = null,
    @ProtoNumber(4) val title: String? = null
)

@Serializable
data class MomentPrayer(
    @ProtoNumber(1) val guideId: Int,
    @ProtoNumber(2) val id: Int,
    @ProtoNumber(3) val prayer: String? = null,
    @ProtoNumber(4) val imageUrl: String? = null,
    @ProtoNumber(5) val unreadPrayer: Boolean = false,
    @ProtoNumber(6) val viewed: Boolean = false
)

@Serializable
data class MomentBanner(
    @ProtoNumber(1) val banner: Banner,
    @ProtoNumber(2) val configuration: BannerConfiguration
)

@Serializable
data class MomentVerse(
    @ProtoNumber(1)
    val reference: BibleReference,
    @ProtoNumber(2)
    @Serializable(with = VerseContentSerializer::class)
    val content: IChapterPart?
) {

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as MomentVerse

        if (reference != other.reference) return false

        return true
    }

    override fun hashCode(): Int {
        return reference.hashCode()
    }
}

internal class VerseContentSerializer : KSerializer<IChapterPart?> {

    override val descriptor = buildClassSerialDescriptor("VerseContent")

    override fun deserialize(decoder: Decoder): IChapterPart? {
        val bytes = decoder.decodeSerializableValue(ByteArraySerializer())
        return ChapterPartSerializer.deserialize(bytes)
    }

    override fun serialize(encoder: Encoder, value: IChapterPart?) {
        encoder.encodeSerializableValue(ByteArraySerializer(), value?.let { ChapterPartSerializer.serialize(it) } ?: ByteArray(0))
    }
}

@Serializable
data class Moment(
    @ProtoNumber(1) val id: Long,
    @ProtoNumber(2) val base: MomentBase? = null,
    @ProtoNumber(3) val commenting: MomentCommenting? = null,
    @ProtoNumber(4) @Serializable(with = DateSerializer::class) val created: Date? = null,
    @ProtoNumber(5) val extras: MomentExtras? = null,
    @SerialName("kind_color") @ProtoNumber(6) val kindColor: String? = null,
    @SerialName("kind_id") @ProtoNumber(7) val kindId: String? = null,
    @ProtoNumber(8) val liking: MomentLiking? = null,
    @SerialName("updated_dt") @ProtoNumber(9) @Serializable(with = DateSerializer::class) val updated: Date? = null,
    @ProtoNumber(100) val behaviors: MomentClientSideBehavior? = null,
    @ProtoNumber(101) val clientId: String? = null,
    @ProtoNumber(102) val viewType: MomentViewType = MomentViewType.UNKNOWN,
    @ProtoNumber(103) val source: Long = 0,
    @ProtoNumber(104) val page: Int = 0,
    @ProtoNumber(105) val clientExtras: ClientExtras? = null,
    @ProtoNumber(106) val banner: MomentBanner? = null,
    @Transient val verseOfTheDay: VerseOfTheDay? = null,
    @Transient val story: MomentStory? = null,
    @ProtoNumber(109) val plans: MomentPlans? = null,
    @ProtoNumber(110) val badges: List<MomentBadgeProgress>? = null,
    @ProtoNumber(111) val prayer: MomentPrayer? = null,
    @ProtoNumber(112) val verses: List<MomentVerse?>? = null,
    @Deprecated("Used for the stories moment that was deleted")
    @Transient val storyReferences: List<BibleReference?>? = null,
    @Deprecated("Used for the stories moment that was deleted")
    @Transient val stories: List<MomentStory?>? = null
) {

    init {
        freeze()
    }

    companion object {

        private const val CREATIVE_ID_TIMEOUT = 2592000000L

        const val KIND_BOOKMARK_ID = "bookmark.v1"
        const val KIND_HIGHLIGHT_ID = "highlight.v1"
        const val KIND_IMAGES_ID = "images.v1"
        const val KIND_BANNER = "banner.v0"
        private const val KIND_PLAN_COMPLETE = "plan_completion.v1"
        private const val KIND_PLAN_COMPLETE_V2 = "plan_completion.v2"
        private const val KIND_PLAN_SUBSCRIBED = "plan_subscription.v1"
        private const val KIND_PLAN_SUBSCRIBED_V2 = "plan_subscription.v2"
        private const val KIND_PLAN_SEGMENT_COMPLETE = "plan_segment_completion.v1"
        private const val KIND_PLAN_SEGMENT_COMPLETE_V2 = "plan_segment_completion.v2"
        const val KIND_NOTE_ID = "note.v1"
        private const val KIND_SYSTEM_ID = "system.v1"
        private const val KIND_FRIENDSHIP = "friendship.v2"
        private const val KIND_PROMOTED_RICH_ID = "promoted_rich.v1"
        private const val KIND_PROMOTED_SIMPLE_ID = "promoted_simple.v1"
        private const val KIND_IMAGE_V1_ID = "image.v1"
        private const val KIND_IMAGE_V2_ID = "image.v2"
        private const val KIND_IMAGE_V3_ID = "image.v3"
        const val KIND_COMMENT = "comment"
        private const val KIND_BADGE = "badge.v1"
        private const val KIND_BADGE_V2 = "badge.v2"
        private const val KIND_BADGE_V3 = "badge.v3"
        const val KIND_PLAN_CAROUSEL = "reading_plan_carousel.v1"
        private const val KIND_VOTD_OFFER = "votd.offer"
        private const val KIND_VOTD_SET = "votd.set"
        const val KIND_VOTD = "votd"
        const val KIND_VOTD_IMAGE = "votd_image"
        const val KIND_DOWNLOAD_BIBLE = "download_bible"
        const val KIND_WELCOME = "welcome"
        const val KIND_LANGUAGE = "bible_language"
        const val KIND_KBE = "kids_bible_experience"
        const val KIND_BADGE_PROGRESS = "badge-progress"
        const val KIND_PLANS = "plans"
        const val KIND_GUIDED_PRAYER = "guided-prayer"
        const val KIND_FRIEND_SUGGESTED = "friend_suggestions"
        const val KIND_FRIEND_CONNECT = "friend_suggestions.connect"
        const val KIND_ADD_FRIEND = "add_friend"
        const val KIND_COMMUNITY_PLACEHOLDER = "community_placeholder"
        const val KIND_PRAYER_LIST = "prayer-list"
        const val KIND_IMAGE_DOWNLOAD_SETTINGS = "image-download-settings"

        const val CREATIVE_ID_FRIEND_CONNECT = "client_side_friend_connect"
        const val CREATIVE_ID_FRIEND_SUGGESTED = "client_side_friend_suggest"

        const val HOMEFEED_OFFSET_TIPPY_TOP = 2592000000L
        const val HOMEFEED_OFFSET_TOP = 2591000000L
        const val HOMEFEED_OFFSET_VOTD = 2400000000L
        const val HOMEFEED_OFFSET_IMAGE_DOWNLOAD_SETTINGS = 10003L
        const val HOMEFEED_OFFSET_VOTD_IMAGE = 110000L
        const val HOMEFEED_OFFSET_DOWNLOAD = 10002L
        const val HOMEFEED_OFFSET_LANGUAGE = 9001L
        const val HOMEFEED_OFFSET_PLAN_CAROUSEL = 8000L
        const val HOMEFEED_OFFSET_PRAYER = 7000L
        const val HOMEFEED_OFFSET_BANNER = 6000L
        const val HOMEFEED_OFFSET_KIDS_STORIES = 5000L
        const val HOMEFEED_OFFSET_BADGE_PROGRESS = 4000L
        const val HOMEFEED_OFFSET_SUGGESTED_FRIEND = 3000L

        const val LOCALID_DOWNLOAD = 0
        const val LOCALID_LANGUAGE = 1
        const val LOCALID_ADD_FRIEND = 2
        const val LOCALID_PLANS = 3
        const val LOCALID_WELCOME = 4
        const val LOCALID_FRIEND_SUGGESTED = 5
        const val LOCALID_FRIEND_CONNECT = 6
        const val LOCALID_VOTD_OFFER = 7
        const val LOCALID_VOTD_SET = 8
        const val LOCALID_VOTD = 10
        const val LOCALID_VOTD_IMAGE = 12
        const val LOCALID_BANNER = 13
        const val LOCALID_BADGE_PROGRESS = 14
        const val LOCALID_GUIDED_PRAYER = 16
        const val LOCALID_KIDS_STORIES = 17
        const val LOCALID_COMMUNITY_PLACEHOLDER = 18
        const val LOCALID_PRAYER_LIST = 19
        const val LOCALID_STORIES = 20
        const val LOCALID_IMAGE_DOWNLOAD_SETTINGS = 21

        private const val OFFSET_VOTD = 0
        private const val OFFSET_LOCAL = 1000
        private const val OFFSET_VOTDIMAGE = 2000

        private const val KEY_BASE = "VerseOfTheDay"

        const val VOTD_OFFER_1 = "OFFER_1"
        const val VOTD_OFFER_2 = "OFFER_2"

        fun getType(kindId: String?): MomentViewType {
            return when (kindId ?: return MomentViewType.DEFAULT_MOMENT) {
                KIND_VOTD -> MomentViewType.VOTD
                KIND_PLAN_COMPLETE, KIND_PLAN_COMPLETE_V2 -> MomentViewType.PLAN_COMPLETE_MOMENT
                KIND_PLAN_SEGMENT_COMPLETE, KIND_PLAN_SEGMENT_COMPLETE_V2 -> MomentViewType.PLAN_SEGMENT_MOMENT
                KIND_PLAN_SUBSCRIBED, KIND_PLAN_SUBSCRIBED_V2 -> MomentViewType.PLAN_SUBSCRIBED_MOMENT
                KIND_BOOKMARK_ID -> MomentViewType.BOOKMARK_MOMENT
                KIND_HIGHLIGHT_ID -> MomentViewType.HIGHLIGHT_MOMENT
                KIND_NOTE_ID -> MomentViewType.NOTE_MOMENT
                KIND_SYSTEM_ID -> MomentViewType.SYSTEM_MOMENT
                KIND_FRIENDSHIP -> MomentViewType.FRIENDSHIP_MOMENT
                KIND_IMAGE_V1_ID, KIND_IMAGE_V2_ID, KIND_IMAGE_V3_ID, KIND_IMAGES_ID -> MomentViewType.IMAGE_MOMENT
                KIND_PLANS -> MomentViewType.PLANS
                KIND_PROMOTED_RICH_ID -> MomentViewType.BANNER_RICH_MOMENT
                KIND_PROMOTED_SIMPLE_ID -> MomentViewType.BANNER_SIMPLE_MOMENT
                KIND_FRIEND_SUGGESTED -> MomentViewType.FRIEND_SUGGESTED
                KIND_FRIEND_CONNECT -> MomentViewType.FRIEND_CONNECT
                KIND_VOTD_OFFER -> MomentViewType.VOTD_OFFER
                KIND_VOTD_SET -> MomentViewType.VOTD_SET
                KIND_BADGE, KIND_BADGE_V2, KIND_BADGE_V3 -> MomentViewType.BADGE_MOMENT
                KIND_BADGE_PROGRESS -> MomentViewType.BADGE_PROGRESS
                KIND_KBE -> MomentViewType.KIDS_STORIES
                KIND_LANGUAGE -> MomentViewType.SET_LANGUAGE
                KIND_DOWNLOAD_BIBLE -> MomentViewType.DOWNLOAD_BIBLE
                else -> MomentViewType.DEFAULT_MOMENT
            }
        }

        private fun getMomentId(id: Int, offset: Int): Long =
            -(id + offset).toLong()

        val votdId: Long
            get() = getVotdId(now().toCalendar())

        private fun getVotdId(c: Calendar): Long {
            return getVotdId(c.dayOfYear)
        }

        private fun getVotdId(day: Int): Long {
            return getMomentId(day, OFFSET_VOTD)
        }

        fun getLocalId(id: Int): Long {
            return getMomentId(id, OFFSET_LOCAL)
        }

        val votdImageId: Long
            get() = getVotdImageId(now().toCalendar())

        private fun getVotdImageId(c: Calendar): Long {
            return getVotdImageId(c.dayOfYear)
        }

        private fun getVotdImageId(day: Int): Long {
            return getMomentId(day, OFFSET_VOTDIMAGE)
        }
    }
}
