package red.module

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import red.module.IModule
import red.module.ModuleRegistry
import red.platform.threads.AtomicReference
import red.platform.threads.setAssertTrue
import youversion.red.churches.module.ChurchesSharedModuleInitializer

public object RedChurchesSharedModule : IModule {
  private val factoriesRegistered: AtomicReference<Boolean> = AtomicReference(false)

  private val initialized: AtomicReference<Boolean> = AtomicReference(false)

  private val initializer: ChurchesSharedModuleInitializer = ChurchesSharedModuleInitializer()

  public override val name: String = "churches-shared"

  public override fun registerServiceFactories(): Unit {
    if (factoriesRegistered.value) return
    factoriesRegistered.setAssertTrue(true)
    
  }

  public override fun initialize(): Unit {
    if (initialized.value) return
    initialized.setAssertTrue(true)
    registerServiceFactories()
    initializer.initialize()
    
  }
}
