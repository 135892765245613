// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.plans

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("plans.PlanDayComplete")
data class PlanDayComplete(
  @SerialName("plan_id")
  @ProtoNumber(2)
  val planId: Int? = null,
  @SerialName("day")
  @ProtoNumber(3)
  val day: Int? = null,
  @SerialName("language_tag")
  @ProtoNumber(4)
  val languageTag: String? = null,
  /**
   * my desc
   */
  @SerialName("completion_date")
  @ProtoNumber(5)
  val completionDate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    planId: Int? = null,
    day: Int? = null,
    languageTag: String? = null,
    completionDate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
    createdmicros: Int? = null
  ) : this(planId, day, languageTag, completionDate, red.platform.now(), createdmicros)
}
