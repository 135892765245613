package youversion.red.banner.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer
import red.platform.currentTimeMillis
import red.platform.newDate
import red.platform.now
import red.platform.toDateTime
import red.platform.toLong

@Serializable
data class BannerConfiguration(@ProtoNumber(1) val imageUrl: String)

internal expect fun Int.toBannerImageSize(): BannerImageSize

expect class BannerImageSize

internal expect fun BannerImageSize.toImageSizeString(): String

fun BannerConfiguration.getImageUrl(width: BannerImageSize, height: BannerImageSize, imageId: String): String {
    return imageUrl.replace("{w}", width.toImageSizeString())
        .replace("{h}", height.toImageSizeString())
        .replace("{id}", imageId)
}

typealias BannerId = Int
typealias BannerLists = Map<BannerLocalization, BannerList>
typealias BannerLocalization = String

@Serializable
data class BannerList(val banners: List<Banner>) {

    init {
        check(banners.isNotEmpty()) { "Cannot contain empty banners" }
    }
}

@Serializable
data class BannerStats(
    @ProtoNumber(1) val impressions: Int = 0,
    @ProtoNumber(2) val clicks: Int = 0,
    @ProtoNumber(3) val served: Int = 0,
    @ProtoNumber(4) val dismissed: Int = 0
)

@Serializable
data class BannerServerConfiguration(
    @ProtoNumber(10) val bannerList: Map<BannerLocalization, BannerList> = emptyMap(),
    @ProtoNumber(1) val banners: Map<BannerLocalization, Banner> = emptyMap(), /* deprecated, use bannerList */
    @ProtoNumber(2) val logic: String,
    @ProtoNumber(3) val weight: Double,
    @ProtoNumber(4) val disabled: Boolean = false,
    @ProtoNumber(5) @Serializable(with = DateSerializer::class) val created: Date = now(),
    @ProtoNumber(6) @Serializable(with = DateSerializer::class) val modified: Date = now(),
    @ProtoNumber(7) @Serializable(with = DateSerializer::class) val starts: Date = now(),
    @ProtoNumber(8) @Serializable(with = DateSerializer::class) val expires: Date = newDate((currentTimeMillis().toLong() + 315400000000000L).toDateTime()),
    @ProtoNumber(9) val id: BannerId = 0,
    @ProtoNumber(11) val title: String = "",
    @ProtoNumber(12) val tags: List<String> = emptyList(),
    @ProtoNumber(13) val impressionLimit: Int? = null,
    @ProtoNumber(18) val disableOnImpressionLimit: Boolean = true,
    @ProtoNumber(17) val clickLimit: Int? = null,
    @ProtoNumber(19) val disableOnClickLimit: Boolean = true,
    @ProtoNumber(14) val translationProject: Int? = null,
    @ProtoNumber(15) val variables: Map<BannerLocalization, Map<String, Parameter<String>>> = emptyMap(),
    @ProtoNumber(16) val disabledLanguages: Set<BannerLocalization> = emptySet(),
    @ProtoNumber(20) val excludedLanguages: Map<BannerLocalization, Set<BannerLocalization>> = emptyMap(), // allows us to exclude alternates for a given language
    @ProtoNumber(21) val dailyImpressionLimit: MetricLimit = MetricLimit(),
    @ProtoNumber(22) val dailyClickLimit: MetricLimit = MetricLimit(),
    @ProtoNumber(23) val languagesDailyImpressionLimit: Map<BannerLocalization, MetricLimit> = emptyMap(),
    @ProtoNumber(24) val languagesDailyClickLimit: Map<BannerLocalization, MetricLimit> = emptyMap(),
    @ProtoNumber(25) val disabledBannerUUIDs: Set<String> = emptySet(),
    @ProtoNumber(26) val bannerConfigurationLinks: Map<BannerId, BannerConfigurationLink> = emptyMap(),
    @ProtoNumber(27) val userSegment: String? = null
)

@Serializable
data class BannerConfigurationId(
    @ProtoNumber(1) val id: BannerId
)

@Serializable
data class BannerServerConfigurations(
    @ProtoNumber(1) val banners: List<BannerServerConfiguration> = emptyList()
)

@Serializable
data class MetricLimit(
    @ProtoNumber(1) val limit: Int? = null,
    @ProtoNumber(2) val disableOnLimit: Boolean = true
)

@Serializable
data class BannerConfigurationLink(
    @ProtoNumber(1) val linkTypes: Set<BannerConfigurationLinkType> = emptySet()
)

@Serializable
enum class BannerConfigurationLinkType {
    UNKNOWN,
    ORIGINAL,
    ALTERNATE
}
