// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GivingFrequency(
  @SerialName("id")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.givingmikey.api.model.FrequencyTypeSerializer::class)
  @JvmField
  val id: FrequencyType? = null,
  /**
   * Localized name for the Frequency
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
