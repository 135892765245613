// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class GivingDonationRecord(
  /**
   * ID of donation in Rock
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * The date/time of the donation
   */
  @SerialName("payment_date")
  @ProtoNumber(2)
  @JvmField
  val paymentDate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * Name of giver
   */
  @SerialName("attributed_to")
  @ProtoNumber(3)
  @JvmField
  val attributedTo: String? = null,
  @SerialName("frequency_id")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.givingmikey.api.model.FrequencyTypeSerializer::class)
  @JvmField
  val frequencyId: FrequencyType? = null,
  /**
   * Unique ID of Fund
   */
  @SerialName("fund_id")
  @ProtoNumber(5)
  @JvmField
  val fundId: Int? = null,
  /**
   * Three-character ISO code for the currency being used
   */
  @SerialName("currency")
  @ProtoNumber(6)
  @JvmField
  val currency: String? = null,
  /**
   * Localized gift amount
   */
  @SerialName("payment_amount")
  @ProtoNumber(7)
  @JvmField
  val paymentAmount: String? = null,
  @SerialName("payment_method")
  @ProtoNumber(8)
  @JvmField
  val paymentMethod: GivingPaymentMethod? = null
) : red.Serializable {
  init {
    freeze()
  }
}
