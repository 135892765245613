// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.lessons

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class Cohort(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ADULT("adult", 1),

  TWEEN("tween", 2),

  BIBLE_LOOP("bible_loop", 3);
}

internal class CohortSerializer : KSerializer<Cohort> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.stories.api.model.lessons.Cohort")

  override fun serialize(encoder: Encoder, value: Cohort) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): Cohort {
    if (decoder is JsonDecoder) {
      return Cohort.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> Cohort.UNSUPPORTED_PLACEHOLDER
      1 -> Cohort.ADULT
      2 -> Cohort.TWEEN
      3 -> Cohort.BIBLE_LOOP
      else -> Cohort.UNSUPPORTED_PLACEHOLDER
    }
  }
}
