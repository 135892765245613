// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.organizations.OrganizationRelationshipType

@Serializable
data class OrganizationRelationship(
  /**
   * The parent id.
   */
  @SerialName("parent_id")
  @ProtoNumber(1)
  @JvmField
  val parentId: String? = null,
  /**
   * The child id.
   */
  @SerialName("child_id")
  @ProtoNumber(2)
  @JvmField
  val childId: String? = null,
  @SerialName("type")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.organizations.api.model.organizations.OrganizationRelationshipTypeSerializer::class)
  @JvmField
  val type: OrganizationRelationshipType? = null
) : red.Serializable {
  init {
    freeze()
  }
}
