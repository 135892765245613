// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api

import kotlin.ByteArray
import kotlin.OptIn
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.serializer
import red.platform.http.FormatType
import red.platform.http.Serializer
import red.platform.threads.freeze
import youversion.red.givingmikey.api.model.events.DonateAction
import youversion.red.givingmikey.api.model.events.EditScheduleStarted
import youversion.red.givingmikey.api.model.events.GivingButtonTap
import youversion.red.givingmikey.api.model.events.GivingCauseEventFireBase
import youversion.red.givingmikey.api.model.events.GivingClickEventFireBase
import youversion.red.givingmikey.api.model.events.GivingError
import youversion.red.givingmikey.api.model.events.GivingGave
import youversion.red.givingmikey.api.model.events.GivingLandingEventFireBase
import youversion.red.givingmikey.api.model.events.GivingReady
import youversion.red.givingmikey.api.model.events.GivingScreenView
import youversion.red.givingmikey.api.model.events.GivingStarted
import youversion.red.givingmikey.api.model.events.GivingValueUpdated
import youversion.red.givingmikey.api.model.events.PaymentMethodCreated
import youversion.red.givingmikey.api.model.events.ScheduleSaved
import youversion.red.givingmikey.deeplink.GivingMikeyDeepLinkHandler

object GivingMikeyApiSerializer : Serializer {
  private val context: SerializersModule = SerializersModule {

      }

  private val json: Json = Json { isLenient = true; ignoreUnknownKeys = true;
      allowSpecialFloatingPointValues = true; serializersModule = context }

  @OptIn(kotlinx.serialization.ExperimentalSerializationApi::class)
  private val protobuf: ProtoBuf = ProtoBuf { encodeDefaults = true; serializersModule = context }

  init {
    freeze()}

  override fun <T> serialize(
    type: FormatType,
    serializer: SerializationStrategy<T>,
    obj: T
  ): ByteArray = when (type) {
     FormatType.JSON -> json.encodeToString(serializer, obj).encodeToByteArray()
     FormatType.PROTOBUF -> protobuf.encodeToByteArray(serializer, obj)
  }
  override fun <T> deserialize(
    type: FormatType,
    deserializer: DeserializationStrategy<T>,
    data: ByteArray
  ): T? = when (type) {
     FormatType.JSON -> json.decodeFromString(deserializer, data.decodeToString())
     FormatType.PROTOBUF -> protobuf.decodeFromByteArray(deserializer, data)
  }}
