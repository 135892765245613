// Auto-Generated from OpenAPI Spec
package youversion.red.discovery.api.model.events

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.AnalyticsDiscoverSectionType
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class DiscoverItemTapFireBase(
  @SerialName("section_type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.analytics.AnalyticsDiscoverSectionTypeSerializer::class)
  val sectionType: AnalyticsDiscoverSectionType =
      youversion.red.analytics.AnalyticsDiscoverSectionType.TEACHING_CLIPS,
  /**
   * Index of the section containing the tapped item. The section index for the search bar is null.
   */
  @SerialName("section_index")
  @ProtoNumber(2)
  val sectionIndex: Int? = null,
  /**
   * Index of the tapped item in the list it's contained in
   */
  @SerialName("tapped_index")
  @ProtoNumber(3)
  val tappedIndex: Int? = null,
  /**
   * The item description (e.g. reading plan title, video title, More items (plans, video, image),
   * emotion text)
   */
  @SerialName("item_text")
  @ProtoNumber(4)
  val itemText: String? = null,
  /**
   * plan id, video id, null
   */
  @SerialName("item_id")
  @ProtoNumber(5)
  val itemId: Int? = null,
  /**
   * App interface language
   */
  @SerialName("app_language")
  @ProtoNumber(6)
  val appLanguage: String? = null,
  /**
   * Plan language
   */
  @SerialName("plan_language")
  @ProtoNumber(7)
  val planLanguage: String? = null,
  /**
   * Video language
   */
  @SerialName("video_language")
  @ProtoNumber(8)
  val videoLanguage: String? = null,
  /**
   * True when the item was tapped within the "See All" list view, otherwise false
   */
  @SerialName("tapped_in_see_all")
  @ProtoNumber(9)
  val tappedInSeeAll: Boolean? = null,
  /**
   * organization id
   */
  @SerialName("item_sid")
  @ProtoNumber(10)
  val itemSid: String? = null
) : Event(), red.Serializable {
  override val key: String = "discover_item_tap"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putString("section_type", sectionType.serialName)
    sectionIndex?.let { bundle.putInt("section_index", sectionIndex) }
    tappedIndex?.let { bundle.putInt("tapped_index", tappedIndex) }
    itemText?.let { bundle.putString("item_text", itemText) }
    itemId?.let { bundle.putInt("item_id", itemId) }
    appLanguage?.let { bundle.putString("app_language", appLanguage) }
    planLanguage?.let { bundle.putString("plan_language", planLanguage) }
    videoLanguage?.let { bundle.putString("video_language", videoLanguage) }
    tappedInSeeAll?.let { bundle.putBoolean("tapped_in_see_all", tappedInSeeAll) }
    itemSid?.let { bundle.putString("item_sid", itemSid) }
    return bundle
  }
}
