// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.Int
import kotlin.Suppress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import red.Serializable
import red.platform.Date
import red.platform.threads.freeze

interface AnalyticsEvent : Serializable {
  val created: @kotlinx.serialization.Serializable(with=red.platform.DateSerializer::class) Date

  /**
   * The microsecond component of the Created timestamp
   */
  val createdmicros: Int?

  companion object {
    @Suppress("unchecked_cast")
    fun serializer(): KSerializer<AnalyticsEvent> = PolymorphicSerializer(AnalyticsEvent::class)
  }
}
