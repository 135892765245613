// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Boolean
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ConvertChurchResponse(
  /**
   * If performed, convert will carry out the conversion to single or multi site
   */
  @SerialName("performed")
  @ProtoNumber(1)
  @JvmField
  val performed: Boolean? = null,
  /**
   * The target organization you want to take single-site or multi-site.
   */
  @SerialName("target_organization")
  @ProtoNumber(3)
  @JvmField
  val targetOrganization: AdminOrganization? = null
) : red.Serializable {
  init {
    freeze()
  }
}
