// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.admin.content_partners.ExternalIds
import youversion.red.organizations.api.model.profiles.MutateSocials
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
@SerialName("organizations.organization_profiles.PatchContentProfile")
data class PatchContentProfile(
  @SerialName("status")
  @ProtoNumber(77)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  val status: ProfileStatus? = null,
  /**
   * The name of the contact person
   */
  @SerialName("primary_contact_name")
  @ProtoNumber(79)
  val primaryContactName: String? = null,
  /**
   * The email of the contact person
   */
  @SerialName("primary_contact_email")
  @ProtoNumber(80)
  val primaryContactEmail: String? = null,
  @SerialName("social_media")
  @ProtoNumber(81)
  val socialMedia: List<MutateSocials>? = null,
  @SerialName("external_ids")
  @ProtoNumber(82)
  val externalIds: List<ExternalIds>? = null,
  @SerialName("hubspot_partner_type")
  @ProtoNumber(83)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.HubspotTypesSerializer::class)
  val hubspotPartnerType: HubspotTypes? = null
) : PatchBaseProfile, red.Serializable {
  init {
    freeze()
  }
}
