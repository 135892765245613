package youversion.red.images.service

import kotlinx.coroutines.delay
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import red.platform.DateTime
import red.platform.Log
import red.platform.currentTimeMillis
import red.platform.http.DefaultSerializer
import red.platform.http.FormatType
import red.platform.io.File
import red.platform.io.deleteRecursively
import red.platform.io.fileSystem
import red.platform.settings.Settings
import red.platform.threads.SuspendLockOwner
import red.platform.threads.SuspendedLock
import red.platform.threads.freeze
import red.platform.threads.newLockOwner
import red.platform.threads.sync
import red.platform.toLong
import red.service.DefaultService
import red.tasks.CoroutineDispatchers
import youversion.red.bible.reference.BibleReference
import youversion.red.images.api.ImagesApi
import youversion.red.images.model.ImageCategory
import youversion.red.images.model.ImageMetadata
import youversion.red.images.model.ImageOrientation
import youversion.red.images.model.Images
import youversion.red.images.service.ImagesCacheProvider.cacheDir
import youversion.red.images.service.ImagesCacheProvider.writeCache
import youversion.red.versification.service.VersificationService

@DefaultService(IImagesService::class)
internal class ImagesServiceImpl : IImagesService {

    private val versificationService by VersificationService()

    override suspend fun getImage(id: Int, cacheOnly: Boolean): ImageMetadata? =
        (getCachedImage(id)?.also {
            if (!cacheOnly) {
                CoroutineDispatchers.launch {
                    getImageAndSetCache(id)
                }
            }
        } ?: (if (cacheOnly) null else getImageAndSetCache(id))).also {
            purgeExpiredCacheIfNeeded()
        }

    override suspend fun getImagesForReader(
        reference: BibleReference?,
        languageTag: String?,
        category: ImageCategory?,
        orientation: ImageOrientation?,
        page: Int
    ): Images {
        val versifiedReference = reference?.let {
            val versification = versificationService.newVersification()
            versification.versify(reference, 1)[0]
        }
        Log.w("ImageService", "Reference: $reference - $versifiedReference")
        return getImages(versifiedReference, languageTag, category, orientation, page)
    }

    override suspend fun getImages(
        reference: BibleReference?,
        languageTag: String?,
        category: ImageCategory?,
        orientation: ImageOrientation?,
        page: Int
    ): Images {
        return (getCachedImages(
            reference,
            languageTag,
            category,
            orientation,
            page
        )?.let { imagesCache ->
            imagesCache.images.takeIf { it != null }?.also {
                CoroutineDispatchers.launch {
                    getImagesAndSetCache(
                        cache = imagesCache,
                        reference = reference,
                        languageTag = languageTag,
                        category = category,
                        orientation = orientation,
                        page = page
                    )
                }
            }
        } ?: getImagesAndSetCache(
            reference = reference,
            languageTag = languageTag,
            category = category,
            orientation = orientation,
            page = page
        )?.images ?: Images(null, null)).also {
            purgeExpiredCacheIfNeeded()
        }
    }

    override suspend fun clearCache() = cacheDir.deleteRecursively()

    internal suspend fun getCachedImages(
        reference: BibleReference?,
        languageTag: String?,
        category: ImageCategory?,
        orientation: ImageOrientation?,
        page: Int
    ): ImagesCache? = try {
        ImagesCache(
            reference = reference,
            languageTag = languageTag,
            category = category,
            orientation = orientation,
            page = page,
            images = null
        ).cacheFile().deserialize().takeIf {
            it.page == page &&
                    it.orientation == orientation &&
                    it.category == category &&
                    it.languageTag == languageTag &&
                    it.reference == reference
        }
    } catch (e: Exception) {
        if (e.message == "file does not exist") {
            null
        } else {
            Log.e("ImagesService", "Error getting cached images", e)
            null
        }
    }

    internal suspend fun getImagesAndSetCache(
        cache: ImagesCache? = null,
        reference: BibleReference?,
        languageTag: String?,
        category: ImageCategory?,
        orientation: ImageOrientation?,
        page: Int
    ): ImagesCache? = try {
        try {
            ImagesApi.getImages(
                reference?.usfmArray?.toList() ?: emptyList(),
                languageTag,
                category?.type,
                orientation?.type,
                page
            )
        } catch (e: Exception) {
            Log.e("ImageService", "Failed to get images from network", e)
            null
        }?.let { networkImages ->
            val imagesCache = cache ?: ImagesCache(
                reference = reference,
                languageTag = languageTag,
                category = category,
                orientation = orientation,
                page = page,
                images = null
            )
            imagesCache.copy(images = networkImages).also {
                it.cacheFile().writeCache(it, ImagesCache.serializer())
            }
        }
    } catch (e: Exception) {
        Log.e("ImagesService", "Error updating images cache", e)
        null
    }

    internal suspend fun getCachedImage(id: Int): ImageMetadata? = try {
        fileSystem.open(id.cacheFile())?.let {
            DefaultSerializer.deserialize(FormatType.PROTOBUF, ImageMetadata.serializer(), it)
        }
    } catch (e: Exception) {
        Log.e("ImagesService", "Error getting cached image for image id: $id", e)
        null
    }

    private suspend fun getImageAndSetCache(id: Int): ImageMetadata? = try {
        ImagesApi.getImage(id)?.also {
            id.cacheFile().writeCache(it, ImageMetadata.serializer())
        }
    } catch (e: Exception) {
        Log.e("ImagesService", "Error updating image cache for image id: $id", e)
        null
    }

    private fun purgeExpiredCacheIfNeeded() = CoroutineDispatchers.launch {
        delay(10_000)
        ImagesCacheProvider.purgeExpiredCacheFromDisk()
    }
}

@kotlinx.serialization.Serializable
internal data class ImagesCache(
    val images: Images?,
    val reference: BibleReference?,
    val languageTag: String?,
    val category: ImageCategory?,
    val orientation: ImageOrientation?,
    val page: Int
) {
    init {
        freeze()
    }
}

internal object ImagesCacheProvider {

    val lock = SuspendedLock()
    private val cacheTimesLock = SuspendedLock()

    suspend fun <T> File.writeCache(
        data: T,
        serializer: SerializationStrategy<T>,
        cacheTime: DateTime = currentTimeMillis()
    ) {
        val lockOwner = newLockOwner()
        lock.sync(lockOwner) {
            if (!fileSystem.exists(cacheDir)) {
                fileSystem.mkdirs(cacheDir)
            }
            fileSystem.writeFile(
                this@writeCache,
                DefaultSerializer.serialize(FormatType.PROTOBUF, serializer, data)
            )
        }
        writeCacheTimes(lockOwner) {
            it + Pair(fileSystem.getName(this@writeCache), cacheTime.toLong())
        }
    }

    suspend fun purgeExpiredCacheFromDisk(
        force: Boolean = false,
        currentTimeInMillis: Long = currentTimeMillis().toLong()
    ) {
        val lockOwner = newLockOwner()
        if (!force && currentTimeInMillis < (Settings.redSettings.getLong(
                KEY_LAST_PURGE,
                0
            ) + PURGE_TIME)
        ) {
            return
        }
        lock.sync(lockOwner) {
            val cachedItems = getCacheTimes(lockOwner)
            val cacheTimeDeletes = mutableSetOf<String>()
            cachedItems.forEach {
                try {
                    val cacheTimeInMillis = it.value
                    val expirationTime = cacheTimeInMillis + CACHE_TIME
                    if (currentTimeInMillis > expirationTime) {
                        fileSystem.deleteFile(fileSystem.newFile(cacheDir, it.key))
                        cacheTimeDeletes.add(it.key)
                    }
                } catch (e: Exception) {
                    Log.e("ImagesService", "Error deleting cache file", e)
                }
            }
            writeCacheTimes(lockOwner) {
                it - cacheTimeDeletes
            }
            Settings.redSettings.edit().putLong(KEY_LAST_PURGE, currentTimeInMillis).commit()
        }
    }

    internal suspend fun getCacheTimes(lockOwner: SuspendLockOwner = newLockOwner()): Map<String, Long> =
        cacheTimesLock.sync(lockOwner) {
            fileSystem.open(cacheTimeFile)
        }?.let {
            DefaultSerializer.deserialize(
                FormatType.JSON,
                MapSerializer(String.serializer(), Long.serializer()),
                it
            )
        } ?: emptyMap()

    private suspend fun writeCacheTimes(
        lockOwner: SuspendLockOwner,
        block: (currentCache: Map<String, Long>) -> Map<String, Long>
    ) {
        val newCache = DefaultSerializer.serialize(
            FormatType.JSON,
            MapSerializer(String.serializer(), Long.serializer()),
            block(getCacheTimes(lockOwner))
        )
        cacheTimesLock.sync(lockOwner) {
            fileSystem.writeFile(cacheTimeFile, newCache)
        }
    }

    internal val cacheDir = fileSystem.newFile(fileSystem.internalStorage, "images")
    private val cacheTimeFile = fileSystem.newFile(cacheDir, "cache_time.cache")

    private const val CACHE_TIME = 1209600000 // 14 days
    private const val PURGE_TIME = 172800000 // 2 days
    private const val KEY_LAST_PURGE = "red.settings.images.last_purge"
}

internal fun ImagesCache.cacheFile(): File = fileSystem.newFile(
    cacheDir,
    "images_" + listOfNotNull(
        reference,
        languageTag,
        category,
        orientation,
        page
    ).joinToString("_") + ".cache"
)

internal suspend fun File.deserialize(): ImagesCache = ImagesCacheProvider.lock.sync {
    if (!fileSystem.exists(this)) {
        error("file does not exist")
    }
    return fileSystem.open(this)?.let {
        DefaultSerializer.deserialize(FormatType.PROTOBUF, ImagesCache.serializer(), it)
    } ?: error("Could not deserialize images cache")
}

private fun Int.cacheFile(): File = fileSystem.newFile(
    cacheDir,
    "image_$this.cache"
)
