// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminTime(
  /**
   * Start date of the event.
   */
  @SerialName("start_date")
  @ProtoNumber(1)
  @JvmField
  val startDate: String? = null,
  /**
   * This is the logical start time of the event 24 hour time format.
   */
  @SerialName("logical_start_time")
  @ProtoNumber(2)
  @JvmField
  val logicalStartTime: String? = null,
  /**
   * This is the duration of the event in minutes
   */
  @SerialName("duration")
  @ProtoNumber(3)
  @JvmField
  val duration: Int? = null,
  /**
   * The location information for the event.
   */
  @SerialName("locations")
  @ProtoNumber(4)
  @JvmField
  val locations: List<String>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
