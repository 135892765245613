package youversion.red.metrics.aggregates.aggregators

import red.platform.newDate
import red.platform.toDateTime
import red.platform.toLong
import red.platform.toMillis
import red.resolvers.metrics.model.IMetric
import red.resolvers.metrics.model.RequestManagerCumulativeResponseMetric
import youversion.red.dataman.api.model.AnalyticsEvent
import youversion.red.dataman.api.model.metrics.RequestManagerCumulativeResponseMetricEvent
import youversion.red.metrics.aggregates.IMetricAggregator

object RequestManagerCumulativeResponseMetricAggregator : IMetricAggregator {

    @Suppress("UNCHECKED_CAST")
    override fun aggregate(
        metrics: List<IMetric>,
        collectedSessionId: String
    ): List<AnalyticsEvent> {
        return metrics.aggregate(
            groupByKeySelector = { _: RequestManagerCumulativeResponseMetric ->
                ""
            } as (IMetric) -> String,
            foldInitialValue = RequestManagerCumulativeResponseMetric(
                earliestCollectedTimePlaceholder = (metrics.first() as RequestManagerCumulativeResponseMetric).earliestCollectedTimePlaceholder,
                latestCollectedTimePlaceholder = (metrics.first() as RequestManagerCumulativeResponseMetric).latestCollectedTimePlaceholder
            ),
            foldOperation = { aggregate: RequestManagerCumulativeResponseMetric, element: RequestManagerCumulativeResponseMetric ->
                element.copy(
                    responseBodyBytes = aggregate.responseBodyBytes + element.responseBodyBytes,
                    earliestCollectedTimePlaceholder = newDate(
                        minOf(
                            aggregate.earliestCollectedTimePlaceholder.toMillis().toLong(),
                            element.earliestCollectedTimePlaceholder.toMillis().toLong()
                        ).toDateTime()
                    ),
                    latestCollectedTimePlaceholder = newDate(
                        maxOf(
                            aggregate.latestCollectedTimePlaceholder.toMillis().toLong(),
                            element.latestCollectedTimePlaceholder.toMillis().toLong()
                        ).toDateTime()
                    )
                )
            } as (IMetric, IMetric) -> IMetric,
            aggregateMapOperation = { metric: RequestManagerCumulativeResponseMetric, count: Int ->
                RequestManagerCumulativeResponseMetricEvent(
                    totalResponseBodyBytes = metric.responseBodyBytes,
                    earliestMetricCollectedTime = metric.earliestCollectedTimePlaceholder,
                    latestMetricCollectedTime = metric.latestCollectedTimePlaceholder,
                    count = count,
                    collectedSessionId = collectedSessionId
                )
            } as (IMetric, Int) -> AnalyticsEvent
        )
    }
}
