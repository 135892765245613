package youversion.red.discover.service

import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.flow.firstOrNull
import red.platform.http.RequestManager
import red.platform.http.headers.AcceptLanguage.Companion.locale
import red.platform.localization.ColorProvider
import red.platform.localization.LocaleContext
import red.platform.localization.Locales
import red.platform.localization.LocalizationKey
import red.platform.localization.Localizer
import red.platform.localization.YVColorKey
import red.platform.localization.resolve
import red.service.DefaultService
import red.tasks.CoroutineDispatchers.async
import youversion.red.churches.model.AddressProfile
import youversion.red.churches.service.ChurchesService
import youversion.red.discover.api.model.DiscoverChurch
import youversion.red.discover.api.model.DiscoverEmotion
import youversion.red.discover.api.model.DiscoverPlan
import youversion.red.discover.api.model.RecommendedPlans
import youversion.red.discover.util.Renditions
import youversion.red.discovery.api.DiscoveryApi
import youversion.red.movies.Movie
import youversion.red.movies.MoviePublisher
import youversion.red.movies.api.MoviesUIContext
import youversion.red.movies.service.MoviesService
import youversion.red.plans.service.PlansService
import youversion.red.search.api.SearchApi
import youversion.red.search.api.model.UserIntent
import youversion.red.security.service.UsersService

@DefaultService(IDiscoverService::class)
internal class DiscoverServiceImpl : IDiscoverService {

    private val usersService by UsersService()
    private val plansService by PlansService()
    private val moviesService by MoviesService()
    private val churchesService by ChurchesService()

    private class EmotionKeys(
        val textKey: LocalizationKey,
        val colorIntKey: YVColorKey
    )

    private val orderedEmotionsKeys by lazy {
        listOf(
            EmotionKeys(
                LocalizationKey.EMOTION_LOVE,
                YVColorKey.MAGENTA30
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_ANXIETY,
                YVColorKey.BLUE30
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_ANGER,
                YVColorKey.ORANGE30
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_HOPE,
                YVColorKey.TEAL30
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_DEPRESSION,
                YVColorKey.PURPLE40
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_PEACE,
                YVColorKey.BLUE40
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_FEAR,
                YVColorKey.PURPLE30
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_STRESS,
                YVColorKey.YELLOW40
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_PATIENCE,
                YVColorKey.BLUE30
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_TEMPTATION,
                YVColorKey.ORANGE40
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_PRIDE,
                YVColorKey.GRAY40
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_DOUBT,
                YVColorKey.GRAY30
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_JOY,
                YVColorKey.GREEN40
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_JEALOUSY,
                YVColorKey.MAGENTA40
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_LOSS,
                YVColorKey.GRAY40
            ),
            EmotionKeys(
                LocalizationKey.EMOTION_HEALING,
                YVColorKey.GREEN30
            )
        )
    }

    override suspend fun getOrder() = DiscoveryApi.getSections().sections

    override suspend fun getTeachingClips(
        languageTag: String?,
        pageSize: Int?
    ): Pair<List<Pair<Movie, MoviePublisher?>>, Boolean> {
        val movies = DiscoveryApi.getStoryVideos(
            languageTag = languageTag,
            pageSize = pageSize
        )?.storyvideos?.mapNotNull {
            it.id?.let { videoId ->
                val movie = moviesService.getMovie(videoId, MoviesUIContext.Movies)
                movie?.let {
                    Pair(
                        movie,
                        movie.publisherId?.let { publisherId ->
                            moviesService.getPublisher(
                                publisherId
                            )
                        })
                }
            }
        }
        return Pair(movies ?: emptyList(), false)
    }

    private suspend fun getPlansCollection(
        slug: String,
        languageTag: String,
        page: Int
    ): Pair<List<DiscoverPlan>?, Int?>? {
        val collectionId =
            plansService.getDiscover(languageTag)?.items?.firstOrNull { it.slug == slug }?.id
                ?: return null
        val collection =
            plansService.getDiscoverCollection(collectionId, page)?.collections?.firstOrNull()
        val plans =
            collection?.items?.mapNotNull {
                val planId = it.id ?: return@mapNotNull null
                DiscoverPlan(planId, it.title, it.formattedLength)
            }
        return Pair(plans, collection?.nextPage)
    }

    override suspend fun getFeaturedPlans(
        languageTag: String,
        page: Int
    ): Pair<List<DiscoverPlan>?, Int?>? =
        getPlansCollection(SLUG_FEATURED_PLANS, languageTag, page)

    override suspend fun getNewToFaithPlans(
        languageTag: String,
        page: Int
    ): Pair<List<DiscoverPlan>?, Int?>? =
        getPlansCollection(SLUG_NEW_TO_FAITH, languageTag, page)

    override suspend fun getFeaturedVideos(
        page: Int?,
        pageSize: Int?
    ): Pair<List<Pair<Movie, MoviePublisher?>>, Boolean> = moviesService.getMoviesInCollection(
        if (RequestManager.staging) 33 else 130,
        pageSize = pageSize,
        page = page
    ).first.let { movies ->
        Pair(
            movies.movies.mapNotNull {
                val publisherId = it.publisherId ?: return@mapNotNull null
                Pair(it, moviesService.getPublisher(publisherId))
            },
            movies.hasNextPage
        )
    }

    override suspend fun getRecommendedPlans(
        languageTag: String,
        planId: Int,
        page: Int?,
        pageSize: Int?
    ): Pair<RecommendedPlans?, Boolean?> {
        val title = plansService.getPlan(planId, languageTag)?.name

        val recommendedPlans = DiscoveryApi.getRelatedPlans(planId, languageTag, pageSize, page)
        val plans = recommendedPlans?.relatedPlans?.mapNotNull {
            val relatedPlanId = it.id

            if (relatedPlanId != null) {
                val plan = plansService.getPlan(relatedPlanId, languageTag)
                DiscoverPlan(
                    relatedPlanId,
                    plan?.name,
                    plan?.formattedLength
                )
            } else {
                null
            }
        }
        return Pair(RecommendedPlans(title, plans), recommendedPlans?.nextPage)
    }

    override suspend fun getTrendingPlans(
        languageTag: String,
        page: Int?,
        pageSize: Int?,
        treatmentId: Int?
    ): Pair<List<DiscoverPlan>?, Boolean?> {
        val trends = DiscoveryApi.getTrends(languageTag, page = page, pageSize = pageSize, treatmentId = treatmentId)
        val plans = trends?.trends?.first { it.kind == "plans" }?.kindIds?.map {
            val plan = plansService.getPlan(it, languageTag)
            DiscoverPlan(
                it,
                plan?.name,
                plan?.formattedLength
            )
        }
        return Pair(plans, trends?.nextPage)
    }

    override suspend fun getFriendsPlans(
        languageTag: String,
        page: Int?,
        pageSize: Int?
    ): Pair<List<DiscoverPlan>?, Boolean?>? {
        if (usersService.getCurrentUser() == null) return null
        val plansOfFriends = DiscoveryApi.getFriendsPlans(page = page, pageSize = pageSize)
        val plans = plansOfFriends?.planOfFriends?.mapNotNull {
            val planId = it.planId ?: return@mapNotNull null
            val plan = plansService.getPlan(planId, languageTag)
            DiscoverPlan(
                planId,
                plan?.name,
                plan?.formattedLength,
                it.userIds
            )
        }
        return Pair(plans, plansOfFriends?.nextPage)
    }

    override suspend fun getEmotions(languageTag: String): List<DiscoverEmotion> {
        return orderedEmotionsKeys.mapNotNull {
            val emotionText = Localizer.provider?.localizedString(it.textKey)
            val emotionColorInt = ColorProvider.provider?.getColor(it.colorIntKey)

            if (emotionText != null && emotionColorInt != null)
                DiscoverEmotion(emotionText, emotionColorInt)
            else
                null
        }
    }

    override suspend fun getPlanThumbnail(id: Int, minWidth: Int): String? {
        val configuration = plansService.getConfiguration()
        return Renditions.getImageUrlFormatSquare(id, minWidth, configuration.images.plans)
    }

    override suspend fun getChurchesMyFriendsAttend(
        page: Int?,
        pageSize: Int?
    ): Pair<List<DiscoverChurch>?, Boolean?>? {
        if (usersService.getCurrentUser() == null) return null

        val churchesOfFriends = DiscoveryApi.getFriendsChurches(page = page, pageSize = pageSize)

        val churches = churchesOfFriends?.churchOfFriends?.map {
            async {
                val church = churchesService.getOrganization(it.churchId ?: "").firstOrNull()

                if (church != null) {
                    val profiles = async {
                        churchesService.getProfiles(church.id).firstOrNull()
                    }
                    val addressProfile =
                        profiles.await()?.find { profile -> profile is AddressProfile } as AddressProfile?

                    val membersCount = async {
                        churchesService.getMembersCount(church.id).firstOrNull()
                    }

                    DiscoverChurch(
                        it.churchId ?: "",
                        church.name,
                        church.imageUrl,
                        church.parentOrganization?.name,
                        addressProfile,
                        it.userIds,
                        membersCount.await()
                    )
                } else {
                    null
                }
            }
        }?.awaitAll()?.filterNotNull()

        return Pair(churches, churchesOfFriends?.nextPage)
    }

    override suspend fun getChurchesNearMe(
        latitude: Double,
        longitude: Double,
        page: Int?,
        pageSize: Int?
    ): Pair<List<DiscoverChurch>?, Boolean?> {
        val appLocale = Locales.resolve(LocaleContext.DEFAULT, null).firstOrNull() ?: locale
        val churchesResult = SearchApi.getChurches(
            null,
            null,
            null,
            appLocale.getApiTag2(),
            latitude,
            longitude,
            false,
            page,
            pageSize,
            null,
            UserIntent.UNKNOWN
        )
        val churches = churchesResult?.churches?.map {
            async {
                val church = churchesService.getOrganization(it.id ?: "").firstOrNull()

                if (church != null) {
                    val profiles = async {
                        churchesService.getProfiles(church.id).firstOrNull()
                    }

                    val userIds = async {
                        val currentUser = usersService.getCurrentUser()
                        churchesService.getOrganizationMembers(church.id, currentUser?.id)
                            .firstOrNull()?.data?.map { orgMember ->
                                orgMember.userId.toInt()
                            }
                    }

                    val membersCount = async {
                        churchesService.getMembersCount(church.id).firstOrNull()
                    }

                    val addressProfile =
                        profiles.await()?.find { profile -> profile is AddressProfile } as AddressProfile?

                    DiscoverChurch(
                        church.id,
                        church.name,
                        church.imageUrl,
                        church.parentOrganization?.name,
                        addressProfile,
                        userIds.await(),
                        membersCount.await()
                    )
                } else {
                    null
                }
            }
        }?.awaitAll()?.filterNotNull()
        return Pair(churches, churchesResult?.nextPage)
    }

    companion object {

        private const val SLUG_FEATURED_PLANS = "featured-plans"
        private const val SLUG_NEW_TO_FAITH = "new-to-faith"
    }
}
