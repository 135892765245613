// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Int
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.organizations.api.model.admin.organizations.PastorPostMutation
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
@SerialName("organizations.organization_profiles.PatchChurchProfile")
data class PatchChurchProfile(
  @SerialName("pastor")
  @ProtoNumber(11)
  val pastor: PastorPostMutation? = null,
  @SerialName("status")
  @ProtoNumber(12)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  /**
   * This is the count of how many locations the organization has. This can never be 1.
   */
  @SerialName("location_count")
  @ProtoNumber(13)
  val locationCount: Int = 0,
  /**
   * This is the day of the week the organization starts on. This is an integer as the days of week
   * are equal to integers.
   */
  @SerialName("week_starts_on")
  @ProtoNumber(14)
  val weekStartsOn: Int = 0,
  /**
   * This is the weekly attendance of the church profile.
   */
  @SerialName("weekly_attendance")
  @ProtoNumber(15)
  val weeklyAttendance: Int? = null,
  @SerialName("support_user_id")
  @ProtoNumber(16)
  val supportUserId: Int? = null,
  /**
   * This is the time the organization got verified.
   */
  @SerialName("verified_dt")
  @ProtoNumber(17)
  val verifiedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : PatchBaseProfile, red.Serializable {
  init {
    freeze()
  }
}
