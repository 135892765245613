// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.events.event_content

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.events.event_contents.BaseContent

@Serializable
@SerialName("events.event_content.PlanContent")
data class PlanContent(
  /**
   * The ID of the plan
   */
  @SerialName("plan_id")
  @ProtoNumber(40)
  val planId: Int = 0,
  /**
   * The language tag of the plan
   */
  @SerialName("language_tag")
  @ProtoNumber(41)
  val languageTag: String = "",
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Long = 0L,
  @SerialName("comment")
  @ProtoNumber(3)
  override val comment: String? = null
) : BaseContent, red.Serializable {
  init {
    freeze()
  }
}
