// Auto-Generated from OpenAPI Spec
package youversion.red.banner.model.userstate.state.banner

import kotlin.collections.List
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.banner.model.userstate.state.State

@Serializable
@SerialName("state.banner.BannerImpressionsState")
data class BannerImpressionsState(
    @SerialName("impressions")
    @ProtoNumber(3)
    val impressions: List<BannerImpression>? = null
) : State, red.Serializable {
    init {
        freeze()
    }
}
