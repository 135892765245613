package youversion.red.metrics.service.repository

import kotlin.reflect.KClass
import kotlinx.serialization.KSerializer
import red.resolvers.metrics.model.IMetric
import red.resolvers.metrics.model.RequestManagerCumulativeResponseMetric
import red.resolvers.metrics.model.RequestManagerExecutionExceptionMetric
import red.resolvers.metrics.model.RequestManagerResponseCodeMetric
import red.resolvers.metrics.model.RequestManagerUrlMetric

internal object MetricsConfiguration {

    private const val REQUEST_MANAGER_URL_METRIC_CONFIG_ID: Byte = 1
    private const val REQUEST_MANAGER_RESPONSE_CODE_METRIC_CONFIG_ID: Byte = 2
    private const val REQUEST_MANAGER_EXECUTION_EXCEPTION_METRIC_CONFIG_ID: Byte = 3
    private const val REQUEST_MANAGER_CUMULATIVE_RESPONSE_METRIC_CONFIG_ID: Byte = 4

    private val classNameToIdMap: Map<KClass<out IMetric>, Byte> = mapOf(
        RequestManagerUrlMetric::class to REQUEST_MANAGER_URL_METRIC_CONFIG_ID,
        RequestManagerResponseCodeMetric::class to REQUEST_MANAGER_RESPONSE_CODE_METRIC_CONFIG_ID,
        RequestManagerExecutionExceptionMetric::class to REQUEST_MANAGER_EXECUTION_EXCEPTION_METRIC_CONFIG_ID,
        RequestManagerCumulativeResponseMetric::class to REQUEST_MANAGER_CUMULATIVE_RESPONSE_METRIC_CONFIG_ID
    )

    private val idToSerializerMap: Map<Byte, KSerializer<out IMetric>> = mapOf(
        REQUEST_MANAGER_URL_METRIC_CONFIG_ID to RequestManagerUrlMetric.serializer(),
        REQUEST_MANAGER_RESPONSE_CODE_METRIC_CONFIG_ID to RequestManagerResponseCodeMetric.serializer(),
        REQUEST_MANAGER_EXECUTION_EXCEPTION_METRIC_CONFIG_ID to RequestManagerExecutionExceptionMetric.serializer(),
        REQUEST_MANAGER_CUMULATIVE_RESPONSE_METRIC_CONFIG_ID to RequestManagerCumulativeResponseMetric.serializer()
    )

    fun getMetricSerializerConfigurationId(metric: IMetric): Byte {
        val clazz = metric::class
        return classNameToIdMap[clazz]
            ?: throw IllegalStateException("Metric Configuration ID not found for metric of class $clazz")
    }

    fun getMetricSerializerFromConfigurationId(id: Byte): KSerializer<out IMetric> {
        return idToSerializerMap[id]
            ?: throw IllegalStateException("Metric Serializer not found for Configuration ID $id")
    }
}
