// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The type of relationship between two organizations.
 */
enum class OrganizationRelationshipType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  BIBLE_PUBLISHER_GROUP("bible_publisher_group", 1),

  NETWORK("network", 2),

  CAMPUS("campus", 3);
}

internal class OrganizationRelationshipTypeSerializer : KSerializer<OrganizationRelationshipType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.OrganizationRelationshipType")

  override fun serialize(encoder: Encoder, value: OrganizationRelationshipType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): OrganizationRelationshipType {
    if (decoder is JsonDecoder) {
      return OrganizationRelationshipType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> OrganizationRelationshipType.UNSUPPORTED_PLACEHOLDER
      1 -> OrganizationRelationshipType.BIBLE_PUBLISHER_GROUP
      2 -> OrganizationRelationshipType.NETWORK
      3 -> OrganizationRelationshipType.CAMPUS
      else -> OrganizationRelationshipType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
