// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine the type of License Agreement.
 */
enum class LicenseAgreementTypeEnum(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  UNIVERSAL("universal", 1),

  CUSTOM("custom", 2);
}

internal class LicenseAgreementTypeEnumSerializer : KSerializer<LicenseAgreementTypeEnum> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.organization_profiles.LicenseAgreementTypeEnum")

  override fun serialize(encoder: Encoder, value: LicenseAgreementTypeEnum) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): LicenseAgreementTypeEnum {
    if (decoder is JsonDecoder) {
      return LicenseAgreementTypeEnum.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> LicenseAgreementTypeEnum.UNSUPPORTED_PLACEHOLDER
      1 -> LicenseAgreementTypeEnum.UNIVERSAL
      2 -> LicenseAgreementTypeEnum.CUSTOM
      else -> LicenseAgreementTypeEnum.UNSUPPORTED_PLACEHOLDER
    }
  }
}
