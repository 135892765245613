// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class AnalyticsContentType(
  internal val serialName: String,
  internal val serialId: Int
) {
  NONE("none", 0),

  VOTD("votd", 1),

  BIBLE_REFERENCE("bible_reference", 2),

  BIBLE("bible", 3),

  SEARCH("search", 4),

  PLAN("plan", 5),

  VIDEO("video", 6),

  ORGANIZATION("organization", 7),

  VERSE_IMAGE("verse_image", 8),

  PRAYER("prayer", 9),

  STORY("story", 10),

  STORY_MODULE("story_module", 11),

  GUIDED_PRAYER("guided_prayer", 12),

  GUIDED_PRAYER_MODULE("guided_prayer_module", 13),

  MOMENT("moment", 14),

  AUDIO_BIBLE("audio_bible", 15),

  PLAN_DAY("plan_day", 16),

  PAGE("page", 17),

  USER("user", 18),

  APP("app", 19),

  GIFT("gift", 20),

  VIDEO_COLLECTION("video_collection", 21),

  CONTENT_PLAYLIST("content_playlist", 22),

  FRIENDSHIP("friendship", 23),

  AUDIO_BIBLE_REFERENCE("audio_bible_reference", 24),

  PLAN_DEVOTIONAL_AUDIO("plan_devotional_audio", 25),

  GUIDED_PRAYER_BACKGROUND_AUDIO("guided_prayer_background_audio", 26),

  BANNER("banner", 27),

  CONTENT_PLAYLIST_ITEM("content_playlist_item", 28),

  GUIDE("guide", 29),

  GUIDE_DAY("guide_day", 30),

  COMMENTARY_KEYWORD("commentary_keyword", 31),

  COMMENTARY_ANNOTATION("commentary_annotation", 32),

  RELATED_CONTENT_BIBLE_REFERENCE("related_content_bible_reference", 33),

  VERSE_MEMORIZATION("verse_memorization", 34),

  NON_IDLE_SEGMENT("non_idle_segment", 35),

  LOCAL_VERSE_IMAGE("local_verse_image", 36),

  AUTH("auth", 37),

  STORY_QUESTION("story_question", 38),

  STORY_SCENE("story_scene", 39),

  STORY_QUESTION_ANSWER("story_question_answer", 40),

  STORY_ACTIVITY("story_activity", 41);
}

internal class AnalyticsContentTypeSerializer : KSerializer<AnalyticsContentType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.AnalyticsContentType")

  override fun serialize(encoder: Encoder, value: AnalyticsContentType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): AnalyticsContentType {
    if (decoder is JsonDecoder) {
      return AnalyticsContentType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> AnalyticsContentType.NONE
      1 -> AnalyticsContentType.VOTD
      2 -> AnalyticsContentType.BIBLE_REFERENCE
      3 -> AnalyticsContentType.BIBLE
      4 -> AnalyticsContentType.SEARCH
      5 -> AnalyticsContentType.PLAN
      6 -> AnalyticsContentType.VIDEO
      7 -> AnalyticsContentType.ORGANIZATION
      8 -> AnalyticsContentType.VERSE_IMAGE
      9 -> AnalyticsContentType.PRAYER
      10 -> AnalyticsContentType.STORY
      11 -> AnalyticsContentType.STORY_MODULE
      12 -> AnalyticsContentType.GUIDED_PRAYER
      13 -> AnalyticsContentType.GUIDED_PRAYER_MODULE
      14 -> AnalyticsContentType.MOMENT
      15 -> AnalyticsContentType.AUDIO_BIBLE
      16 -> AnalyticsContentType.PLAN_DAY
      17 -> AnalyticsContentType.PAGE
      18 -> AnalyticsContentType.USER
      19 -> AnalyticsContentType.APP
      20 -> AnalyticsContentType.GIFT
      21 -> AnalyticsContentType.VIDEO_COLLECTION
      22 -> AnalyticsContentType.CONTENT_PLAYLIST
      23 -> AnalyticsContentType.FRIENDSHIP
      24 -> AnalyticsContentType.AUDIO_BIBLE_REFERENCE
      25 -> AnalyticsContentType.PLAN_DEVOTIONAL_AUDIO
      26 -> AnalyticsContentType.GUIDED_PRAYER_BACKGROUND_AUDIO
      27 -> AnalyticsContentType.BANNER
      28 -> AnalyticsContentType.CONTENT_PLAYLIST_ITEM
      29 -> AnalyticsContentType.GUIDE
      30 -> AnalyticsContentType.GUIDE_DAY
      31 -> AnalyticsContentType.COMMENTARY_KEYWORD
      32 -> AnalyticsContentType.COMMENTARY_ANNOTATION
      33 -> AnalyticsContentType.RELATED_CONTENT_BIBLE_REFERENCE
      34 -> AnalyticsContentType.VERSE_MEMORIZATION
      35 -> AnalyticsContentType.NON_IDLE_SEGMENT
      36 -> AnalyticsContentType.LOCAL_VERSE_IMAGE
      37 -> AnalyticsContentType.AUTH
      38 -> AnalyticsContentType.STORY_QUESTION
      39 -> AnalyticsContentType.STORY_SCENE
      40 -> AnalyticsContentType.STORY_QUESTION_ANSWER
      41 -> AnalyticsContentType.STORY_ACTIVITY
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
