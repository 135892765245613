// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.accounts

import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Invite(
  /**
   * This is the email to the account that wants to be invited
   */
  @SerialName("email")
  @ProtoNumber(1)
  @JvmField
  val email: String? = null,
  /**
   * array of roles to assign the invited account
   */
  @SerialName("authz_roles")
  @ProtoNumber(2)
  @JvmField
  val authzRoles: List<AuthzRole>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
