// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api

import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.dataman.api.model.Events

/**
 * Dataman API for collection of YouVersion Data Events
 */
object DataManApi : AbstractApi(ApiDefaults("dataman", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", DataManApiSerializer)) {
  /**
   * Use this service to add analytics events. From the Events schema, create serialized protobuffer
   * data and send it in the request body.
   */
  suspend fun addEvents(body: Events? = null) = execute("/events", version = "4.0", method =
      RequestMethods.PUT, authAllowed = false, authOptional = false, body = body, bodySerializer =
      Events.serializer(), serializer = Unit.serializer())
}
