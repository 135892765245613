// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.common

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class UtmSource(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  YVAPP("yvapp", 1),

  BAFK("bafk", 2),

  WEB("web", 3);
}

internal class UtmSourceSerializer : KSerializer<UtmSource> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.movies.api.model.common.UtmSource")

  override fun serialize(encoder: Encoder, value: UtmSource) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): UtmSource {
    if (decoder is JsonDecoder) {
      return UtmSource.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> UtmSource.UNSUPPORTED_PLACEHOLDER
      1 -> UtmSource.YVAPP
      2 -> UtmSource.BAFK
      3 -> UtmSource.WEB
      else -> UtmSource.UNSUPPORTED_PLACEHOLDER
    }
  }
}
