package youversion.red.images.module

import red.service.ServiceRegistry
import red.tasks.launch
import youversion.red.cache.CacheClearListener
import youversion.red.cache.CacheType
import youversion.red.images.service.IImagesService

class ImagesCacheClearListener : CacheClearListener {

    override fun onClearCache(type: CacheType) {
        if (type != CacheType.IMAGES)
            return
        launch {
            ServiceRegistry[IImagesService::class].clearCache()
        }
    }
}
