// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class PaypalCheckoutPost(
  /**
   * Value to be charged. Only USD is currently supported for PayPal and USD is assumed when client
   * makes a request.
   */
  @SerialName("amount")
  @ProtoNumber(1)
  @JvmField
  val amount: String? = null,
  @SerialName("frequency_id")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.givingmikey.api.model.FrequencyTypeSerializer::class)
  @JvmField
  val frequencyId: FrequencyType? = null,
  /**
   * Unique ID of Fund
   */
  @SerialName("fund_id")
  @ProtoNumber(3)
  @JvmField
  val fundId: Int? = null,
  /**
   * When the payment should take place
   */
  @SerialName("charge_dt")
  @ProtoNumber(4)
  @JvmField
  val chargeDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The IANA timezone name of the timezone in which the charge_dt applies
   */
  @SerialName("charge_tz")
  @ProtoNumber(5)
  @JvmField
  val chargeTz: String? = null,
  /**
   * URL to be returned to after user sets up PayPal donation
   */
  @SerialName("return_url")
  @ProtoNumber(6)
  @JvmField
  val returnUrl: String? = null,
  /**
   * URL to be returned if PayPal donation is not approved by user
   */
  @SerialName("cancel_url")
  @ProtoNumber(7)
  @JvmField
  val cancelUrl: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
