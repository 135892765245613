// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.users

import kotlin.Int
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class SearchUsers(
  /**
   * List of user_ids.
   */
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val data: List<Int>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
