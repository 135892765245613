// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.featured_organizations

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine the current state of a featured organization for a language
 */
enum class FeaturedOrganizationStatus(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ACTIVE("active", 1),

  DRAFT("draft", 2),

  DISABLED("disabled", 3);
}

internal class FeaturedOrganizationStatusSerializer : KSerializer<FeaturedOrganizationStatus> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.admin.featured_organizations.FeaturedOrganizationStatus")

  override fun serialize(encoder: Encoder, value: FeaturedOrganizationStatus) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): FeaturedOrganizationStatus {
    if (decoder is JsonDecoder) {
      return FeaturedOrganizationStatus.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> FeaturedOrganizationStatus.UNSUPPORTED_PLACEHOLDER
      1 -> FeaturedOrganizationStatus.ACTIVE
      2 -> FeaturedOrganizationStatus.DRAFT
      3 -> FeaturedOrganizationStatus.DISABLED
      else -> FeaturedOrganizationStatus.UNSUPPORTED_PLACEHOLDER
    }
  }
}
