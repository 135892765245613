// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.givingmikey.api.model.giving.GivingCauseReferralSource

@Serializable
data class GivingCauseEventFireBase(
  /**
   * Referral source of the Giving Cause screen
   */
  @SerialName("referrer")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.givingmikey.api.model.giving.GivingCauseReferralSourceSerializer::class)
  val referrer: GivingCauseReferralSource? = null,
  /**
   * Unique identifier of the Giving Cause page
   */
  @SerialName("cause_id")
  @ProtoNumber(2)
  val causeId: String? = null
) : Event(), red.Serializable {
  override val key: String = "GivingCauseEvent"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    referrer?.let { bundle.putString("referrer", referrer.serialName) }
    causeId?.let { bundle.putString("cause_id", causeId) }
    return bundle
  }
}
