package youversion.red.metrics.service

import kotlin.js.JsName
import kotlinx.serialization.KSerializer
import red.resolvers.metrics.model.IMetric
import red.service.ExportedService
import red.service.IService
import red.service.service

@JsName("MetricsServiceProvider")
fun MetricsService() = service<IMetricsService>()

@ExportedService
interface IMetricsService : IService {

    suspend fun collectMetric(metric: IMetric, serializer: KSerializer<out IMetric>)

    suspend fun uploadMetrics()

    suspend fun collectPeriodicMetric(metric: IMetric, serializer: KSerializer<out IMetric>)

    suspend fun uploadPreviousPeriodicMetricsBatch()

    suspend fun flushPeriodicMetrics()

    suspend fun clearCache()
}
