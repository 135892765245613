// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent

@Serializable
data class AdminEventContent(
  @SerialName("content")
  @ProtoNumber(1)
  @JvmField
  val content: List<AdminBaseContent>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
