// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdministrativeAreaLevel1(
  /**
   * The short name of the administrative area, e.g. "OK" for Oklahoma.
   */
  @SerialName("short_name")
  @ProtoNumber(81)
  @JvmField
  val shortName: String? = null,
  /**
   * The long name of the administrative area, e.g. "Oklahoma" for the state of Oklahoma.
   */
  @SerialName("long_name")
  @ProtoNumber(82)
  @JvmField
  val longName: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
