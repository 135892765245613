package youversion.red.plans.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.plans.deeplink.PlansDeepLinkHandler

@ModuleDependencies("core", "bible", "versification")
class PlansModuleInitializer : IModuleInitializer {

    override fun initialize() {
        DeepLinks.addHandlers(PlansDeepLinkHandler as AbstractDeepLinkHandler)
    }
}
