// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Boolean
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
@SerialName("organizations.organization_profiles.PostBibleProfile")
data class PostBibleProfile(
  /**
   * The digital bible library id
   */
  @SerialName("dbl_id")
  @ProtoNumber(51)
  val dblId: String? = null,
  /**
   * A shortened identifier for the Bible profile
   */
  @SerialName("code")
  @ProtoNumber(52)
  val code: String? = null,
  /**
   * Allows for the profile to have auto grant access.
   */
  @SerialName("auto_grant_agency")
  @ProtoNumber(53)
  val autoGrantAgency: Boolean? = null,
  @SerialName("status")
  @ProtoNumber(54)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  /**
   * The time that this bible version grant was created.
   */
  @SerialName("created_dt")
  @ProtoNumber(55)
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The time that this bible version grant was created
   */
  @SerialName("updated_dt")
  @ProtoNumber(56)
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: String = "",
  @SerialName("organization_id")
  @ProtoNumber(2)
  override val organizationId: String = ""
) : PostBaseProfile, red.Serializable {
  init {
    freeze()
  }
}
