package red.platform.threads

data class SuspendLockOwner(val id: Long = idSource.incr()) {

    companion object {

        val idSource = AtomicLong(0)
    }
}

fun newLockOwner() = SuspendLockOwner()

expect class SuspendedLock() {

    val locked: Boolean
    suspend fun lock(owner: SuspendLockOwner)
    fun unlock(owner: SuspendLockOwner)
}

suspend inline fun <T> SuspendedLock.sync(owner: SuspendLockOwner = newLockOwner(), block: () -> T): T {
    return try {
        lock(owner)
        block()
    } finally {
        unlock(owner)
    }
}
