// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminParentOrganization(
  /**
   * The id of the parent organization.
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * The name of the parent organization.
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
