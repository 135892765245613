// Auto-Generated from OpenAPI Spec
package youversion.red.search.api

import kotlin.Boolean
import kotlin.Double
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.search.api.model.AdminSuggestion
import youversion.red.search.api.model.AdminSuggestions
import youversion.red.search.api.model.AdminTrendings
import youversion.red.search.api.model.Churches
import youversion.red.search.api.model.Elevation
import youversion.red.search.api.model.Elevations
import youversion.red.search.api.model.Feature
import youversion.red.search.api.model.FeatureSource
import youversion.red.search.api.model.Features
import youversion.red.search.api.model.Images
import youversion.red.search.api.model.Kind
import youversion.red.search.api.model.Languages
import youversion.red.search.api.model.Partners
import youversion.red.search.api.model.Plans
import youversion.red.search.api.model.QuerySuggestions
import youversion.red.search.api.model.Results
import youversion.red.search.api.model.SearchHistories
import youversion.red.search.api.model.SearchParamCanon
import youversion.red.search.api.model.Topic
import youversion.red.search.api.model.Topics
import youversion.red.search.api.model.UserIntent
import youversion.red.search.api.model.Users
import youversion.red.search.api.model.Verses
import youversion.red.search.api.model.Videos

/**
 * This microservice powers Unified Search and various other Search APIs.
 */
object SearchApi : AbstractApi(ApiDefaults("search", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", SearchApiSerializer)) {
  /**
   * - Who - This API can be used by all clients.
   * - What - This API endpoint returns a collection object with a data array of church objects
   *   along with the page number and page size the query used.
   * - When - When you want to display Church search results without any other types/kinds.
   * - Where - This is used in Unified Search (page 2 & beyond of results), Discover
   *   & Church Portal.
   * - Why - Gives the users the ability to find specific churches from a query.
   * - How - This API takes the provided parameters and queries Apache Solr with them to
   *   return just the desired churches.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param statuses Filter churches returned by statuses. Default value is to only return those
   * with "published" status.
   * @param query Search data input by user.
   * @param languageTag The localization the user has selected for the API object being returned.
   * @param latitude The latitude of the client
   * @param longitude The longitude of the client
   * @param onlyParentOrgs If "true", this tells the Search API to filter search to only parent
   * organizations.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param parentOrg Filter search results by a parent organization id.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @param supportUserId Unique identifier of the support User to filter search by
   * @return Successful request
   */
  suspend fun getChurches(
    checkSpelling: Boolean? = null,
    statuses: List<String>? = null,
    query: String? = null,
    languageTag: String,
    latitude: Double? = null,
    longitude: Double? = null,
    onlyParentOrgs: Boolean? = null,
    page: Int? = null,
    pageSize: Int? = null,
    parentOrg: String? = null,
    userIntent: UserIntent,
    supportUserId: Int? = null
  ): Churches? = execute("/churches", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      statuses?.let { addStrings("statuses", statuses) }
      query?.let { add("query", query) }
      add("language_tag", languageTag)
      latitude?.let { add("latitude", latitude) }
      longitude?.let { add("longitude", longitude) }
      onlyParentOrgs?.let { add("only_parent_orgs", onlyParentOrgs) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      parentOrg?.let { add("parent_org", parentOrg) }
      add("user_intent", userIntent.serialName)
      supportUserId?.let { add("support_user_id", supportUserId) }
  }, serializer = Churches.serializer())

  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows getting all the elevations
   * - When - Use this API to get all the elevations.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - To populate the list of elevations in Seek.
   * - How - Call this API to get all the elevations.
   *
   * @param query Search data input when we want the default value to be all records (aka *). This
   * parameter is used by search and ask services.
   * @return Successful request
   */
  suspend fun getElevations(query: String? = null): Elevations = execute("/elevations", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      query?.let { add("query", query) }
  }, serializer = Elevations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows creating an elevation.
   * - When - Use this API when an admin user is wanting to create a new elevation.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - In cases where we want to elevate certain documents (objects) for a given query.
   * - How - Call this URL with the POST HTTP method passing the elevation object in the body.
   *
   * @return Successfully created elevation.
   */
  suspend fun createElevation(body: Elevation? = null): Elevation = execute("/elevations", version =
      "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = Elevation.serializer(), serializer = Elevation.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows deleting an elevation by its id / primary key.
   * - When - Use this API when an admin user is wanting to delete an elevation for *all* languages.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - In cases where we no longer what to elevate certain documents (objects) for a given
   * query.
   * - How - Call this URL with the DELETE HTTP method passing the unique id in the path.
   *
   * @param elevationId The unique identifier for the Elevation resource.
   */
  suspend fun deleteElevation(elevationId: Int) = execute("/elevations/${elevationId}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Unit.serializer())

  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows getting a single elevation resource by it's id / primary key.
   * - When - Use this API when an admin user is wanting to view a single elevation.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - In cases where we want a view with a single elevation to show all the elevated thigns.
   * - How - Call this URL with the unique id in the path.
   *
   * @param elevationId The unique identifier for the Elevation resource.
   * @return Successful Request
   */
  suspend fun getElevation(elevationId: Int): Elevation? = execute("/elevations/${elevationId}",
      method = RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false,
      serializer = Elevation.serializer())

  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows updating a single elevation resource by it's id / primary key.
   * - When - Use this API when an admin user is wanting to update a single elevation.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - In cases where we want to allow an admin user to *edit* an existing elevation e.g. add
   * new verses, change plans, etc.
   * - How - Call this URL with the unique id in the path and send the appropriate schema in the
   * body.
   *
   * @param elevationId The unique identifier for the Elevation resource.
   * @return Successful Request
   */
  suspend fun editElevation(elevationId: Int, body: Elevation? = null): Elevation =
      execute("/elevations/${elevationId}", version = "4.0", method = RequestMethods.PUT,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      Elevation.serializer(), serializer = Elevation.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This will return an array of feature objects based on parameters. For now there is no need to
   * paginate with such a small set of results and limited use for this API.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param source Query parameter which informs the API that the client wants features from a
   * specific source.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @return Returns collection of feature objects based on parameters
   */
  suspend fun getFeatures(
    checkSpelling: Boolean? = null,
    source: FeatureSource? = null,
    query: String
  ): Features = execute("/features", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      source?.let { add("source", source.serialName) }
      add("query", query)
  }, serializer = Features.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This should *NOT* be used by clients. Called on a build pipeline from our service specs
   * project. It may also be used from an admin tool.
   *
   * @return Successfully created feature
   */
  suspend fun createFeature(body: Feature? = null): Feature = execute("/features", version = "4.0",
      method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = Feature.serializer(), serializer = Feature.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Delete a feature from search by its id.
   *
   * @param featureId The unique identifier for the Feature resource.
   */
  suspend fun deleteFeature(featureId: Int) = execute("/features/${featureId}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Unit.serializer())

  /**
   * Get a feature from search by its id.
   *
   * @param featureId The unique identifier for the Feature resource.
   * @return Successful request
   */
  suspend fun getFeature(featureId: Int): Feature = execute("/features/${featureId}", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      Feature.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * Updates a feature from search by its id.
   *
   * @param featureId The unique identifier for the Feature resource.
   * @return Successful request
   */
  suspend fun editFeature(featureId: Int, body: Feature? = null): Feature =
      execute("/features/${featureId}", version = "4.0", method = RequestMethods.PUT, authAllowed =
      true, authOptional = false, body = body, bodySerializer = Feature.serializer(), serializer =
      Feature.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * This will return an array of history objects for the authenticated user based on parameters.
   *
   * @param kind Kind parameter to indicate which facet of search is desired.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input when we want the default value to be all records (aka *). This
   * parameter is used by search and ask services.
   * @return Returns collection of history search objects for the user based on parameters
   */
  suspend fun getHistory(
    kind: Kind? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String? = null
  ): SearchHistories? = execute("/history", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      kind?.let { add("kind", kind.serialName) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      query?.let { add("query", query) }
  }, serializer = SearchHistories.serializer())

  /**
   * This will remove all search history associated with the authenticated user.
   */
  suspend fun deleteHistory() = execute("/history", method = RequestMethods.DELETE, version = "4.0",
      authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * This will return an array of image objects based on parameters.
   *
   * @param bibleVersionId The user's current Bible version selected in the reader.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Returns collection of image objects based on parameters
   */
  suspend fun getImages(
    bibleVersionId: Int,
    query: String,
    page: Int? = null,
    pageSize: Int? = null
  ): Images = execute("/images", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      add("bible_version_id", bibleVersionId)
      add("query", query)
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Images.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This API endpoint returns a dictionary with an array of all languages currently supported by
   * search.
   *
   * @param kind Kind parameter to indicate which facet of search is desired.
   * @return Successful request
   */
  suspend fun getLanguages(kind: Kind? = null): Languages = execute("/languages", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true, queryString =
      red.platform.http.queryString {
      kind?.let { add("kind", kind.serialName) }
  }, serializer = Languages.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Why: This API allows clients to search for Partners without other objects in the response. Who:
   * For all client devs. What: This API endpoint returns a collection object with a data array of
   * partner objects along with the page number and page size the query used. When: This can be used to
   * power any screen or admin where we want to get a list of partners by a search query filtered by
   * language tag. How: This endpoint queries our Apache Solr backend and stores results in cache to
   * match partners name & associated metadata to our search query.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param languageTag The localization to use for showing content to the user
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @return Successful request
   */
  suspend fun getPartners(
    checkSpelling: Boolean? = null,
    languageTag: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String,
    userIntent: UserIntent
  ): Partners? = execute("/partners", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      languageTag?.let { add("language_tag", languageTag) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      add("query", query)
      add("user_intent", userIntent.serialName)
  }, serializer = Partners.serializer())

  /**
   * This API endpoint returns a collection object with a data array of plan objects along with the
   * page number and page size the query used.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param languageTag The localization to use for showing content to the user
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search query input by user looking for plans.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @param organizationIds An array of uuid strings that represent the ids of organizations to
   * filter by
   * @return Successful request
   */
  suspend fun getPlans(
    checkSpelling: Boolean? = null,
    languageTag: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String? = null,
    userIntent: UserIntent,
    organizationIds: List<String>? = null
  ): Plans? = execute("/plans", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      languageTag?.let { add("language_tag", languageTag) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      query?.let { add("query", query) }
      add("user_intent", userIntent.serialName)
      organizationIds?.let { addStrings("organization_ids", organizationIds) }
  }, serializer = Plans.serializer())

  /**
   * Podcasts are deprecated. Returns an empty list
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param languageTag The localization to use for showing content to the user
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   */
  suspend fun getPodcasts(
    checkSpelling: Boolean? = null,
    languageTag: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String,
    userIntent: UserIntent
  ) = execute("/podcasts", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      languageTag?.let { add("language_tag", languageTag) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      add("query", query)
      add("user_intent", userIntent.serialName)
  }, serializer = Unit.serializer())

  /**
   * This API endpoint returns an array of result kinds based on search query input. This is the API
   * where a user's search history gets saved under Unified Search so make sure and pass auth when it
   * is available.
   *
   * @param appLanguageTag App localization language tag
   * @param bibleVersionId The user's current Bible version selected in the reader.
   * @param book The Bible USFM book name to filter the query results by.
   * @param canon The Bible canon section to filter query results by. Either NT (New Testament), OT
   * (Old Testament), or AP (Apocrypha). The "all" default state means to return all results
   * unfiltered.
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param organizationsLanguageTag The language the user has selected for searching churches.
   * @param partnersLanguageTag The language the user has selected for searching partners.
   * @param plansLanguageTag The localization the user has selected for their reading plans.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @param videosLanguageTag The localization to use for showing Videos to the user
   * @return Successful request
   */
  suspend fun getResults(
    appLanguageTag: String,
    bibleVersionId: Int,
    book: String? = null,
    canon: SearchParamCanon? = null,
    checkSpelling: Boolean? = null,
    organizationsLanguageTag: String? = null,
    partnersLanguageTag: String? = null,
    plansLanguageTag: String,
    query: String,
    userIntent: UserIntent,
    videosLanguageTag: String
  ): Results? = execute("/results", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = true, queryString = red.platform.http.queryString {
      add("app_language_tag", appLanguageTag)
      add("bible_version_id", bibleVersionId)
      book?.let { add("book", book) }
      canon?.let { add("canon", canon.serialName) }
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      organizationsLanguageTag?.let { add("organizations_language_tag", organizationsLanguageTag) }
      partnersLanguageTag?.let { add("partners_language_tag", partnersLanguageTag) }
      add("plans_language_tag", plansLanguageTag)
      add("query", query)
      add("user_intent", userIntent.serialName)
      add("videos_language_tag", videosLanguageTag)
  }, serializer = Results.serializer())

  /**
   * This endpoint has been deprecated in favor of /4.1/suggestions and will return 204.
   *
   * @param appLanguageTag App localization language tag
   * @param bibleVersionId The user's current Bible version selected in the reader.
   * @param kind Kind parameter to indicate which facet of search is desired.
   * @param plansLanguageTag The localization the user has selected for their reading plans.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param videosLanguageTag The localization to use for showing Videos to the user
   */
  suspend fun deprecatedGetSuggestions(
    appLanguageTag: String,
    bibleVersionId: Int,
    kind: Kind? = null,
    plansLanguageTag: String,
    query: String,
    videosLanguageTag: String
  ) = execute("/suggestions", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      add("app_language_tag", appLanguageTag)
      add("bible_version_id", bibleVersionId)
      kind?.let { add("kind", kind.serialName) }
      add("plans_language_tag", plansLanguageTag)
      add("query", query)
      add("videos_language_tag", videosLanguageTag)
  }, serializer = Unit.serializer())

  /**
   * - Who - This API can be used by all clients. - What - This API endpoint returns a collection
   * object with a data array of topic objects,
   *   did_you_mean for spelling corrections, search_instead_for to apply corrections, and the
   *   original query
   * - When - When you want to display Topics search results without any other types/kinds. -
   * Where - In the app when searching for topics as well as in Seek. - Why - List of topics for the
   * search results. - How - Call this URL with the appropriate parameters.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @return Successful request
   */
  suspend fun getTopics(
    checkSpelling: Boolean? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String
  ): Topics? = execute("/topics", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      add("query", query)
  }, serializer = Topics.serializer())

  /**
   * - Who - This API can be used by all clients. - What - This API endpoint returns a single topic
   * object. - When - When you want to display a single topic. - Where - In the app when displaying a
   * single topic. - Why - Single topic for the search results. - How - Call this URL with the topic
   * id.
   *
   * @param topicId The id of the topic resource
   * @return Successful request
   */
  suspend fun getTopic(topicId: Int): Topic? = execute("/topics/${topicId}", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true, serializer =
      Topic.serializer())

  /**
   * - Who - This API should only be used by admin clients.
   * - What - This API endpoint returns a collection object with a data array of user resources.
   * - When - When an admin user is trying to find a user's account.
   * - Where - Currently this is used in YouVersion Connect.
   * - Why - This API allows us to provide user account(s) based on given parameters.
   * - How - We use Solr to find a users account. Provide either an email or phone number to search
   * by.
   *
   * @param email A user's email address.
   * @param phoneNumber A user's phone number
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Successful request
   */
  suspend fun getUsers(
    email: String? = null,
    phoneNumber: String? = null,
    page: Int? = null,
    pageSize: Int? = null
  ): Users? = execute("in/4.0/users", method = RequestMethods.GET, version = "admin", authAllowed =
      true, authOptional = false, queryString = red.platform.http.queryString {
      email?.let { add("email", email) }
      phoneNumber?.let { add("phone_number", phoneNumber) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Users.serializer())

  /**
   * This API endpoint returns a collection object with a data array of verse objects along with the
   * page number and page size the query used. If this is being used in RED for bundling data in a
   * build, there is a special token that can be used instead of auth. *Note* Page size with the user
   * intent "text" is currently locked to 25. This is because it calls the monolith 3.1 API internally
   * which had no notion of dynamic page sizing.
   *
   * @param bibleVersionId The user's current Bible version selected in the reader.
   * @param book The Bible USFM book name to filter the query results by.
   * @param canon The Bible canon section to filter query results by. Either NT (New Testament), OT
   * (Old Testament), or AP (Apocrypha). The "all" default state means to return all results
   * unfiltered.
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @return Successful request
   */
  suspend fun getVerses(
    bibleVersionId: Int,
    book: String? = null,
    canon: SearchParamCanon? = null,
    checkSpelling: Boolean? = null,
    fields: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String,
    userIntent: UserIntent
  ): Verses? = execute("/verses", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      add("bible_version_id", bibleVersionId)
      book?.let { add("book", book) }
      canon?.let { add("canon", canon.serialName) }
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      fields?.let { add("fields", fields) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      add("query", query)
      add("user_intent", userIntent.serialName)
  }, serializer = Verses.serializer())

  /**
   * - Who - All clients could use this API.
   * - What - This API endpoint returns a collection object with a data array of video objects along
   * with the page number and page size the query used.
   * - When - When you want to search for videos and not display other search kinds/objects.
   * - Where - This API is used in Unified Search for results beyond page 1, also used in
   * Blockbuster & Stories CMS.
   * - Why - This allows a user to search for just videos.
   * - How - The API takes the parameters from the client as uses them to find Videos in Apache
   * Solr.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param languageTag The localization to use for showing content to the user
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @param query Search query input by user looking for videos.
   * @param type Filter videos returned by a provided type.
   * @param organizationIds An array of uuid strings that represent the ids of organizations to
   * filter by
   * @return Successful Request
   */
  suspend fun getVideos(
    checkSpelling: Boolean? = null,
    languageTag: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    userIntent: UserIntent,
    query: String? = null,
    type: String? = null,
    organizationIds: List<String>? = null
  ): Videos? = execute("/videos", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      languageTag?.let { add("language_tag", languageTag) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      add("user_intent", userIntent.serialName)
      query?.let { add("query", query) }
      type?.let { add("type", type) }
      organizationIds?.let { addStrings("organization_ids", organizationIds) }
  }, serializer = Videos.serializer())

  /**
   * - Who - This API should only be used by web clients building admin portals.
   * - What - This API endpoint returns a collection object with a data array of suggestion objects.
   * - When - Use this API when getting a list of suggestions for an admin tool.
   * - Where - Currently used for Seek admin tool.
   * - Why - This allows an admin to view suggestions directly and separately from clients which
   * serve end users.
   * - How - This API uses PostgreSQL to get a list of suggestions by language tag.
   *
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param languageTag The localization to use for showing content to the user
   * @return Successful Request
   */
  suspend fun getAdminSuggestions(
    page: Int? = null,
    pageSize: Int? = null,
    languageTag: String? = null
  ): AdminSuggestions? = execute("in/4.1/suggestions", method = RequestMethods.GET, version =
      "admin", authAllowed = true, authOptional = false, queryString = red.platform.http.queryString
      {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      languageTag?.let { add("language_tag", languageTag) }
  }, serializer = AdminSuggestions.serializer())

  /**
   * - Who - This should *NOT* be used by clients. Called from an admin tool when necessary to add
   * special cases.
   * - What - This API allows us to add to suggestions that are normally only created by crowd
   * sourced data from our end users.
   * - When - Use this when an admin wants to add a manual suggestion.
   * - Where - Only use this API from an admin tool.
   * - Why - This allows us to insert items into suggestions that users may not naturally search
   * frequently enough to get into the suggestions list.
   * - How - The POST body is parsed and saved to PG & Solr.
   */
  suspend fun createAdminSuggestion(body: AdminSuggestion? = null) = execute("in/4.1/suggestions",
      version = "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false,
      body = body, bodySerializer = AdminSuggestion.serializer(), serializer = Unit.serializer()) ?:
      throw NullPointerException("Response not sent from server")

  /**
   * - Who - Only admins should use this API.
   * - What - This marks a suggestion as deleted so that it will be ignored from future GET calls.
   * - When - When we have a suggestion that is a typo or maybe offensive, an admin could use this
   * API to ignore it.
   * - Where - This should only be implemented in our admin search tool (Seek).
   * - Why - Gives us flexibility to remove bad suggestions.
   * - How - The API looks at the suggestion by id and deletes it from PG & Solr.
   *
   * @param suggestionId Unique identifier of the Suggestion being referenced
   */
  suspend fun deleteSuggestion(suggestionId: Int) = execute("in/4.1/suggestions/${suggestionId}",
      method = RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false,
      serializer = Unit.serializer())

  /**
   * - Who - Any admin user can use this API but only in an admin tool (Seek).
   * - What - This is an authenticated update to an already existing suggestion.
   * - When - When there is a suggestion with a typo, we can use this API to fix it.
   * - Where - This API should only be usable from Seek.
   * - Why - Allows us to update crowd sourced data that might have typos, etc.
   * - How - The API uses the request body to update the suggestion in Solr & PG.
   *
   * @param suggestionId Unique identifier of the Suggestion being referenced
   * @return Suggestion updated successfully
   */
  suspend fun updateSuggestion(suggestionId: Int, body: AdminSuggestion? = null): AdminSuggestion? =
      execute("in/4.1/suggestions/${suggestionId}", version = "admin", method = RequestMethods.PUT,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      AdminSuggestion.serializer(), serializer = AdminSuggestion.serializer())

  /**
   * - Who - Admin users wanting to view trending in an admin tool
   * - What - Returns an object containing a data field populated with a list of trending searches
   * in the given language.
   * - When - When we want to display trending by language in our admin.
   * - Where - This API should only be used from Seek.
   * - Why - This gives us an admin GET view different from what we display in clients.
   * - How - Get the data from PG and send it to the browser.
   *
   * @param languageTag The localization the user has selected for the API object being returned.
   * @return Succesful Request
   */
  suspend fun getAdminTrending(languageTag: String): AdminTrendings? = execute("in/4.1/trending",
      method = RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false,
      queryString = red.platform.http.queryString {
      add("language_tag", languageTag)
  }, serializer = AdminTrendings.serializer())

  /**
   * - Who - Only admins / admin tools should use this API.
   * - What - This is a marked deletion of a trending query that will be ignored from future GET
   * calls.
   * - When - When there is an offensive trending query we can use this to delete it by id.
   * - Where - This API should only be called from our Seek admin tool
   * - Why - In case there is an offensive / odd trending query, we can use this API to remove it.
   * - How - The API deletes the trending query from the database by its id.
   *
   * @param trendingId Unique identifier of the Trending query being referenced
   */
  suspend fun deleteAdminTrending(trendingId: Int) = execute("in/4.1/trending/${trendingId}", method
      = RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false,
      serializer = Unit.serializer())

  /**
   * - Who - This API can be used by all clients.
   * - What - This API endpoint returns a collection object with a data array of suggestion objects.
   *  There is no pagination to this specific collection. Query is optional and if not passed then a
   * list of "trending" searches is passed for that provided language.
   * - When - When we need to display a list of suggestions to a user as they engage in the Search
   * UI.
   * - Where - Currently this is used when a user taps into the search navigation bar, and is called
   * additionally as they type out each character in their query.
   * - Why - This API allows us to provide high quality suggestions of what we think the user is
   * looking for to get them to the best content as quickly as possible.
   * - How - We use Solr to get the best possible suggestions for a user. They are crowd sourced
   * from what our community has searched for in the past. The order they are returned in is related to
   * the frequency with which the community has searched for that specific query.
   *
   * @param query Search query input by user on predictive search view
   * @param languageTag The localization the user has selected for the API object being returned.
   * @return Successful Request
   */
  suspend fun getSuggestions(query: String? = null, languageTag: String): QuerySuggestions? =
      execute("/suggestions", method = RequestMethods.GET, version = "4.1", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      query?.let { add("query", query) }
      add("language_tag", languageTag)
  }, serializer = QuerySuggestions.serializer())
}
