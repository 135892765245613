// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GivingPaymentMethodPost(
  /**
   * Unique token received from the gateway (Stripe or PayPal)
   */
  @SerialName("payment_token")
  @ProtoNumber(1)
  @JvmField
  val paymentToken: String? = null,
  /**
   * The first name of the user (required only in the case of guest giving in order to populate
   * Rock)
   */
  @SerialName("first_name")
  @ProtoNumber(2)
  @JvmField
  val firstName: String? = null,
  /**
   * The last name of the user (required only in the case of guest giving in order to populate Rock)
   */
  @SerialName("last_name")
  @ProtoNumber(3)
  @JvmField
  val lastName: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
