// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.videos

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class OrientationType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  LANDSCAPE("landscape", 1),

  PORTRAIT("portrait", 2);
}

internal class OrientationTypeSerializer : KSerializer<OrientationType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.movies.api.model.videos.OrientationType")

  override fun serialize(encoder: Encoder, value: OrientationType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): OrientationType {
    if (decoder is JsonDecoder) {
      return OrientationType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> OrientationType.UNSUPPORTED_PLACEHOLDER
      1 -> OrientationType.LANDSCAPE
      2 -> OrientationType.PORTRAIT
      else -> OrientationType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
