// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.onboarding

import kotlin.Int
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("onboarding.OnboardingChoiceTap")
data class OnboardingChoiceTap(
  @SerialName("choice_type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.dataman.api.model.onboarding.OnboardingChoiceTypeSerializer::class)
  val choiceType: OnboardingChoiceType? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(choiceType: OnboardingChoiceType? = null, createdmicros: Int? = null) :
      this(choiceType, red.platform.now(), createdmicros)
}
