// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.v2

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import red.Serializable
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@SerialName("v2.AbstractEvent")
abstract class AbstractEvent : AbstractAnalyticsEvent(), Serializable {
  /**
   * The primary YouVersion identifier for the content
   */
  abstract val primarycontentid: String?

  /**
   * A unique identifier for the event (Firebase push ID)
   */
  abstract val eventid: String

  /**
   * An identifier for the UI element (snake case)
   */
  abstract val elementid: String?

  /**
   * A secondary identifier describing the content
   */
  abstract val secondarycontentid: String?

  /**
   * A tertiary identifier describing the content
   */
  abstract val tertiarycontentid: String?

  /**
   * A quaternary identifier describing the content
   */
  abstract val quaternarycontentid: String?

  /**
   * A platform-specific screen identifier
   */
  abstract val screenid: String?

  /**
   * Extra contextual event metadata
   */
  abstract val extraa: String?

  /**
   * Extra contextual event metadata
   */
  abstract val extrab: String?

  /**
   * Extra contextual event metadata
   */
  abstract val extrac: String?

  /**
   * Extra contextual event metadata
   */
  abstract val extrad: String?
}
