// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class FeatureFlagPrincipal(
  /**
   * The ID and name of the feature flag.
   */
  @SerialName("feature_flag_id")
  @ProtoNumber(1)
  @JvmField
  val featureFlagId: String? = null,
  /**
   * Name of the feature flag.
   */
  @SerialName("feature_flag_name")
  @ProtoNumber(5)
  @JvmField
  val featureFlagName: String? = null,
  /**
   * Id of the principal.
   */
  @SerialName("principal_id")
  @ProtoNumber(2)
  @JvmField
  val principalId: String? = null,
  /**
   * The created date time of the feature flag.
   */
  @SerialName("created_dt")
  @ProtoNumber(3)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The updated date time of the feature flag.
   */
  @SerialName("updated_dt")
  @ProtoNumber(4)
  @JvmField
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
