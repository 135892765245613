// Auto-Generated from OpenAPI Spec
package youversion.red.users.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object UsersDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<UsersDeepLinkListener?> = AtomicReference(null)

  var listener: UsersDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/donate") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val campaign = queries["campaign"]?.firstOrNull()
        listener.onDonate(context, campaign)
    }
    register("/sign-up") { context, _, _ -> 
        listener.onSignUp(context)
    }
    register("/sign_up") { context, _, _ -> 
        listener.onSignUpOld(context)
    }
    register("/sign_up_facebook") { context, _, _ -> 
        listener.onSignUpFB(context)
    }
    register("/sign-in") { context, _, _ -> 
        listener.onSignIn(context)
    }
    register("/sign_in") { context, _, _ -> 
        listener.onSignInOld(context)
    }
    freeze()
  }
}
