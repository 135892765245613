// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class FeatureResult(
  /**
   * Name of feature
   */
  @SerialName("name")
  @ProtoNumber(1)
  @JvmField
  val name: String = "",
  /**
   * Link / deeplink to feature
   */
  @SerialName("link")
  @ProtoNumber(2)
  @JvmField
  val link: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
