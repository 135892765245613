// Auto-Generated from OpenAPI Spec
package youversion.red.banner.model.userstate.state

import kotlin.String
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("state.DeviceState")
data class DeviceState(
    @SerialName("installationId")
    @ProtoNumber(1)
    val installationid: String = "",
    @SerialName("namespaceId")
    @ProtoNumber(2)
    val namespaceid: String = "",
    @SerialName("stateId")
    @ProtoNumber(3)
    val stateid: String = "",
    @SerialName("state")
    @ProtoNumber(4)
    val state: State? = null
) : State, red.Serializable {
    init {
        freeze()
    }
}
