package youversion.red.locales.module

import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.ListSerializer
import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.Log
import red.platform.http.json
import red.platform.localization.Locales
import red.platform.localization.toLowerCase
import red.platform.settings.Settings
import red.platform.threads.AtomicReference
import red.platform.threads.SuspendLockOwner
import red.platform.threads.SuspendedLock
import red.platform.threads.freeze
import red.platform.threads.newLockOwner
import red.platform.threads.set
import red.platform.threads.setAssertTrue
import red.platform.threads.sync
import red.resolvers.locales.LocaleHeader
import red.resolvers.locales.LocalesServiceResolver
import red.tasks.CoroutineDispatchers
import youversion.red.locales.service.LocalesService

@ModuleDependencies("core")
class LocalesModuleInitializer : IModuleInitializer {

    override fun initialize() {
        if (enabled) {
            LocalesServiceResolver.resolver = LocalesServiceResolverImpl()
        }
    }

    companion object {

        private val _enabled = AtomicReference(false)

        var enabled: Boolean
            get() = _enabled.value
            set(value) {
                _enabled.setAssertTrue(value)
                if (value) {
                    LocalesServiceResolver.resolver = LocalesServiceResolverImpl()
                }
            }
    }
}

private class LocalesServiceResolverImpl : LocalesServiceResolver {

    private val service by LocalesService()
    private val lock = SuspendedLock()
    private val localeHeadersCache = AtomicReference<Map<String, LocaleHeader>?>(null)
    private val bibleTagsCache = AtomicReference<Set<String>?>(null)
    private val _initializing = AtomicReference(false)

    init {
        freeze()
    }

    override val initializing: Boolean
        get() = _initializing.value

    override suspend fun getApiTag(locale: String): String {
        val localeCacheKey = "$cacheKey.$locale"
        val tag = Settings.bibleSharedSettings.getString(localeCacheKey)

        val tagToReturn = if (tag == null) {
            val tagFromApi = service.getApiTag(locale)
            Settings.bibleSharedSettings.edit().putString(localeCacheKey, tagFromApi).commit()
            tagFromApi
        } else {
            CoroutineDispatchers.launch {
                try {
                    Settings.bibleSharedSettings.edit().putString(localeCacheKey, tag).commit()
                } catch (e: Exception) {
                    Log.e("Locales", "Failed to update tag", e)
                }
            }
            tag
        }

        return tagToReturn
    }

    override suspend fun getLocaleHeader(tag: String): LocaleHeader? {
        if (localeHeadersCache.value == null) {
            initialize()
        }
        return localeHeadersCache.value?.get(tag.normalized)
    }

    override suspend fun getBibleTags(): Set<String>? {
        if (bibleTagsCache.value == null) {
            initialize()
        }
        return bibleTagsCache.value
    }

    override suspend fun findByApiLanguageTag(tag: String): LocaleHeader? {
        val normalizedTag = tag.normalized
        val header = localeHeadersCache.value
            ?.entries?.firstOrNull { it.value.apiLanguageTag.normalized == normalizedTag }
            ?.value
        if (header == null) {
            Log.e("LocaleModuleInitializer", "Failed to find header using $tag -> ${localeHeadersCache.value?.keys}")
        }
        return header
    }

    override suspend fun initialize() {
        localeHeadersCache.value?.let { return }
        val owner = newLockOwner()
        lock.sync(owner) {
            localeHeadersCache.value?.let { return }
            try {
                _initializing.setAssertTrue(true)
                Settings.bibleSharedSettings.getString(cacheKey)?.let {
                    CoroutineDispatchers.launch {
                        try {
                            refresh(newLockOwner())
                        } catch (e: Exception) {
                            Log.e("Locales", "Failed to refresh tags", e)
                        }
                    }
                    initialize(owner, json.decodeFromString(serializer, it).toHeaderList())
                    return
                }
                refresh(owner)
            } finally {
                _initializing.setAssertTrue(false)
            }
        }
    }

    override fun clearMemoryCache() {
        localeHeadersCache.set(null)
        bibleTagsCache.set(null)
    }

    override fun clearCache() {
        clearMemoryCache()
        clearLocaleSettings()
    }

    private fun clearLocaleSettings() {
        for (key in Settings.bibleSharedSettings.keys) {
            if (key.startsWith(cacheKey)) {
                Settings.bibleSharedSettings.edit().remove(key).commit()
            }
        }
    }

    private suspend fun initialize(owner: SuspendLockOwner, headers: List<LocaleHeader>) {
        lock.sync(owner) {
            val localeHeadersMap = mutableMapOf<String, LocaleHeader>()
            val bibleTagsSet = mutableSetOf<String>()
            headers.forEach {
                localeHeadersMap[it.locale.normalized] = it
                bibleTagsSet.add(it.bibleLanguageTag)
            }
            localeHeadersCache.setAssertTrue(localeHeadersMap)
            bibleTagsCache.setAssertTrue(bibleTagsSet)
        }
    }

    private suspend fun refresh(owner: SuspendLockOwner) {
        val locales = Locales.getAssetLocales()
        if (locales.isEmpty()) return
        val headers = service.getLocaleHeaders(locales)
        Settings.bibleSharedSettings.edit()
            .putString(
                cacheKey,
                json.encodeToString(serializer, headers)
            )
            .commit()
        initialize(owner, headers.toHeaderList())
    }

    private val serializer: KSerializer<List<youversion.red.locales.api.model.LocaleHeader>>
        get() = ListSerializer(youversion.red.locales.api.model.LocaleHeader.serializer())

    private val String.normalized
        get() = replace("-", "_").toLowerCase(Locales.getEnglish())

    private fun List<youversion.red.locales.api.model.LocaleHeader>.toHeaderList() = map {
        LocaleHeader(
            bibleLanguageTag = it.bibleLanguageTag,
            apiLanguageTag = it.apiLanguageTag,
            locale = it.locale,
            name = it.name,
            localName = it.localName
        )
    }

    companion object {

        private const val cacheKey = "red.locale.headers"
    }
}
