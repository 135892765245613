// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations.organization_profiles

import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.organizations.api.model.organizations.organization_profiles.LicenseAgreementTypeEnum
import youversion.red.organizations.api.model.organizations.organization_profiles.LicenseStatusEnum
import youversion.red.organizations.api.model.organizations.organization_profiles.ProfileTypeEnum

@Serializable
data class ProfileLicense(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Long? = null,
  @SerialName("license_status")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.LicenseStatusEnumSerializer::class)
  @JvmField
  val licenseStatus: LicenseStatusEnum? = null,
  @SerialName("license_type")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.LicenseAgreementTypeEnumSerializer::class)
  @JvmField
  val licenseType: LicenseAgreementTypeEnum? = null,
  /**
   * The stringified version of the date the license agreement was last updated.
   */
  @SerialName("version")
  @ProtoNumber(4)
  @JvmField
  val version: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The user_id of the user that updated the license agreement.
   */
  @SerialName("updated_by")
  @ProtoNumber(5)
  @JvmField
  val updatedBy: Long? = null,
  /**
   * The date and time that the license agreement was accepted.
   */
  @SerialName("accepted_dt")
  @ProtoNumber(6)
  @JvmField
  val acceptedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The date and time that the license agreement was rejected.
   */
  @SerialName("rejected_dt")
  @ProtoNumber(7)
  @JvmField
  val rejectedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The organization_id that the license agreement is associated with.
   */
  @SerialName("organization_id")
  @ProtoNumber(8)
  @JvmField
  val organizationId: String? = null,
  @SerialName("profile_type")
  @ProtoNumber(9)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.ProfileTypeEnumSerializer::class)
  @JvmField
  val profileType: ProfileTypeEnum? = null
) : red.Serializable {
  init {
    freeze()
  }
}
