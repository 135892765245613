// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events.event_content

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.admin.events.event_contents.AdminBaseContent

@Serializable
@SerialName("admin.events.event_content.AdminPlanContent")
data class AdminPlanContent(
  /**
   * The ID of the plan
   */
  @SerialName("plan_id")
  @ProtoNumber(40)
  val planId: Int = 0,
  /**
   * The language tag of the plan
   */
  @SerialName("language_tag")
  @ProtoNumber(41)
  val languageTag: String = "",
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Long? = null
) : AdminBaseContent, red.Serializable {
  init {
    freeze()
  }
}
