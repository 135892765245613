// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The source device of a gift.
 */
enum class GivingTransactionSourceType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  WEB_WEB("web_web", 1),

  WEB_IOS("web_ios", 2),

  WEB_ANDROID("web_android", 3);
}

internal class GivingTransactionSourceTypeSerializer : KSerializer<GivingTransactionSourceType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.givingmikey.api.model.GivingTransactionSourceType")

  override fun serialize(encoder: Encoder, value: GivingTransactionSourceType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GivingTransactionSourceType {
    if (decoder is JsonDecoder) {
      return GivingTransactionSourceType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GivingTransactionSourceType.UNSUPPORTED_PLACEHOLDER
      1 -> GivingTransactionSourceType.WEB_WEB
      2 -> GivingTransactionSourceType.WEB_IOS
      3 -> GivingTransactionSourceType.WEB_ANDROID
      else -> GivingTransactionSourceType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
