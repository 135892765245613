package youversion.red.discover.mocks

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.flow
import red.platform.now
import youversion.red.churches.model.AddressProfile
import youversion.red.churches.model.BaseOrganizationProfile
import youversion.red.churches.model.ChurchServiceTime
import youversion.red.churches.model.Location
import youversion.red.churches.model.Organization
import youversion.red.churches.model.ParentOrganization
import youversion.red.churches.service.IChurchesService
import youversion.red.events.api.model.events.Events
import youversion.red.organizations.api.model.organizations.CommonOrganizationMember
import youversion.red.organizations.api.model.organizations.OrganizationMember
import youversion.red.organizations.api.model.organizations.OrganizationMembers
import youversion.red.organizations.api.model.organizations.OrganizationPost
import youversion.red.organizations.api.model.organizations.OrganizationPosts
import youversion.red.organizations.api.model.organizations.PostAction
import youversion.red.organizations.api.model.organizations.PostActionKind
import youversion.red.organizations.api.model.organizations.VisibilityPreference
import youversion.red.organizations.api.model.profiles.AdministrativeAreaLevel1
import youversion.red.organizations.api.model.profiles.Country
import youversion.red.plans.model.Plan

class MockChurchesService : IChurchesService {
    override val savedOrganization: Flow<Organization?> = MutableStateFlow<Organization?>(null)

    override suspend fun saveOrganization(id: String, visibilityPreference: VisibilityPreference?) {}

    override suspend fun leaveOrganization(id: String) {}

    override fun getFeaturedPlan(organizationId: String): Flow<Plan?> = flow {
        emit(null)
    }

    override fun getOrganizations(userId: Int?): Flow<List<Organization>> = flow {
        emit(emptyList())
    }

    override fun getOrganization(id: String): Flow<Organization?> = flow {
        if (id == "base") {
            emit(generateFakeOrg(id, "Life.Church Edmond", ParentOrganization("parent", "Life.Church")))
        } else if (id == "no_profiles") {
            emit(generateFakeOrg(id))
        } else if (id == "not_found") {
            emit(null)
        } else {
            emit(null)
        }
    }

    override suspend fun getOrganizationSync(id: String): Organization? {
        return null
    }

    override fun getProfiles(organizationId: String): Flow<List<BaseOrganizationProfile>> = flow {
        if (organizationId == "base") {
            val locationProfile = AddressProfile(
                "123",
                "base",
                "4600 E 2nd St, Edmond, OK 73034",
                "Edmond, OK",
                "321",
                35.64952f,
                -97.4214f,
                Country("US", "United States of America"),
                AdministrativeAreaLevel1("OK", "Oklahoma"),
                null
            )
            emit(listOf(locationProfile))
        } else {
            emit(emptyList())
        }
    }

    override fun getLocations(organizationId: String, latitude: Double?, longitude: Double?): Flow<List<Location>> = flow {
        emit(emptyList())
    }

    override fun getChurchServiceTimes(organizationId: String): Flow<List<ChurchServiceTime>> = flow {
        emit(emptyList())
    }

    override fun getOrganizationMembers(
        organizationId: String,
        userId: Int?,
        page: Int?,
        pageSize: Int?
    ): Flow<OrganizationMembers?> = flow {
        val organizationMember = OrganizationMembers(
            listOf(
                CommonOrganizationMember(1, true),
                CommonOrganizationMember(2, true),
                CommonOrganizationMember(3, false)
            ),
            1,
            false,
            3
        )
        emit(organizationMember)
    }

    override fun getMembersCount(organizationId: String): Flow<Int> = flow {
        emit(1)
    }

    override fun getOrganizationMember(
        organizationId: String,
        userId: Int?
    ): Flow<OrganizationMember?> = flow {
        emit(null)
    }

    override fun getOrganizationPost(
        organizationId: String,
        postId: String,
        bodyAccept: String?
    ): Flow<OrganizationPost?> = flow {
        emit(OrganizationPost("1", "1", "Cook Out", "<p>Bring the family</p>", now(), now()))
    }

    override fun getOrganizationPosts(
        organizationId: String,
        page: Int?,
        pageSize: Int?,
        bodyAccept: String?
    ): Flow<OrganizationPosts> = flow {
        emit(OrganizationPosts(emptyList(), 1, false))
    }

    override fun getOrganizationPostActions(organizationId: String, postId: String): Flow<List<PostAction>> = flow {
        emit(emptyList())
    }

    override suspend fun createOrganizationPostAction(
        organizationId: String,
        postId: String,
        postActionKind: PostActionKind
    ): PostAction {
        return PostAction(PostActionKind.LIKE, true, 1)
    }

    override suspend fun deleteOrganizationPostAction(organizationId: String, postId: String, postActionKind: PostActionKind) {
        TODO("Not yet implemented")
    }

    override suspend fun getEvents(organizationId: String): Events {
        TODO("Not yet implemented")
    }

    private fun generateFakeOrg(id: String = "base", name: String = "Life.Church Edmond", parentOrganization: ParentOrganization? = null): Organization {
        return Organization(
            id,
            name,
            "life.church",
            "(405) 688-5433",
            "info@life.church",
            "Join us for church this weekend!",
            "https://www.life.church/static/img/logo.svg",
            "#3D3834",
            parentOrganization,
            null,
            "en"
        )
    }
}
