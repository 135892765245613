// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminSuggestions(
  /**
   * A list of Suggestion resource objects for admin
   */
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val adminSuggestions: List<AdminSuggestion>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
