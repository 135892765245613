// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.events

import kotlin.Boolean
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.givingmikey.api.model.giving.GivingFormReferralSource

@Serializable
data class GivingClickEventFireBase(
  /**
   * Give now button pressed
   */
  @SerialName("give_button")
  @ProtoNumber(1)
  val giveButton: Boolean? = null,
  /**
   * Payment Method pressed
   */
  @SerialName("payment_method")
  @ProtoNumber(2)
  val paymentMethod: Boolean? = null,
  /**
   * Currency pressed
   */
  @SerialName("currency")
  @ProtoNumber(3)
  val currency: Boolean? = null,
  /**
   * Help Button pressed
   */
  @SerialName("help_button")
  @ProtoNumber(4)
  val helpButton: Boolean? = null,
  /**
   * Privacy Statement Button pressed
   */
  @SerialName("privacy_statement_button")
  @ProtoNumber(5)
  val privacyStatementButton: Boolean? = null,
  /**
   * Referral source of the Giving Form screen
   */
  @SerialName("referrer")
  @ProtoNumber(6)
  @Serializable(with=youversion.red.givingmikey.api.model.giving.GivingFormReferralSourceSerializer::class)
  val referrer: GivingFormReferralSource? = null,
  /**
   * Suggested Giving Amount Selected
   */
  @SerialName("suggested_amount")
  @ProtoNumber(7)
  val suggestedAmount: String? = null
) : Event(), red.Serializable {
  override val key: String = "GivingClickEvent"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    giveButton?.let { bundle.putBoolean("give_button", giveButton) }
    paymentMethod?.let { bundle.putBoolean("payment_method", paymentMethod) }
    currency?.let { bundle.putBoolean("currency", currency) }
    helpButton?.let { bundle.putBoolean("help_button", helpButton) }
    privacyStatementButton?.let { bundle.putBoolean("privacy_statement_button",
        privacyStatementButton) }
    referrer?.let { bundle.putString("referrer", referrer.serialName) }
    suggestedAmount?.let { bundle.putString("suggested_amount", suggestedAmount) }
    return bundle
  }
}
