package youversion.red.metrics.service.repository

import kotlinx.serialization.KSerializer
import red.platform.Log
import red.platform.UUID
import red.platform.http.DefaultSerializer
import red.platform.http.FormatType
import red.platform.io.ByteBuffer
import red.platform.io.File
import red.platform.io.InputStream
import red.platform.io.OutputStream
import red.platform.io.StorageType
import red.platform.io.deleteRecursively
import red.platform.io.fileSystem
import red.platform.io.getAppAppropriateFileLocation
import red.platform.io.use
import red.platform.threads.AtomicReference
import red.platform.threads.SuspendLockOwner
import red.platform.threads.SuspendedLock
import red.platform.threads.set
import red.platform.threads.sync
import red.resolvers.metrics.model.IMetric
import red.tasks.CoroutineDispatchers.withIO
import youversion.red.analytics.AnalyticsContextManager

internal object MetricsStorage {

    private const val METRICS_STORAGE_FOLDER = ".metrics"
    private const val PERIODIC_METRICS_STORAGE_FOLDER = ".metrics.periodic"

    private val lock = SuspendedLock()

    private val sessionFileName: String
        get() = AnalyticsContextManager.context.sessionId

    private val currentPeriodicMetricFileName = AtomicReference(UUID.randomUUID().toString())
    private val previousPeriodicMetricFileName: AtomicReference<String?> = AtomicReference(null)

    fun isCurrentSessionFile(fileName: String) = fileName == sessionFileName

    fun getSessionMetricsSessionId(fileName: String) = fileName

    fun getPeriodicMetricsSessionId(folderName: String) = folderName

    suspend fun writeMetric(
        owner: SuspendLockOwner,
        metric: IMetric,
        serializer: KSerializer<out IMetric>
    ) = withIO {
        lock.sync(owner) {
            writeMetricHelper(metric, serializer, getMetricsFile())
        }
    }

    suspend fun writePeriodicMetric(
        owner: SuspendLockOwner,
        metric: IMetric,
        serializer: KSerializer<out IMetric>
    ) = withIO {
        lock.sync(owner) {
            writeMetricHelper(metric, serializer, getPeriodicMetricsFile())
        }
    }

    @Suppress("UNCHECKED_CAST")
    private suspend fun writeMetricHelper(
        metric: IMetric,
        serializer: KSerializer<out IMetric>,
        metricFile: File?
    ) {
        val id = MetricsConfiguration.getMetricSerializerConfigurationId(metric)
        val bytes = DefaultSerializer.serialize(
            FormatType.PROTOBUF,
            serializer as KSerializer<IMetric>,
            metric
        )
        metricFile?.let { file ->
            fileSystem.newOutputStream(file, true).use {
                try {
                    writeMetricSerializerId(id)
                    writeMetricData(bytes)
                } catch (exception: Exception) {
                    Log.e(
                        "MetricsStorage",
                        "Failed to write metric to file $file",
                        exception
                    )
                }
            }
        }
    }

    suspend fun readMetrics(owner: SuspendLockOwner, fileName: String): List<IMetric> = withIO {
        lock.sync(owner) {
            readMetricsHelper(getMetricsFile(fileName))
        }
    }

    suspend fun readPeriodicMetrics(
        owner: SuspendLockOwner,
        folderName: String,
        fileName: String
    ): List<IMetric> = withIO {
        lock.sync(owner) {
            readMetricsHelper(getPeriodicMetricsFile(folderName, fileName))
        }
    }

    private suspend fun readMetricsHelper(metricFile: File?): List<IMetric> {
        val metrics = mutableListOf<IMetric>()
        metricFile?.let { file ->
            try {
                if (!fileSystem.exists(file)) {
                    Log.e("MetricsStorage", "File $file does not exist for reading.")
                    return metrics
                }
                fileSystem.newInputStream(file).use {
                    while (available() > 0) {
                        try {
                            metrics.add(readNextMetric())
                        } catch (exception: Exception) {
                            Log.e(
                                "MetricsStorage",
                                "Failed to decode metric when reading from file $file",
                                exception
                            )
                            break
                        }
                    }
                }
            } catch (exception: Exception) {
                Log.e(
                    "MetricsStorage",
                    "Failed to read metric from file $file",
                    exception
                )
            }
        }
        return metrics
    }

    suspend fun getMetricsFileNames(owner: SuspendLockOwner): List<String> = withIO {
        lock.sync(owner) {
            try {
                fileSystem.listFiles(getMetricsFolder()).map {
                    fileSystem.getName(it)
                }
            } catch (exception: Exception) {
                Log.e(
                    "MetricsStorage",
                    "Failed to get metrics files.",
                    exception
                )
                emptyList()
            }
        }
    }

    suspend fun getPreviousPeriodicMetricsBatchFolderAndFileName(
        owner: SuspendLockOwner
    ): MetricsFolderAndFileName = withIO {
        lock.sync(owner) {
            MetricsFolderAndFileName(sessionFileName, previousPeriodicMetricFileName.value)
        }
    }

    suspend fun getAllPeriodicMetricsBatchFolderAndFileNames(
        owner: SuspendLockOwner
    ): List<MetricsFolderAndFileName> = withIO {
        lock.sync(owner) {
            fileSystem.listFiles(getPeriodicMetricsBaseFolder()).flatMap { folder ->
                val folderName = fileSystem.getName(folder)
                val files = fileSystem.listFiles(getPeriodicMetricsFolder(folderName)).map { file ->
                    MetricsFolderAndFileName(folderName, fileSystem.getName(file))
                }
                files
            }
        }
    }

    suspend fun clearSessionData(owner: SuspendLockOwner, fileName: String) = withIO {
        lock.sync(owner) {
            try {
                getMetricsFile(fileName)!!.deleteRecursively()
            } catch (exception: Exception) {
                Log.e(
                    "MetricsStorage",
                    "Failed to clear session $fileName data.",
                    exception
                )
            }
        }
    }

    suspend fun clearPeriodicMetricsBatchData(
        owner: SuspendLockOwner,
        folderName: String,
        fileName: String
    ) = withIO {
        lock.sync(owner) {
            try {
                getPeriodicMetricsFile(folderName, fileName)!!.deleteRecursively()
            } catch (exception: Exception) {
                Log.e(
                    "MetricsStorage",
                    "Failed to clear periodic metrics batch $fileName data in folder $folderName.",
                    exception
                )
            }
        }
    }

    suspend fun updatePeriodicMetricsStorage(owner: SuspendLockOwner) = withIO {
        lock.sync(owner) {
            previousPeriodicMetricFileName.set(currentPeriodicMetricFileName.value)
            currentPeriodicMetricFileName.set(UUID.randomUUID().toString())
        }
    }

    suspend fun clearCache(owner: SuspendLockOwner) = withIO {
        lock.sync(owner) {
            try {
                getMetricsFolder().deleteRecursively()
                getPeriodicMetricsBaseFolder().deleteRecursively()
            } catch (exception: Exception) {
                Log.e(
                    "MetricsStorage",
                    "Failed to clear cache.",
                    exception
                )
            }
        }
    }

    private suspend fun getMetricsFolder() = getMetricsFolderHelper(METRICS_STORAGE_FOLDER)

    private suspend fun getPeriodicMetricsBaseFolder() =
        getMetricsFolderHelper(PERIODIC_METRICS_STORAGE_FOLDER)

    private suspend fun getPeriodicMetricsFolder(folderName: String) =
        createFolder(getPeriodicMetricsBaseFolder(), folderName)

    private suspend fun getMetricsFolderHelper(folderName: String): File {
        val fileLocation = fileSystem.getAppAppropriateFileLocation(StorageType.Persistent)
        return createFolder(fileLocation.file, folderName)
    }

    private suspend fun createFolder(parentFolder: File, folderName: String): File {
        val folder = fileSystem.newFile(parentFolder, folderName)
        if (!fileSystem.exists(folder)) {
            fileSystem.mkdirs(folder)
        }
        return folder
    }

    private suspend fun getMetricsFile(fileName: String = sessionFileName) =
        getMetricsFileHelper(getMetricsFolder(), fileName)

    private suspend fun getPeriodicMetricsFile(
        folderName: String = sessionFileName,
        fileName: String = currentPeriodicMetricFileName.value
    ) = getMetricsFileHelper(getPeriodicMetricsFolder(folderName), fileName)

    private fun getMetricsFileHelper(folder: File, fileName: String): File? {
        return try {
            fileSystem.newFile(folder, fileName)
        } catch (exception: Exception) {
            Log.e(
                "MetricsStorage",
                "Failed to get metrics file for fileName = $fileName in " +
                        "folder = ${fileSystem.getName(folder)}",
                exception
            )
            null
        }
    }

    private fun OutputStream.writeMetricSerializerId(id: Byte) {
        write(ByteArray(Byte.SIZE_BYTES) { id })
    }

    private fun OutputStream.writeMetricData(dataBytes: ByteArray) {
        val buffer = ByteBuffer.allocate(Int.SIZE_BYTES)
        buffer.putInt(dataBytes.size)
        write(buffer.array())
        write(dataBytes)
    }

    private fun InputStream.readSerializerId(): Byte {
        val idBytes = ByteArray(Byte.SIZE_BYTES)
        read(idBytes, 0, Byte.SIZE_BYTES)
        return idBytes[0]
    }

    private fun InputStream.readMetricDataByteSize(): Int {
        val sizeBytes = ByteArray(Int.SIZE_BYTES)
        read(sizeBytes, 0, Int.SIZE_BYTES)
        val buffer = ByteBuffer.allocate(Int.SIZE_BYTES)
        buffer.put(sizeBytes)
        return buffer.getInt(0)
    }

    private fun InputStream.readMetricData(serializerId: Byte, metricDataByteSize: Int): IMetric {
        val dataBytes = ByteArray(metricDataByteSize)
        read(dataBytes, 0, metricDataByteSize)
        val serializer = MetricsConfiguration.getMetricSerializerFromConfigurationId(serializerId)
        return DefaultSerializer.deserialize(FormatType.PROTOBUF, serializer, dataBytes) as IMetric
    }

    private fun InputStream.readNextMetric(): IMetric {
        val serializerId = readSerializerId()
        val metricDataByteSize = readMetricDataByteSize()
        return readMetricData(serializerId, metricDataByteSize)
    }
}

internal data class MetricsFolderAndFileName(
    val folderName: String,
    val fileName: String?
)
