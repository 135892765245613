// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminEventTimes(
  @SerialName("times")
  @ProtoNumber(7)
  @JvmField
  val times: List<AdminTime>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
