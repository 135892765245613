package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.bible.reference.VersionId
import youversion.red.versification.VersificationTransaction

@Serializable
data class PlanDay(
    @ProtoNumber(1) val day: Int = 0,
    @ProtoNumber(2) val segments: List<PlanSegment> = emptyList()
) {
    init {
        freeze()
    }

    suspend fun versify(
        versificationTransaction: VersificationTransaction,
        versionId: VersionId
    ): PlanDay {
        val segmentsVersified = segments.map {
            it.versify(versificationTransaction, versionId)
        }.freeze()
        return copy(segments = segmentsVersified).freeze()
    }
}
