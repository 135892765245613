package youversion.red.analytics.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.threads.freeze
import red.tasks.Dispatchers
import youversion.red.analytics.AnalyticsUserIdListener
import youversion.red.security.service.UsersService

@ModuleDependencies("core", "installation", "blue")
class AnalyticsModuleInitializer : IModuleInitializer {

    private val usersService by UsersService()
    private val analyticsUserIdListener = AnalyticsUserIdListener().freeze()

    override fun initialize() {
        usersService.addListener(analyticsUserIdListener)
        // make sure user id is set every launch
        Dispatchers.backgroundDispatcher.dispatchCoroutine {
            usersService.getCurrentUser()?.let {
                analyticsUserIdListener.onUserChanged(it, null, false)
            }
        }
    }
}
