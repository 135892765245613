// Auto-Generated from OpenAPI Spec
package youversion.red.discovery.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Type is a way to identify a unique layout and/or collection of a feature set
 */
enum class DiscoverSectionCategoryType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  CHIPS_CATEGORIES("chips_categories", 1),

  CHURCHES_NEAR_ME("churches_near_me", 2),

  CHURCHES_OF_FRIENDS("churches_of_friends", 3),

  EMOTIONS("emotions", 4),

  PLANS_COLLECTION("plans_collection", 5),

  PLANS_OF_FRIENDS("plans_of_friends", 6),

  PLANS_RELATED("plans_related", 7),

  PLANS_TRENDING("plans_trending", 8),

  PLANS_FEATURED("plans_featured", 9),

  PLANS_NEW_TO_FAITH("plans_new_to_faith", 10),

  VIDEOS_COLLECTION("videos_collection", 11),

  VIDEOS_VOTD("videos_votd", 12),

  VIDEOS_FEATURED("videos_featured", 13),

  ORGANIZATIONS_FEATURED("organizations_featured", 14),

  ORGANIZATIONS_FOLLOWED("organizations_followed", 15);
}

internal class DiscoverSectionCategoryTypeSerializer : KSerializer<DiscoverSectionCategoryType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.discovery.api.model.DiscoverSectionCategoryType")

  override fun serialize(encoder: Encoder, value: DiscoverSectionCategoryType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): DiscoverSectionCategoryType {
    if (decoder is JsonDecoder) {
      return DiscoverSectionCategoryType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> DiscoverSectionCategoryType.UNSUPPORTED_PLACEHOLDER
      1 -> DiscoverSectionCategoryType.CHIPS_CATEGORIES
      2 -> DiscoverSectionCategoryType.CHURCHES_NEAR_ME
      3 -> DiscoverSectionCategoryType.CHURCHES_OF_FRIENDS
      4 -> DiscoverSectionCategoryType.EMOTIONS
      5 -> DiscoverSectionCategoryType.PLANS_COLLECTION
      6 -> DiscoverSectionCategoryType.PLANS_OF_FRIENDS
      7 -> DiscoverSectionCategoryType.PLANS_RELATED
      8 -> DiscoverSectionCategoryType.PLANS_TRENDING
      9 -> DiscoverSectionCategoryType.PLANS_FEATURED
      10 -> DiscoverSectionCategoryType.PLANS_NEW_TO_FAITH
      11 -> DiscoverSectionCategoryType.VIDEOS_COLLECTION
      12 -> DiscoverSectionCategoryType.VIDEOS_VOTD
      13 -> DiscoverSectionCategoryType.VIDEOS_FEATURED
      14 -> DiscoverSectionCategoryType.ORGANIZATIONS_FEATURED
      15 -> DiscoverSectionCategoryType.ORGANIZATIONS_FOLLOWED
      else -> DiscoverSectionCategoryType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
