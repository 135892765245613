// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class GivingSubscription(
  /**
   * ID of subscription in Rock
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * Value to be charged in string form
   */
  @SerialName("amount")
  @ProtoNumber(2)
  @JvmField
  val amount: String? = null,
  /**
   * Unique ID of Currency code
   */
  @SerialName("currency_id")
  @ProtoNumber(3)
  @JvmField
  val currencyId: Int? = null,
  @SerialName("frequency_id")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.givingmikey.api.model.FrequencyTypeSerializer::class)
  @JvmField
  val frequencyId: FrequencyType? = null,
  /**
   * Unique ID of Fund
   */
  @SerialName("fund_id")
  @ProtoNumber(5)
  @JvmField
  val fundId: Int? = null,
  @SerialName("payment_method")
  @ProtoNumber(6)
  @JvmField
  val paymentMethod: GivingPaymentMethod? = null,
  /**
   * the next payment date of this subscription in ISO-8601 format
   */
  @SerialName("next_payment_date")
  @ProtoNumber(7)
  @JvmField
  val nextPaymentDate: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
