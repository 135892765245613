// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.bible

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("bible.VersionDownloadFailureEvent")
data class VersionDownloadFailureEvent(
  @SerialName("version")
  @ProtoNumber(1)
  val version: Int? = null,
  @SerialName("metadata_build")
  @ProtoNumber(2)
  val metadataBuild: Int? = null,
  @SerialName("message")
  @ProtoNumber(3)
  val message: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    version: Int? = null,
    metadataBuild: Int? = null,
    message: String? = null,
    createdmicros: Int? = null
  ) : this(version, metadataBuild, message, red.platform.now(), createdmicros)
}
