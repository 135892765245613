// Auto-Generated from OpenAPI Spec
package youversion.red.banner.model.userstate

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Namespaces(
    @SerialName("features")
    @ProtoNumber(1)
    @JvmField
    val features: List<Namespace>? = null
) : red.Serializable {
    init {
        freeze()
    }
}
