// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.bafk

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("bafk.KidsPieceCollectedEvent")
data class KidsPieceCollectedEvent(
  @SerialName("set")
  @ProtoNumber(1)
  val set: String? = null,
  @SerialName("item")
  @ProtoNumber(2)
  val item: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    set: String? = null,
    item: String? = null,
    createdmicros: Int? = null
  ) : this(set, item, red.platform.now(), createdmicros)
}
