package youversion.red.movies.service.js

import kotlin.Boolean
import kotlin.Int
import kotlin.Pair
import kotlin.String
import kotlin.collections.List
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.movies.Movie
import youversion.red.movies.MovieChapters
import youversion.red.movies.MovieLanguages
import youversion.red.movies.MovieOrientation
import youversion.red.movies.MoviePublisher
import youversion.red.movies.Movies
import youversion.red.movies.MoviesCollection
import youversion.red.movies.MoviesConfiguration
import youversion.red.movies.VideoMetadata
import youversion.red.movies.api.MoviesUIContext
import youversion.red.movies.api.model.collections.Collection
import youversion.red.movies.api.model.collections.Collections
import youversion.red.movies.api.model.videos.OrientationQSEnum
import youversion.red.movies.api.model.videos.StatusQSEnum
import youversion.red.movies.api.model.videos.Video
import youversion.red.movies.api.model.videos.VideoTypeEnum
import youversion.red.movies.api.model.videos.Videos
import youversion.red.movies.service.IMoviesService

public object MoviesService {
  private val service: IMoviesService by service<youversion.red.movies.service.IMoviesService>()

  @JsName(name = "getConfiguration")
  public fun getConfiguration(cacheOnly: Boolean): Promise<MoviesConfiguration?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getConfiguration(cacheOnly)

  }.toPromise()

  @JsName(name = "getPublisher")
  public fun getPublisher(id: Int): Promise<MoviePublisher?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getPublisher(id)

  }.toPromise()

  @JsName(name = "getMovie")
  public fun getMovie(id: Int, context: MoviesUIContext): Promise<Movie?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getMovie(id, context)

  }.toPromise()

  @JsName(name = "getChapters")
  public fun getChapters(): Promise<MovieChapters?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getChapters()

  }.toPromise()

  @JsName(name = "getMoviesRelatedTo")
  public fun getMoviesRelatedTo(usfm: String, page: Int?): Promise<Pair<Movies, String?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getMoviesRelatedTo(usfm, page)

  }.toPromise()

  @JsName(name = "getMoviesInCollection")
  public fun getMoviesInCollection(
    id: Int,
    type: VideoTypeEnum?,
    orientation: MovieOrientation?,
    pageSize: Int?,
    page: Int?
  ): Promise<Pair<Movies, String?>?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default)
      {
    service.getMoviesInCollection(id, type, orientation, pageSize, page)

  }.toPromise()

  @JsName(name = "getCollection")
  public fun getCollection(id: Int): Promise<MoviesCollection?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getCollection(id)

  }.toPromise()

  @JsName(name = "getLanguages")
  public fun getLanguages(type: String): Promise<MovieLanguages?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getLanguages(type)

  }.toPromise()

  @JsName(name = "getLegacyVideo")
  public fun getLegacyVideo(id: Int): Promise<VideoMetadata?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getLegacyVideo(id)

  }.toPromise()

  @JsName(name = "getActualMovieId")
  public fun getActualMovieId(id: Int): Promise<Pair<Int, MoviesCollection?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getActualMovieId(id)

  }.toPromise()

  @JsName(name = "getCollections")
  public fun getCollections(
    fields: String?,
    page: String?,
    pageSize: Int?,
    videoId: Int?,
    showSeasonal: Boolean?,
    showEmpty: Boolean?
  ): Promise<Collections?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getCollections(fields, page, pageSize, videoId, showSeasonal, showEmpty)

  }.toPromise()

  @JsName(name = "getVideos")
  public fun getVideos(
    fields: String?,
    page: String?,
    pageSize: Int?,
    related: String?,
    collectionId: Int?,
    publisherId: Int?,
    status: StatusQSEnum?,
    types: String?,
    orientation: OrientationQSEnum?
  ): Promise<Videos?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getVideos(fields, page, pageSize, related, collectionId, publisherId, status, types,
      orientation)

  }.toPromise()

  @JsName(name = "getCollectionVideos")
  public fun getCollectionVideos(
    fields: String?,
    page: String?,
    pageSize: Int?,
    related: String?,
    collectionId: Int?,
    publisherId: Int?,
    status: StatusQSEnum?,
    types: String?,
    orientation: OrientationQSEnum?
  ): Promise<List<Pair<Video, MoviePublisher?>>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getCollectionVideos(fields, page, pageSize, related, collectionId, publisherId, status,
      types, orientation)

  }.toPromise()

  @JsName(name = "getNewCollection")
  public fun getNewCollection(collectionId: Int): Promise<Collection?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getNewCollection(collectionId)

  }.toPromise()
}
