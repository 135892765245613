package youversion.red.banner.model

import red.platform.PlatformType
import red.platform.localization.Locales
import red.platform.localization.toLowerCase
import red.platform.platformType

interface IParameterSchema {

    val parameterKeys: Set<String>
    fun addSystemParameter(key: String, type: ParameterSchemaType)
    fun isValidKey(key: String): Boolean
    fun getType(key: String): ParameterSchemaType?
    fun getBit(key: String): Long?
    fun toComparable(key: String, value: String): Comparable<Any>
}

data class ParameterSchemaItem(val name: String, val type: ParameterSchemaType, val bit: Long)

abstract class AbstractParameterSchema : IParameterSchema {

    protected abstract val parameters: MutableMap<String, ParameterSchemaItem>

    override val parameterKeys: Set<String>
        get() = parameters.keys

    override fun addSystemParameter(key: String, type: ParameterSchemaType) {
        if (platformType == PlatformType.Android || platformType == PlatformType.iOS || platformType == PlatformType.JavaScript) {
            throw IllegalStateException("This is only supported on the server")
        }
        parameters[key] = ParameterSchemaItem(key, type, 1.toLong() shl parameters.size)
    }

    override fun isValidKey(key: String) = parameters.containsKey(key)
    override fun getType(key: String) = parameters[key]?.type
    override fun getBit(key: String) = parameters[key]?.bit

    @Suppress("unchecked_cast")
    override fun toComparable(key: String, value: String): Comparable<Any> = when (parameters[key]?.type) {
        ParameterSchemaType.Int -> value.toInt() as Comparable<Any>
        ParameterSchemaType.String -> value as Comparable<Any>
        ParameterSchemaType.Boolean -> when (value.toLowerCase(Locales.getEnglish())) {
            "1" -> true
            "true" -> true
            else -> false
        } as Comparable<Any>
        else -> throw IllegalArgumentException("Unsupported comparable key: $key")
    }
}
