// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The name of the screen that the person is seeing.
 */
enum class ScreenName(
  internal val serialName: String,
  internal val serialId: Int
) {
  GIVING_LANDING("giving_landing", 0),

  GIVING_GLOBAL_IMPACT("giving_global_impact", 1),

  GIVING_FORM("giving_form", 2),

  GIVING_CURRENCY("giving_currency", 3),

  GIVING_PAYMENT_METHODS("giving_payment_methods", 4),

  GIVING_ADD_CARD("giving_add_card", 5),

  GIVING_ADD_BANK_ACCOUNT("giving_add_bank_account", 6),

  GIVING_PROCESSING("giving_processing", 7),

  GIVING_RESULT("giving_result", 8),

  GIVING_RESULT_RECURRING_FREQUENCY("giving_result_recurring_frequency", 9),

  GIVING_SCHEDULE_LIST("giving_schedule_list", 10),

  GIVING_SCHEDULE_DETAIL("giving_schedule_detail", 11),

  GIVING_HISTORY("giving_history", 12),

  GIVING_STATEMENT("giving_statement", 13),

  GIVING_HELP("giving_help", 14),

  GIVING_ADD_PAYMENT_METHOD("giving_add_payment_method", 15),

  GIVING_CAUSE("giving_cause", 16),

  GIVING_FREQUENCY("giving_frequency", 17),

  GIVING_MY_GIVING("giving_my_giving", 18),

  GIVING_PROCESS_DATE("giving_process_date", 19),

  PARTNER_GIVING_FORM("partner_giving_form", 20),

  PARTNER_GIVING_CURRENCY("partner_giving_currency", 21),

  PARTNER_GIVING_PAYMENT_METHODS("partner_giving_payment_methods", 22),

  PARTNER_GIVING_ADD_CARD("partner_giving_add_card", 23),

  PARTNER_GIVING_ADD_BANK_ACCOUNT("partner_giving_add_bank_account", 24),

  PARTNER_GIVING_PROCESSING("partner_giving_processing", 25),

  PARTNER_GIVING_RESULT("partner_giving_result", 26),

  PARTNER_GIVING_ADD_PAYMENT_METHOD("partner_giving_add_payment_method", 27),

  GIVING_HISTORY_YEAR("giving_history_year", 28),

  GIVING_PAPER_STATEMENT("giving_paper_statement", 29),

  MANAGE_GIVING_DETAIL("manage_giving_detail", 30);
}

internal class ScreenNameSerializer : KSerializer<ScreenName> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.givingmikey.api.model.ScreenName")

  override fun serialize(encoder: Encoder, value: ScreenName) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ScreenName {
    if (decoder is JsonDecoder) {
      return ScreenName.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ScreenName.GIVING_LANDING
      1 -> ScreenName.GIVING_GLOBAL_IMPACT
      2 -> ScreenName.GIVING_FORM
      3 -> ScreenName.GIVING_CURRENCY
      4 -> ScreenName.GIVING_PAYMENT_METHODS
      5 -> ScreenName.GIVING_ADD_CARD
      6 -> ScreenName.GIVING_ADD_BANK_ACCOUNT
      7 -> ScreenName.GIVING_PROCESSING
      8 -> ScreenName.GIVING_RESULT
      9 -> ScreenName.GIVING_RESULT_RECURRING_FREQUENCY
      10 -> ScreenName.GIVING_SCHEDULE_LIST
      11 -> ScreenName.GIVING_SCHEDULE_DETAIL
      12 -> ScreenName.GIVING_HISTORY
      13 -> ScreenName.GIVING_STATEMENT
      14 -> ScreenName.GIVING_HELP
      15 -> ScreenName.GIVING_ADD_PAYMENT_METHOD
      16 -> ScreenName.GIVING_CAUSE
      17 -> ScreenName.GIVING_FREQUENCY
      18 -> ScreenName.GIVING_MY_GIVING
      19 -> ScreenName.GIVING_PROCESS_DATE
      20 -> ScreenName.PARTNER_GIVING_FORM
      21 -> ScreenName.PARTNER_GIVING_CURRENCY
      22 -> ScreenName.PARTNER_GIVING_PAYMENT_METHODS
      23 -> ScreenName.PARTNER_GIVING_ADD_CARD
      24 -> ScreenName.PARTNER_GIVING_ADD_BANK_ACCOUNT
      25 -> ScreenName.PARTNER_GIVING_PROCESSING
      26 -> ScreenName.PARTNER_GIVING_RESULT
      27 -> ScreenName.PARTNER_GIVING_ADD_PAYMENT_METHOD
      28 -> ScreenName.GIVING_HISTORY_YEAR
      29 -> ScreenName.GIVING_PAPER_STATEMENT
      30 -> ScreenName.MANAGE_GIVING_DETAIL
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
