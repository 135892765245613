// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminOrganizationUpdate(
  /**
   * This is the name of the organization.
   */
  @SerialName("name")
  @ProtoNumber(1)
  @JvmField
  val name: String? = null,
  /**
   * This is the website_url of the organization.
   */
  @SerialName("website_url")
  @ProtoNumber(2)
  @JvmField
  val websiteUrl: String? = null,
  /**
   * This is the phone number of the organization.
   */
  @SerialName("phone")
  @ProtoNumber(3)
  @JvmField
  val phone: String? = null,
  /**
   * This is the email of the organization.
   */
  @SerialName("email")
  @ProtoNumber(4)
  @JvmField
  val email: String? = null,
  @SerialName("logo_image_id")
  @ProtoNumber(5)
  @JvmField
  val logoImageId: String? = null,
  /**
   * The hex color code for the background color of an organization.
   */
  @SerialName("background_color")
  @ProtoNumber(6)
  @JvmField
  val backgroundColor: String? = null,
  /**
   * The primary language of the organization represented as a an iso 639 language code
   */
  @SerialName("primary_language")
  @ProtoNumber(7)
  @JvmField
  val primaryLanguage: String? = null,
  @SerialName("i18ns")
  @ProtoNumber(8)
  @JvmField
  val i18ns: List<I18n>? = null,
  /**
   * Who or what referred the organization to sign up for yv4c
   */
  @SerialName("signup_referrer")
  @ProtoNumber(9)
  @JvmField
  val signupReferrer: String? = null,
  /**
   * This timezone the organization is set in.
   */
  @SerialName("timezone")
  @ProtoNumber(10)
  @JvmField
  val timezone: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
