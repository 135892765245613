// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.profiles

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator of social media platforms
 */
enum class SocialNetworkPlatforms(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  FACEBOOK("Facebook", 1),

  LINKED_IN("LinkedIn", 2),

  INSTAGRAM("Instagram", 3),

  TWITTER("Twitter", 4),

  SNAPCHAT("Snapchat", 5),

  PINTEREST("Pinterest", 6),

  REDDIT("Reddit", 7),

  YOU_TUBE("YouTube", 8),

  TIK_TOK("TikTok", 9),

  VIMEO("Vimeo", 10),

  TWITCH("Twitch", 11),

  WHATS_APP("WhatsApp", 12),

  MESSENGER("Messenger", 13),

  TELEGRAM("Telegram", 14),

  SIGNAL("Signal", 15),

  VIBER("Viber", 16),

  SOUND_CLOUD("SoundCloud", 17),

  SPOTIFY("Spotify", 18),

  QUORA("Quora", 19),

  GOODREADS("Goodreads", 20),

  STRAVA("Strava", 21),

  RAVELRY("Ravelry", 22),

  LETTERBOXD("Letterboxd", 23),

  FOURSQUARE("Foursquare", 24),

  SWARM("Swarm", 25),

  ETSY("Etsy", 26),

  WEIBO("Weibo", 27),

  CAFFEINE("Caffeine", 28),

  CRUNCHYROLL("Crunchyroll", 29);
}

internal class SocialNetworkPlatformsSerializer : KSerializer<SocialNetworkPlatforms> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.profiles.SocialNetworkPlatforms")

  override fun serialize(encoder: Encoder, value: SocialNetworkPlatforms) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): SocialNetworkPlatforms {
    if (decoder is JsonDecoder) {
      return SocialNetworkPlatforms.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> SocialNetworkPlatforms.UNSUPPORTED_PLACEHOLDER
      1 -> SocialNetworkPlatforms.FACEBOOK
      2 -> SocialNetworkPlatforms.LINKED_IN
      3 -> SocialNetworkPlatforms.INSTAGRAM
      4 -> SocialNetworkPlatforms.TWITTER
      5 -> SocialNetworkPlatforms.SNAPCHAT
      6 -> SocialNetworkPlatforms.PINTEREST
      7 -> SocialNetworkPlatforms.REDDIT
      8 -> SocialNetworkPlatforms.YOU_TUBE
      9 -> SocialNetworkPlatforms.TIK_TOK
      10 -> SocialNetworkPlatforms.VIMEO
      11 -> SocialNetworkPlatforms.TWITCH
      12 -> SocialNetworkPlatforms.WHATS_APP
      13 -> SocialNetworkPlatforms.MESSENGER
      14 -> SocialNetworkPlatforms.TELEGRAM
      15 -> SocialNetworkPlatforms.SIGNAL
      16 -> SocialNetworkPlatforms.VIBER
      17 -> SocialNetworkPlatforms.SOUND_CLOUD
      18 -> SocialNetworkPlatforms.SPOTIFY
      19 -> SocialNetworkPlatforms.QUORA
      20 -> SocialNetworkPlatforms.GOODREADS
      21 -> SocialNetworkPlatforms.STRAVA
      22 -> SocialNetworkPlatforms.RAVELRY
      23 -> SocialNetworkPlatforms.LETTERBOXD
      24 -> SocialNetworkPlatforms.FOURSQUARE
      25 -> SocialNetworkPlatforms.SWARM
      26 -> SocialNetworkPlatforms.ETSY
      27 -> SocialNetworkPlatforms.WEIBO
      28 -> SocialNetworkPlatforms.CAFFEINE
      29 -> SocialNetworkPlatforms.CRUNCHYROLL
      else -> SocialNetworkPlatforms.UNSUPPORTED_PLACEHOLDER
    }
  }
}
