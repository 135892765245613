// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GivingStripeConfig(
  /**
   * Instance Id for the Stripe Account found here: https://dashboard.stripe.com/apikeys 
   */
  @SerialName("instance_id")
  @ProtoNumber(1)
  @JvmField
  val instanceId: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
