// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminImage(
  /**
   * The id of the image
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Long? = null,
  /**
   * The image proxy pre-pended url to the image from the relevant GCS bucket for the asset.
   */
  @SerialName("url")
  @ProtoNumber(2)
  @JvmField
  val url: String = "",
  /**
   * How wide is this image?
   */
  @SerialName("width")
  @ProtoNumber(3)
  @JvmField
  val width: Int = 0,
  /**
   * How tall is this image?
   */
  @SerialName("height")
  @ProtoNumber(4)
  @JvmField
  val height: Int = 0
) : red.Serializable {
  init {
    freeze()
  }
}
