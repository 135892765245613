package youversion.red.prayer.guided.ext

import red.platform.now
import youversion.red.dataman.api.model.guidedprayer.GuideModuleAction
import youversion.red.dataman.api.model.guidedprayer.GuideModuleView
import youversion.red.dataman.api.model.guidedprayer.GuideReferrer
import youversion.red.dataman.api.model.guidedprayer.GuidedPrayerAnalyticsModuleType
import youversion.red.guidedprayer.api.model.GuidedPrayerDay as ApiDay
import youversion.red.guidedprayer.api.model.GuidedPrayerGuide as ApiGuide
import youversion.red.guidedprayer.api.model.GuidedPrayerModule as ApiModule
import youversion.red.guidedprayer.api.model.GuidedPrayerModuleType
import youversion.red.guidedprayer.api.model.events.GuideModuleActionFireBase
import youversion.red.guidedprayer.api.model.events.GuideModuleViewFireBase
import youversion.red.guidedprayer.api.model.guidedprayer.GuideReferrerFireBase
import youversion.red.guidedprayer.api.model.guidedprayer.GuidedPrayerAnalyticsModuleType as FirebaseGPAnalyticsModuleType
import youversion.red.prayer.guided.model.GuidedPrayerDay as DbDay
import youversion.red.prayer.guided.model.GuidedPrayerGuide as DbGuide
import youversion.red.prayer.guided.model.GuidedPrayerModule as DbModule

fun ApiDay.toDb(guideId: Int): DbDay = DbDay(
    guideId = guideId,
    id = this.id!!,
    imageUrl = this.imageUrl,
    title = this.title,
    lightBgColor = this.lightBgColor,
    darkBgColor = this.darkBgColor
)

fun ApiModule.toDb(dayId: Int, guideId: Int): DbModule {
    return DbModule(
        guideId = guideId,
        id = this.id!!,
        dayId = dayId,
        type = this.Type,
        title = this.title,
        header = this.header,
        usfm = this.usfm,
        text = this.text,
        ctaText = this.cta?.text,
        ctaUrl = this.cta?.url,
        animationId = this.animationId
    )
}

fun ApiGuide.toGuide(): DbGuide = DbGuide(
    this.id ?: throw NullPointerException("guide id is required"),
    this.languages ?: throw NullPointerException("guide languages are required")
)

// this exists because we can not yet share api specs with dataman specs
fun GuidedPrayerModuleType.toAnalyticsType(): GuidedPrayerAnalyticsModuleType = when (this) {
    GuidedPrayerModuleType.TEXT -> GuidedPrayerAnalyticsModuleType.TEXT
    GuidedPrayerModuleType.PRAYER_LIST -> GuidedPrayerAnalyticsModuleType.PRAYER_LIST
    GuidedPrayerModuleType.OUTRO -> GuidedPrayerAnalyticsModuleType.OUTRO
    GuidedPrayerModuleType.USFM_TEXT -> GuidedPrayerAnalyticsModuleType.USFM_TEXT
    GuidedPrayerModuleType.UNSUPPORTED_PLACEHOLDER -> GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER
}

fun GuidedPrayerModuleType.toFirebaseAnalyticsType(): FirebaseGPAnalyticsModuleType = when (this) {
    GuidedPrayerModuleType.TEXT -> FirebaseGPAnalyticsModuleType.TEXT
    GuidedPrayerModuleType.PRAYER_LIST -> FirebaseGPAnalyticsModuleType.PRAYER_LIST
    GuidedPrayerModuleType.OUTRO -> FirebaseGPAnalyticsModuleType.OUTRO
    GuidedPrayerModuleType.USFM_TEXT -> FirebaseGPAnalyticsModuleType.USFM_TEXT
    GuidedPrayerModuleType.UNSUPPORTED_PLACEHOLDER -> FirebaseGPAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER
}

fun youversion.red.prayer.guided.model.GuidedPrayerModule.toViewEvent(
    exitGuide: Boolean,
    viewDuration: Int? = null,
    textAnimation: String? = null,
    referrer: GuideReferrer? = null,
    moduleIndex: Int? = null
): GuideModuleView = GuideModuleView(
    guideId = this.guideId,
    dayId = this.dayId,
    animationId = this.animationId,
    moduleId = this.id,
    duration = viewDuration,
    type = this.type?.toAnalyticsType() ?: GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER,
    exitGuide = exitGuide,
    reference = this.usfm,
    created = now(),
    textAnimationId = textAnimation,
    referrer = referrer,
    weight = moduleIndex,
    title = title
)

fun youversion.red.prayer.guided.model.GuidedPrayerModule.toFirebaseViewEvent(
    exitGuide: Boolean,
    viewDuration: Int? = null,
    textAnimation: String? = null,
    referrer: GuideReferrerFireBase? = null,
    moduleIndex: Int? = null
): GuideModuleViewFireBase = GuideModuleViewFireBase(
    guideId = this.guideId,
    dayId = this.dayId,
    animationId = this.animationId,
    moduleId = this.id,
    duration = viewDuration,
    type = this.type?.toFirebaseAnalyticsType() ?: FirebaseGPAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER,
    exitGuide = exitGuide,
    reference = this.usfm,
    textAnimationId = textAnimation,
    referrer = referrer,
    weight = moduleIndex,
    title = title
)

fun youversion.red.prayer.guided.model.GuidedPrayerModule.toActionEvent(): GuideModuleAction = GuideModuleAction(
    guideId = guideId,
    moduleId = id,
    dayId = dayId,
    url = ctaUrl,
    text = ctaText,
    type = type?.toAnalyticsType() ?: GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER
)

fun youversion.red.prayer.guided.model.GuidedPrayerModule.toFirebaseActionEvent(): GuideModuleActionFireBase = GuideModuleActionFireBase(
    guideId = guideId,
    moduleId = id,
    dayId = dayId,
    url = ctaUrl,
    text = ctaText,
    type = type?.toFirebaseAnalyticsType() ?: FirebaseGPAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER
)
