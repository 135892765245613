// Auto-Generated from OpenAPI Spec
package youversion.red.locales.api

import kotlin.Int
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.locales.api.model.ApiTag
import youversion.red.locales.api.model.BibleLocaleHeaders
import youversion.red.locales.api.model.LocaleHeaders
import youversion.red.locales.api.model.LocalePlatform
import youversion.red.locales.api.model.Rules

/**
 * Locales API for returning appropriate content to clients based on the user's locales.
 */
object LocalesApi : AbstractApi(ApiDefaults("locales", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0")) {
  /**
   * @param locale The locale
   * @param platform The platform for a locale
   * @param poVersionId The version of the PO file that the client is requesting.
   */
  suspend fun getFilesPoLocale(
    locale: String,
    platform: LocalePlatform? = null,
    poVersionId: Int? = null
  ) = execute("/files/po/${locale}", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      platform?.let { add("platform", platform.serialName) }
      poVersionId?.let { add("po_version_id", poVersionId) }
  }, serializer = Unit.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param locales The list of locales as a comma separated string. Ex. "en,en-GB"
   * @param platform The platform for a locale
   * @return Successful request.
   */
  suspend fun getLocaleHeaders(locales: String? = null, platform: LocalePlatform? = null):
      LocaleHeaders = execute("/locales", method = RequestMethods.GET, version = "4.0", authAllowed
      = false, authOptional = false, queryString = red.platform.http.queryString {
      locales?.let { add("locales", locales) }
      platform?.let { add("platform", platform.serialName) }
  }, serializer = LocaleHeaders.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param locale The locale
   * @param platform The platform for a locale
   * @return Successful request.
   */
  suspend fun getApiTag(locale: String, platform: LocalePlatform? = null): ApiTag? =
      execute("/locales/tag/${locale}", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      platform?.let { add("platform", platform.serialName) }
  }, serializer = ApiTag.serializer())

  /**
   * @return Successful request.
   */
  suspend fun getBibleLocaleHeaders(): BibleLocaleHeaders = execute("/locales/bible", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      BibleLocaleHeaders.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Successful request.
   */
  suspend fun getRules(): Rules = execute("/rules", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, serializer = Rules.serializer()) ?: throw
      NullPointerException("Response not sent from server")
}
