// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.following

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.organizations.api.model.images.OrganizationImage

@Serializable
data class Following(
  /**
   * This is the id of the organization followed
   */
  @SerialName("organization_id")
  @ProtoNumber(1)
  @JvmField
  val organizationId: String = "",
  /**
   * This is the name of the organization followed
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  @SerialName("logo")
  @ProtoNumber(3)
  @JvmField
  val logo: OrganizationImage? = null,
  /**
   * The created date time of the organization.
   */
  @SerialName("created_dt")
  @ProtoNumber(4)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
