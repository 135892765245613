package youversion.red.banner.model.variant

import red.platform.threads.SharedImmutable

@SharedImmutable
const val VARIANTS_VERSION_PARAMETER_KEY = "variants_version"

// This is to prevent old clients from displaying new banner variants.
// Increment this when a new variant version is introduced.
@SharedImmutable
internal const val SUPPORTED_VARIANTS_VERSION = 1

enum class Variant(val minimumSupportedVersion: Int) {
    Default(minimumSupportedVersion = 0),
    Compact(minimumSupportedVersion = 1),
    Comfortable(minimumSupportedVersion = 1),
}
