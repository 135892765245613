// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
data class ChurchPostMutation(
  /**
   * The organization id.
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * The name of the organization.
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String? = null,
  /**
   * The website_url of the organization.
   */
  @SerialName("website_url")
  @ProtoNumber(3)
  @JvmField
  val websiteUrl: String? = null,
  /**
   * The phone number of the organization.
   */
  @SerialName("phone")
  @ProtoNumber(4)
  @JvmField
  val phone: String? = null,
  /**
   * The email of the organization.
   */
  @SerialName("email")
  @ProtoNumber(5)
  @JvmField
  val email: String? = null,
  /**
   * This is the default hex color code for the background color of an organization if none is
   * supplied the hex color for Regent Grey will be used as the default.
   */
  @SerialName("background_color")
  @ProtoNumber(7)
  @JvmField
  val backgroundColor: String? = null,
  /**
   * The weekly attendance of the organization.
   */
  @SerialName("weekly_attendance")
  @ProtoNumber(9)
  @JvmField
  val weeklyAttendance: Int? = null,
  @SerialName("status")
  @ProtoNumber(10)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  @JvmField
  val status: ProfileStatus? = null,
  /**
   * The timezone of the organization.
   */
  @SerialName("timezone")
  @ProtoNumber(20)
  @JvmField
  val timezone: String? = null,
  /**
   * Who or what referred the organization to sign up for yv4c
   */
  @SerialName("signup_referrer")
  @ProtoNumber(23)
  @JvmField
  val signupReferrer: String? = null,
  /**
   * The primary language of the organization represented as a an iso 639 language code
   */
  @SerialName("primary_language")
  @ProtoNumber(24)
  @JvmField
  val primaryLanguage: String? = null,
  @SerialName("address")
  @ProtoNumber(25)
  @JvmField
  val address: Address? = null
) : red.Serializable {
  init {
    freeze()
  }
}
