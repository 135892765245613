// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Float
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.AdministrativeAreaLevel1
import youversion.red.organizations.api.model.profiles.Country
import youversion.red.organizations.api.model.profiles.Locality
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
@SerialName("organizations.organization_profiles.AddressProfile")
data class AddressProfile(
  /**
   * The human-readable address of the organization.
   */
  @SerialName("formatted_address")
  @ProtoNumber(61)
  val formattedAddress: String = "",
  /**
   * A less specific and more broad field that can be a combination of different regional fields.
   */
  @SerialName("formatted_locality")
  @ProtoNumber(62)
  val formattedLocality: String = "",
  /**
   * The textual identifier that uniquely identifies a place.
   */
  @SerialName("place_id")
  @ProtoNumber(63)
  val placeId: String = "",
  /**
   * The location of the address profile.
   */
  @SerialName("latitude")
  @ProtoNumber(64)
  val latitude: Float = 0f,
  /**
   * The location of the address profile.
   */
  @SerialName("longitude")
  @ProtoNumber(65)
  val longitude: Float = 0f,
  @SerialName("administrative_area_level_1")
  @ProtoNumber(66)
  val administrativeAreaLevel1: AdministrativeAreaLevel1,
  @SerialName("locality")
  @ProtoNumber(67)
  val locality: Locality? = null,
  @SerialName("country")
  @ProtoNumber(68)
  val country: Country,
  @SerialName("status")
  @ProtoNumber(69)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: String = "",
  @SerialName("organization_id")
  @ProtoNumber(2)
  override val organizationId: String = ""
) : BaseProfile, red.Serializable {
  init {
    freeze()
  }
}
