// Auto-Generated from OpenAPI Spec
package youversion.red.plans.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object PlansDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<PlansDeepLinkListener?> = AtomicReference(null)

  var listener: PlansDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/reading-plans") { context, _, _ -> 
        listener.onPlans(context)
    }
    register("/my-plans") { context, _, _ -> 
        listener.onMyPlans(context)
    }
    register("/saved_plans") { context, _, _ -> 
        listener.onSavedPlans(context)
    }
    register("/plan_finder") { context, _, _ -> 
        listener.onPlanFinder(context)
    }
    register("/reading-plans/$INTEGER_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val subscribe = queries["subscribe"]?.firstOrNull()?.toBoolean()
        val addToQueue = queries["add_to_queue"]?.firstOrNull()?.toBoolean()
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onPlan(context, id, subscribe, addToQueue)
    }
    register("/reading-plans-collection/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onPlanCollection(context, id)
    }
    register("/reading-plans/$INTEGER_EXPRESSION/day/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val id = values[1].toIntOrNull() ?: return@register false
        val day = values[2].toIntOrNull() ?: return@register false
        listener.onPlanDay(context, id, day)
    }
    freeze()
  }
}
