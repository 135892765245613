// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.following.collection_get

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine the type of sort to return
 */
enum class SortTypeEnum(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ALPHA("alpha", 1),

  NEWEST("newest", 2);
}

internal class SortTypeEnumSerializer : KSerializer<SortTypeEnum> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.following.collection_get.SortTypeEnum")

  override fun serialize(encoder: Encoder, value: SortTypeEnum) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): SortTypeEnum {
    if (decoder is JsonDecoder) {
      return SortTypeEnum.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> SortTypeEnum.UNSUPPORTED_PLACEHOLDER
      1 -> SortTypeEnum.ALPHA
      2 -> SortTypeEnum.NEWEST
      else -> SortTypeEnum.UNSUPPORTED_PLACEHOLDER
    }
  }
}
