// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PostActionRequest(
  /**
   * Type of action to perform
   */
  @SerialName("type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.organizations.api.model.organizations.PostActionKindSerializer::class)
  @JvmField
  val type: PostActionKind =
      youversion.red.organizations.api.model.organizations.PostActionKind.UNSUPPORTED_PLACEHOLDER
) : red.Serializable {
  init {
    freeze()
  }
}
