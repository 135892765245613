// Auto-Generated from OpenAPI Spec
package youversion.red.discovery.api.model

import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class DiscoverSectionCategory(
  /**
   * Type is a way to identify a unique layout and/or collection of a feature set
   */
  @SerialName("type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.discovery.api.model.DiscoverSectionCategoryTypeSerializer::class)
  @JvmField
  val type: DiscoverSectionCategoryType? = null
) : red.Serializable {
  init {
    freeze()
  }
}
