package youversion.red.locales.service

import red.platform.Log
import red.service.DefaultService
import youversion.red.locales.api.LocalesApi
import youversion.red.locales.api.model.LocaleHeader
import youversion.red.locales.api.model.LocalePlatform

@DefaultService(ILocalesService::class)
class LocalesServiceImpl : ILocalesService {

    override suspend fun getLocaleHeaders(locales: Set<String>, platform: LocalePlatform?): List<LocaleHeader> {
        val localesStr = locales.joinToString(",") { it }
        Log.w("LocalesService", "About to fetch locales for : $localesStr")
        val headers = LocalesApi.getLocaleHeaders(localesStr, platform).locales
        Log.w("LocalesService", "Found headers for $localesStr -> $headers")
        return headers
    }

    override suspend fun getApiTag(locale: String): String {
        return LocalesApi.getApiTag(locale)?.tag ?: ""
    }
}
