// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api

import kotlin.ByteArray
import kotlin.OptIn
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.serializer
import red.platform.http.FormatType
import red.platform.http.Serializer
import red.platform.threads.freeze
import youversion.red.organizations.api.model.organizations.OrganizationPostView
import youversion.red.organizations.api.model.organizations.OrganizationProfileView
import youversion.red.organizations.api.model.organizations.organization_profiles.AddressProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.AdminGivingProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.BibleProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.ChurchProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.ContentProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.GivingProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PatchAddressProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PatchBaseProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PatchChurchProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PatchContentProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PatchGivingProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PostAddressProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PostBaseProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PostBibleProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PostChurchProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PostContentProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.PostGivingProfile
import youversion.red.organizations.deeplink.OrganizationsDeepLinkHandler

object OrganizationsApiSerializer : Serializer {
  private val context: SerializersModule = SerializersModule {

      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.ChurchProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.ChurchProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.BibleProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.BibleProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.AddressProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.AddressProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.GivingProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.GivingProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.ContentProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.ContentProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.AdminGivingProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.AdminGivingProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PatchBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PatchChurchProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PatchChurchProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PatchBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PatchContentProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PatchContentProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PatchBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PatchAddressProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PatchAddressProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PatchBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PatchGivingProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PatchGivingProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PostBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostChurchProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostChurchProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PostBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostBibleProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostBibleProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PostBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostAddressProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostAddressProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PostBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostContentProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostContentProfile.serializer())
      polymorphic(youversion.red.organizations.api.model.organizations.organization_profiles.PostBaseProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostGivingProfile::class,
          youversion.red.organizations.api.model.organizations.organization_profiles.PostGivingProfile.serializer())
      }

  private val json: Json = Json { isLenient = true; ignoreUnknownKeys = true;
      allowSpecialFloatingPointValues = true; classDiscriminator = "_type";  serializersModule =
      context }

  @OptIn(kotlinx.serialization.ExperimentalSerializationApi::class)
  private val protobuf: ProtoBuf = ProtoBuf { encodeDefaults = true; serializersModule = context }

  init {
    freeze()}

  override fun <T> serialize(
    type: FormatType,
    serializer: SerializationStrategy<T>,
    obj: T
  ): ByteArray = when (type) {
     FormatType.JSON -> json.encodeToString(serializer, obj).encodeToByteArray()
     FormatType.PROTOBUF -> protobuf.encodeToByteArray(serializer, obj)
  }
  override fun <T> deserialize(
    type: FormatType,
    deserializer: DeserializationStrategy<T>,
    data: ByteArray
  ): T? = when (type) {
     FormatType.JSON -> json.decodeFromString(deserializer, data.decodeToString())
     FormatType.PROTOBUF -> protobuf.decodeFromByteArray(deserializer, data)
  }}
