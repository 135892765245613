package youversion.red.plans.service

import red.platform.localization.Locales
import red.platform.threads.AtomicReference
import red.platform.threads.SharedImmutable
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import red.service.DefaultService
import red.tasks.blockingSuspendCoroutine
import youversion.red.bible.model.BibleLocale
import youversion.red.bible.reference.VersionId
import youversion.red.bible.service.BibleLocaleService
import youversion.red.bible.service.BibleService
import youversion.red.plans.api.PlansApi
import youversion.red.plans.api.ReadingPlansApi
import youversion.red.plans.model.Plan
import youversion.red.plans.model.PlanDay
import youversion.red.plans.model.PlanResults
import youversion.red.versification.service.VersificationService

@DefaultService(IPlansService::class)
internal class PlansServiceImpl : IPlansService {

    private val bibleService by BibleService()
    private val localeService by BibleLocaleService()
    private val versificationService by VersificationService()

    override suspend fun getPlan(id: Int, languageTag: String?) = _repository.value?.let {
        blockingSuspendCoroutine<Plan> {
            repository.getPlan(id, it)
        }
    } ?: try {
        ReadingPlansApi.getPlan(id, languageTag ?: Locales.getDefault().getApiTag2())
            ?: ReadingPlansApi.getUnAuthedPlan(id, languageTag ?: Locales.getDefault().getApiTag2())
    } catch (e: Exception) {
        ReadingPlansApi.getUnAuthedPlan(id, languageTag ?: Locales.getDefault().getApiTag2())
    }

    override suspend fun getPlanDay(
        id: Int,
        day: Int,
        together: Boolean,
        versionId: VersionId?
    ): PlanDay {
        val planDay = _repository.value?.let {
            blockingSuspendCoroutine<PlanDay> {
                repository.getPlanDay(id, day, it)
            }
        } ?: PlansApi.getPlanDay(id, day, together)
        val versionIdForVersification = versionId ?: bibleService.getCurrentVersion().id
        return planDay.versify(versificationService.newVersification(), versionIdForVersification)
    }

    override suspend fun saveForLater(id: Int) =
        blockingSuspendCoroutine<Unit> {
            repository.saveForLater(id, it)
        }

    override suspend fun getCompletedPlanIds(): IntArray = blockingSuspendCoroutine {
        repository.getCompletedPlanIds(it)
    }

    override suspend fun getConfiguration() = ReadingPlansApi.getConfiguration() ?: error("Configuration not found")

    override suspend fun getDiscover(languageTag: String) = ReadingPlansApi.getDiscover(languageTag)

    override suspend fun getDiscoverCollection(collectionId: Int, page: Int) = ReadingPlansApi.getDiscoverCollection(collectionId, page)

    override suspend fun getSuggestedPlans(planId: Int, languageTag: String) =
        ReadingPlansApi.getSuggestedPlans(planId, languageTag)

    override suspend fun getPlansByCategory(category: String): PlanResults =
        ReadingPlansApi.getPlansByCategory(category) ?: PlanResults()

    override suspend fun getPlansByReference(usfm: String): PlanResults =
        ReadingPlansApi.getPlansByReference(usfm) ?: PlanResults()

    override suspend fun getLanguages(): List<BibleLocale> = localeService.getLocales(getConfiguration().languageTags.toSet())

    override suspend fun getCollection(id: Int) = ReadingPlansApi.getPlanCollection(id)
}

@SharedImmutable
private val _repository = AtomicReference<PlanRepository?>(null).freeze()

var repository: PlanRepository
    get() = _repository.value!!
    set(repository) {
        _repository.setAssertTrue(repository.freeze())
    }
