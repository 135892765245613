// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.images

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PresignedImageResponse(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Long? = null,
  /**
   * Presigned GCS Image upload URL
   */
  @SerialName("uri")
  @ProtoNumber(2)
  @JvmField
  val uri: String? = null,
  /**
   * How tall is this image?
   */
  @SerialName("height")
  @ProtoNumber(3)
  @JvmField
  val height: Int? = null,
  /**
   * How wide is this image?
   */
  @SerialName("width")
  @ProtoNumber(4)
  @JvmField
  val width: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
