// Auto-Generated from OpenAPI Spec
package youversion.red.banner.model.userstate.state.banner

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class BannerImpression(
    @SerialName("id")
    @ProtoNumber(3)
    @JvmField
    val id: String? = null,
    @SerialName("language_tag")
    @ProtoNumber(4)
    @JvmField
    val languageTag: String? = null,
    @SerialName("index")
    @ProtoNumber(5)
    @JvmField
    val index: Int? = null,
    @SerialName("firstImpression")
    @ProtoNumber(6)
    @JvmField
    val firstimpression: @Serializable(with = red.platform.DateSerializer::class) Date? = null,
    @SerialName("count")
    @ProtoNumber(7)
    @JvmField
    val count: Int? = null,
    @SerialName("uuid")
    @ProtoNumber(8)
    @JvmField
    val uuid: String? = null
) : red.Serializable {
    init {
        freeze()
    }
}
