// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api

import kotlin.Int
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.givingmikey.api.model.GivingConfiguration
import youversion.red.givingmikey.api.model.GivingCurrencies
import youversion.red.givingmikey.api.model.GivingDonationRecords
import youversion.red.givingmikey.api.model.GivingFrequencies
import youversion.red.givingmikey.api.model.GivingFunds
import youversion.red.givingmikey.api.model.GivingPayment
import youversion.red.givingmikey.api.model.GivingPaymentMethod
import youversion.red.givingmikey.api.model.GivingPaymentMethodPost
import youversion.red.givingmikey.api.model.GivingPaymentMethods
import youversion.red.givingmikey.api.model.GivingPaymentPost
import youversion.red.givingmikey.api.model.GivingSetupIntent
import youversion.red.givingmikey.api.model.GivingStatement
import youversion.red.givingmikey.api.model.GivingSubscription
import youversion.red.givingmikey.api.model.GivingSubscriptions
import youversion.red.givingmikey.api.model.GivingUser
import youversion.red.givingmikey.api.model.GivingUserUpdate
import youversion.red.givingmikey.api.model.PaypalCheckout
import youversion.red.givingmikey.api.model.PaypalCheckoutPost
import youversion.red.givingmikey.api.model.impacts.MyImpact
import youversion.red.givingmikey.api.model.interactions.Interactions

/**
 * Giving API (Michangelo)
 */
object GivingMikeyApi : AbstractApi(ApiDefaults("giving", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", GivingMikeyApiSerializer)) {
  /**
   * Helpful for displaying a list of user currencies and formatting relevant details.
   *
   * @return Return a list of currencies with their corresponding details.
   */
  suspend fun getCurrencies(): GivingCurrencies = execute("/currencies", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      GivingCurrencies.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * Helpful for displaying a list of fund types.
   *
   * @return Return a list of funds with their corresponding details.
   */
  suspend fun getFunds(): GivingFunds = execute("/funds", method = RequestMethods.GET, version =
      "4.0", authAllowed = false, authOptional = false, serializer = GivingFunds.serializer()) ?:
      throw NullPointerException("Response not sent from server")

  /**
   * Helpful for providing a list of the user's existing recurring gifts.
   *
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return a list of subscriptions with their corresponding details.
   */
  suspend fun getSubscriptions(page: Int? = null, pageSize: Int? = null): GivingSubscriptions =
      execute("/subscriptions", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = false, queryString = red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = GivingSubscriptions.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated deletion of a user's recurring gift.
   *
   * @param subscriptionId The unique identifier for a subscription (a recurring gift).
   */
  suspend fun deleteSubscription(subscriptionId: Int) = execute("/subscriptions/${subscriptionId}",
      method = RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false,
      serializer = Unit.serializer())

  /**
   * Fetch a recurring gift
   *
   * @param subscriptionId The unique identifier for a subscription (a recurring gift).
   * @return Subscription retrieved successfully
   */
  suspend fun getSubscription(subscriptionId: Int): GivingSubscription? =
      execute("/subscriptions/${subscriptionId}", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, serializer = GivingSubscription.serializer())

  /**
   * Stripe refers to this as "PaymentIntent" which notifies the gateway that money should be
   * transferred.
   *
   * @return Payment created successfully
   */
  suspend fun makePayment(body: GivingPaymentPost? = null): GivingPayment = execute("/payments",
      version = "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = true, body =
      body, bodySerializer = GivingPaymentPost.serializer(), serializer =
      GivingPayment.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * Stripe refers to this as "SetupIntent" which preserves a payment method for future use.
   *
   * @return Payment Method created successfully
   */
  suspend fun addPaymentMethod(body: GivingPaymentMethodPost? = null): GivingPaymentMethod =
      execute("/payment-methods", version = "4.0", method = RequestMethods.POST, authAllowed = true,
      authOptional = true, body = body, bodySerializer = GivingPaymentMethodPost.serializer(),
      serializer = GivingPaymentMethod.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Helpful for providing a list of payment methods before a user is makes a gift.
   *
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return a list of payment methods with their corresponding details.
   */
  suspend fun getPaymentMethods(page: Int? = null, pageSize: Int? = null): GivingPaymentMethods =
      execute("/payment-methods", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = false, queryString = red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = GivingPaymentMethods.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated deletion of a user's payment method.
   *
   * @param paymentMethodId The unique identifier for a payment_method.
   */
  suspend fun deletePaymentMethod(paymentMethodId: Int) =
      execute("/payment-methods/${paymentMethodId}", method = RequestMethods.DELETE, version =
      "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * Fetch a payment method
   *
   * @param paymentMethodId The unique identifier for a payment_method.
   * @return Payment Method retrieved successfully
   */
  suspend fun getPaymentMethod(paymentMethodId: Int): GivingPaymentMethod? =
      execute("/payment-methods/${paymentMethodId}", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, serializer = GivingPaymentMethod.serializer())

  /**
   * Used to get the URI the client should call to make a donation or setup a subscription using
   * PayPal.
   *
   * @return Successfully return the PayPal checkout URL the client should call to setup the PayPal
   * donation.
   */
  suspend fun addPaypalCheckout(body: PaypalCheckoutPost? = null): PaypalCheckout =
      execute("/paypal-checkout", version = "4.0", method = RequestMethods.POST, authAllowed = true,
      authOptional = false, body = body, bodySerializer = PaypalCheckoutPost.serializer(),
      serializer = PaypalCheckout.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Used to get list of possible giving frequencies displayed i.e. recurring, one-time, etc.
   *
   * @return Return a list of possible giving frequencies.
   */
  suspend fun getFrequencies(): GivingFrequencies = execute("/frequencies", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      GivingFrequencies.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * Fetch the url to download a yearly giving statement from the cloud
   *
   * @param statementYear The unique identifier for a statement year.
   * @return Successful response
   */
  suspend fun getGivingStatement(statementYear: Int): GivingStatement? =
      execute("/giving-statements/${statementYear}", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, serializer = GivingStatement.serializer())

  /**
   * Retrieve a user's transaction history for a given year and defaults to the current year's
   * transactions
   *
   * @param historyYear The unique identifier for a donation year.
   * @return Successful response
   */
  suspend fun getHistory(historyYear: Int? = null): GivingDonationRecords? = execute("/history",
      method = RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false,
      queryString = red.platform.http.queryString {
      historyYear?.let { add("history_year", historyYear) }
  }, serializer = GivingDonationRecords.serializer())

  /**
   * configurations like 'Stripe instance Id' can be fetched from the client and used to communicate
   * with Stripe
   *
   * @return Return the giving configuration info for the client to connect with
   */
  suspend fun getConfiguration(): GivingConfiguration = execute("/configuration", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      GivingConfiguration.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Create Stripe SetupIntent resource will be created in Stripe and the client secreate that
   * provides access to it by clients will be returned.
   *
   * @return Return a Stripe client secret for a new SetupIntent belonging to the currently auth'ed
   * user's Stripe Customer.
   */
  suspend fun getSetupIntent(): GivingSetupIntent = execute("/stripe-customers/me/setup-intents",
      method = RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true,
      serializer = GivingSetupIntent.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Get the authenticated user's information including address, email, preferred statement type,
   * etc.
   *
   * @return Return the authenticated user's giving information
   */
  suspend fun getUser(): GivingUser? = execute("/users", method = RequestMethods.GET, version =
      "4.0", authAllowed = true, authOptional = false, serializer = GivingUser.serializer())

  /**
   * Update a user's information such as address, email, preferred statement type, etc.
   *
   * @param userId A user's ID in Rock
   * @return Resource updated successfully
   */
  suspend fun updateUser(userId: Int, body: GivingUserUpdate? = null): GivingUser? =
      execute("/users/${userId}", version = "4.0", method = RequestMethods.PUT, authAllowed = true,
      authOptional = false, body = body, bodySerializer = GivingUserUpdate.serializer(), serializer
      = GivingUser.serializer())

  /**
   * Return a list of 100 random interactions as a sample set of interactions that may have been
   * funded by a user's giving. This is not paged, because results are randomized, and requests are not
   * repeatable. Each user gets a *different* subset of 100 interactions. Current implementation is for
   * clients to cache this data for a set window of time before requesting a fresh set _only_ if the
   * user stayed on the screen that displays the interactions.
   *
   * @return Successfully fetched a list of interactions
   */
  suspend fun getInteractions(): Interactions? = execute("/interactions", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Interactions.serializer())

  /**
   * Returns numbers calculated from the authenticating user's total dollar number amounts without
   * specifying the dollar amount directly, as the amount may be somewhat out of sync with reality at
   * any given time.
   *
   * @return Successfully fetched impact resource for the authenticating user
   */
  suspend fun getMyImpact(): MyImpact = execute("/impacts/me", method = RequestMethods.GET, version
      = "4.0", authAllowed = true, authOptional = false, serializer = MyImpact.serializer()) ?:
      throw NullPointerException("Response not sent from server")
}
