package youversion.red.prayer.guided.model

import red.Error
import red.platform.Log
import red.platform.WeakReference
import red.platform.threads.AtomicInt
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.getValue
import red.platform.threads.set
import red.platform.threads.setValue
import red.tasks.CoroutineDispatchers.launch
import red.toError
import youversion.red.bible.reference.VersionId
import youversion.red.guidedprayer.api.model.GuidedPrayerModuleType
import youversion.red.prayer.guided.tasks.mapToGuidedPrayerModules
import youversion.red.prayer.model.Prayer
import youversion.red.versification.VersificationTransaction

class GuideModuleItems(
    modules: List<GuidedPrayerModule>,
    private val fetcher: suspend (fetchSize: Int) -> Pair<List<GuidedPrayerModule>, Int>,
    private val seeder: PrayerItemSeedingStrategy
) : PrayerItemSeedingStrategy by seeder {

    internal val _modules = AtomicReference(modules.freeze())
    internal val _listener = AtomicReference<WeakReference<GuideModuleItemsListener>?>(null)
    private val _prayersInNextBatch = AtomicInt(MAX_BATCH_SIZE)

    val modules: List<GuidedPrayerModule>
        get() = _modules.value

    init {
        freeze()
        if (modules.isNullOrEmpty())
            throw IllegalArgumentException("Must pass guided prayer modules")
    }

    val prayersInNextBatch: Int
        get() = _prayersInNextBatch.getValue()

    operator fun get(index: Int) = _modules.value[index]

    val size: Int
        get() = _modules.value.size

    fun setListener(listener: GuideModuleItemsListener) {
        _listener.set(WeakReference(listener.freeze()).freeze())
    }

    internal suspend fun fetchNextBatchSync() {
        try {
            val result = fetcher(prayersInNextBatch)
            val (items, size) = result
            insertPrayers(items)
            _prayersInNextBatch.setValue(size)
        } catch (e: Exception) {
            e.toError()?.let {
                _listener.value?.get()?.onError(it)
            }
        }
    }

    fun fetchNextBatch() {
        launch {
            fetchNextBatchSync()
        }
    }

    internal suspend fun versify(
        versificationTransaction: VersificationTransaction,
        versionId: VersionId
    ) {
        val modulesVersified = modules.map {
            it.versify(versificationTransaction, versionId)
        }
        _modules.set(modulesVersified.freeze())
    }

    companion object {
        internal const val MAX_BATCH_SIZE = 3
    }
}

interface GuideModuleItemsListener {
    fun onPrayersReady(startIndex: Int, size: Int)
    fun onError(error: Error)
}

interface PrayerItemSeedingStrategy {
    suspend fun GuideModuleItems.seed(prayerList: List<Prayer>)
}

internal fun GuideModuleItems.insertPrayers(items: List<GuidedPrayerModule>) {
    val mutableModules = _modules.value.toMutableList()
    val lastPrayer = mutableModules.findLast { it.type == GuidedPrayerModuleType.PRAYER_LIST }
    var lastPrayerIndex = mutableModules.indexOf(lastPrayer).takeUnless { it == -1 } ?: mutableModules.size - 1
    if (lastPrayer?.prayer == null)
        mutableModules.apply { removeAt(lastPrayerIndex) }
    else
        lastPrayerIndex++
    try {
        _modules.set(
            mutableModules.apply {
                addAll(lastPrayerIndex, items)
            }.freeze()
        )
        _listener.value?.get()?.onPrayersReady(lastPrayerIndex, items.size)
    } catch (e: Exception) {
        e.toError()?.let {
            _listener.value?.get()?.onError(it)
        }
    }
}

/**
 * Strategy for seeding prayer list item modules inside a list of [GuidedPrayerModule]. The [PrayerItemSeedingStrategy] determines which prayer list items
 * a user will see when they first interact with Guided Prayer in a given "session".
 *
 * [SeedWithUnseen] seeds with the next [GuideModuleItems.prayersInNextBatch] [Prayer]s that the user has not seen in the current "session".
 *
 * See [GuideModuleItems]
 * See [SessionDiskCache]
 * See [FullPrayerListDiskCache]
 */
internal class SeedWithUnseen : PrayerItemSeedingStrategy {

    init {
        freeze()
    }

    override suspend fun GuideModuleItems.seed(prayerList: List<Prayer>) {
        fetchNextBatchSync()
    }
}

/**
 * Strategy for seeding prayer list item modules inside a list of [GuidedPrayerModule] (via [GuidedPrayerModule.prayer]). The [PrayerItemSeedingStrategy] determines which prayer list items
 * a user will see when they first interact with Guided Prayer in a given "session".
 *
 * [SeedWithSeen] seeds with the last [GuideModuleItems.MAX_BATCH_SIZE] [Prayer]s that the user has already seen in the current "session".
 *
 * See [GuideModuleItems]
 * See [SessionDiskCache]
 * See [FullPrayerListDiskCache]
 */
internal class SeedWithSeen(
    val sessionCache: ViewedPrayersCache,
    val prayerListCycleCache: ViewedPrayersCache
) : PrayerItemSeedingStrategy {

    init {
        freeze()
    }

    override suspend fun GuideModuleItems.seed(prayerList: List<Prayer>) {
        try {
            val batchSize = GuideModuleItems.MAX_BATCH_SIZE
            val lastShownBatchSizePrayerIds =
                sessionCache.ids.takeLast(batchSize).takeIf { it.isNotEmpty() }
                    ?: prayerListCycleCache.ids.takeLast(batchSize)
            val prayerIds = mutableMapOf<String, String>()

            for (last in lastShownBatchSizePrayerIds) {
                if (last.isNotBlank()) prayerIds[last] = last
            }

            val prayerListGuideModule = _modules.value.find { it.type == GuidedPrayerModuleType.PRAYER_LIST }
            val prayers = if (prayerIds.isNotEmpty()) {
                val prayerListItems = MutableList<Prayer?>(prayerIds.size) {
                    null
                }
                var foundPrayers = 0
                for (prayer in prayerList) {
                    val prayerMatch = prayerIds.getOrElse(prayer.clientId) {
                        null
                    }
                    if (prayerMatch != null) {
                        prayerListItems[foundPrayers] = prayer
                        foundPrayers++
                    }
                    if (foundPrayers == prayerIds.size)
                        break
                }
                prayerListItems.filterNotNull().mapToGuidedPrayerModules(prayerListGuideModule!!)
            } else
                prayerList.take(batchSize).mapToGuidedPrayerModules(prayerListGuideModule!!)
            insertPrayers(prayers)
        } catch (e: Exception) {
            Log.e("red-guide-module-items", "error seeding prayer list items: $e")
        }
    }
}
