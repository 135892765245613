// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.content_partners

import kotlin.Int
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ExternalIds(
  /**
   * What kind of id is this?
   */
  @SerialName("type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.organizations.api.model.admin.content_partners.KindSerializer::class)
  @JvmField
  val type: Kind? = null,
  /**
   * The identifier for an external organization
   */
  @SerialName("id")
  @ProtoNumber(2)
  @JvmField
  val id: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
