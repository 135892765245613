// Auto-Generated from OpenAPI Spec
package youversion.red.discovery.api.model

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class DiscoverSections(
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val sections: List<DiscoverSection>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
