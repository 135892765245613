// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine the current state of a License.
 */
enum class LicenseStatusEnum(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  PENDING("pending", 1),

  ACCEPTED("accepted", 2),

  REJECTED("rejected", 3);
}

internal class LicenseStatusEnumSerializer : KSerializer<LicenseStatusEnum> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.organization_profiles.LicenseStatusEnum")

  override fun serialize(encoder: Encoder, value: LicenseStatusEnum) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): LicenseStatusEnum {
    if (decoder is JsonDecoder) {
      return LicenseStatusEnum.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> LicenseStatusEnum.UNSUPPORTED_PLACEHOLDER
      1 -> LicenseStatusEnum.PENDING
      2 -> LicenseStatusEnum.ACCEPTED
      3 -> LicenseStatusEnum.REJECTED
      else -> LicenseStatusEnum.UNSUPPORTED_PLACEHOLDER
    }
  }
}
