// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GivingUser(
  /**
   * Unique id for a user in Rock
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * Unique id for a user in YouVersion
   */
  @SerialName("yv_id")
  @ProtoNumber(2)
  @JvmField
  val yvId: Int? = null,
  /**
   * First name of the user in Rock
   */
  @SerialName("first_name")
  @ProtoNumber(3)
  @JvmField
  val firstName: String? = null,
  /**
   * Last name of the user in Rock
   */
  @SerialName("last_name")
  @ProtoNumber(4)
  @JvmField
  val lastName: String? = null,
  /**
   * The mailing address where a user would like to have their year-end statement mailed. US-only.
   */
  @SerialName("address")
  @ProtoNumber(5)
  @JvmField
  val address: String? = null,
  /**
   * The mailing address (line 2) where a user would like to have their year-end statement mailed.
   * US-only.
   */
  @SerialName("address2")
  @ProtoNumber(6)
  @JvmField
  val address2: String? = null,
  /**
   * The city where a user would like to have their year-end statement mailed. US-only.
   */
  @SerialName("city")
  @ProtoNumber(7)
  @JvmField
  val city: String? = null,
  /**
   * State where a user would like to have their year-end statement mailed. US-only. Accepts
   * long-form state name. Rock will likely overwrite with abbreviation.
   */
  @SerialName("state_province")
  @ProtoNumber(8)
  @JvmField
  val stateProvince: String? = null,
  /**
   * Country where a user would like to have their year-end statement mailed. US-only.
   */
  @SerialName("country")
  @ProtoNumber(9)
  @JvmField
  val country: String? = null,
  /**
   * Zip code where a user would like to have their year-end statement mailed. US-only.
   */
  @SerialName("postal")
  @ProtoNumber(10)
  @JvmField
  val postal: String? = null,
  @SerialName("statement_type")
  @ProtoNumber(11)
  @Serializable(with=youversion.red.givingmikey.api.model.GivingStatementTypeSerializer::class)
  @JvmField
  val statementType: GivingStatementType? = null,
  /**
   * Email address in Rock the financial statement should be emailed to (if statement_type is
   * “email”)
   */
  @SerialName("email")
  @ProtoNumber(12)
  @JvmField
  val email: String? = null,
  /**
   * IANA timezone of the user as defined in YouVersion
   */
  @SerialName("timezone")
  @ProtoNumber(13)
  @JvmField
  val timezone: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
