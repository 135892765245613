// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.events.event_content

import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.events.event_contents.BaseContent

@Serializable
@SerialName("events.event_content.TextContent")
data class TextContent(
  /**
   * The body of the text content
   */
  @SerialName("body")
  @ProtoNumber(60)
  val body: String = "",
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Long = 0L,
  @SerialName("comment")
  @ProtoNumber(3)
  override val comment: String? = null
) : BaseContent, red.Serializable {
  init {
    freeze()
  }
}
