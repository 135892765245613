// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api

import kotlin.ByteArray
import kotlin.OptIn
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.serializer
import red.platform.http.FormatType
import red.platform.http.Serializer
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent
import youversion.red.dataman.api.model.AbstractShareEvent
import youversion.red.dataman.api.model.AnalyticsEvent
import youversion.red.dataman.api.model.bafk.ActivityStartedEvent
import youversion.red.dataman.api.model.bafk.KidsAchievementEarnedEvent
import youversion.red.dataman.api.model.bafk.KidsActivityCompletedEvent
import youversion.red.dataman.api.model.bafk.KidsItemCompletedEvent
import youversion.red.dataman.api.model.bafk.KidsPieceCollectedEvent
import youversion.red.dataman.api.model.bafk.KidsSetCompletedEvent
import youversion.red.dataman.api.model.bafk.KidsStoryNotCompletedEvent
import youversion.red.dataman.api.model.bafk.KidsTriviaAnswerEvent
import youversion.red.dataman.api.model.bafk.LanguageSwitchEvent
import youversion.red.dataman.api.model.bafk.SceneCompletedEvent
import youversion.red.dataman.api.model.bafk.StartStoryEvent
import youversion.red.dataman.api.model.bafk.StoryCompletedEvent
import youversion.red.dataman.api.model.bafk.StoryDownloadResultEvent
import youversion.red.dataman.api.model.bafk.StoryUninstalledEvent
import youversion.red.dataman.api.model.banner.BannerStatsBatchEvent
import youversion.red.dataman.api.model.bible.ChapterRequestEvent
import youversion.red.dataman.api.model.bible.TrialEndEvent
import youversion.red.dataman.api.model.bible.TrialStartEvent
import youversion.red.dataman.api.model.bible.VersionDownloadFailureEvent
import youversion.red.dataman.api.model.bible.VersionDownloadStartEvent
import youversion.red.dataman.api.model.bible.VersionDownloadSuccessEvent
import youversion.red.dataman.api.model.bible.VersionSetEvent
import youversion.red.dataman.api.model.discover.DiscoverItemTap
import youversion.red.dataman.api.model.discover.VideoAction
import youversion.red.dataman.api.model.giving.GivingCauseEvent
import youversion.red.dataman.api.model.giving.GivingClickEvent
import youversion.red.dataman.api.model.giving.GivingLandingEvent
import youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioCompleteEvent
import youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioPlayEvent
import youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioStopEvent
import youversion.red.dataman.api.model.guidedprayer.GuideModuleAction
import youversion.red.dataman.api.model.guidedprayer.GuideModuleView
import youversion.red.dataman.api.model.installations.InstallationsV2
import youversion.red.dataman.api.model.media.AbstractMediaCompleteEvent
import youversion.red.dataman.api.model.media.AbstractMediaPlayEvent
import youversion.red.dataman.api.model.media.AbstractMediaStopEvent
import youversion.red.dataman.api.model.metrics.RequestManagerCumulativeResponseMetricEvent
import youversion.red.dataman.api.model.metrics.RequestManagerExecutionExceptionMetricEvent
import youversion.red.dataman.api.model.metrics.RequestManagerResponseCodeMetricEvent
import youversion.red.dataman.api.model.metrics.RequestManagerUrlMetricEvent
import youversion.red.dataman.api.model.moments.MomentEvent
import youversion.red.dataman.api.model.onboarding.OnboardingChoiceTap
import youversion.red.dataman.api.model.organizations.OrganizationProfileShareEvent
import youversion.red.dataman.api.model.plans.PlanDayComplete
import youversion.red.dataman.api.model.podcasts.EpisodeAudioCompleteEvent
import youversion.red.dataman.api.model.podcasts.EpisodeAudioPlayEvent
import youversion.red.dataman.api.model.podcasts.EpisodeAudioStopEvent
import youversion.red.dataman.api.model.podcasts.PodcastEpisodeInteraction
import youversion.red.dataman.api.model.reader.ReaderThemeSetEvent
import youversion.red.dataman.api.model.users.UserCreated
import youversion.red.dataman.api.model.users.UserRecencyEvent
import youversion.red.dataman.api.model.v2.AbstractEvent
import youversion.red.dataman.api.model.v2.BackgroundEvent
import youversion.red.dataman.api.model.v2.CompletionEvent
import youversion.red.dataman.api.model.v2.ExposureEvent
import youversion.red.dataman.api.model.v2.ForegroundEvent
import youversion.red.dataman.api.model.v2.ImpressionEvent
import youversion.red.dataman.api.model.v2.InteractionEvent
import youversion.red.dataman.api.model.v2.UserPropertiesEvent

object DataManApiSerializer : Serializer {
  private val context: SerializersModule = SerializersModule {

      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.discover.VideoAction::class,
          youversion.red.dataman.api.model.discover.VideoAction.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.users.UserRecencyEvent::class,
          youversion.red.dataman.api.model.users.UserRecencyEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.users.UserCreated::class,
          youversion.red.dataman.api.model.users.UserCreated.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.ActivityStartedEvent::class,
          youversion.red.dataman.api.model.bafk.ActivityStartedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.LanguageSwitchEvent::class,
          youversion.red.dataman.api.model.bafk.LanguageSwitchEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.SceneCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.SceneCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.StartStoryEvent::class,
          youversion.red.dataman.api.model.bafk.StartStoryEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.StoryCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.StoryCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.StoryDownloadResultEvent::class,
          youversion.red.dataman.api.model.bafk.StoryDownloadResultEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.StoryUninstalledEvent::class,
          youversion.red.dataman.api.model.bafk.StoryUninstalledEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.banner.BannerStatsBatchEvent::class,
          youversion.red.dataman.api.model.banner.BannerStatsBatchEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsAchievementEarnedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsAchievementEarnedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsActivityCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsActivityCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsItemCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsItemCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsPieceCollectedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsPieceCollectedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsSetCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsSetCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsStoryNotCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsStoryNotCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsTriviaAnswerEvent::class,
          youversion.red.dataman.api.model.bafk.KidsTriviaAnswerEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.discover.DiscoverItemTap::class,
          youversion.red.dataman.api.model.discover.DiscoverItemTap.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.onboarding.OnboardingChoiceTap::class,
          youversion.red.dataman.api.model.onboarding.OnboardingChoiceTap.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GuideModuleView::class,
          youversion.red.dataman.api.model.guidedprayer.GuideModuleView.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GuideModuleAction::class,
          youversion.red.dataman.api.model.guidedprayer.GuideModuleAction.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioPlayEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioPlayEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioStopEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioStopEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioCompleteEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioCompleteEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.ChapterRequestEvent::class,
          youversion.red.dataman.api.model.bible.ChapterRequestEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.VersionSetEvent::class,
          youversion.red.dataman.api.model.bible.VersionSetEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadStartEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadStartEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadFailureEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadFailureEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadSuccessEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadSuccessEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.moments.MomentEvent::class,
          youversion.red.dataman.api.model.moments.MomentEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.giving.GivingClickEvent::class,
          youversion.red.dataman.api.model.giving.GivingClickEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.giving.GivingLandingEvent::class,
          youversion.red.dataman.api.model.giving.GivingLandingEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.giving.GivingCauseEvent::class,
          youversion.red.dataman.api.model.giving.GivingCauseEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.reader.ReaderThemeSetEvent::class,
          youversion.red.dataman.api.model.reader.ReaderThemeSetEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.podcasts.PodcastEpisodeInteraction::class,
          youversion.red.dataman.api.model.podcasts.PodcastEpisodeInteraction.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioPlayEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioPlayEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioStopEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioStopEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioCompleteEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioCompleteEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.plans.PlanDayComplete::class,
          youversion.red.dataman.api.model.plans.PlanDayComplete.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.installations.InstallationsV2::class,
          youversion.red.dataman.api.model.installations.InstallationsV2.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.TrialStartEvent::class,
          youversion.red.dataman.api.model.bible.TrialStartEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.TrialEndEvent::class,
          youversion.red.dataman.api.model.bible.TrialEndEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.organizations.OrganizationProfileShareEvent::class,
          youversion.red.dataman.api.model.organizations.OrganizationProfileShareEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerUrlMetricEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerUrlMetricEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerResponseCodeMetricEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerResponseCodeMetricEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerExecutionExceptionMetricEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerExecutionExceptionMetricEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerCumulativeResponseMetricEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerCumulativeResponseMetricEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.CompletionEvent::class,
          youversion.red.dataman.api.model.v2.CompletionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.ImpressionEvent::class,
          youversion.red.dataman.api.model.v2.ImpressionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.InteractionEvent::class,
          youversion.red.dataman.api.model.v2.InteractionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.BackgroundEvent::class,
          youversion.red.dataman.api.model.v2.BackgroundEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.ForegroundEvent::class,
          youversion.red.dataman.api.model.v2.ForegroundEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.UserPropertiesEvent::class,
          youversion.red.dataman.api.model.v2.UserPropertiesEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.ExposureEvent::class,
          youversion.red.dataman.api.model.v2.ExposureEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.users.UserRecencyEvent::class,
          youversion.red.dataman.api.model.users.UserRecencyEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.users.UserCreated::class,
          youversion.red.dataman.api.model.users.UserCreated.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.ActivityStartedEvent::class,
          youversion.red.dataman.api.model.bafk.ActivityStartedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.LanguageSwitchEvent::class,
          youversion.red.dataman.api.model.bafk.LanguageSwitchEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.SceneCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.SceneCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.StartStoryEvent::class,
          youversion.red.dataman.api.model.bafk.StartStoryEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.StoryCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.StoryCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.StoryDownloadResultEvent::class,
          youversion.red.dataman.api.model.bafk.StoryDownloadResultEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.StoryUninstalledEvent::class,
          youversion.red.dataman.api.model.bafk.StoryUninstalledEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.banner.BannerStatsBatchEvent::class,
          youversion.red.dataman.api.model.banner.BannerStatsBatchEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsAchievementEarnedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsAchievementEarnedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsActivityCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsActivityCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsItemCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsItemCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsPieceCollectedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsPieceCollectedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsSetCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsSetCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsStoryNotCompletedEvent::class,
          youversion.red.dataman.api.model.bafk.KidsStoryNotCompletedEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bafk.KidsTriviaAnswerEvent::class,
          youversion.red.dataman.api.model.bafk.KidsTriviaAnswerEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.discover.DiscoverItemTap::class,
          youversion.red.dataman.api.model.discover.DiscoverItemTap.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.onboarding.OnboardingChoiceTap::class,
          youversion.red.dataman.api.model.onboarding.OnboardingChoiceTap.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GuideModuleView::class,
          youversion.red.dataman.api.model.guidedprayer.GuideModuleView.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GuideModuleAction::class,
          youversion.red.dataman.api.model.guidedprayer.GuideModuleAction.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioPlayEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioPlayEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioStopEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioStopEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioCompleteEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioCompleteEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.ChapterRequestEvent::class,
          youversion.red.dataman.api.model.bible.ChapterRequestEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.VersionSetEvent::class,
          youversion.red.dataman.api.model.bible.VersionSetEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadStartEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadStartEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadFailureEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadFailureEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadSuccessEvent::class,
          youversion.red.dataman.api.model.bible.VersionDownloadSuccessEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.moments.MomentEvent::class,
          youversion.red.dataman.api.model.moments.MomentEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.giving.GivingClickEvent::class,
          youversion.red.dataman.api.model.giving.GivingClickEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.giving.GivingLandingEvent::class,
          youversion.red.dataman.api.model.giving.GivingLandingEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.giving.GivingCauseEvent::class,
          youversion.red.dataman.api.model.giving.GivingCauseEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.reader.ReaderThemeSetEvent::class,
          youversion.red.dataman.api.model.reader.ReaderThemeSetEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.podcasts.PodcastEpisodeInteraction::class,
          youversion.red.dataman.api.model.podcasts.PodcastEpisodeInteraction.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioPlayEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioPlayEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioStopEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioStopEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioCompleteEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioCompleteEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.plans.PlanDayComplete::class,
          youversion.red.dataman.api.model.plans.PlanDayComplete.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.installations.InstallationsV2::class,
          youversion.red.dataman.api.model.installations.InstallationsV2.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.TrialStartEvent::class,
          youversion.red.dataman.api.model.bible.TrialStartEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.bible.TrialEndEvent::class,
          youversion.red.dataman.api.model.bible.TrialEndEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.organizations.OrganizationProfileShareEvent::class,
          youversion.red.dataman.api.model.organizations.OrganizationProfileShareEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerUrlMetricEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerUrlMetricEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerResponseCodeMetricEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerResponseCodeMetricEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerExecutionExceptionMetricEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerExecutionExceptionMetricEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerCumulativeResponseMetricEvent::class,
          youversion.red.dataman.api.model.metrics.RequestManagerCumulativeResponseMetricEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.CompletionEvent::class,
          youversion.red.dataman.api.model.v2.CompletionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.ImpressionEvent::class,
          youversion.red.dataman.api.model.v2.ImpressionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.InteractionEvent::class,
          youversion.red.dataman.api.model.v2.InteractionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.BackgroundEvent::class,
          youversion.red.dataman.api.model.v2.BackgroundEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.ForegroundEvent::class,
          youversion.red.dataman.api.model.v2.ForegroundEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.UserPropertiesEvent::class,
          youversion.red.dataman.api.model.v2.UserPropertiesEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractAnalyticsEvent::class,
          youversion.red.dataman.api.model.v2.ExposureEvent::class,
          youversion.red.dataman.api.model.v2.ExposureEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.AbstractShareEvent::class,
          youversion.red.dataman.api.model.organizations.OrganizationProfileShareEvent::class,
          youversion.red.dataman.api.model.organizations.OrganizationProfileShareEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.media.AbstractMediaPlayEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioPlayEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioPlayEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.media.AbstractMediaPlayEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioPlayEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioPlayEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.media.AbstractMediaStopEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioStopEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioStopEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.media.AbstractMediaStopEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioStopEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioStopEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.media.AbstractMediaCompleteEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioCompleteEvent::class,
          youversion.red.dataman.api.model.guidedprayer.GPBackgroundAudioCompleteEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.media.AbstractMediaCompleteEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioCompleteEvent::class,
          youversion.red.dataman.api.model.podcasts.EpisodeAudioCompleteEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.v2.AbstractEvent::class,
          youversion.red.dataman.api.model.v2.CompletionEvent::class,
          youversion.red.dataman.api.model.v2.CompletionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.v2.AbstractEvent::class,
          youversion.red.dataman.api.model.v2.ImpressionEvent::class,
          youversion.red.dataman.api.model.v2.ImpressionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.v2.AbstractEvent::class,
          youversion.red.dataman.api.model.v2.InteractionEvent::class,
          youversion.red.dataman.api.model.v2.InteractionEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.v2.AbstractEvent::class,
          youversion.red.dataman.api.model.v2.BackgroundEvent::class,
          youversion.red.dataman.api.model.v2.BackgroundEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.v2.AbstractEvent::class,
          youversion.red.dataman.api.model.v2.ForegroundEvent::class,
          youversion.red.dataman.api.model.v2.ForegroundEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.v2.AbstractEvent::class,
          youversion.red.dataman.api.model.v2.UserPropertiesEvent::class,
          youversion.red.dataman.api.model.v2.UserPropertiesEvent.serializer())
      polymorphic(youversion.red.dataman.api.model.v2.AbstractEvent::class,
          youversion.red.dataman.api.model.v2.ExposureEvent::class,
          youversion.red.dataman.api.model.v2.ExposureEvent.serializer())
      }

  private val json: Json = Json { isLenient = true; ignoreUnknownKeys = true;
      allowSpecialFloatingPointValues = true; classDiscriminator = "_type";  serializersModule =
      context }

  @OptIn(kotlinx.serialization.ExperimentalSerializationApi::class)
  private val protobuf: ProtoBuf = ProtoBuf { encodeDefaults = true; serializersModule = context }

  init {
    freeze()}

  override fun <T> serialize(
    type: FormatType,
    serializer: SerializationStrategy<T>,
    obj: T
  ): ByteArray = when (type) {
     FormatType.JSON -> json.encodeToString(serializer, obj).encodeToByteArray()
     FormatType.PROTOBUF -> protobuf.encodeToByteArray(serializer, obj)
  }
  override fun <T> deserialize(
    type: FormatType,
    deserializer: DeserializationStrategy<T>,
    data: ByteArray
  ): T? = when (type) {
     FormatType.JSON -> json.decodeFromString(deserializer, data.decodeToString())
     FormatType.PROTOBUF -> protobuf.decodeFromByteArray(deserializer, data)
  }}
