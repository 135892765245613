// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Float
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Address(
  /**
   * The angular distance of a location north or south of the earths equator.
   */
  @SerialName("latitude")
  @ProtoNumber(22)
  @JvmField
  val latitude: Float? = null,
  /**
   * The angular distance of a location east or west of the meridian.
   */
  @SerialName("longitude")
  @ProtoNumber(23)
  @JvmField
  val longitude: Float? = null,
  /**
   * The human-readable address of the organization.
   */
  @SerialName("formatted_address")
  @ProtoNumber(24)
  @JvmField
  val formattedAddress: String? = null,
  /**
   * The textual identifier that uniquely identifies a place using google places api id.
   */
  @SerialName("place_id")
  @ProtoNumber(25)
  @JvmField
  val placeId: String? = null,
  @SerialName("country")
  @ProtoNumber(26)
  @JvmField
  val country: Country? = null,
  @SerialName("administrative_area_level_1")
  @ProtoNumber(28)
  @JvmField
  val administrativeAreaLevel1: AdministrativeAreaLevel1? = null,
  @SerialName("locality")
  @ProtoNumber(30)
  @JvmField
  val locality: Locality? = null
) : red.Serializable {
  init {
    freeze()
  }
}
