// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.interactions

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Interaction(
  @SerialName("country_code")
  @ProtoNumber(1)
  @JvmField
  val countryCode: String? = null,
  @SerialName("type")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.givingmikey.api.model.interactions.InteractionTypeSerializer::class)
  @JvmField
  val type: InteractionType? = null,
  /**
   * Localized string to describe a given interaction taken due to the impact of the user's giving.
   * Double asterisks mark bolded portions of the string.
   */
  @SerialName("description")
  @ProtoNumber(3)
  @JvmField
  val description: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
