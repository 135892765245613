package youversion.red.discover.service.js

import kotlin.Boolean
import kotlin.Double
import kotlin.Int
import kotlin.Pair
import kotlin.String
import kotlin.collections.List
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.discover.api.model.DiscoverChurch
import youversion.red.discover.api.model.DiscoverEmotion
import youversion.red.discover.api.model.DiscoverPlan
import youversion.red.discover.api.model.RecommendedPlans
import youversion.red.discover.service.IDiscoverService
import youversion.red.discovery.api.model.DiscoverSection
import youversion.red.movies.Movie
import youversion.red.movies.MoviePublisher

public object DiscoverService {
  private val service: IDiscoverService by
      service<youversion.red.discover.service.IDiscoverService>()

  @JsName(name = "getOrder")
  public fun getOrder(): Promise<List<DiscoverSection>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getOrder()

  }.toPromise()

  @JsName(name = "getTeachingClips")
  public fun getTeachingClips(languageTag: String?, pageSize: Int?):
      Promise<Pair<List<Pair<Movie, MoviePublisher?>>, Boolean>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getTeachingClips(languageTag, pageSize)

  }.toPromise()

  @JsName(name = "getFeaturedPlans")
  public fun getFeaturedPlans(languageTag: String, page: Int):
      Promise<Pair<List<DiscoverPlan>?, Int?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getFeaturedPlans(languageTag, page)

  }.toPromise()

  @JsName(name = "getNewToFaithPlans")
  public fun getNewToFaithPlans(languageTag: String, page: Int):
      Promise<Pair<List<DiscoverPlan>?, Int?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getNewToFaithPlans(languageTag, page)

  }.toPromise()

  @JsName(name = "getFeaturedVideos")
  public fun getFeaturedVideos(page: Int?, pageSize: Int?):
      Promise<Pair<List<Pair<Movie, MoviePublisher?>>, Boolean>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getFeaturedVideos(page, pageSize)

  }.toPromise()

  @JsName(name = "getRecommendedPlans")
  public fun getRecommendedPlans(
    languageTag: String,
    planId: Int,
    page: Int?,
    pageSize: Int?
  ): Promise<Pair<RecommendedPlans?, Boolean?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getRecommendedPlans(languageTag, planId, page, pageSize)

  }.toPromise()

  @JsName(name = "getTrendingPlans")
  public fun getTrendingPlans(
    languageTag: String,
    page: Int?,
    pageSize: Int?,
    treatmentId: Int?
  ): Promise<Pair<List<DiscoverPlan>?, Boolean?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getTrendingPlans(languageTag, page, pageSize, treatmentId)

  }.toPromise()

  @JsName(name = "getFriendsPlans")
  public fun getFriendsPlans(
    languageTag: String,
    page: Int?,
    pageSize: Int?
  ): Promise<Pair<List<DiscoverPlan>?, Boolean?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getFriendsPlans(languageTag, page, pageSize)

  }.toPromise()

  @JsName(name = "getEmotions")
  public fun getEmotions(languageTag: String): Promise<List<DiscoverEmotion>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getEmotions(languageTag)

  }.toPromise()

  @JsName(name = "getPlanThumbnail")
  public fun getPlanThumbnail(id: Int, minWidth: Int): Promise<String?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getPlanThumbnail(id, minWidth)

  }.toPromise()

  @JsName(name = "getChurchesMyFriendsAttend")
  public fun getChurchesMyFriendsAttend(page: Int?, pageSize: Int?):
      Promise<Pair<List<DiscoverChurch>?, Boolean?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getChurchesMyFriendsAttend(page, pageSize)

  }.toPromise()

  @JsName(name = "getChurchesNearMe")
  public fun getChurchesNearMe(
    latitude: Double,
    longitude: Double,
    page: Int?,
    pageSize: Int?
  ): Promise<Pair<List<DiscoverChurch>?, Boolean?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getChurchesNearMe(latitude, longitude, page, pageSize)

  }.toPromise()
}
