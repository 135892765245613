// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.events.Time

@Serializable
data class AdminEventUpdate(
  @SerialName("description")
  @ProtoNumber(1)
  @JvmField
  val description: String? = null,
  /**
   * This is the title of the event.
   */
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  /**
   * The image id of the image that was set.
   */
  @SerialName("image_id")
  @ProtoNumber(3)
  @JvmField
  val imageId: Long? = null,
  @SerialName("times")
  @ProtoNumber(4)
  @JvmField
  val times: List<Time>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
