// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class ContentGrant(
  /**
   * The id of the content grant.
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * The external id that relates to the content.
   */
  @SerialName("external_id")
  @ProtoNumber(2)
  @JvmField
  val externalId: Int? = null,
  @SerialName("content_type")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.organizations.api.model.organizations.ContentTypeSerializer::class)
  @JvmField
  val contentType: ContentType? = null,
  /**
   * The created method.
   */
  @SerialName("created_method")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.organizations.api.model.organizations.CreatedMethodTypeSerializer::class)
  @JvmField
  val createdMethod: CreatedMethodType? = null,
  /**
   * The time that this content grant was created.
   */
  @SerialName("created_dt")
  @ProtoNumber(5)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The time that this content grant was created
   */
  @SerialName("updated_dt")
  @ProtoNumber(6)
  @JvmField
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
