// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Churches(
  /**
   * A list of Church resource objects
   */
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val churches: List<Church>? = null,
  @SerialName("page_size")
  @ProtoNumber(2)
  @JvmField
  val pageSize: Int? = null,
  @SerialName("next_page")
  @ProtoNumber(3)
  @JvmField
  val nextPage: Boolean? = null,
  @SerialName("did_you_mean")
  @ProtoNumber(4)
  @JvmField
  val didYouMean: List<String>? = null,
  /**
   * If a correction was applied, search for the original query instead.
   */
  @SerialName("search_instead_for")
  @ProtoNumber(5)
  @JvmField
  val searchInsteadFor: String? = null,
  /**
   * This is the actual query the server used to get the results it returned. Normally, it would be
   * the same as the query the user input, but if it is a spelling mistake, the API might auto-correct
   * it and run a different query than what the user input.
   */
  @SerialName("query")
  @ProtoNumber(6)
  @JvmField
  val query: String? = null,
  /**
   * This is the user_intent the server used to get the results it returned. Normally, it would be
   * the same as the client supplied to get results. *But* if the client did not supply it the API
   * tries to intelligently chose an intent.
   */
  @SerialName("user_intent")
  @ProtoNumber(7)
  @Serializable(with=youversion.red.search.api.model.UserIntentSerializer::class)
  @JvmField
  val userIntent: UserIntent? = null
) : red.Serializable {
  init {
    freeze()
  }
}
