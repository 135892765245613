// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * What the user did to prompt account creation
 */
enum class CreateAccountReferrer(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  FIRST_RUN("first_run", 1),

  SUBSCRIBE_NOTIFICATIONS("subscribe_notifications", 2),

  VOTD_SUBSCRIBE("votd_subscribe", 3),

  PROFILE_SCREEN("profile_screen", 4),

  SETTINGS_SCREEN("settings_screen", 5),

  MORE_SCREEN("more_screen", 6),

  PLANS_SCREEN("plans_screen", 7),

  SINGLE_MOMENT_TYPE_SCREEN("single_moment_type_screen", 8),

  READER_NOTE("reader_note", 9),

  READER_BOOKMARK("reader_bookmark", 10),

  READER_HIGHLIGHT("reader_highlight", 11),

  READER_IMAGE("reader_image", 12),

  READER_PRAY("reader_pray", 13),

  OFFLINE_DOWNLOAD("offline_download", 14),

  CREATE_IMAGE("create_image", 15),

  SAVE_IMAGE("save_image", 16),

  SAVE_EVENT("save_event", 17),

  SAVE_PLAN("save_plan", 18),

  START_PLAN("start_plan", 19),

  DEEP_LINK("deep_link", 20),

  YEAR_REVIEW("year_review", 21),

  STORY_PRAY("story_pray", 22),

  STORY_NOTE("story_note", 23),

  STORY_SAVE_PLAN("story_save_plan", 24),

  STORY_SAVE_IMAGE("story_save_image", 25),

  ADD_PRAYER("add_prayer", 26),

  PRAYER_LIST("prayer_list", 27),

  GIVING("giving", 28),

  ADD_FRIEND("add_friend", 29),

  FAILED_AUTH("failed_auth", 30),

  WIDGET("widget", 31),

  ONBOARDING("onboarding", 32),

  GUEST_PLAN_PROMPT("guest_plan_prompt", 33),

  JOIN_ORGANIZATION("join_organization", 34),

  WELCOME_GUIDE("welcome_guide", 35),

  LIKE_ORGANIZATION_POST("like_organization_post", 36),

  VOTD_LIKE("votd_like", 37);
}

internal class CreateAccountReferrerSerializer : KSerializer<CreateAccountReferrer> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.users.api.model.CreateAccountReferrer")

  override fun serialize(encoder: Encoder, value: CreateAccountReferrer) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): CreateAccountReferrer {
    if (decoder is JsonDecoder) {
      return CreateAccountReferrer.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> CreateAccountReferrer.UNKNOWN
      1 -> CreateAccountReferrer.FIRST_RUN
      2 -> CreateAccountReferrer.SUBSCRIBE_NOTIFICATIONS
      3 -> CreateAccountReferrer.VOTD_SUBSCRIBE
      4 -> CreateAccountReferrer.PROFILE_SCREEN
      5 -> CreateAccountReferrer.SETTINGS_SCREEN
      6 -> CreateAccountReferrer.MORE_SCREEN
      7 -> CreateAccountReferrer.PLANS_SCREEN
      8 -> CreateAccountReferrer.SINGLE_MOMENT_TYPE_SCREEN
      9 -> CreateAccountReferrer.READER_NOTE
      10 -> CreateAccountReferrer.READER_BOOKMARK
      11 -> CreateAccountReferrer.READER_HIGHLIGHT
      12 -> CreateAccountReferrer.READER_IMAGE
      13 -> CreateAccountReferrer.READER_PRAY
      14 -> CreateAccountReferrer.OFFLINE_DOWNLOAD
      15 -> CreateAccountReferrer.CREATE_IMAGE
      16 -> CreateAccountReferrer.SAVE_IMAGE
      17 -> CreateAccountReferrer.SAVE_EVENT
      18 -> CreateAccountReferrer.SAVE_PLAN
      19 -> CreateAccountReferrer.START_PLAN
      20 -> CreateAccountReferrer.DEEP_LINK
      21 -> CreateAccountReferrer.YEAR_REVIEW
      22 -> CreateAccountReferrer.STORY_PRAY
      23 -> CreateAccountReferrer.STORY_NOTE
      24 -> CreateAccountReferrer.STORY_SAVE_PLAN
      25 -> CreateAccountReferrer.STORY_SAVE_IMAGE
      26 -> CreateAccountReferrer.ADD_PRAYER
      27 -> CreateAccountReferrer.PRAYER_LIST
      28 -> CreateAccountReferrer.GIVING
      29 -> CreateAccountReferrer.ADD_FRIEND
      30 -> CreateAccountReferrer.FAILED_AUTH
      31 -> CreateAccountReferrer.WIDGET
      32 -> CreateAccountReferrer.ONBOARDING
      33 -> CreateAccountReferrer.GUEST_PLAN_PROMPT
      34 -> CreateAccountReferrer.JOIN_ORGANIZATION
      35 -> CreateAccountReferrer.WELCOME_GUIDE
      36 -> CreateAccountReferrer.LIKE_ORGANIZATION_POST
      37 -> CreateAccountReferrer.VOTD_LIKE
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
