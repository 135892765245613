// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.videos

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class VideoSuggestions(
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val suggestions: List<VideoSuggestion>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
