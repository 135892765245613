// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.saves

import kotlin.Long
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class SaveCreate(
  /**
   * This property represents an event_id
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Long? = null,
  @SerialName("comments")
  @ProtoNumber(2)
  @JvmField
  val comments: List<SaveCreateComment>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
