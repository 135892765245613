// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Partner(
  /**
   * Partner's organization id
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
