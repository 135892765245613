package youversion.red.churches.service.repository

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import red.platform.platformType
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.set
import red.service.ServiceRegistry
import red.tasks.CoroutineDispatchers.withIO
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.churches.ext.toConcreteBaseProfile
import youversion.red.churches.ext.toLocation
import youversion.red.churches.ext.toOrganization
import youversion.red.churches.ext.toServiceTime
import youversion.red.churches.model.BaseOrganizationProfile
import youversion.red.churches.model.ChurchServiceTime
import youversion.red.churches.model.Location
import youversion.red.churches.model.Organization
import youversion.red.organizations.api.OrganizationsApi
import youversion.red.organizations.api.OrganizationsApiSerializer
import youversion.red.organizations.api.model.organizations.OrganizationJoin
import youversion.red.organizations.api.model.organizations.OrganizationMember
import youversion.red.organizations.api.model.organizations.OrganizationMembers
import youversion.red.organizations.api.model.organizations.OrganizationPost
import youversion.red.organizations.api.model.organizations.OrganizationPosts
import youversion.red.organizations.api.model.organizations.PostAction
import youversion.red.organizations.api.model.organizations.PostActionKind
import youversion.red.organizations.api.model.organizations.PostActionRequest
import youversion.red.organizations.api.model.organizations.VisibilityPreference
import youversion.red.plans.model.Plan
import youversion.red.plans.service.IPlansService

@Suppress("UNUSED_PARAMETER")
internal object OrganizationsRepository :
    AbstractApi(
        ApiDefaults(
            "organizations",
            if (platformType == PlatformType.JavaScript) {
                ContentTypes.JSON
            } else {
                ContentTypes.PROTO
            },
            if (platformType == PlatformType.JavaScript) {
                ContentTypes.JSON
            } else {
                ContentTypes.PROTO
            },
            "4.0",
            OrganizationsApiSerializer
        )
    ) {

    suspend fun saveOrganization(id: String, visibilityPreference: VisibilityPreference?) = withIO {
        val preference = OrganizationJoin(visibilityPreference)
        OrganizationsApi.joinOrganization(id, preference)
    }

    suspend fun leaveOrganization(id: String) = withIO {
        OrganizationsApi.leaveOrganization(id)
    }

    fun getFeaturedPlan(organizationId: String): Flow<Plan?> = flow {
        val plansService = ServiceRegistry[IPlansService::class]
        val featuredPlans = OrganizationsApi.getOrganizationFeaturedPlans(organizationId)?.data?.map {
            plansService.getPlan(it.planId)
        }
        emit(featuredPlans?.first())
    }

    suspend fun getOrganizations(userId: Int): List<Organization> = withIO {
        try {
            OrganizationsApi.getOrganizations(userId.toLong()).data.map { it.toOrganization() }
        } catch (e: NullPointerException) {
            emptyList()
        }
    }

    fun getOrganizationProfiles(id: String): Flow<List<BaseOrganizationProfile>> = flow {
        val profiles = OrganizationsApi.getOrganizationProfiles(id)?.data?.mapNotNull {
            try {
                it.toConcreteBaseProfile()
            } catch (e: Exception) {
                null
            }
        }
        emit(profiles ?: emptyList())
    }

    fun getOrganization(id: String): Flow<Organization?> = flow {
        val organization = OrganizationsApi.getOrganization(id)?.toOrganization()
        emit(organization)
    }

    suspend fun suspendGetOrganization(id: String) = withIO {
        val contentLanguage = AtomicReference<String?>(null).freeze()
        execute(
            "/organizations/$id",
            method = RequestMethods.GET,
            version = "4.0",
            authAllowed = false,
            authOptional = false,
            serializer = youversion.red.organizations.api.model.organizations.Organization.serializer(),
            interceptor = {
                contentLanguage.set(it.findHeader("Content-Language").firstOrNull())
                it
            }
        )?.toOrganization(contentLanguage = contentLanguage.value)
    }

    fun getLocations(organizationId: String, latitude: Double?, longitude: Double?): Flow<List<Location>> = flow {
        val locations = OrganizationsApi.getOrganizationsOrganizationLocations(
            organizationId,
            clientLatitude = latitude,
            clientLongitude = longitude
        )?.data?.map { it.toLocation() }
        emit(locations ?: emptyList())
    }

    fun getChurchServiceTimes(organizationId: String): Flow<List<ChurchServiceTime>> = flow {
        val serviceTimes = OrganizationsApi.getServiceTimes(organizationId)?.data?.map { it.toServiceTime() }
        emit(serviceTimes ?: emptyList())
    }

    suspend fun getOrganizationMembers(organizationId: String, userId: Int, page: Int?, pageSize: Int?): OrganizationMembers? = withIO {
        OrganizationsApi.getOrganizationMembers(organizationId, userId.toLong(), page, pageSize)
    }

    fun getMembersCount(organizationId: String): Flow<Int> = flow {
        val churchMembers = OrganizationsApi.getOrganizationMembers(organizationId)?.totalMembers ?: 0
        emit(churchMembers)
    }

    suspend fun getOrganizationMember(organizationId: String, userId: Int): OrganizationMember? = withIO {
        OrganizationsApi.getOrganizationMember(organizationId, userId.toLong())
    }

    fun getOrganizationPost(organizationId: String, postId: String, bodyAccept: String?): Flow<OrganizationPost?> = flow {
        emit(OrganizationsApi.getOrganizationPost(organizationId, postId, bodyAccept))
    }

    fun getOrganizationPosts(organizationId: String, page: Int?, pageSize: Int?, bodyAccept: String?): Flow<OrganizationPosts> = flow {
        emit(OrganizationsApi.getOrganizationPosts(organizationId, page, pageSize, bodyAccept))
    }

    fun getOrganizationPostActions(organizationId: String, postId: String): Flow<List<PostAction>> = flow {
        emit(OrganizationsApi.getOrganizationPostActions(organizationId, postId).data)
    }

    suspend fun createOrganizationPostAction(organizationId: String, postId: String, postActionKind: PostActionKind): PostAction = withIO {
        OrganizationsApi.createOrganizationPostAction(organizationId, postId, PostActionRequest(postActionKind))
    }

    suspend fun deleteOrganizationPostAction(organizationId: String, postId: String, postActionKind: PostActionKind) = withIO {
        OrganizationsApi.deleteOrganizationPostAction(organizationId, postId, postActionKind.name)
    }
}
