package youversion.red.churches.model

import kotlinx.serialization.Serializable
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.AdministrativeAreaLevel1
import youversion.red.organizations.api.model.profiles.Country
import youversion.red.organizations.api.model.profiles.Locality
import youversion.red.organizations.api.model.profiles.ProfileStatus

interface BaseOrganizationProfile {
    val id: String
    val organizationId: String
}

data class ChurchProfile(
    override val id: String,
    override val organizationId: String,
    val pastor: Pastor,
    val locationCount: Int
) : BaseOrganizationProfile {
    init {
        freeze()
    }
}

data class AddressProfile(
    override val id: String,
    override val organizationId: String,
    val formattedAddress: String,
    val formattedLocality: String,
    val placeId: String,
    val longitude: Float,
    val latitude: Float,
    val country: Country,
    val administrativeAreaLevel1: AdministrativeAreaLevel1,
    val locality: Locality?
) : BaseOrganizationProfile {
    init {
        freeze()
    }
}

data class GivingProfile(
    override val id: String,
    override val organizationId: String
) : BaseOrganizationProfile {
    init {
        freeze()
    }
}

data class ContentProfile(
    override val id: String,
    override val organizationId: String,
    val portalPlanOrgId: Int?,
    val videoPublisherId: Int?,
    val status: ProfileStatus?,
    val createdDt: @Serializable(with = red.platform.DateSerializer::class) Date?,
    val updatedDt: @Serializable(with = red.platform.DateSerializer::class) Date?
) : BaseOrganizationProfile {
    init {
        freeze()
    }
}

data class Pastor(
    val id: String,
    val name: String,
    val churchProfileId: String,
    val title: String,
    val imageUrl: String?
) {
    init {
        freeze()
    }
}
