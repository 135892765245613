// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.giving

import kotlin.Int
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("giving.GivingLandingEvent")
data class GivingLandingEvent(
  /**
   * Referral source of the Giving Landing screen
   */
  @SerialName("referrer")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.dataman.api.model.giving.GivingLandingReferralSourceSerializer::class)
  val referrer: GivingLandingReferralSource? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(referrer: GivingLandingReferralSource? = null, createdmicros: Int? = null) :
      this(referrer, red.platform.now(), createdmicros)
}
