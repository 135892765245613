// Auto-Generated from OpenAPI Spec
package youversion.red.users.api

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.users.api.model.FriendsLimit
import youversion.red.users.api.model.UserV4
import youversion.red.users.api.model.accounts.Account
import youversion.red.users.api.model.accounts.Accounts
import youversion.red.users.api.model.accounts.Invite
import youversion.red.users.api.model.accounts.InviteToken
import youversion.red.users.api.model.users.AdminSearch
import youversion.red.users.api.model.users.AdminUser
import youversion.red.users.api.model.users.SearchUsers
import youversion.red.users.api.model.verifications.Verification
import youversion.red.users.api.model.verifications.VerificationPost

/**
 * The users service contains all the CRUD API calls a client would need to work with user features.
 */
object UsersApi : AbstractApi(ApiDefaults("users", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", UsersApiSerializer)) {
  /**
   * This is an authenticated update to an already existing user's friend limit.
   *
   * @param userId The unique identifier for a user.
   * @return Friends Limit updated successfully
   */
  suspend fun editFriendsLimit(userId: Long, body: FriendsLimit? = null): FriendsLimit? =
      execute("/${userId}/friends_limit", version = "4.0", method = RequestMethods.PUT, authAllowed
      = true, authOptional = false, body = body, bodySerializer = FriendsLimit.serializer(),
      serializer = FriendsLimit.serializer())

  /**
   * This is an authenticated count of the max friends a user can have.
   *
   * @param userId The unique identifier for a user.
   * @return Successful request.
   */
  suspend fun getFriendsLimit(userId: Long): FriendsLimit? = execute("/${userId}/friends_limit",
      method = RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false,
      serializer = FriendsLimit.serializer())

  /**
   * This is an authenticated request to acquire a users data.
   *
   * @return Successful request.
   */
  suspend fun getMeData(): UserV4? = execute("/me", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, serializer = UserV4.serializer())

  /**
   * This is an authenticated request to acquire a users data from prayer , reading plans etc..."
   *
   * @return Successful request.
   */
  suspend fun getDownloads(): String? = execute("/download", method = RequestMethods.GET, version =
      "4.0", authAllowed = true, authOptional = false, serializer = String.serializer())

  /**
   * @param userId The unique identifier for a user.
   * @return Return the Admin user Resource
   */
  suspend fun getAdminUser(userId: Long): AdminUser = execute("in/4.0/users/${userId}", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, serializer =
      AdminUser.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @return Return the search results
   */
  suspend fun searchAdminUsers(body: AdminSearch? = null): SearchUsers =
      execute("in/4.0/users:search", version = "admin", method = RequestMethods.POST, authAllowed =
      true, authOptional = false, body = body, bodySerializer = AdminSearch.serializer(), serializer
      = SearchUsers.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * This is an authenticated request to see all accounts.
   *
   * @param search Wildcard search on first_name, last_name, and email
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return all accounts.
   */
  suspend fun getAdminAccounts(
    search: String? = null,
    page: Int? = null,
    pageSize: Int? = null
  ): Accounts = execute("in/4.0/accounts", method = RequestMethods.GET, version = "admin",
      authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      search?.let { add("search", search) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Accounts.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Return the created Account resource.
   */
  suspend fun createAdminAccount(body: Invite? = null): Account = execute("in/4.0/accounts", version
      = "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false, body =
      body, bodySerializer = Invite.serializer(), serializer = Account.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param accountId The id of the account resource
   */
  suspend fun resendAdminAccountInvite(accountId: String) =
      execute("in/4.0/accounts/${accountId}:resend", method = RequestMethods.POST, version =
      "admin", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * @param accountId The id of the account resource
   * @return Returns the successfully claimed account.
   */
  suspend fun claimAdminAccount(accountId: String, body: InviteToken? = null): Account? =
      execute("in/4.0/accounts/${accountId}:claim", version = "admin", method = RequestMethods.POST,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      InviteToken.serializer(), serializer = Account.serializer())

  /**
   * @param accountId The id of the account resource
   * @param verify A JWT token used to verify authorization.
   * @return Return an account.
   */
  suspend fun getAdminAccount(accountId: String, verify: String? = null): Account? =
      execute("in/4.0/accounts/${accountId}", method = RequestMethods.GET, version = "admin",
      authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      verify?.let { add("verify", verify) }
  }, serializer = Account.serializer())

  /**
   * @param accountId The id of the account resource
   */
  suspend fun deleteAdminAccount(accountId: String) = execute("in/4.0/accounts/${accountId}", method
      = RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false,
      serializer = Unit.serializer())

  /**
   * @return Verification initiated successfully.
   */
  suspend fun createVerificationUsers(body: VerificationPost? = null): Verification? =
      execute("/verifications", version = "4.0", method = RequestMethods.POST, authAllowed = false,
      authOptional = false, body = body, bodySerializer = VerificationPost.serializer(), serializer
      = Verification.serializer())
}
