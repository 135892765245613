package youversion.red.analytics

import red.platform.UUID
import red.platform.currentTimeMillis
import red.platform.threads.AtomicLong
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.getValue
import red.platform.threads.set
import red.platform.threads.setValue
import red.platform.toLong
import youversion.red.dataman.api.model.media.AbstractMediaCompleteEvent
import youversion.red.dataman.api.model.media.AbstractMediaPlayEvent
import youversion.red.dataman.api.model.media.AbstractMediaStopEvent

class AudioAnalyticsManager(
    private val playEventFactory: (mediaSessionId: String, position: Int, duration: Int) -> AbstractMediaPlayEvent,
    private val stopEventFactory: (mediaSessionId: String, secondsSkipped: Int, secondsPlayed: Int, position: Int) -> AbstractMediaStopEvent,
    private val completeEventFactory: (mediaSessionId: String, secondsSkipped: Int, secondsPlayed: Int) -> AbstractMediaCompleteEvent,
    private val playEventFactoryFireBase: ((mediaSessionId: String, position: Int, duration: Int) -> Event)? = null,
    private val stopEventFactoryFireBase: ((mediaSessionId: String, secondsSkipped: Int, secondsPlayed: Int, position: Int) -> Event)? = null,
    private val completeEventFactoryFireBase: ((mediaSessionId: String, secondsSkipped: Int, secondsPlayed: Int) -> Event)? = null
) {

    private val sessionId = UUID.randomUUID().toString()
    private val started = AtomicLong(0L)
    private val skippedInMs = AtomicLong(0L)
    private val played = AtomicLong(0)
    private val playing = AtomicReference(false)

    init {
        freeze()
    }

    private val secondsPlayed: Int
        get() = (played.getValue() / 1000).toInt()

    private val secondsSkipped: Int
        get() = (skippedInMs.getValue() / 1000).toInt()

    private val Long.seconds: Int
        get() = (this / 1000).toInt()

    /**
     * @param positionInMs - Position in milliseconds in the stream when starting to play
     * @param duration - Expected duration in milliseconds of the media
     */
    fun onPlay(positionInMs: Long, durationInMs: Long) {
        playing.set(true)
        started.setValue(currentTimeMillis().toLong())
        playEventFactory(sessionId, positionInMs.seconds, durationInMs.seconds).log()
        playEventFactoryFireBase?.let { it(sessionId, positionInMs.seconds, durationInMs.seconds) }?.log()
    }

    fun onSeekTo(currentPositionMs: Long, newPositionMs: Long) {
        skippedInMs.setValue(skippedInMs.getValue() + currentPositionMs - newPositionMs)
    }

    private fun stopPlaying() {
        if (playing.value) {
            played.setValue(played.getValue() + (currentTimeMillis().toLong() - started.getValue()))
            playing.set(false)
        }
    }

    /**
     * @param positionInMs - The position in milliseconds in the stream when stopped
     */
    fun onStop(positionInMs: Long) {
        stopPlaying()
        stopEventFactory(sessionId, secondsSkipped, secondsPlayed, positionInMs.seconds).log()
        stopEventFactoryFireBase?.let { it(sessionId, secondsSkipped, secondsPlayed, positionInMs.seconds) }?.log()
    }

    fun onComplete() {
        stopPlaying()
        completeEventFactory(sessionId, secondsSkipped, secondsPlayed).log()
        completeEventFactoryFireBase?.let { it(sessionId, secondsSkipped, secondsPlayed) }?.log()
    }
}
