// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Source of query. Was it from autoComplete (trending, history, community based suggestion) or
 * userInput or emotion
 */
enum class QueryType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  TRENDING("trending", 1),

  COMMUNITY("community", 2),

  HISTORY("history", 3),

  USER_INPUT("user_input", 4),

  EMOTIONS("emotions", 5),

  DEEPLINK("deeplink", 6);
}

internal class QueryTypeSerializer : KSerializer<QueryType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.search.api.model.QueryType")

  override fun serialize(encoder: Encoder, value: QueryType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): QueryType {
    if (decoder is JsonDecoder) {
      return QueryType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> QueryType.UNSUPPORTED_PLACEHOLDER
      1 -> QueryType.TRENDING
      2 -> QueryType.COMMUNITY
      3 -> QueryType.HISTORY
      4 -> QueryType.USER_INPUT
      5 -> QueryType.EMOTIONS
      6 -> QueryType.DEEPLINK
      else -> QueryType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
