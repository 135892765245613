// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class SearchResultItemTap(
  /**
   * text searched for (includes mis-spellings)
   */
  @SerialName("query")
  @ProtoNumber(3)
  val query: String? = null,
  /**
   * User interface language
   */
  @SerialName("app_language")
  @ProtoNumber(9)
  val appLanguage: String? = null,
  /**
   * Plans language
   */
  @SerialName("plan_language")
  @ProtoNumber(10)
  val planLanguage: String? = null,
  /**
   * Bible version id
   */
  @SerialName("version_id")
  @ProtoNumber(11)
  val versionId: Int? = null,
  /**
   * USFM of the book/chapter
   */
  @SerialName("usfm")
  @ProtoNumber(12)
  val usfm: String? = null,
  /**
   * Filterchip selected (ex. plans, videos, all)
   */
  @SerialName("selected_filter")
  @ProtoNumber(13)
  val selectedFilter: String? = null,
  /**
   * Topical or text (this is the final intent returned by the server)
   */
  @SerialName("user_intent")
  @ProtoNumber(14)
  val userIntent: String? = null,
  /**
   * Content type (ex: plans, Bible, images, video)
   */
  @SerialName("results_type")
  @ProtoNumber(15)
  val resultsType: String? = null,
  /**
   * Index of the section that the selected result is in
   */
  @SerialName("section_index")
  @ProtoNumber(16)
  val sectionIndex: Int? = null,
  /**
   * Plans, or videos title, or related searches text
   */
  @SerialName("results_text")
  @ProtoNumber(17)
  val resultsText: String? = null,
  /**
   * Unique identifier for content (i.e. reading_plan_id, video_id)
   */
  @SerialName("content_id")
  @ProtoNumber(18)
  val contentId: Int? = null,
  /**
   * Index of the selected results, within a section
   */
  @SerialName("tapped_index")
  @ProtoNumber(19)
  val tappedIndex: Int? = null,
  /**
   * Videos language
   */
  @SerialName("video_language")
  @ProtoNumber(20)
  val videoLanguage: String? = null,
  /**
   * organization id
   */
  @SerialName("content_sid")
  @ProtoNumber(21)
  val contentSid: String? = null
) : Event(), red.Serializable {
  override val key: String = "SearchResultItemTap"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    query?.let { bundle.putString("query", query) }
    appLanguage?.let { bundle.putString("app_language", appLanguage) }
    planLanguage?.let { bundle.putString("plan_language", planLanguage) }
    versionId?.let { bundle.putInt("version_id", versionId) }
    usfm?.let { bundle.putString("usfm", usfm) }
    selectedFilter?.let { bundle.putString("selected_filter", selectedFilter) }
    userIntent?.let { bundle.putString("user_intent", userIntent) }
    resultsType?.let { bundle.putString("results_type", resultsType) }
    sectionIndex?.let { bundle.putInt("section_index", sectionIndex) }
    resultsText?.let { bundle.putString("results_text", resultsText) }
    contentId?.let { bundle.putInt("content_id", contentId) }
    tappedIndex?.let { bundle.putInt("tapped_index", tappedIndex) }
    videoLanguage?.let { bundle.putString("video_language", videoLanguage) }
    contentSid?.let { bundle.putString("content_sid", contentSid) }
    return bundle
  }
}
