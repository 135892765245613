// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.content_partners

import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.organizations.organization_profiles.LicenseAgreementTypeEnum
import youversion.red.organizations.api.model.organizations.organization_profiles.LicenseStatusEnum

@Serializable
data class ContentPartner(
  /**
   * The organization id.
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  @SerialName("email")
  @ProtoNumber(2)
  @JvmField
  val email: String? = null,
  @SerialName("name")
  @ProtoNumber(3)
  @JvmField
  val name: String? = null,
  @SerialName("external_ids")
  @ProtoNumber(4)
  @JvmField
  val externalIds: List<ExternalIds>? = null,
  @SerialName("primary_language")
  @ProtoNumber(5)
  @JvmField
  val primaryLanguage: String? = null,
  @SerialName("license_status")
  @ProtoNumber(6)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.LicenseStatusEnumSerializer::class)
  @JvmField
  val licenseStatus: LicenseStatusEnum? = null,
  @SerialName("license_type")
  @ProtoNumber(7)
  @Serializable(with=youversion.red.organizations.api.model.organizations.organization_profiles.LicenseAgreementTypeEnumSerializer::class)
  @JvmField
  val licenseType: LicenseAgreementTypeEnum? = null,
  /**
   * The user_id of the user that updated the license agreement.
   */
  @SerialName("license_updated_by")
  @ProtoNumber(8)
  @JvmField
  val licenseUpdatedBy: Long? = null
) : red.Serializable {
  init {
    freeze()
  }
}
