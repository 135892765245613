// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.deeplink

import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object AchievementsDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<AchievementsDeepLinkListener?> = AtomicReference(null)

  var listener: AchievementsDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/badges") { context, _, _ -> 
        listener.onViewAchievements(context)
    }
    register("/badges/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val achievementId = values[1].toIntOrNull() ?: return@register false
        listener.onViewAchievement(context, achievementId)
    }
    register("/users/$INTEGER_EXPRESSION/badges") { context, parts, _ -> 
        val values = parts.first().groupValues
        val userId = values[1].toIntOrNull() ?: return@register false
        listener.onViewUsersAchievements(context, userId)
    }
    register("/users/$INTEGER_EXPRESSION/badges/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val userId = values[1].toIntOrNull() ?: return@register false
        val achievementId = values[2].toIntOrNull() ?: return@register false
        listener.onViewUsersAchievement(context, userId, achievementId)
    }
    register("/users/$INTEGER_EXPRESSION/badges/$INTEGER_EXPRESSION/award") { context, parts, _ -> 
        val values = parts.first().groupValues
        val userId = values[1].toIntOrNull() ?: return@register false
        val achievementId = values[2].toIntOrNull() ?: return@register false
        listener.onViewUsersAchievementAward(context, userId, achievementId)
    }
    freeze()
  }
}
