// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.givingmikey.api.model.ExperienceType

@Serializable
data class GivingError(
  /**
   * The UI of the giving form. `baseline` is the current experience. `one_screen` is the variation
   * being tested that looks like the Life.Church app.
   */
  @SerialName("experience")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.givingmikey.api.model.ExperienceTypeSerializer::class)
  val experience: ExperienceType? = null,
  /**
   * The error the user experienced.
   */
  @SerialName("error")
  @ProtoNumber(2)
  val error: String? = null
) : Event(), red.Serializable {
  override val key: String = "GivingError"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    experience?.let { bundle.putString("experience", experience.serialName) }
    error?.let { bundle.putString("error", error) }
    return bundle
  }
}
