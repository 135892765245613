// Auto-Generated from OpenAPI Spec
package youversion.red.discovery.api

import kotlin.Int
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.discovery.api.model.ChurchesOfFriends
import youversion.red.discovery.api.model.DiscoverSections
import youversion.red.discovery.api.model.DiscoverSectionsCategories
import youversion.red.discovery.api.model.PlansOfFriends
import youversion.red.discovery.api.model.RelatedPlans
import youversion.red.discovery.api.model.StoryVideos
import youversion.red.discovery.api.model.Trends

/**
 * service providing content to discover
 */
object DiscoveryApi : AbstractApi(ApiDefaults("discovery", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", DiscoveryApiSerializer)) {
  /**
   * This API endpoint returns an ordered array of sections for the Discover homepage, sections are
   * already defined on the clients.
   *
   * @return Successful request
   */
  suspend fun getSections(): DiscoverSections = execute("/sections", method = RequestMethods.GET,
      version = "4.0", authAllowed = false, authOptional = false, serializer =
      DiscoverSections.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * This API endpoint returns an ordered array of sections for the Discover homepage, sections are
   * already defined on the clients.
   *
   * @return Successful request
   */
  suspend fun getSectionsV4_1(): DiscoverSectionsCategories = execute("/sections", method =
      RequestMethods.GET, version = "4.1", authAllowed = false, authOptional = false, serializer =
      DiscoverSectionsCategories.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This API endpoint returns a collection object with a data array of plan ids along with the page
   * size from the query used.
   *
   * @param languageTag Language Tag parameter to indicate which language the results are desired
   * in.
   * @param pageSize Size of page for pagination purposes. Defaults to 10. Requesting a page size
   * over 10 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param treatmentId Optional parameter to indicate which algorithm to use to generate trending
   * plans.
   * @return Successful request
   */
  suspend fun getTrends(
    languageTag: String? = null,
    pageSize: Int? = null,
    page: Int? = null,
    treatmentId: Int? = null
  ): Trends? = execute("/trends", method = RequestMethods.GET, version = "4.0", authAllowed = false,
      authOptional = false, queryString = red.platform.http.queryString {
      languageTag?.let { add("language_tag", languageTag) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
      treatmentId?.let { add("treatment_id", treatmentId) }
  }, serializer = Trends.serializer())

  /**
   * This API endpoint returns a collection object with a data array of plan ids along with the page
   * size from the query used.
   *
   * @param pageSize Size of page for pagination purposes. Defaults to 10. Requesting a page size
   * over 10 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request
   */
  suspend fun getFriendsPlans(pageSize: Int? = null, page: Int? = null): PlansOfFriends? =
      execute("/friends-plans", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = false, queryString = red.platform.http.queryString {
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = PlansOfFriends.serializer())

  /**
   * This API endpoint returns a collection object with a data array of plan ids along with the page
   * size from the query used.
   *
   * @param planId The unique identifier for the Plan resource.
   * @param languageTag Language Tag parameter to indicate which language the results are desired
   * in.
   * @param pageSize Size of page for pagination purposes. Defaults to 10. Requesting a page size
   * over 10 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request
   */
  suspend fun getRelatedPlans(
    planId: Int,
    languageTag: String? = null,
    pageSize: Int? = null,
    page: Int? = null
  ): RelatedPlans? = execute("/related-plans/${planId}", method = RequestMethods.GET, version =
      "4.0", authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      languageTag?.let { add("language_tag", languageTag) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = RelatedPlans.serializer())

  /**
   * This API endpoint returns a collection object with a data array of story video ids along with
   * the page size from the query used.
   *
   * @param pageSize Size of page for pagination purposes. Defaults to 10. Requesting a page size
   * over 10 will result in a 400 Bad Request.
   * @param languageTag Language Tag parameter to indicate which language the results are desired
   * in.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request
   */
  suspend fun getStoryVideos(
    pageSize: Int? = null,
    languageTag: String? = null,
    page: Int? = null
  ): StoryVideos? = execute("/story-videos", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, queryString = red.platform.http.queryString {
      pageSize?.let { add("page_size", pageSize) }
      languageTag?.let { add("language_tag", languageTag) }
      page?.let { add("page", page) }
  }, serializer = StoryVideos.serializer())

  /**
   * This API endpoint returns a collection object with a data array of church ids along with the
   * page size from the query used.
   *
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request
   */
  suspend fun getFriendsChurches(pageSize: Int? = null, page: Int? = null): ChurchesOfFriends? =
      execute("/friends-churches", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = false, queryString = red.platform.http.queryString {
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = ChurchesOfFriends.serializer())
}
