// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.v2

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The network connection type
 */
enum class NetworkInterfaceType(
  internal val serialName: String,
  internal val serialId: Int
) {
  OFFLINE("offline", 0),

  CELLULAR("cellular", 1),

  WIFI("wifi", 2),

  ETHERNET("ethernet", 3),

  VPN("vpn", 4),

  BLUETOOTH("bluetooth", 5),

  UNKNOWN("unknown", 6),

  ONLINE("online", 7);
}

internal class NetworkInterfaceTypeSerializer : KSerializer<NetworkInterfaceType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.v2.NetworkInterfaceType")

  override fun serialize(encoder: Encoder, value: NetworkInterfaceType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): NetworkInterfaceType {
    if (decoder is JsonDecoder) {
      return NetworkInterfaceType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> NetworkInterfaceType.OFFLINE
      1 -> NetworkInterfaceType.CELLULAR
      2 -> NetworkInterfaceType.WIFI
      3 -> NetworkInterfaceType.ETHERNET
      4 -> NetworkInterfaceType.VPN
      5 -> NetworkInterfaceType.BLUETOOTH
      6 -> NetworkInterfaceType.UNKNOWN
      7 -> NetworkInterfaceType.ONLINE
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
