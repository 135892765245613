// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.profiles

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Socials(
  @SerialName("title")
  @ProtoNumber(90)
  @Serializable(with=youversion.red.organizations.api.model.profiles.SocialNetworkPlatformsSerializer::class)
  @JvmField
  val title: SocialNetworkPlatforms? = null,
  /**
   * The url of the social media platform
   */
  @SerialName("url")
  @ProtoNumber(91)
  @JvmField
  val url: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
