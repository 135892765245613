// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.featured_organizations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminFeaturedOrganization(
  /**
   * The organization id of the organization.
   */
  @SerialName("organization_id")
  @ProtoNumber(1)
  @JvmField
  val organizationId: String = "",
  /**
   * The order of the featured organization.
   */
  @SerialName("sort")
  @ProtoNumber(2)
  @JvmField
  val sort: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
