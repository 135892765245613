// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.events

import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.events.api.model.events.event_contents.BaseContent

@Serializable
data class Event(
  /**
   * This is the ID of the event.
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Long? = null,
  /**
   * This is the ID of the organization.
   */
  @SerialName("organization_id")
  @ProtoNumber(2)
  @JvmField
  val organizationId: String? = null,
  @SerialName("description")
  @ProtoNumber(3)
  @JvmField
  val description: String? = null,
  /**
   * This is the title of the event.
   */
  @SerialName("title")
  @ProtoNumber(4)
  @JvmField
  val title: String? = null,
  @SerialName("content")
  @ProtoNumber(5)
  @JvmField
  val content: List<BaseContent>? = null,
  @SerialName("image")
  @ProtoNumber(6)
  @JvmField
  val image: Image? = null,
  @SerialName("times")
  @ProtoNumber(7)
  @JvmField
  val times: List<Time>? = null,
  /**
   * This is a flag that indicates if the event has been saved or not.
   */
  @SerialName("saved")
  @ProtoNumber(8)
  @JvmField
  val saved: Boolean? = null
) : red.Serializable {
  init {
    freeze()
  }
}
