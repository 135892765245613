package youversion.red.movies.service

import red.platform.Log
import red.platform.http.DefaultSerializer
import red.platform.http.FormatType
import red.platform.io.fileSystem
import red.platform.threads.AtomicReference
import red.platform.threads.SuspendedLock
import red.platform.threads.set
import red.platform.threads.sync
import red.service.DefaultService
import red.tasks.CoroutineDispatchers
import youversion.red.movies.MovieOrientation
import youversion.red.movies.MoviePublisher
import youversion.red.movies.MoviesCollection
import youversion.red.movies.MoviesConfiguration
import youversion.red.movies.api.LegacyVideosApi
import youversion.red.movies.api.MoviesApi
import youversion.red.movies.api.MoviesApi2
import youversion.red.movies.api.MoviesUIContext
import youversion.red.movies.api.model.videos.OrientationQSEnum
import youversion.red.movies.api.model.videos.StatusQSEnum
import youversion.red.movies.api.model.videos.Video
import youversion.red.movies.api.model.videos.VideoTypeEnum

@DefaultService(IMoviesService::class)
internal class MoviesServiceImpl : IMoviesService {

    private val configuration = AtomicReference<MoviesConfiguration?>(null)
    private val lock = SuspendedLock()
    private val configurationCacheFile
        get() = fileSystem.newFile(fileSystem.internalStorage, "movies-configuration.cache")

    override suspend fun getConfiguration(cacheOnly: Boolean): MoviesConfiguration? {
        configuration.value?.let { return it }
        lock.sync {
            configuration.value?.let { return it }
            getMoviesConfigurationFromDisk()?.let {
                configuration.set(it)
                if (!cacheOnly) {
                    CoroutineDispatchers.launch {
                        try {
                            configuration.set(getAndUpdateConfigurationCache())
                        } catch (e: Exception) {
                            Log.e("MoviesService", "Failed to update configuration cache", e)
                        }
                    }
                }
                return it
            }
            if (cacheOnly) return null
            return getAndUpdateConfigurationCache().also {
                configuration.set(it)
            }
        }
    }

    private suspend fun getMoviesConfigurationFromDisk(): MoviesConfiguration? = try {
        val cacheFile = configurationCacheFile
        if (fileSystem.exists(cacheFile)) {
            val cachedConfiguration = fileSystem.open(cacheFile)?.let {
                DefaultSerializer.deserialize(
                    FormatType.PROTOBUF,
                    MoviesConfiguration.serializer(),
                    it
                )
            } ?: error("Missing file contents")
            cachedConfiguration
        } else {
            null
        }
    } catch (e: Exception) {
        Log.e("MoviesService", "Failed to read cache from disk", e)
        null
    }

    private suspend fun getAndUpdateConfigurationCache() =
        MoviesApi2.getConfiguration()
            ?.also {
                fileSystem.writeFile(
                    configurationCacheFile,
                    DefaultSerializer.serialize(
                        FormatType.PROTOBUF,
                        MoviesConfiguration.serializer(),
                        it
                    )
                )
            }
            ?: error("Failed to get configuration")

    override suspend fun getPublisher(id: Int) = MoviesApi2.getPublisher(id)

    override suspend fun getMovie(id: Int, context: MoviesUIContext) =
        MoviesApi2.getMovie(id, context)

    override suspend fun getChapters() = MoviesApi2.getChapters()

    override suspend fun getMoviesRelatedTo(usfm: String, page: Int?) =
        MoviesApi2.getMoviesRelatedTo(usfm, page)

    override suspend fun getMoviesInCollection(
        id: Int,
        type: VideoTypeEnum?,
        orientation: MovieOrientation?,
        pageSize: Int?,
        page: Int?
    ) = MoviesApi2.getMoviesInCollection(id, type, orientation, pageSize, page)

    override suspend fun getCollection(id: Int) = MoviesApi2.getCollection(id)

    override suspend fun getLanguages(type: String) = MoviesApi2.getLanguages(type)

    override suspend fun getLegacyVideo(id: Int) = LegacyVideosApi.getLegacyVideo(id)

    override suspend fun getActualMovieId(id: Int): Pair<Int, MoviesCollection?>? {
        if (id < 25000) {
            val video = getLegacyVideo(id) ?: return null
            val movieId = video.moviesId ?: return null
            if (video.subVideos?.isNotEmpty() == true) {
                return Pair(movieId, getCollection(video.moviesId))
            }
            return Pair(movieId, null)
        }
        return Pair(id, null)
    }

    override suspend fun getCollections(
        fields: String?,
        page: String?,
        pageSize: Int?,
        videoId: Int?,
        showSeasonal: Boolean?,
        showEmpty: Boolean?
    ) = MoviesApi.getCollections(fields, page, pageSize, videoId, showSeasonal, showEmpty)

    override suspend fun getVideos(
        fields: String?,
        page: String?,
        pageSize: Int?,
        related: String?,
        collectionId: Int?,
        publisherId: Int?,
        status: StatusQSEnum?,
        types: String?,
        orientation: OrientationQSEnum?
    ) = MoviesApi.getVideos(
        fields,
        pageSize,
        page,
        related,
        collectionId,
        publisherId,
        status,
        types,
        orientation
    )

    override suspend fun getCollectionVideos(
        fields: String?,
        page: String?,
        pageSize: Int?,
        related: String?,
        collectionId: Int?,
        publisherId: Int?,
        status: StatusQSEnum?,
        types: String?,
        orientation: OrientationQSEnum?
    ): List<Pair<Video, MoviePublisher?>>? = MoviesApi.getVideos(
        fields,
        pageSize,
        page,
        related,
        collectionId,
        publisherId,
        status,
        types,
        orientation
    ).data?.mapNotNull {
        val publisher: Int = it.publisherId ?: return@mapNotNull null
        try {
            Pair(it, getPublisher(publisher))
        } catch (e: Exception) {
            Log.e("MoviesService", "Could not get video's publisher", e)
            null
        }
    }

    override suspend fun getNewCollection(collectionId: Int) = MoviesApi.getCollection(collectionId)
}
