package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.bible.reference.BibleReference
import youversion.red.bible.reference.VersionId
import youversion.red.versification.VersificationTransaction

@Suppress("DEPRECATION")
@Serializable
data class PlanSegment(
    @ProtoNumber(1) val kind: PlanSegmentKind,
    @ProtoNumber(2) val content: String = "",
    @ProtoNumber(3) private val references: List<String> = emptyList(),
    @ProtoNumber(4) val referenceVersified: List<BibleReference> = emptyList()
) {
    init {
        freeze()
    }

    val referencesNonVersified: List<BibleReference>
        get() = references.map { BibleReference(it) }

    suspend fun versify(
        versificationTransaction: VersificationTransaction,
        versionId: VersionId
    ): PlanSegment {
        if (references.isEmpty()) return this
        return copy(referenceVersified = references.flatMap {
            versificationTransaction.versification.versifier.versify(BibleReference(it, 1), versionId).freeze()
        }).freeze()
    }
}
