// Auto-Generated from OpenAPI Spec
package youversion.red.bible.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object BibleDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<BibleDeepLinkListener?> = AtomicReference(null)

  var listener: BibleDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/bible") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val usfm = queries["usfm"]?.firstOrNull()
        val versionid = queries["versionId"]?.firstOrNull()?.toIntOrNull()
        val reference = queries["reference"]?.firstOrNull()
        listener.onBible(context, usfm, versionid, reference)
    }
    register("/bible/$INTEGER_EXPRESSION/$STRING_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val versionid = values[1].toIntOrNull() ?: return@register false
        val usfm = values[2]
        listener.onBibleReference(context, versionid, usfm)
    }
    register("/bible/$STRING_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val reference = queries["reference"]?.firstOrNull()
        val usfm = values[1]
        listener.onBibleReferenceUsfmOnly(context, usfm, reference)
    }
    freeze()
  }
}
