// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine the current state of an Event
 */
enum class EventStatus(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  DRAFT("draft", 1),

  PUBLISHED("published", 2);
}

internal class EventStatusSerializer : KSerializer<EventStatus> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.events.api.model.events.EventStatus")

  override fun serialize(encoder: Encoder, value: EventStatus) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): EventStatus {
    if (decoder is JsonDecoder) {
      return EventStatus.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> EventStatus.UNSUPPORTED_PLACEHOLDER
      1 -> EventStatus.DRAFT
      2 -> EventStatus.PUBLISHED
      else -> EventStatus.UNSUPPORTED_PLACEHOLDER
    }
  }
}
