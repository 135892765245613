package youversion.red.images.service

import kotlin.js.JsName
import red.service.ExportedService
import red.service.IService
import red.service.ServiceRegistry
import red.service.service
import youversion.red.bible.reference.BibleReference
import youversion.red.images.model.ImageCategory
import youversion.red.images.model.ImageMetadata
import youversion.red.images.model.ImageOrientation
import youversion.red.images.model.Images

@Deprecated("Use ImagesService Provider")
val ImagesService: IImagesService
    get() = ServiceRegistry[IImagesService::class]

@JsName("ImagesServiceProvider")
fun ImagesService() = service<IImagesService>()

@ExportedService
interface IImagesService : IService {

    suspend fun getImage(id: Int, cacheOnly: Boolean = false): ImageMetadata?

    /**
     * Will return a list of images where the BibleReference has been inverse versified.
     *
     * Meaning, if someone in Russian is looking at a Psalms reference, we will convert that
     * reference to an english standard reference and then fetch the images.
     */
    suspend fun getImagesForReader(
        reference: BibleReference? = null,
        languageTag: String? = null,
        category: ImageCategory? = null,
        orientation: ImageOrientation? = null,
        page: Int = 1
    ): Images

    suspend fun getImages(
        reference: BibleReference? = null,
        languageTag: String? = null,
        category: ImageCategory? = null,
        orientation: ImageOrientation? = null,
        page: Int = 1
    ): Images

    suspend fun clearCache()
}
