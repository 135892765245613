package youversion.red.metrics.aggregates

import kotlin.reflect.KClass
import kotlinx.coroutines.awaitAll
import red.resolvers.metrics.model.IMetric
import red.resolvers.metrics.model.RequestManagerCumulativeResponseMetric
import red.resolvers.metrics.model.RequestManagerExecutionExceptionMetric
import red.resolvers.metrics.model.RequestManagerResponseCodeMetric
import red.resolvers.metrics.model.RequestManagerUrlMetric
import red.tasks.CoroutineDispatchers
import youversion.red.dataman.api.model.AnalyticsEvent
import youversion.red.metrics.aggregates.aggregators.RequestManagerCumulativeResponseMetricAggregator
import youversion.red.metrics.aggregates.aggregators.RequestManagerExecutionExceptionMetricAggregator
import youversion.red.metrics.aggregates.aggregators.RequestManagerResponseCodeMetricAggregator
import youversion.red.metrics.aggregates.aggregators.RequestManagerUrlMetricAggregator

internal object MetricsAggregatorManager {

    private val aggregators: Map<KClass<out IMetric>, IMetricAggregator> = mapOf(
        RequestManagerUrlMetric::class to RequestManagerUrlMetricAggregator,
        RequestManagerResponseCodeMetric::class to RequestManagerResponseCodeMetricAggregator,
        RequestManagerExecutionExceptionMetric::class to RequestManagerExecutionExceptionMetricAggregator,
        RequestManagerCumulativeResponseMetric::class to RequestManagerCumulativeResponseMetricAggregator
    )

    suspend fun aggregate(
        metrics: List<IMetric>,
        collectedSessionId: String
    ): List<AnalyticsEvent> {
        val classNameToMetricsMap = metrics.groupBy {
            it::class
        }
        val aggregatedMetrics = classNameToMetricsMap.entries.map { (clazz, metrics) ->
            CoroutineDispatchers.async {
                val aggregator = aggregators[clazz]
                    ?: throw IllegalStateException("Aggregator not found for class $clazz")
                aggregator.aggregate(metrics, collectedSessionId)
            }
        }.awaitAll()
        return aggregatedMetrics.flatten()
    }
}
