// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Float
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.AdministrativeAreaLevel1
import youversion.red.organizations.api.model.profiles.Locality
import youversion.red.organizations.api.model.profiles.ProfileStatus

/**
 * This is a specific view of an Organization but still represents an Organization. Here it is
 * combined with its Location Profile if it has one in order to filter/show Organizations relative to
 * their physical location.
 */
@Serializable
data class OrganizationLocation(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String = "",
  /**
   * The name of the location.
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  /**
   * The current approval state of a profile.
   */
  @SerialName("status")
  @ProtoNumber(10)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  @JvmField
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  /**
   * This is the timezone the organization is located in.
   */
  @SerialName("timezone")
  @ProtoNumber(17)
  @JvmField
  val timezone: String = "",
  /**
   * The angular distance of a location north or south of the earths equator.
   */
  @SerialName("latitude")
  @ProtoNumber(19)
  @JvmField
  val latitude: Float? = null,
  /**
   * The angular distance of a location east or west of the meridian.
   */
  @SerialName("longitude")
  @ProtoNumber(20)
  @JvmField
  val longitude: Float? = null,
  /**
   * The distance from the client's location in miles to this location.
   */
  @SerialName("distance")
  @ProtoNumber(21)
  @JvmField
  val distance: Float? = null,
  /**
   * The human-readable address of the organization.
   */
  @SerialName("formatted_address")
  @ProtoNumber(22)
  @JvmField
  val formattedAddress: String? = null,
  /**
   * A less specific and more broad field that can be a combination of different regional fields.
   */
  @SerialName("formatted_locality")
  @ProtoNumber(23)
  @JvmField
  val formattedLocality: String? = null,
  /**
   * The textual identifier that uniquely identifies a place using google places api id.
   */
  @SerialName("place_id")
  @ProtoNumber(24)
  @JvmField
  val placeId: String? = null,
  @SerialName("administrative_area_level_1")
  @ProtoNumber(25)
  @JvmField
  val administrativeAreaLevel1: AdministrativeAreaLevel1? = null,
  @SerialName("locality")
  @ProtoNumber(26)
  @JvmField
  val locality: Locality? = null
) : red.Serializable {
  init {
    freeze()
  }
}
