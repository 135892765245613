package youversion.red.metrics.service.js

import kotlin.Unit
import kotlin.js.JsName
import kotlin.js.Promise
import kotlinx.serialization.KSerializer
import red.resolvers.metrics.model.IMetric
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.metrics.service.IMetricsService

public object MetricsService {
  private val service: IMetricsService by service<youversion.red.metrics.service.IMetricsService>()

  @JsName(name = "collectMetric")
  public fun collectMetric(metric: IMetric, serializer: KSerializer<out IMetric>): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.collectMetric(metric, serializer)

  }.toPromise()

  @JsName(name = "uploadMetrics")
  public fun uploadMetrics(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.uploadMetrics()

  }.toPromise()

  @JsName(name = "collectPeriodicMetric")
  public fun collectPeriodicMetric(metric: IMetric, serializer: KSerializer<out IMetric>):
      Promise<Unit?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.collectPeriodicMetric(metric, serializer)

  }.toPromise()

  @JsName(name = "uploadPreviousPeriodicMetricsBatch")
  public fun uploadPreviousPeriodicMetricsBatch(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.uploadPreviousPeriodicMetricsBatch()

  }.toPromise()

  @JsName(name = "flushPeriodicMetrics")
  public fun flushPeriodicMetrics(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.flushPeriodicMetrics()

  }.toPromise()

  @JsName(name = "clearCache")
  public fun clearCache(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.clearCache()

  }.toPromise()
}
