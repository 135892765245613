// Auto-Generated from OpenAPI Spec
package youversion.red.locales.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class LocaleHeader(
  @SerialName("bible_language_tag")
  @ProtoNumber(1)
  @JvmField
  val bibleLanguageTag: String = "",
  @SerialName("api_language_tag")
  @ProtoNumber(2)
  @JvmField
  val apiLanguageTag: String = "",
  @SerialName("locale")
  @ProtoNumber(3)
  @JvmField
  val locale: String = "",
  @SerialName("name")
  @ProtoNumber(4)
  @JvmField
  val name: String = "",
  @SerialName("local_name")
  @ProtoNumber(5)
  @JvmField
  val localName: String = "",
  @SerialName("text_direction")
  @ProtoNumber(6)
  @JvmField
  val textDirection: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
