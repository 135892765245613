// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminEventDuplicate(
  /**
   * New title for the new event.
   */
  @SerialName("title")
  @ProtoNumber(1)
  @JvmField
  val title: String? = null,
  /**
   * Id of the image tied to the new event.
   */
  @SerialName("image_id")
  @ProtoNumber(2)
  @JvmField
  val imageId: Long? = null,
  @SerialName("shiftDates")
  @ProtoNumber(3)
  @JvmField
  val shiftdates: AdminTimeShift? = null
) : red.Serializable {
  init {
    freeze()
  }
}
