// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.Int
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminTimeShift(
  /**
   * Number of minutes to shift event times.
   */
  @SerialName("minutes")
  @ProtoNumber(1)
  @JvmField
  val minutes: Int? = null,
  /**
   * Number of hours to shift event times.
   */
  @SerialName("hours")
  @ProtoNumber(2)
  @JvmField
  val hours: Int? = null,
  /**
   * Number of days to shift event times.
   */
  @SerialName("days")
  @ProtoNumber(3)
  @JvmField
  val days: Int? = null,
  /**
   * Number of weeks to shift event times.
   */
  @SerialName("weeks")
  @ProtoNumber(4)
  @JvmField
  val weeks: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
