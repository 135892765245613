// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * specific activity that the user undertook; defined as category_noun_action[_object]
 */
enum class UserActions(
  internal val serialName: String,
  internal val serialId: Int
) {
  MOMENT_WELCOME_START("moment_welcome_start", 0),

  MOMENT_WELCOME_REMOVE("moment_welcome_remove", 1),

  MOMENT_WELCOME_DONE("moment_welcome_done", 2),

  MOMENT_WELCOME_VIEW_ACHIEVEMENTS("moment_welcome_view_achievements", 3),

  MOMENT_WELCOME_VIEW_WELCOME_ACHIEVEMENTS("moment_welcome_view_welcome_achievements", 4),

  SEARCH_SUGGESTION_AUTO_FILL("search_suggestion_auto_fill", 5),

  MOMENT_WELCOME_VIEW_ACHIEVEMENT_STEPS("moment_welcome_view_achievement_steps", 6),

  SOCIAL_COMMENT_CREATE("social_comment_create", 7),

  SOCIAL_COMMENT_DELETE("social_comment_delete", 8),

  SOCIAL_LIKE_CREATE("social_like_create", 9),

  SOCIAL_LIKE_DELETE("social_like_delete", 10),

  SOCIAL_FRIEND_OFFER("social_friend_offer", 11),

  SOCIAL_FRIEND_ACCEPT("social_friend_accept", 12),

  SOCIAL_FRIEND_DECLINE("social_friend_decline", 13),

  SOCIAL_FRIEND_DELETE("social_friend_delete", 14),

  READER_CROSSREFERENCE_EXPAND("reader_crossreference_expand", 15),

  READER_CROSSREFERENCE_TAPLINK("reader_crossreference_taplink", 16),

  STORY_REFLECTION_TAP("story_reflection_tap", 17),

  MEDIA_VIDEO_SHARE("media_video_share", 18),

  ORGANIZATION_POST_LIKE("organization_post_like", 19),

  ORGANIZATION_POST_UNLIKE("organization_post_unlike", 20),

  ORGANIZATION_POST_SHARE("organization_post_share", 21),

  ORGANIZATION_CONNECTION_ADD("organization_connection_add", 22),

  ORGANIZATION_CONNECTION_REMOVE("organization_connection_remove", 23),

  SETTINGS_NOTIFICATION_ENABLED("settings_notification_enabled", 24),

  SETTINGS_NOTIFICATION_DISABLED("settings_notification_disabled", 25),

  HOME_NOTIFICATIONBELL_TAP("home_notificationbell_tap", 26),

  NOTIFICATIONCENTER_PROMPT_ACCEPT("notificationcenter_prompt_accept", 27),

  NOTIFICATIONCENTER_PROMPT_DECLINE("notificationcenter_prompt_decline", 28),

  ONBOARDING_BUTTON_TAPPED("onboarding_button_tapped", 29),

  GUIDED_PRAYER_DRAW("guided_prayer_draw", 30);
}

internal class UserActionsSerializer : KSerializer<UserActions> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.users.api.model.UserActions")

  override fun serialize(encoder: Encoder, value: UserActions) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): UserActions {
    if (decoder is JsonDecoder) {
      return UserActions.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> UserActions.MOMENT_WELCOME_START
      1 -> UserActions.MOMENT_WELCOME_REMOVE
      2 -> UserActions.MOMENT_WELCOME_DONE
      3 -> UserActions.MOMENT_WELCOME_VIEW_ACHIEVEMENTS
      4 -> UserActions.MOMENT_WELCOME_VIEW_WELCOME_ACHIEVEMENTS
      5 -> UserActions.SEARCH_SUGGESTION_AUTO_FILL
      6 -> UserActions.MOMENT_WELCOME_VIEW_ACHIEVEMENT_STEPS
      7 -> UserActions.SOCIAL_COMMENT_CREATE
      8 -> UserActions.SOCIAL_COMMENT_DELETE
      9 -> UserActions.SOCIAL_LIKE_CREATE
      10 -> UserActions.SOCIAL_LIKE_DELETE
      11 -> UserActions.SOCIAL_FRIEND_OFFER
      12 -> UserActions.SOCIAL_FRIEND_ACCEPT
      13 -> UserActions.SOCIAL_FRIEND_DECLINE
      14 -> UserActions.SOCIAL_FRIEND_DELETE
      15 -> UserActions.READER_CROSSREFERENCE_EXPAND
      16 -> UserActions.READER_CROSSREFERENCE_TAPLINK
      17 -> UserActions.STORY_REFLECTION_TAP
      18 -> UserActions.MEDIA_VIDEO_SHARE
      19 -> UserActions.ORGANIZATION_POST_LIKE
      20 -> UserActions.ORGANIZATION_POST_UNLIKE
      21 -> UserActions.ORGANIZATION_POST_SHARE
      22 -> UserActions.ORGANIZATION_CONNECTION_ADD
      23 -> UserActions.ORGANIZATION_CONNECTION_REMOVE
      24 -> UserActions.SETTINGS_NOTIFICATION_ENABLED
      25 -> UserActions.SETTINGS_NOTIFICATION_DISABLED
      26 -> UserActions.HOME_NOTIFICATIONBELL_TAP
      27 -> UserActions.NOTIFICATIONCENTER_PROMPT_ACCEPT
      28 -> UserActions.NOTIFICATIONCENTER_PROMPT_DECLINE
      29 -> UserActions.ONBOARDING_BUTTON_TAPPED
      30 -> UserActions.GUIDED_PRAYER_DRAW
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
