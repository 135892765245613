package youversion.red.plans.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

enum class CollectionType {
    UNKNOWN,
    reading_plan,
    collection,
    saved,
    recommended
}

enum class CollectionDisplay {
    UNKNOWN_COLLECTION,
    standard,
    banner,
    gradient,
    title
}

@Serializable
data class PlanCollectionItems(
    val collections: List<PlanCollections>? = null
)

@Serializable
data class PlanCollections(
    val id: Int? = null,
    val items: List<PlanCollection>? = null,
    @SerialName("has_collections")
    val hasCollections: Boolean = false,
    @SerialName("next_page")
    val nextPage: Int? = null,
    val title: String? = null
)

@Serializable
data class PlanCollection(
    val id: Int? = null,
    val title: String? = null,
    val slug: String? = null,
    @SerialName("image_id")
    val imageId: Int? = null,
    val gradient: CollectionGradient? = null,
    val type: CollectionType? = null,
    val display: CollectionDisplay? = null,
    @SerialName("has_devotional_audio")
    val hasDevotionalAudio: Boolean? = null,
    @SerialName("formatted_length")
    val formattedLength: String? = null,
    val rating: Float? = null
)
