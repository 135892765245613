package red.module

@ModuleDependencies(
    "blue",
    "core",
    "core-google",
    "achievements",
    "analytics",
    "analytics-dataman",
    "banner",
    "bible",
    "bible-shared",
    "downloads",
    "geoip",
    "giving",
    "images",
    "installation",
    "login",
    "moments",
    "moments-shared",
    "movies",
    "plans",
    "prayer",
    "search",
    "settings",
    "stories",
    "stories-shared",
    "users",
    "versification",
    "locales",
    "metrics"
)
class RedModuleInitializer : IModuleInitializer {

    override fun initialize() {
    }
}
