package youversion.red.metrics.aggregates

import red.resolvers.metrics.model.IMetric
import youversion.red.dataman.api.model.AnalyticsEvent

interface IMetricAggregator {

    fun aggregate(metrics: List<IMetric>, collectedSessionId: String): List<AnalyticsEvent>

    fun List<IMetric>.aggregate(
        groupByKeySelector: (IMetric) -> String,
        foldInitialValue: IMetric,
        foldOperation: (IMetric, IMetric) -> IMetric,
        aggregateMapOperation: (IMetric, Int) -> AnalyticsEvent
    ): List<AnalyticsEvent> {
        val group = groupBy(groupByKeySelector)
        val fold = group.map { (_, value) ->
            value.fold(foldInitialValue, foldOperation)
        }
        val aggregate = fold.map {
            val count = group[groupByKeySelector(it)]!!.size
            aggregateMapOperation(it, count)
        }
        return aggregate
    }
}
