// Auto-Generated from OpenAPI Spec
package youversion.red.discovery.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Type is a way to identify a unique layout and/or collection of a feature set
 */
enum class DiscoverSectionType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  CHIPS_CATEGORIES("chips_categories", 1),

  CHURCHES_NEAR_ME("churches_near_me", 2),

  CHURCHES_OF_FRIENDS("churches_of_friends", 3),

  EMOTIONS("emotions", 4),

  PLANS_COLLECTION("plans_collection", 5),

  PLANS_OF_FRIENDS("plans_of_friends", 6),

  PLANS_RELATED("plans_related", 7),

  PLANS_TRENDING("plans_trending", 8),

  PLANS_FEATURED("plans_featured", 9),

  PLANS_NEW_TO_FAITH("plans_new_to_faith", 10),

  PODCASTS_FEATURED("podcasts_featured", 11),

  PODCASTS_CONTINUED("podcasts_continued", 12),

  VIDEOS_COLLECTION("videos_collection", 13),

  VIDEOS_VOTD("videos_votd", 14),

  VIDEOS_FEATURED("videos_featured", 15);
}

internal class DiscoverSectionTypeSerializer : KSerializer<DiscoverSectionType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.discovery.api.model.DiscoverSectionType")

  override fun serialize(encoder: Encoder, value: DiscoverSectionType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): DiscoverSectionType {
    if (decoder is JsonDecoder) {
      return DiscoverSectionType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> DiscoverSectionType.UNSUPPORTED_PLACEHOLDER
      1 -> DiscoverSectionType.CHIPS_CATEGORIES
      2 -> DiscoverSectionType.CHURCHES_NEAR_ME
      3 -> DiscoverSectionType.CHURCHES_OF_FRIENDS
      4 -> DiscoverSectionType.EMOTIONS
      5 -> DiscoverSectionType.PLANS_COLLECTION
      6 -> DiscoverSectionType.PLANS_OF_FRIENDS
      7 -> DiscoverSectionType.PLANS_RELATED
      8 -> DiscoverSectionType.PLANS_TRENDING
      9 -> DiscoverSectionType.PLANS_FEATURED
      10 -> DiscoverSectionType.PLANS_NEW_TO_FAITH
      11 -> DiscoverSectionType.PODCASTS_FEATURED
      12 -> DiscoverSectionType.PODCASTS_CONTINUED
      13 -> DiscoverSectionType.VIDEOS_COLLECTION
      14 -> DiscoverSectionType.VIDEOS_VOTD
      15 -> DiscoverSectionType.VIDEOS_FEATURED
      else -> DiscoverSectionType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
