// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Preferred delivery method for year-end financial statements. 'email' or 'paper' (technically, any
 * value not “email” results in “paper”)
 */
enum class GivingStatementType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  EMAIL("email", 1),

  PAPER("paper", 2);
}

internal class GivingStatementTypeSerializer : KSerializer<GivingStatementType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.givingmikey.api.model.GivingStatementType")

  override fun serialize(encoder: Encoder, value: GivingStatementType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GivingStatementType {
    if (decoder is JsonDecoder) {
      return GivingStatementType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GivingStatementType.UNSUPPORTED_PLACEHOLDER
      1 -> GivingStatementType.EMAIL
      2 -> GivingStatementType.PAPER
      else -> GivingStatementType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
