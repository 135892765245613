package youversion.red.movies.service

import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.movies.Movie
import youversion.red.movies.MovieChapters
import youversion.red.movies.MovieLanguages
import youversion.red.movies.MovieOrientation
import youversion.red.movies.MoviePublisher
import youversion.red.movies.Movies
import youversion.red.movies.MoviesCollection
import youversion.red.movies.MoviesConfiguration
import youversion.red.movies.VideoMetadata
import youversion.red.movies.api.MoviesUIContext
import youversion.red.movies.api.model.collections.Collection
import youversion.red.movies.api.model.collections.Collections
import youversion.red.movies.api.model.videos.OrientationQSEnum
import youversion.red.movies.api.model.videos.StatusQSEnum
import youversion.red.movies.api.model.videos.Video
import youversion.red.movies.api.model.videos.VideoTypeEnum
import youversion.red.movies.api.model.videos.Videos

fun MoviesService() = service<IMoviesService>()

@ExportedService
interface IMoviesService : IService {

    suspend fun getConfiguration(cacheOnly: Boolean = false): MoviesConfiguration?

    suspend fun getPublisher(id: Int): MoviePublisher?

    suspend fun getMovie(id: Int, context: MoviesUIContext): Movie?

    suspend fun getChapters(): MovieChapters?

    suspend fun getMoviesRelatedTo(usfm: String, page: Int? = null): Pair<Movies, String?>

    suspend fun getMoviesInCollection(
        id: Int,
        type: VideoTypeEnum? = null,
        orientation: MovieOrientation? = null,
        pageSize: Int? = null,
        page: Int? = null
    ): Pair<Movies, String?>

    suspend fun getCollection(id: Int): MoviesCollection?

    suspend fun getLanguages(type: String): MovieLanguages?

    suspend fun getLegacyVideo(id: Int): VideoMetadata?

    suspend fun getActualMovieId(id: Int): Pair<Int, MoviesCollection?>?

    suspend fun getCollections(
        fields: String? = null,
        page: String? = null,
        pageSize: Int? = null,
        videoId: Int? = null,
        showSeasonal: Boolean? = null,
        showEmpty: Boolean? = null
    ): Collections

    suspend fun getVideos(
        fields: String? = null,
        page: String? = null,
        pageSize: Int? = null,
        related: String? = null,
        collectionId: Int? = null,
        publisherId: Int? = null,
        status: StatusQSEnum? = null,
        types: String? = null,
        orientation: OrientationQSEnum? = null
    ): Videos

    suspend fun getCollectionVideos(
        fields: String? = null,
        page: String? = null,
        pageSize: Int? = null,
        related: String? = null,
        collectionId: Int? = null,
        publisherId: Int? = null,
        status: StatusQSEnum? = null,
        types: String? = null,
        orientation: OrientationQSEnum? = null
    ): List<Pair<Video, MoviePublisher?>>?

    suspend fun getNewCollection(collectionId: Int): Collection
}
