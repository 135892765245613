// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.admin.events

import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminEventCreate(
  /**
   * This is the ID of the organization creating the event.
   */
  @SerialName("organization_id")
  @ProtoNumber(1)
  @JvmField
  val organizationId: String? = null,
  /**
   * This is the title of the new event.
   */
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  /**
   * This is the description of the new event.
   */
  @SerialName("description")
  @ProtoNumber(3)
  @JvmField
  val description: String? = null,
  /**
   * Id of the image tied to the new event.
   */
  @SerialName("image_id")
  @ProtoNumber(4)
  @JvmField
  val imageId: Long? = null
) : red.Serializable {
  init {
    freeze()
  }
}
