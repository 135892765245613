// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.following

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class SlimFollowing(
  /**
   * This is the id of the organization followed
   */
  @SerialName("organization_id")
  @ProtoNumber(1)
  @JvmField
  val organizationId: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
