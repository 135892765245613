// Auto-Generated from OpenAPI Spec
package youversion.red.bible.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.bible.api.model.reader.ReaderTheme

@Serializable
data class ReaderThemeSetEventFireBase(
  /**
   * Reader theme selected
   */
  @SerialName("theme_id")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.bible.api.model.reader.ReaderThemeSerializer::class)
  val themeId: ReaderTheme? = null
) : Event(), red.Serializable {
  override val key: String = "ReaderThemeSetEvent"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    themeId?.let { bundle.putString("theme_id", themeId.serialName) }
    return bundle
  }
}
