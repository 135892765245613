// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class BibleVersionGrant(
  /**
   * The id of the bible version grant.
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * The version id.
   */
  @SerialName("version_id")
  @ProtoNumber(3)
  @JvmField
  val versionId: Int? = null,
  /**
   * The type of version.
   */
  @SerialName("version_type")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.organizations.api.model.organizations.BibleVersionTypeSerializer::class)
  @JvmField
  val versionType: BibleVersionType? = null,
  /**
   * The created method.
   */
  @SerialName("created_method")
  @ProtoNumber(5)
  @Serializable(with=youversion.red.organizations.api.model.organizations.CreatedMethodTypeSerializer::class)
  @JvmField
  val createdMethod: CreatedMethodType? = null,
  /**
   * The time that this bible version grant was created.
   */
  @SerialName("created_dt")
  @ProtoNumber(6)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The time that this bible version grant was created
   */
  @SerialName("updated_dt")
  @ProtoNumber(7)
  @JvmField
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
