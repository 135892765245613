// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class GPBackgroundAudioPlayEventFireBase(
  @SerialName("audioCategoryId")
  @ProtoNumber(1)
  val audiocategoryid: Int? = null,
  /**
   * session for this media event
   */
  @SerialName("mediaSessionId")
  @ProtoNumber(50)
  val mediasessionid: String? = null,
  /**
   * expected duration (in seconds) of the media
   */
  @SerialName("duration")
  @ProtoNumber(51)
  val duration: Int? = null,
  /**
   * position (in seconds) in the stream when played
   */
  @SerialName("position")
  @ProtoNumber(52)
  val position: Int? = null
) : Event(), red.Serializable {
  override val key: String = "background_audio_play_event"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    audiocategoryid?.let { bundle.putInt("audioCategoryId", audiocategoryid) }
    mediasessionid?.let { bundle.putString("mediaSessionId", mediasessionid) }
    duration?.let { bundle.putInt("duration", duration) }
    position?.let { bundle.putInt("position", position) }
    return bundle
  }
}
