// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
data class ChurchMutation(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * The name of the organization.
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  /**
   * The website_url or the organization.
   */
  @SerialName("website_url")
  @ProtoNumber(3)
  @JvmField
  val websiteUrl: String? = null,
  /**
   * The phone number of the organization.
   */
  @SerialName("phone")
  @ProtoNumber(4)
  @JvmField
  val phone: String? = null,
  /**
   * The email of the organization.
   */
  @SerialName("email")
  @ProtoNumber(5)
  @JvmField
  val email: String? = null,
  /**
   * the different descriptions and language_tags associated with an organization's international
   * identity
   */
  @SerialName("i18ns")
  @ProtoNumber(6)
  @JvmField
  val i18ns: List<I18n>? = null,
  /**
   * This is the hex color code for the background color of an organization.
   */
  @SerialName("background_color")
  @ProtoNumber(7)
  @JvmField
  val backgroundColor: String? = null,
  /**
   * This is the weekly attendance of the organization.
   */
  @SerialName("weekly_attendance")
  @ProtoNumber(9)
  @JvmField
  val weeklyAttendance: Int? = null,
  @SerialName("status")
  @ProtoNumber(10)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  @JvmField
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  @SerialName("logo_image_id")
  @ProtoNumber(11)
  @JvmField
  val logoImageId: String = "",
  @SerialName("pastor")
  @ProtoNumber(12)
  @JvmField
  val pastor: PastorPatchMutation? = null,
  /**
   * The primary language of the organization represented as a an iso 639 language code
   */
  @SerialName("primary_language")
  @ProtoNumber(13)
  @JvmField
  val primaryLanguage: String = "",
  /**
   * The id of the last user to update the organization
   */
  @SerialName("support_user_id")
  @ProtoNumber(14)
  @JvmField
  val supportUserId: Int? = null,
  /**
   * The timezone of the organization.
   */
  @SerialName("timezone")
  @ProtoNumber(21)
  @JvmField
  val timezone: String = "",
  @SerialName("address")
  @ProtoNumber(22)
  @JvmField
  val address: Address? = null,
  @SerialName("support_account_id")
  @ProtoNumber(23)
  @JvmField
  val supportAccountId: String? = null,
  /**
   * The date time when the church got verified.
   */
  @SerialName("verified_dt")
  @ProtoNumber(24)
  @JvmField
  val verifiedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
