// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.v2

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AnalyticsContentType

@Serializable
@SerialName("v2.BackgroundEvent")
data class BackgroundEvent(
  @SerialName("contentType")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.dataman.api.model.AnalyticsContentTypeSerializer::class)
  val contenttype: AnalyticsContentType =
      youversion.red.dataman.api.model.AnalyticsContentType.NONE,
  /**
   * The primary YouVersion identifier for the content
   */
  @SerialName("primaryContentId")
  @ProtoNumber(50)
  override val primarycontentid: String? = null,
  /**
   * A unique identifier for the event (Firebase push ID)
   */
  @SerialName("eventId")
  @ProtoNumber(51)
  override val eventid: String = "",
  /**
   * An identifier for the UI element (snake case)
   */
  @SerialName("elementId")
  @ProtoNumber(52)
  override val elementid: String? = null,
  /**
   * A secondary identifier describing the content
   */
  @SerialName("secondaryContentId")
  @ProtoNumber(53)
  override val secondarycontentid: String? = null,
  /**
   * A tertiary identifier describing the content
   */
  @SerialName("tertiaryContentId")
  @ProtoNumber(54)
  override val tertiarycontentid: String? = null,
  /**
   * A quaternary identifier describing the content
   */
  @SerialName("quaternaryContentId")
  @ProtoNumber(55)
  override val quaternarycontentid: String? = null,
  /**
   * A platform-specific screen identifier
   */
  @SerialName("screenId")
  @ProtoNumber(56)
  override val screenid: String? = null,
  /**
   * Extra contextual event metadata
   */
  @SerialName("extraA")
  @ProtoNumber(57)
  override val extraa: String? = null,
  /**
   * Extra contextual event metadata
   */
  @SerialName("extraB")
  @ProtoNumber(58)
  override val extrab: String? = null,
  /**
   * Extra contextual event metadata
   */
  @SerialName("extraC")
  @ProtoNumber(59)
  override val extrac: String? = null,
  /**
   * Extra contextual event metadata
   */
  @SerialName("extraD")
  @ProtoNumber(60)
  override val extrad: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now(),
  /**
   * The microsecond component of the Created timestamp
   */
  @SerialName("createdMicros")
  @ProtoNumber(203)
  override val createdmicros: Int? = null
) : AbstractEvent(), red.Serializable {
  init {
    freeze()
  }
}
