package youversion.red.churches.model

import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.AdministrativeAreaLevel1
import youversion.red.organizations.api.model.profiles.Locality

data class Location(
    val id: String,
    val name: String,
    val formattedAddress: String?,
    val formattedLocality: String?,
    val longitude: Float?,
    val latitude: Float?,
    val distance: Float?,
    val placeId: String?,
    val administrativeAreaLevel1: AdministrativeAreaLevel1?,
    val locality: Locality?
) {
    init {
        freeze()
    }
}
