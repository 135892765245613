// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.givingmikey.api.model.giving.GivingLandingReferralSource

@Serializable
data class GivingLandingEventFireBase(
  /**
   * Referral source of the Giving Landing screen
   */
  @SerialName("referrer")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.givingmikey.api.model.giving.GivingLandingReferralSourceSerializer::class)
  val referrer: GivingLandingReferralSource? = null
) : Event(), red.Serializable {
  override val key: String = "GivingLandingEvent"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    referrer?.let { bundle.putString("referrer", referrer.serialName) }
    return bundle
  }
}
