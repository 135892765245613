// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api

import kotlin.Double
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.serializer
import red.platform.Date
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.organizations.api.model.admin.FeatureFlag
import youversion.red.organizations.api.model.admin.FeatureFlagPrincipal
import youversion.red.organizations.api.model.admin.FeatureFlagPrincipals
import youversion.red.organizations.api.model.admin.FeatureFlags
import youversion.red.organizations.api.model.admin.content_partners.ContentPartner
import youversion.red.organizations.api.model.admin.content_partners.ContentPartnerPost
import youversion.red.organizations.api.model.admin.content_partners.ContentPartners
import youversion.red.organizations.api.model.admin.featured_organizations.AdminFeaturedOrganizations
import youversion.red.organizations.api.model.admin.organizations.AdminFeaturedPlan
import youversion.red.organizations.api.model.admin.organizations.AdminFeaturedPlans
import youversion.red.organizations.api.model.admin.organizations.AdminOrganization
import youversion.red.organizations.api.model.admin.organizations.AdminOrganizationCreate
import youversion.red.organizations.api.model.admin.organizations.AdminOrganizationPosts
import youversion.red.organizations.api.model.admin.organizations.AdminOrganizations
import youversion.red.organizations.api.model.admin.organizations.AdminServiceTimes
import youversion.red.organizations.api.model.admin.organizations.ChurchPostMutation
import youversion.red.organizations.api.model.admin.organizations.ConvertChurchRequest
import youversion.red.organizations.api.model.admin.organizations.ConvertChurchResponse
import youversion.red.organizations.api.model.admin.organizations.FeaturedPlanCreate
import youversion.red.organizations.api.model.admin.organizations.LocationPostMutation
import youversion.red.organizations.api.model.admin.organizations.OrganizationFollowers
import youversion.red.organizations.api.model.admin.organizations.OrganizationFollowersWithCursor
import youversion.red.organizations.api.model.admin.organizations.OrganizationRelationship
import youversion.red.organizations.api.model.admin.organizations.Schedule
import youversion.red.organizations.api.model.admin.organizations.organization_posts.AdminOrganizationPost
import youversion.red.organizations.api.model.admin.organizations.organization_posts.AdminOrganizationPostsRequest
import youversion.red.organizations.api.model.admin.organizations.organization_profiles.AcceptProfileLicense
import youversion.red.organizations.api.model.admin.organizations.organization_profiles.ProfileLicense
import youversion.red.organizations.api.model.content_type_grants.ContentGrantOrganizations
import youversion.red.organizations.api.model.featured_organizations.FeaturedOrganizations
import youversion.red.organizations.api.model.following.Following
import youversion.red.organizations.api.model.following.Followings
import youversion.red.organizations.api.model.following.SlimFollowing
import youversion.red.organizations.api.model.following.collection_get.SortTypeEnum
import youversion.red.organizations.api.model.images.ImageProperties
import youversion.red.organizations.api.model.images.ImageType
import youversion.red.organizations.api.model.images.PresignedImageResponse
import youversion.red.organizations.api.model.organizations.BibleVersionGrant
import youversion.red.organizations.api.model.organizations.BibleVersionGrants
import youversion.red.organizations.api.model.organizations.ContentGrant
import youversion.red.organizations.api.model.organizations.ContentGrants
import youversion.red.organizations.api.model.organizations.ContentType
import youversion.red.organizations.api.model.organizations.FeaturedPlans
import youversion.red.organizations.api.model.organizations.Organization
import youversion.red.organizations.api.model.organizations.OrganizationJoin
import youversion.red.organizations.api.model.organizations.OrganizationLocations
import youversion.red.organizations.api.model.organizations.OrganizationMember
import youversion.red.organizations.api.model.organizations.OrganizationMembers
import youversion.red.organizations.api.model.organizations.OrganizationPost
import youversion.red.organizations.api.model.organizations.OrganizationPosts
import youversion.red.organizations.api.model.organizations.Organizations
import youversion.red.organizations.api.model.organizations.PostAction
import youversion.red.organizations.api.model.organizations.PostActionRequest
import youversion.red.organizations.api.model.organizations.PostActions
import youversion.red.organizations.api.model.organizations.ProfileType
import youversion.red.organizations.api.model.organizations.ServiceTimes
import youversion.red.organizations.api.model.organizations.accounts.Account
import youversion.red.organizations.api.model.organizations.accounts.Accounts
import youversion.red.organizations.api.model.organizations.accounts.InviteRequest
import youversion.red.organizations.api.model.organizations.accounts.InviteResend
import youversion.red.organizations.api.model.organizations.accounts.InviteResponse
import youversion.red.organizations.api.model.organizations.accounts.InviteToken
import youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.OrganizationProfiles
import youversion.red.organizations.api.model.organizations.organization_profiles.PostBaseProfile

/**
 * The Organizations API is a service that currently houses non-user org and persona entities that
 * can be used to create many types of content. It is possible that this service could be the future
 * home of user identity information not pertaining to authentication and authorization.
 */
object OrganizationsApi : AbstractApi(ApiDefaults("organizations", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", OrganizationsApiSerializer)) {
  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param parentId Organization parent ID to filter children
   * @param forUser Filter for organizations this user has admin access to
   * @param churchStatuses A comma separated list of statuses to return. Defaults to * which is all
   * statuses. A 400 Bad Request will be returned if given invalid statuses.
   * @param supportUserId Filter for organizations with support_user_id
   * @param language Language tag matching primary_language of an organization
   * @param query Filter organizations where name is ILIKE query
   * @param orderBy A comma separated list of field and direction to order by. A 400 Bad Request
   * will be returned if given an invalid field or direction.
   * @param typeStatus An array of strings provides a profile_type and profile_status to filter 
   * organization collection GET. Format must be "profile_type:profile_status".  'all' is an acceptable
   * profile_status to retrieve all of a specified profile_type.
   * @return return all organizations
   */
  suspend fun getAdminOrganizations(
    fields: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    parentId: String? = null,
    forUser: Long? = null,
    churchStatuses: String? = null,
    supportUserId: Long? = null,
    language: String? = null,
    query: String? = null,
    orderBy: String? = null,
    typeStatus: List<String>? = null
  ): AdminOrganizations = execute("in/4.0/organizations", method = RequestMethods.GET, version =
      "admin", authAllowed = true, authOptional = false, queryString = red.platform.http.queryString
      {
      fields?.let { add("fields", fields) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      parentId?.let { add("parent_id", parentId) }
      forUser?.let { add("for_user", forUser) }
      churchStatuses?.let { add("church_statuses", churchStatuses) }
      supportUserId?.let { add("support_user_id", supportUserId) }
      language?.let { add("language", language) }
      query?.let { add("query", query) }
      orderBy?.let { add("order_by", orderBy) }
      typeStatus?.let { addStrings("type_status", typeStatus) }
  }, serializer = AdminOrganizations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return return the created church
   */
  suspend fun createAdminOrganizations(body: AdminOrganizationCreate? = null): AdminOrganization =
      execute("in/4.0/organizations", version = "admin", method = RequestMethods.POST, authAllowed =
      true, authOptional = false, body = body, bodySerializer =
      AdminOrganizationCreate.serializer(), serializer = AdminOrganization.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return returns an organization resource
   */
  suspend fun getAdminOrganization(organizationId: String, fields: String? = null):
      AdminOrganization? = execute("in/4.0/organizations/${organizationId}", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = AdminOrganization.serializer())

  /**
   * @param imageType The type of image to be uploaded
   * @return Return presigned url for image upload
   */
  suspend fun signOrganizationImage(imageType: ImageType, body: ImageProperties? = null):
      PresignedImageResponse = execute("in/4.0/images/${imageType}", version = "admin", method =
      RequestMethods.POST, authAllowed = false, authOptional = false, body = body, bodySerializer =
      ImageProperties.serializer(), serializer = PresignedImageResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return return all profiles for an organization
   */
  suspend fun getAdminOrganizationProfiles(organizationId: String, fields: String? = null):
      OrganizationProfiles? = execute("in/4.0/organizations/${organizationId}/profiles", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = OrganizationProfiles.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Return the created profile resource.
   */
  suspend fun createAdminOrganizationProfile(organizationId: String, body: PostBaseProfile? = null):
      BaseProfile = execute("in/4.0/organizations/${organizationId}/profiles", version = "admin",
      method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = PostBaseProfile.serializer(), serializer = BaseProfile.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param profileType The type of organization profile
   * @param profileId The id of the organization profile
   */
  suspend fun deleteAdminOrganizationProfile(
    organizationId: String,
    profileType: ProfileType,
    profileId: String
  ) = execute("in/4.0/organizations/${organizationId}/profiles/${profileType}/${profileId}", method
      = RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false,
      serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param profileType The type of organization profile
   * @param profileId The id of the organization profile
   * @return Return the accepted license agreement.
   */
  suspend fun acceptLicenseAgreement(
    organizationId: String,
    profileType: ProfileType,
    profileId: String,
    body: AcceptProfileLicense? = null
  ): ProfileLicense? =
      execute("in/4.0/organizations/${organizationId}/profiles/${profileType}/${profileId}/license:accept",
      version = "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false,
      body = body, bodySerializer = AcceptProfileLicense.serializer(), serializer =
      ProfileLicense.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param profileType The type of organization profile
   * @param profileId The id of the organization profile
   * @return Return the rejected license agreement.
   */
  suspend fun rejectLicenseAgreement(
    organizationId: String,
    profileType: ProfileType,
    profileId: String
  ): ProfileLicense? =
      execute("in/4.0/organizations/${organizationId}/profiles/${profileType}/${profileId}/license:reject",
      method = RequestMethods.POST, version = "admin", authAllowed = true, authOptional = false,
      serializer = ProfileLicense.serializer())

  /**
   * @param search Wildcard search on first_name, last_name, and email
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return available accounts for the given parameters.
   */
  suspend fun getAdminAllOrganizationAccounts(
    search: String? = null,
    page: Int? = null,
    pageSize: Int? = null
  ): Accounts = execute("in/4.0/organizations/-/accounts", method = RequestMethods.GET, version =
      "admin", authAllowed = true, authOptional = false, queryString = red.platform.http.queryString
      {
      search?.let { add("search", search) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Accounts.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param search Wildcard search on first_name, last_name, and email
   * @param organizationId The id of the organization resource
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return all accounts for an organization.
   */
  suspend fun getAdminOrganizationAccounts(
    search: String? = null,
    organizationId: String,
    page: Int? = null,
    pageSize: Int? = null
  ): Accounts? = execute("in/4.0/organizations/${organizationId}/accounts", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      search?.let { add("search", search) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Accounts.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Return the created Account resource.
   */
  suspend fun createAdminOrganizationAccount(organizationId: String, body: InviteRequest? = null):
      InviteResponse? = execute("in/4.0/organizations/${organizationId}/accounts", version =
      "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = InviteRequest.serializer(), serializer = InviteResponse.serializer())

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param organizationId The id of the organization resource
   * @return return all service times for organization
   */
  suspend fun getAdminServiceTimes(fields: String? = null, organizationId: String):
      AdminServiceTimes? = execute("in/4.0/organizations/${organizationId}/service_times", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = AdminServiceTimes.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param accountId The id of the account resource
   */
  suspend fun resendAdminOrganizationAccountInvite(
    organizationId: String,
    accountId: String,
    body: InviteResend? = null
  ) = execute("in/4.0/organizations/${organizationId}/accounts/${accountId}:resend", version =
      "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = InviteResend.serializer(), serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param accountId The id of the account resource
   * @return Returns the successfully claimed account.
   */
  suspend fun claimAdminOrganizationAccount(
    organizationId: String,
    accountId: String,
    body: InviteToken? = null
  ): Account? = execute("in/4.0/organizations/${organizationId}/accounts/${accountId}:claim",
      version = "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false,
      body = body, bodySerializer = InviteToken.serializer(), serializer = Account.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param accountId The id of the account resource
   * @param verify A JWT token used to verify authorization.
   * @return Return an account for an organization.
   */
  suspend fun getAdminOrganizationAccount(
    organizationId: String,
    accountId: String,
    verify: String? = null
  ): Account? = execute("in/4.0/organizations/${organizationId}/accounts/${accountId}", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      verify?.let { add("verify", verify) }
  }, serializer = Account.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param accountId The id of the account resource
   */
  suspend fun deleteAdminOrganizationAccount(organizationId: String, accountId: String) =
      execute("in/4.0/organizations/${organizationId}/accounts/${accountId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Returns the successfully claimed account.
   */
  suspend fun createAdminOrganizationRelationship(organizationId: String,
      body: OrganizationRelationship? = null): OrganizationRelationship? =
      execute("in/4.0/organizations/${organizationId}/organization_relationships", version =
      "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = OrganizationRelationship.serializer(), serializer =
      OrganizationRelationship.serializer())

  /**
   * @return return the created church
   */
  suspend fun createChurch(body: ChurchPostMutation? = null): ChurchPostMutation =
      execute("in/4.0/churches", version = "admin", method = RequestMethods.POST, authAllowed =
      true, authOptional = false, body = body, bodySerializer = ChurchPostMutation.serializer(),
      serializer = ChurchPostMutation.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun deleteChurch(organizationId: String) = execute("in/4.0/churches/${organizationId}",
      method = RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false,
      serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return return the created church schedule
   */
  suspend fun updateSchedule(organizationId: String, body: Schedule? = null): Schedule? =
      execute("in/4.0/churches/${organizationId}/schedule", version = "admin", method =
      RequestMethods.PUT, authAllowed = false, authOptional = false, body = body, bodySerializer =
      Schedule.serializer(), serializer = Schedule.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Successfully converted church
   */
  suspend fun convertChurch(organizationId: String, body: ConvertChurchRequest? = null):
      ConvertChurchResponse = execute("in/4.0/churches/${organizationId}:convert", version =
      "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = ConvertChurchRequest.serializer(), serializer =
      ConvertChurchResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @return return the created church location
   */
  suspend fun createChurchLocation(organizationId: String, body: LocationPostMutation? = null):
      LocationPostMutation = execute("in/4.0/churches/${organizationId}/locations", version =
      "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = LocationPostMutation.serializer(), serializer =
      LocationPostMutation.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param locationId The id of the location resource
   */
  suspend fun deleteChurchLocation(organizationId: String, locationId: String) =
      execute("in/4.0/churches/${organizationId}/locations/${locationId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = false, authOptional = false,
      serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param from The time the query will begin querying from
   * @param first Grab n number of record(s) after the `from` timestamp
   * @param last Grab n number of record(s) before the `from` timestamp
   * @return return featured plans for an organization
   */
  suspend fun getAdminOrganizationFeaturedPlans(
    organizationId: String,
    from: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
    first: Int? = null,
    last: Int? = null
  ): AdminFeaturedPlans = execute("in/4.0/organizations/${organizationId}/featured_plans", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      from?.let { add("from", from) }
      first?.let { add("first", first) }
      last?.let { add("last", last) }
  }, serializer = AdminFeaturedPlans.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @return Return presigned url for image upload
   */
  suspend fun createOrganizationFeaturedPlan(organizationId: String, body: FeaturedPlanCreate? =
      null): AdminFeaturedPlan = execute("in/4.0/organizations/${organizationId}/featured_plans",
      version = "admin", method = RequestMethods.POST, authAllowed = false, authOptional = false,
      body = body, bodySerializer = FeaturedPlanCreate.serializer(), serializer =
      AdminFeaturedPlan.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun endOrganizationFeaturedPlan(organizationId: String) =
      execute("in/4.0/organizations/${organizationId}/featured_plans:end", method =
      RequestMethods.POST, version = "admin", authAllowed = false, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param withUser Filter for organizations this user is a member of
   * @return Return a collection of organizations
   */
  suspend fun getOrganizations(withUser: Long): Organizations = execute("/organizations", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true, queryString =
      red.platform.http.queryString {
      add("with_user", withUser)
  }, serializer = Organizations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return returns an organization resource
   */
  suspend fun getOrganization(organizationId: String, fields: String? = null): Organization? =
      execute("/organizations/${organizationId}", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Organization.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return returns the profiles for an organization resource
   */
  suspend fun getOrganizationProfiles(organizationId: String, fields: String? = null):
      OrganizationProfiles? = execute("/organizations/${organizationId}/profiles", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = OrganizationProfiles.serializer())

  /**
   * @param parentIdPath The id of a parent organization resource to be used in path parameters
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param clientLatitude The latitude of the client
   * @param clientLongitude The longitude of the client
   * @return returns an organization location collection
   */
  suspend fun getOrganizationsOrganizationLocations(
    parentIdPath: String,
    fields: String? = null,
    clientLatitude: Double? = null,
    clientLongitude: Double? = null
  ): OrganizationLocations? = execute("/organizations/${parentIdPath}/locations", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      clientLatitude?.let { add("client_latitude", clientLatitude) }
      clientLongitude?.let { add("client_longitude", clientLongitude) }
  }, serializer = OrganizationLocations.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return returns an organization featured plans
   */
  suspend fun getOrganizationFeaturedPlans(organizationId: String): FeaturedPlans? =
      execute("/organizations/${organizationId}/featured_plans", method = RequestMethods.GET,
      version = "4.0", authAllowed = false, authOptional = false, serializer =
      FeaturedPlans.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return returns a collection of service times
   */
  suspend fun getServiceTimes(organizationId: String, fields: String? = null): ServiceTimes? =
      execute("/organizations/${organizationId}/service_times", method = RequestMethods.GET, version
      = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = ServiceTimes.serializer())

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun joinOrganization(organizationId: String, body: OrganizationJoin? = null) =
      execute("/organizations/${organizationId}:join", version = "4.0", method =
      RequestMethods.POST, authAllowed = true, authOptional = false, body = body, bodySerializer =
      OrganizationJoin.serializer(), serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun leaveOrganization(organizationId: String) =
      execute("/organizations/${organizationId}:leave", method = RequestMethods.POST, version =
      "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param friends A user_id for which to restrict data to only containing the friends of the id
   * provided.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return return members of an organization
   */
  suspend fun getOrganizationMembers(
    organizationId: String,
    friends: Long? = null,
    page: Int? = null,
    pageSize: Int? = null
  ): OrganizationMembers? = execute("/organizations/${organizationId}/members", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true, queryString =
      red.platform.http.queryString {
      friends?.let { add("friends", friends) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = OrganizationMembers.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param userId The id of a user.
   * @return Return member of an organization
   */
  suspend fun getOrganizationMember(organizationId: String, userId: Long): OrganizationMember? =
      execute("/organizations/${organizationId}/members/${userId}", method = RequestMethods.GET,
      version = "4.0", authAllowed = true, authOptional = false, serializer =
      OrganizationMember.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return returns the content for an organization resource
   */
  suspend fun getAdminOrganizationContentGrants(
    organizationId: String,
    fields: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    contentType: ContentType? = null
  ): ContentGrants? = execute("in/4.0/organizations/${organizationId}/content_grants", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      contentType?.let { add("content_type", contentType.serialName) }
  }, serializer = ContentGrants.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Return the created bible version grant resource.
   */
  suspend fun createAdminOrganizationContentGrant(organizationId: String, body: ContentGrant? =
      null): ContentGrant? = execute("in/4.0/organizations/${organizationId}/content_grants",
      version = "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false,
      body = body, bodySerializer = ContentGrant.serializer(), serializer =
      ContentGrant.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param grantId The id of the grant resource
   */
  suspend fun deleteAdminOrganizationContentGrant(organizationId: String, grantId: String) =
      execute("in/4.0/organizations/${organizationId}/content_grants/${grantId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param contentType The type of organization content
   * @param externalId Content external id
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return returns the content for an organization resource
   */
  suspend fun getAdminContentGrantOrganizations(
    contentType: ContentType,
    externalId: Long,
    page: Int? = null,
    pageSize: Int? = null
  ): ContentGrantOrganizations? =
      execute("in/4.0/${contentType}_grants/${externalId}/organizations", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = ContentGrantOrganizations.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return returns the versions for an organization resource
   */
  suspend fun getAdminOrganizationBibleVersionGrants(
    organizationId: String,
    fields: String? = null,
    page: Int? = null,
    pageSize: Int? = null
  ): BibleVersionGrants? = execute("in/4.0/organizations/${organizationId}/bible_version_grants",
      method = RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false,
      queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = BibleVersionGrants.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Return the created bible version grant resource.
   */
  suspend fun createAdminOrganizationBibleVersionGrant(organizationId: String,
      body: BibleVersionGrant? = null): BibleVersionGrant? =
      execute("in/4.0/organizations/${organizationId}/bible_version_grants", version = "admin",
      method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = BibleVersionGrant.serializer(), serializer = BibleVersionGrant.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param grantId The id of the grant resource
   */
  suspend fun deleteAdminOrganizationBibleVersionGrant(organizationId: String, grantId: String) =
      execute("in/4.0/organizations/${organizationId}/bible_version_grants/${grantId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Return the created Organization Post resource.
   */
  suspend fun createAdminOrganizationPost(organizationId: String,
      body: AdminOrganizationPostsRequest? = null): AdminOrganizationPost? =
      execute("in/4.0/organizations/${organizationId}/posts", version = "admin", method =
      RequestMethods.POST, authAllowed = true, authOptional = false, body = body, bodySerializer =
      AdminOrganizationPostsRequest.serializer(), serializer = AdminOrganizationPost.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return a collection of Organization Posts.
   */
  suspend fun getAdminOrganizationPosts(
    organizationId: String,
    page: Int? = null,
    pageSize: Int? = null
  ): AdminOrganizationPosts? = execute("in/4.0/organizations/${organizationId}/posts", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = AdminOrganizationPosts.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param postId The id of a post resource to be used in path parameters
   * @return Return the Organization Post resource.
   */
  suspend fun getAdminOrganizationPost(organizationId: String, postId: String):
      AdminOrganizationPost? = execute("in/4.0/organizations/${organizationId}/posts/${postId}",
      method = RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false,
      serializer = AdminOrganizationPost.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param postId The id of a post resource to be used in path parameters
   */
  suspend fun deleteAdminOrganizationPost(organizationId: String, postId: String) =
      execute("in/4.0/organizations/${organizationId}/posts/${postId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param bodyAccept The type of content the client will receive in the body key of the response
   * body.
   * @return Return a collection of Organization Posts.
   */
  suspend fun getOrganizationPosts(
    organizationId: String,
    page: Int? = null,
    pageSize: Int? = null,
    bodyAccept: String? = null
  ): OrganizationPosts = execute("/organizations/${organizationId}/posts", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      bodyAccept?.let { add("body_accept", bodyAccept) }
  }, serializer = OrganizationPosts.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param postId The id of a post resource to be used in path parameters
   * @param bodyAccept The type of content the client will receive in the body key of the response
   * body.
   * @return Return the Organization Post resource.
   */
  suspend fun getOrganizationPost(
    organizationId: String,
    postId: String,
    bodyAccept: String? = null
  ): OrganizationPost? = execute("/organizations/${organizationId}/posts/${postId}", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      bodyAccept?.let { add("body_accept", bodyAccept) }
  }, serializer = OrganizationPost.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param postId The id of a post resource to be used in path parameters
   * @return Return the created Organization Post Action.
   */
  suspend fun createOrganizationPostAction(
    organizationId: String,
    postId: String,
    body: PostActionRequest? = null
  ): PostAction = execute("/organizations/${organizationId}/posts/${postId}/actions", version =
      "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = PostActionRequest.serializer(), serializer = PostAction.serializer()) ?:
      throw NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param postId The id of a post resource to be used in path parameters
   * @return Return a collection of Organization Post Actions.
   */
  suspend fun getOrganizationPostActions(organizationId: String, postId: String): PostActions =
      execute("/organizations/${organizationId}/posts/${postId}/actions", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true, serializer =
      PostActions.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param postId The id of a post resource to be used in path parameters
   * @param actionType The type of action performed on an organization post
   */
  suspend fun deleteOrganizationPostAction(
    organizationId: String,
    postId: String,
    actionType: String
  ) = execute("/organizations/${organizationId}/posts/${postId}/actions/${actionType}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Unit.serializer())

  /**
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result. In some cases, page=* is acceptable if passing a limited
   * list of fields.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param sort Change default sort behavior
   * @return Return a collection of followings for Organizations.
   */
  suspend fun getFollowings(
    page: String? = null,
    pageSize: Int? = null,
    fields: String? = null,
    sort: SortTypeEnum? = null
  ): Followings = execute("/following", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = false, queryString = red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      fields?.let { add("fields", fields) }
      sort?.let { add("sort", sort.serialName) }
  }, serializer = Followings.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Return the id of the Organization followed.
   */
  suspend fun createFollowing(body: SlimFollowing? = null): Following = execute("/following",
      version = "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body
      = body, bodySerializer = SlimFollowing.serializer(), serializer = Following.serializer()) ?:
      throw NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun deleteFollowing(organizationId: String) = execute("/following/${organizationId}",
      method = RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false,
      serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Returns a following object if one exists for the User and this Organization
   */
  suspend fun getFollowing(organizationId: String): Following =
      execute("/following/${organizationId}", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, serializer = Following.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return the Users that are currently following the Organization.
   */
  suspend fun getAdminOrganizationFollowers(
    organizationId: String,
    page: Int? = null,
    pageSize: Int? = null
  ): OrganizationFollowers = execute("in/4.0/organizations/${organizationId}/followers", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = OrganizationFollowers.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param cursor The cursor for pagination. It should be the ID of the last item from the previous
   * page or not present if this is the first page.
   * @param limit How many items to return via cursor based pagination
   * @return Return the Users that are currently following the Organization.
   */
  suspend fun getAdminOrganizationFollowersWithFollowers(
    organizationId: String,
    cursor: Int? = null,
    limit: Int? = null
  ): OrganizationFollowersWithCursor = execute("in/4.1/organizations/${organizationId}/followers",
      method = RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false,
      queryString = red.platform.http.queryString {
      cursor?.let { add("cursor", cursor) }
      limit?.let { add("limit", limit) }
  }, serializer = OrganizationFollowersWithCursor.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param partnerPortalOrganizationId The id of an external partner portal organization entity
   * referenced on an organization's profile
   * @param yvBiblePublisherId The id of YV Bible Publisher organization entity referenced on an
   * organization's profile
   * @param videoPublisherId The id of an external video publisher entity referenced on an
   * organization's profile
   * @return returns content_profiles
   */
  suspend fun getContentPartners(
    page: Int? = null,
    pageSize: Int? = null,
    fields: String? = null,
    partnerPortalOrganizationId: Long? = null,
    yvBiblePublisherId: Long? = null,
    videoPublisherId: Long? = null
  ): ContentPartners = execute("in/4.0/content_partners", method = RequestMethods.GET, version =
      "admin", authAllowed = true, authOptional = false, queryString = red.platform.http.queryString
      {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      fields?.let { add("fields", fields) }
      partnerPortalOrganizationId?.let { add("partner_portal_organization_id",
      partnerPortalOrganizationId) }
      yvBiblePublisherId?.let { add("yv_bible_publisher_id", yvBiblePublisherId) }
      videoPublisherId?.let { add("video_publisher_id", videoPublisherId) }
  }, serializer = ContentPartners.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return return the created content partner
   */
  suspend fun createContentPartner(fields: String? = null, body: ContentPartnerPost? = null):
      ContentPartner = execute("in/4.0/content_partners", version = "admin", method =
      RequestMethods.POST, authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, body = body, bodySerializer = ContentPartnerPost.serializer(), serializer =
      ContentPartner.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param contentPartnerId The organization id.
   * @return return the content partner
   */
  suspend fun getContentPartner(contentPartnerId: String): ContentPartner =
      execute("in/4.0/content_partners/${contentPartnerId}", method = RequestMethods.GET, version =
      "admin", authAllowed = true, authOptional = false, serializer = ContentPartner.serializer())
      ?: throw NullPointerException("Response not sent from server")

  /**
   * @return return the organizations that are featured for the user's language (default english)
   */
  suspend fun getFeaturedOrganizations(): FeaturedOrganizations = execute("/featured_organizations",
      method = RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false,
      serializer = FeaturedOrganizations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param languageTag Specifies the language tag
   * @return return the organizations that are featured for the language tag
   */
  suspend fun getAdminFeaturedOrganizations(languageTag: String): AdminFeaturedOrganizations =
      execute("in/4.0/featured_organizations/${languageTag}", method = RequestMethods.GET, version =
      "admin", authAllowed = true, authOptional = false, serializer =
      AdminFeaturedOrganizations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param languageTag Specifies the language tag
   * @return return the updated featured organizations
   */
  suspend fun updateAdminFeaturedOrganizations(languageTag: String,
      body: AdminFeaturedOrganizations? = null): AdminFeaturedOrganizations =
      execute("in/4.0/featured_organizations/${languageTag}", version = "admin", method =
      RequestMethods.PUT, authAllowed = true, authOptional = false, body = body, bodySerializer =
      AdminFeaturedOrganizations.serializer(), serializer = AdminFeaturedOrganizations.serializer())
      ?: throw NullPointerException("Response not sent from server")

  /**
   * @return Returns all feature flags.
   */
  suspend fun getAdminFeatureFlags(): FeatureFlags? = execute("in/4.0/feature_flags", method =
      RequestMethods.GET, version = "admin", authAllowed = true, authOptional = false, serializer =
      FeatureFlags.serializer())

  /**
   * @return return the created feature flag
   */
  suspend fun createFeatureFlag(body: FeatureFlag? = null): FeatureFlag =
      execute("in/4.0/feature_flags", version = "admin", method = RequestMethods.POST, authAllowed =
      true, authOptional = false, body = body, bodySerializer = FeatureFlag.serializer(), serializer
      = FeatureFlag.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param featureFlagId The id of the feature flag resource.
   * @return Returns all feature flag's principals.
   */
  suspend fun getAdminFeatureFlagPrincipals(featureFlagId: String): FeatureFlagPrincipals? =
      execute("in/4.0/feature_flags/${featureFlagId}/principals", method = RequestMethods.GET,
      version = "admin", authAllowed = true, authOptional = false, serializer =
      FeatureFlagPrincipals.serializer())

  /**
   * @param featureFlagId The id of the feature flag resource.
   * @return return the created principal
   */
  suspend fun createFeatureFlagPrincipal(featureFlagId: String, body: FeatureFlagPrincipal? = null):
      FeatureFlagPrincipal = execute("in/4.0/feature_flags/${featureFlagId}/principals", version =
      "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = FeatureFlagPrincipal.serializer(), serializer =
      FeatureFlagPrincipal.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param featureFlagId The id of the feature flag resource.
   * @param principalId Principal id for a feature flag
   */
  suspend fun deleteFeatureFlagPrincipal(featureFlagId: String, principalId: String) =
      execute("in/4.0/feature_flags/${featureFlagId}/principals/${principalId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param principalId Principal id for a feature flag
   * @return Returns all feature flags for the principal.
   */
  suspend fun getAdminPrincipalFeatureFlags(principalId: String): FeatureFlags? =
      execute("in/4.0/principals/${principalId}/flags", method = RequestMethods.GET, version =
      "admin", authAllowed = true, authOptional = false, serializer = FeatureFlags.serializer())
}
