package youversion.red.churches.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies()
class ChurchesSharedModuleInitializer : IModuleInitializer {
    override fun initialize() {
    }
}
