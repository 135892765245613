// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.impacts

import kotlin.Long
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class MyImpact(
  /**
   * Number of user interactions calculated by API using opaque formula. May be 0.
   */
  @SerialName("interactions")
  @ProtoNumber(1)
  @JvmField
  val interactions: Long? = null,
  /**
   * Number of installs funded calculated by API using opaque formula. May be 0.
   */
  @SerialName("installs")
  @ProtoNumber(2)
  @JvmField
  val installs: Long? = null,
  @SerialName("most_recent_gift_dt")
  @ProtoNumber(3)
  @JvmField
  val mostRecentGiftDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
