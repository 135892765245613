// Auto-Generated from OpenAPI Spec
package youversion.red.settings.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object SettingsDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides", "blog.youversion.com", "web-assets.youversion.com", "lifechurch.formstack.com",
    "help.youversion.com")) {
  private val _listener: AtomicReference<SettingsDeepLinkListener?> = AtomicReference(null)

  var listener: SettingsDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/settings") { context, _, _ -> 
        listener.onSettings(context)
    }
    register("/settings/language") { context, _, _ -> 
        listener.onLanguageSettings(context)
    }
    register("/notifications/edit") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val token = queries["token"]?.firstOrNull()
        listener.onEditNotificationSettings(context, token)
    }
    register("/settings/notifications") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val token = queries["token"]?.firstOrNull()
        listener.onEditNotificationSettings2(context, token)
    }
    register("/notification-settings") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val token = queries["token"]?.firstOrNull()
        listener.onEditNotificationSettings3(context, token)
    }
    register("/notification/settings") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val token = queries["token"]?.firstOrNull()
        listener.onEditNotificationSettings4(context, token)
    }
    register("/settings/kids") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val enable = queries["enable"]?.firstOrNull()?.toBoolean()
        listener.onKidsSettings(context, enable)
    }
    register("/notifications/settings/prayer") { context, _, _ -> 
        listener.onPrayerNotificationsSettings(context)
    }
    freeze()
  }
}
