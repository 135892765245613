// Auto-Generated from OpenAPI Spec
package youversion.red.locales.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Rules(
  @SerialName("cldr_version")
  @ProtoNumber(1)
  @JvmField
  val cldrVersion: String = "",
  @SerialName("created_date")
  @ProtoNumber(2)
  @JvmField
  val createdDate: String = "",
  @SerialName("iana_subtag_registry_date")
  @ProtoNumber(3)
  @JvmField
  val ianaSubtagRegistryDate: String = "",
  @SerialName("url")
  @ProtoNumber(4)
  @JvmField
  val url: String = "",
  @SerialName("version")
  @ProtoNumber(5)
  @JvmField
  val version: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
