// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The type of giving parameter that was updated.
 */
enum class GivingValueType(
  internal val serialName: String,
  internal val serialId: Int
) {
  AMOUNT("amount", 0),

  CURRENCY("currency", 1),

  FREQUENCY("frequency", 2),

  FUND("fund", 3),

  PAYMENT_METHOD("payment_method", 4),

  START_DATE("start_date", 5);
}

internal class GivingValueTypeSerializer : KSerializer<GivingValueType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.givingmikey.api.model.GivingValueType")

  override fun serialize(encoder: Encoder, value: GivingValueType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GivingValueType {
    if (decoder is JsonDecoder) {
      return GivingValueType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GivingValueType.AMOUNT
      1 -> GivingValueType.CURRENCY
      2 -> GivingValueType.FREQUENCY
      3 -> GivingValueType.FUND
      4 -> GivingValueType.PAYMENT_METHOD
      5 -> GivingValueType.START_DATE
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
