// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AnalyticsDevice(
  @SerialName("installation_id")
  @ProtoNumber(1)
  @JvmField
  val installationId: String = "",
  @SerialName("manufacturer")
  @ProtoNumber(2)
  @JvmField
  val manufacturer: String = "",
  @SerialName("model")
  @ProtoNumber(3)
  @JvmField
  val model: String = "",
  @SerialName("product")
  @ProtoNumber(4)
  @JvmField
  val product: String = "",
  @SerialName("platform")
  @ProtoNumber(5)
  @JvmField
  val platform: String = "",
  @SerialName("version")
  @ProtoNumber(6)
  @JvmField
  val version: String = "",
  @SerialName("type")
  @ProtoNumber(7)
  @Serializable(with=youversion.red.dataman.api.model.AnalyticsDevicePlatformSerializer::class)
  @JvmField
  val type: AnalyticsDevicePlatform =
      youversion.red.dataman.api.model.AnalyticsDevicePlatform.UNKNOWN,
  @SerialName("primary_locale")
  @ProtoNumber(8)
  @JvmField
  val primaryLocale: String? = null,
  @SerialName("timezone")
  @ProtoNumber(9)
  @JvmField
  val timezone: String? = null,
  @SerialName("system_name")
  @ProtoNumber(10)
  @JvmField
  val systemName: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
