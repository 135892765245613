// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.featured_organizations

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AdminFeaturedOrganizations(
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val data: List<AdminFeaturedOrganization> = emptyList(),
  @SerialName("status")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.organizations.api.model.admin.featured_organizations.FeaturedOrganizationStatusSerializer::class)
  @JvmField
  val status: FeaturedOrganizationStatus =
      youversion.red.organizations.api.model.admin.featured_organizations.FeaturedOrganizationStatus.UNSUPPORTED_PLACEHOLDER
) : red.Serializable {
  init {
    freeze()
  }
}
