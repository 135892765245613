// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine what type of partner hubspot has classified a profile as
 */
enum class HubspotTypes(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  PASTOR("Pastor", 1),

  CHURCH("Church", 2),

  AUTHOR("Author", 3),

  ORGANIZATION("Organization", 4),

  SOCIAL_MEDIA_INFLUENCER("Social Media Influencer", 5),

  MUSIC_ARTIST("Music Artist", 6),

  PUBLISHER("Publisher", 7),

  INFLUENCING_VOICE("Influencing Voice", 8),

  BIBLE_PUBLISHER("Bible Publisher", 9),

  BIBLE_SOCIETY("Bible Society", 10),

  RECORD_LABEL("Record Label", 11),

  FILM_STUDIO("Film Studio", 12);
}

internal class HubspotTypesSerializer : KSerializer<HubspotTypes> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.organization_profiles.HubspotTypes")

  override fun serialize(encoder: Encoder, value: HubspotTypes) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): HubspotTypes {
    if (decoder is JsonDecoder) {
      return HubspotTypes.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> HubspotTypes.UNSUPPORTED_PLACEHOLDER
      1 -> HubspotTypes.PASTOR
      2 -> HubspotTypes.CHURCH
      3 -> HubspotTypes.AUTHOR
      4 -> HubspotTypes.ORGANIZATION
      5 -> HubspotTypes.SOCIAL_MEDIA_INFLUENCER
      6 -> HubspotTypes.MUSIC_ARTIST
      7 -> HubspotTypes.PUBLISHER
      8 -> HubspotTypes.INFLUENCING_VOICE
      9 -> HubspotTypes.BIBLE_PUBLISHER
      10 -> HubspotTypes.BIBLE_SOCIETY
      11 -> HubspotTypes.RECORD_LABEL
      12 -> HubspotTypes.FILM_STUDIO
      else -> HubspotTypes.UNSUPPORTED_PLACEHOLDER
    }
  }
}
