// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class OrganizationPost(
  /**
   * The id of the post.
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String = "",
  /**
   * The organization id of the organization that this post belongs to.
   */
  @SerialName("organization_id")
  @ProtoNumber(2)
  @JvmField
  val organizationId: String = "",
  /**
   * The title of the post.
   */
  @SerialName("title")
  @ProtoNumber(3)
  @JvmField
  val title: String = "",
  /**
   * The body of the post containing html or markdown based on content type tags only bold, italics,
   * links, headings, numbered lists, and bulleted lists.
   */
  @SerialName("body")
  @ProtoNumber(4)
  @JvmField
  val body: String = "",
  /**
   * The time that this post was created.
   */
  @SerialName("created_dt")
  @ProtoNumber(5)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date = red.platform.now(),
  /**
   * The time that this post was updated.
   */
  @SerialName("updated_dt")
  @ProtoNumber(6)
  @JvmField
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date = red.platform.now()
) : red.Serializable {
  init {
    freeze()
  }
}
