package youversion.red.animations.service

import kotlin.js.JsName
import red.service.ExportedService
import red.service.IService
import youversion.red.animations.api.model.Animation
import youversion.red.animations.api.model.AnimationsPathProviderName

@ExportedService
interface IAnimationsService : IService {
    suspend fun getAnimation(id: Int): Animation?
    @JsName("getAnimationWithName")
    suspend fun getAnimation(id: Int, pathProviderName: AnimationsPathProviderName = AnimationsPathProviderName.LOTTIES): Animation?
}
