// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.users.api.model.UserActions

@Serializable
data class UserActionFailure(
  @SerialName("action")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.users.api.model.UserActionsSerializer::class)
  val action: UserActions = youversion.red.users.api.model.UserActions.MOMENT_WELCOME_START,
  /**
   * optional id of the specific object (plan, church, bible reference, moment, etc.) on which the
   * user took action
   */
  @SerialName("item_id")
  @ProtoNumber(2)
  val itemId: String? = null,
  /**
   * freeform text message with a description of the error which occurred
   */
  @SerialName("message")
  @ProtoNumber(3)
  val message: String? = null
) : Event(), red.Serializable {
  override val key: String = "UserActionFailure"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putString("action", action.serialName)
    itemId?.let { bundle.putString("item_id", itemId) }
    message?.let { bundle.putString("message", message) }
    return bundle
  }
}
