// Auto-Generated from OpenAPI Spec
package youversion.red.bible.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class VersionDownloadFailureEventFireBase(
  @SerialName("version")
  @ProtoNumber(1)
  val version: Int? = null,
  @SerialName("metadata_build")
  @ProtoNumber(2)
  val metadataBuild: Int? = null,
  @SerialName("message")
  @ProtoNumber(3)
  val message: String? = null
) : Event(), red.Serializable {
  override val key: String = "version_download_failure"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    version?.let { bundle.putInt("version", version) }
    metadataBuild?.let { bundle.putInt("metadata_build", metadataBuild) }
    message?.let { bundle.putString("message", message) }
    return bundle
  }
}
