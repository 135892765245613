package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class MomentVotdConfig(@ProtoNumber(1) val iso_639_1: List<String>? = null)

@Serializable
data class MomentImageSize(@ProtoNumber(1) val size: List<Int>? = null) {

    val width: Int
        get() = size?.first() ?: 0

    val height: Int
        get() = size?.last() ?: 0
}

@Serializable
data class MomentImageConfig(
    @ProtoNumber(1) val url: String? = null,
    @ProtoNumber(2) val sizes: List<MomentImageSize>? = null
)

@Serializable
data class VotdBgImageConfig(
    @ProtoNumber(1) val url: String? = null
)

@Serializable
data class MomentImagesConfig(
    @ProtoNumber(1) val verseImages: MomentImageConfig? = null,
    @ProtoNumber(2) val votdBackgroundImage: VotdBgImageConfig? = null
)

@Serializable
data class MomentConfiguration(
    @ProtoNumber(1) val images: MomentImagesConfig? = null,
    @ProtoNumber(2) val votd: MomentVotdConfig? = null
)
