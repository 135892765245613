// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.users

import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class AdminUser(
  /**
   * Unique identifier (user_id)
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Long = 0L,
  /**
   * The user's email address
   */
  @SerialName("email")
  @ProtoNumber(2)
  @JvmField
  val email: String? = null,
  /**
   * Indicates whether or not the user has verified their email.
   */
  @SerialName("verified")
  @ProtoNumber(3)
  @JvmField
  val verified: Boolean? = null,
  /**
   * The first name of a given user.
   */
  @SerialName("first_name")
  @ProtoNumber(4)
  @JvmField
  val firstName: String? = null,
  /**
   * The last name of a given user.
   */
  @SerialName("last_name")
  @ProtoNumber(5)
  @JvmField
  val lastName: String? = null,
  /**
   * The first name of a given user.
   */
  @SerialName("username")
  @ProtoNumber(6)
  @JvmField
  val username: String? = null,
  /**
   * The location of a given user.
   */
  @SerialName("location")
  @ProtoNumber(7)
  @JvmField
  val location: String? = null,
  /**
   * The current language a user is using in the app.
   */
  @SerialName("language_tag")
  @ProtoNumber(8)
  @JvmField
  val languageTag: String? = null,
  /**
   * The current country a user is using in the app in.
   */
  @SerialName("country")
  @ProtoNumber(9)
  @JvmField
  val country: String? = null,
  /**
   * The current timezone of a user.
   */
  @SerialName("timezone")
  @ProtoNumber(10)
  @JvmField
  val timezone: String? = null,
  /**
   * The bio of a given user.
   */
  @SerialName("bio")
  @ProtoNumber(11)
  @JvmField
  val bio: String? = null,
  /**
   * The given user's gender.
   */
  @SerialName("gender")
  @ProtoNumber(12)
  @JvmField
  val gender: Long? = null,
  /**
   * The birthday of a given user.
   */
  @SerialName("birthdate")
  @ProtoNumber(13)
  @JvmField
  val birthdate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The id for the users avatar.
   */
  @SerialName("avatar_image_id")
  @ProtoNumber(14)
  @JvmField
  val avatarImageId: String? = null,
  /**
   * The url of a users avatar.
   */
  @SerialName("avatar_url")
  @ProtoNumber(15)
  @JvmField
  val avatarUrl: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
