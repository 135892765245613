// Auto-Generated from OpenAPI Spec
package youversion.red.events.api.model.saves

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Saves(
  @SerialName("comments")
  @ProtoNumber(1)
  @JvmField
  val comments: List<Save>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
