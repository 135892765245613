// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PostCountry(
  /**
   * Represents the short version of the largest non-global official government entity.
   */
  @SerialName("short_name")
  @ProtoNumber(91)
  @JvmField
  val shortName: String? = null,
  /**
   * Represents the long version of the largest non-global official government entity.
   */
  @SerialName("long_name")
  @ProtoNumber(92)
  @JvmField
  val longName: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
