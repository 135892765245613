// Auto-Generated from OpenAPI Spec
package youversion.red.givingmikey.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.givingmikey.api.model.ExperienceType
import youversion.red.givingmikey.api.model.GivingValueType

@Serializable
data class GivingValueUpdated(
  /**
   * The UI of the giving form. `baseline` is the current experience. `one_screen` is the variation
   * being tested that looks like the Life.Church app.
   */
  @SerialName("experience")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.givingmikey.api.model.ExperienceTypeSerializer::class)
  val experience: ExperienceType? = null,
  /**
   * The type of giving parameter that was updated.
   */
  @SerialName("label")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.givingmikey.api.model.GivingValueTypeSerializer::class)
  val label: GivingValueType? = null,
  /**
   * The value of the giving parameter that was updated.
   */
  @SerialName("value")
  @ProtoNumber(3)
  val value: String? = null
) : Event(), red.Serializable {
  override val key: String = "GivingValueUpdated"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    experience?.let { bundle.putString("experience", experience.serialName) }
    label?.let { bundle.putString("label", label.serialName) }
    value?.let { bundle.putString("value", value) }
    return bundle
  }
}
