package youversion.red.animations.service

import red.service.DefaultService
import red.service.service
import youversion.red.animations.api.AnimationsApi
import youversion.red.animations.api.model.Animation
import youversion.red.animations.api.model.AnimationsPathProviderName

fun AnimationsService() = service<IAnimationsService>()

@DefaultService(IAnimationsService::class)
class AnimationsServiceImpl : IAnimationsService {
    override suspend fun getAnimation(id: Int): Animation? = AnimationsApi.getAnimation(id, AnimationsPathProviderName.LOTTIES)

    override suspend fun getAnimation(id: Int, pathProviderName: AnimationsPathProviderName): Animation? = AnimationsApi.getAnimation(id, pathProviderName)
}
